import { nanoid, customAlphabet } from 'nanoid';
export var UtilsPL2;
(function (UtilsPL2) {
    const userPoolPrefix = 'UserPool_';
    function _isEmpty(obj) {
        if (obj === null || obj === undefined) {
            return true;
        }
        if (Array.isArray(obj) || typeof obj === 'string') {
            return obj.length === 0;
        }
        if (typeof obj !== 'object') {
            return false;
        }
        return obj.constructor === Object && Object.keys(obj).length === 0;
    }
    function isEmpty(o) {
        return _isEmpty(o);
    }
    UtilsPL2.isEmpty = isEmpty;
    // TODO: function to generate a random id to user
    function generateId() {
        return nanoid(14);
    }
    UtilsPL2.generateId = generateId;
    function generateCode() {
        // generate class|student|short code using custom alphabet to disambiguate
        const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
        return customAlphabet(chars, 6)();
    }
    UtilsPL2.generateCode = generateCode;
    function timestamp() {
        return new Date().valueOf().toString();
    }
    UtilsPL2.timestamp = timestamp;
    function excludeEmptyProperties(o) {
        if (typeof o !== 'object') {
            return o;
        }
        if (Array.isArray(o)) {
            return o
                .filter((i) => !isEmpty(i))
                .map((i) => excludeEmptyProperties(i))
                .filter((i) => !isEmpty(i));
        }
        let props = Object.keys(o);
        props = props.filter((prop) => !isEmpty(o[prop]));
        return Object.assign({}, ...props.map((prop) => ({ [prop]: excludeEmptyProperties(o[prop]) })));
    }
    UtilsPL2.excludeEmptyProperties = excludeEmptyProperties;
    function excludeUndefinedProperties(o) {
        const included = {};
        Object.keys(o).forEach((key) => {
            if (o[key] !== undefined) {
                included[key] = o[key];
            }
        });
        return included;
    }
    UtilsPL2.excludeUndefinedProperties = excludeUndefinedProperties;
    function _pick(o, includeEmpty, setEmptyToNull, ...props) {
        if (!includeEmpty) {
            o = excludeEmptyProperties(o);
            props = props.filter((p) => Object.keys(o).some((k) => k === p));
        }
        return Object.assign({}, ...props
            .filter((p) => p in o)
            .map((prop) => ({
            [prop]: isEmpty(o[prop]) && setEmptyToNull ? null : o[prop],
        })));
    }
    function pickWithEmptyArrays(o, ...props) {
        return _pick(o, true, false, ...props);
    }
    UtilsPL2.pickWithEmptyArrays = pickWithEmptyArrays;
    function pick(o, ...props) {
        return _pick(o, true, true, ...props);
    }
    UtilsPL2.pick = pick;
    function pickNoEmptyValue(o, ...props) {
        return _pick(o, false, true, ...props);
    }
    UtilsPL2.pickNoEmptyValue = pickNoEmptyValue;
    function deepEquals(x, y) {
        if (x === y) {
            return true;
        }
        else if (!(x instanceof Object) || !(y instanceof Object)) {
            return false;
        }
        else if (x.constructor !== y.constructor) {
            return false;
        }
        else {
            for (const p in x) {
                if (!x.hasOwnProperty(p)) {
                    continue;
                }
                if (!y.hasOwnProperty(p)) {
                    return false;
                }
                if (x[p] === y[p]) {
                    continue;
                }
                if (typeof x[p] !== 'object') {
                    return false;
                }
                if (!deepEquals(x[p], y[p])) {
                    return false;
                }
            }
            for (const p in y) {
                if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
                    return false;
                }
            }
            return true;
        }
    }
    UtilsPL2.deepEquals = deepEquals;
    function isSameType(a, b) {
        return (Object.prototype.toString.call(a) === Object.prototype.toString.call(b));
    }
    UtilsPL2.isSameType = isSameType;
    function isFirstSubsetOfSecond(first, second) {
        if (second === null || second === undefined) {
            return false;
        }
        return Object.keys(first).every((key) => {
            const sameType = isSameType(first[key], second[key]);
            let sameValue = true;
            if (sameType && isSameType(first[key], {})) {
                sameValue = isFirstSubsetOfSecond(first[key], second[key]);
            }
            else if (sameType && isSameType(first[key], [])) {
                sameValue = first[key].every((v) => second[key].includes(v));
            }
            else {
                sameValue = first[key] === second[key];
            }
            return sameType && sameValue;
        });
    }
    UtilsPL2.isFirstSubsetOfSecond = isFirstSubsetOfSecond;
    function deepCopy(arg) {
        return JSON.parse(JSON.stringify(arg));
    }
    UtilsPL2.deepCopy = deepCopy;
    function searchableStr(str) {
        return typeof str === 'string' ? str.toLowerCase() : null;
    }
    UtilsPL2.searchableStr = searchableStr;
    function isObject(item) {
        return item && typeof item === 'object' && !Array.isArray(item);
    }
    UtilsPL2.isObject = isObject;
    function deepMerge(target, ...sources) {
        if (!sources.length) {
            return target;
        }
        const source = sources.shift();
        if (!target) {
            return deepMerge(Object.assign({}, source), ...sources);
        }
        if (isObject(target) && isObject(source)) {
            for (const key in source) {
                if (isObject(source[key])) {
                    if (!target[key]) {
                        target[key] = {};
                    }
                    deepMerge(target[key], source[key]);
                }
                else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }
        return deepMerge(target, ...sources);
    }
    UtilsPL2.deepMerge = deepMerge;
    UtilsPL2.oBy = {
        asc: 1,
        desc: 2,
    };
    function sortBy(f1, orderBy = UtilsPL2.oBy.asc, f2 = f1, f2OrderBy = UtilsPL2.oBy.asc) {
        return (a, b) => {
            const a1 = (typeof a[f1] === 'string' ? a[f1].toLowerCase() : a[f1]) || 0;
            const b1 = (typeof b[f1] === 'string' ? b[f1].toLowerCase() : b[f1]) || 0;
            const a2 = (typeof a[f2] === 'string' ? a[f2].toLowerCase() : a[f2]) || 0;
            const b2 = (typeof b[f2] === 'string' ? b[f2].toLowerCase() : b[f2]) || 0;
            let d;
            let aO = 0;
            let bO = 0;
            let s;
            if (typeof a[f1] === 'string') {
                let d;
                if (a1 === b1) {
                    d = 0;
                }
                else {
                    d = orderBy === UtilsPL2.oBy.asc ? (a1 < b1 ? -1 : 1) : b1 < a1 ? -1 : 1;
                }
                s = Math.sign(d);
                aO = a2 < b2 ? -1 : 1;
                bO = b2 < a2 ? -1 : 1;
            }
            else {
                const d = orderBy === UtilsPL2.oBy.asc ? a1 - b1 : b1 - a1;
                s = Math.sign(d);
                aO = a2 - b2;
                bO = b2 - a2;
            }
            const f = f2OrderBy === UtilsPL2.oBy.asc ? 1 : -1;
            return orderBy === UtilsPL2.oBy.asc
                ? s + aO * (1 - Math.abs(s)) * f
                : s + bO * (1 - Math.abs(s)) * f;
        };
    }
    UtilsPL2.sortBy = sortBy;
    function getNestedProperty(obj, key) {
        if (isEmpty(key)) {
            return null;
        }
        return key.split('.').reduce(function (o, x) {
            return typeof o === 'undefined' || o === null ? o : o[x];
        }, obj);
    }
    UtilsPL2.getNestedProperty = getNestedProperty;
    function somePromise(promises, p, start = 0) {
        if (isEmpty(promises)) {
            return Promise.resolve(false);
        }
        if (start >= promises.length) {
            return Promise.resolve(false);
        }
        return promises[start]().then((r) => {
            if (p(r)) {
                return Promise.resolve(true);
            }
            else {
                return somePromise(promises, p, start + 1);
            }
        }, (err) => {
            return Promise.reject(err);
        });
    }
    UtilsPL2.somePromise = somePromise;
    function everyPromise(promises, p = (t) => !!t, start = 0) {
        if (isEmpty(promises)) {
            return Promise.resolve(true);
        }
        if (start >= promises.length) {
            return Promise.resolve(true);
        }
        return promises[start]().then((r) => {
            if (p(r)) {
                return everyPromise(promises, p, start + 1);
            }
            else {
                return Promise.resolve(false);
            }
        }, (err) => {
            return Promise.reject(err);
        });
    }
    UtilsPL2.everyPromise = everyPromise;
    UtilsPL2.defaultRegion = 'us-east-1';
    function usernamify(email) {
        if (isEmpty(email)) {
            return email;
        }
        return `${userPoolPrefix}${email.replace(/\./g, '$dot$')}`;
    }
    UtilsPL2.usernamify = usernamify;
    function deusernamify(username) {
        if (isEmpty(username) || !username.startsWith(userPoolPrefix)) {
            return username;
        }
        return username.substring(userPoolPrefix.length).replace(/\$dot\$/g, '.');
    }
    UtilsPL2.deusernamify = deusernamify;
    function studentusernamify(name) {
        if (isEmpty(name)) {
            return name;
        }
        return `${name.replace(/_/g, '$us$').replace(/\ /g, '$ws$').replace(/\@/g, '$at$')}`;
    }
    UtilsPL2.studentusernamify = studentusernamify;
    function destudentusernamify(username) {
        if (isEmpty(username)) {
            return username;
        }
        return username
            .replace(/\$ws\$/g, ' ')
            .replace(/\$us\$/g, '_')
            .replace(/\$at\$/g, '@');
    }
    UtilsPL2.destudentusernamify = destudentusernamify;
    function applyDefaultObject(o, defaultObj) {
        return Object.assign({}, defaultObj, o);
    }
    UtilsPL2.applyDefaultObject = applyDefaultObject;
    function exclude(o, ...keys) {
        const oKeys = Object.keys(o);
        return oKeys.reduce((acc, key) => {
            if (!keys.includes(key)) {
                acc[key] = o[key];
            }
            return acc;
        }, {});
    }
    UtilsPL2.exclude = exclude;
})(UtilsPL2 || (UtilsPL2 = {}));
