import { EntryType } from '../models/models-pl2.js';
import { any, all, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class SectionAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return any(this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */), this.requestInitiatorIs(1 /* RequestInitiator.CopyGatewayDomain */), all(this.tleIsNotReadOnly, this.userIsSetAsCreatorFor(entry), this.userIsParticipantInAuthScope, this._userHasAuthPermissionsIfTleIsCourse()))();
    }
    async canRead(_entry) {
        return any(this.tleIsPublic, this.userIsParticipantInAuthScope)();
    }
    async canUpdate(entry) {
        return all(hasNoBlacklistedAttsIn(entry), this.tleIsNotReadOnly, this.userIsCreatorOf(entry), this.userIsParticipantInAuthScope, this._userHasAuthPermissionsIfTleIsCourse())();
    }
    async canDelete(entry) {
        return all(this.tleIsNotReadOnly, this._userHasAuthPermissionsIfTleIsCourse(), this.userIsPrivilegedParticipantOrParticipantCreatorOf(entry))();
    }
    _userHasAuthPermissionsIfTleIsCourse() {
        return any(this.tleIsNotType(EntryType.Course), this?.user?.auth?.powerTextSection?.canCreate);
    }
}
