import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export const internalServerError = { code: 500, message: 'Unexpected error' };
export const badRequestError = { code: 400, message: 'Invalid parameters' };
export const notFoundError = { code: 404, message: 'Not found' };
export const forbiddenError = { code: 403, message: 'Permission denied' };
export const unauthenticatedError = {
    code: 401,
    message: 'Unauthenticated error',
};
export class PLServerError extends Error {
    constructor(payload) {
        super(payload.message);
        this.payload = payload;
        this.code = payload.code;
    }
}
export class ConsoleErrorSink {
    error(payload) {
        console.error(JSON.stringify(payload));
    }
    debug(message) {
        console.debug(message);
    }
}
export class SNSMsgErroToErrorPayloadAdapter {
    adaptTo(u) {
        if (U.isEmpty(u)) {
            return {};
        }
        let code = Number(u?.code);
        return { message: u.msg, code: Number.isNaN(code) ? 500 : code };
    }
    adaptFrom(u) {
        if (U.isEmpty(u)) {
            return {};
        }
        return { msg: u.message, code: String(u?.code ?? 500) };
    }
}
