import produce from 'immer';

import {Injectable} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';

import {
  Visualization,
  AxesVisibility,
  GraphConfig,
} from '@constants/graph.config';

import {Store} from '@stores/abstract-store';

import {GraphConfigUtils as GCU} from '@utils/graph-config-utils';
import {ChartUtils as CU} from '@utils/chart-utils';
import {UnitUtils as UU} from '@utils/unit-utils';
import {DataConfigStateUtils as DCSU} from '@utils/data-config-state-utils';

@Injectable({
  providedIn: 'root',
})
export class DataConfigStore extends Store<PL2.DataConfigState> {
  readonly maxNonButtons = 4;

  constructor(private translateService: TranslateService) {
    super({});
  }

  updateVisIdx(gCId: string, visIdx: number) {
    const numNonButtonConfigs = DCSU.countNonButtonConfigs(this.state());
    this.setState(
      produce(this.state(), (draft: PL2.DataConfigState) => {
        const graphConfig = GCU.configWithId(gCId);
        if (graphConfig.visualizations[visIdx] === Visualization.Button) {
          draft[gCId] = {...draft[gCId], visIdx: visIdx};
        } else if (
          graphConfig.visualizations[this.dvc(gCId).visIdx] !==
          Visualization.Button
        ) {
          draft[gCId] = {...draft[gCId], visIdx: visIdx};
        } else {
          if (numNonButtonConfigs >= 4) {
            const lastDvcId = DCSU.lastDvc(draft);
            const lastGraphConfig = GCU.configWithId(lastDvcId);
            const buttonIdx = lastGraphConfig.visualizations.findIndex(
              (v) => v === Visualization.Button,
            );
            draft[lastDvcId].visIdx = buttonIdx;
            draft[gCId] = {
              ...draft[gCId],
              visIdx: visIdx,
              pos: draft[lastDvcId].pos,
            };
          } else {
            draft[gCId] = {...draft[gCId], visIdx: visIdx};
          }
        }
      }),
    );
  }

  updateDvcs(opts: Partial<PL2.DataViewConfig>) {
    this.setState(
      produce(this.state(), (draft: PL2.DataConfigState) => {
        Object.keys(this.state()).forEach((gCId) => {
          Object.keys(opts).forEach((attr) => {
            draft[gCId][attr] = opts[attr];
          });
        });
      }),
    );
  }

  updateValIdx(gCId: string, valIdx: number) {
    this.setState(
      produce(this.state(), (draft: PL2.DataConfigState) => {
        const config = GCU.configWithId(gCId);
        if (config.axesVisibility === AxesVisibility.Single) {
          const dsV = draft[gCId].dsV.map((_v: boolean, i: number) => {
            if (config.axesModulus) {
              return (i - valIdx) % config.axesModulus === 0;
            } else {
              return i === valIdx;
            }
          });
          draft[gCId].dsV = dsV;
          draft[gCId].valIdx = valIdx;
        } else if (config.axesVisibility === AxesVisibility.Multiple) {
          draft[gCId].dsV[valIdx] = !this.dvc(gCId).dsV[valIdx];
        }
      }),
    );
  }

  clearPoints(startingValues: PL2.DataViewConfig) {
    this.setState(
      produce(this.state(), (draft: PL2.DataConfigState) => {
        Object.keys(this.state()).forEach((gCId) => {
          draft[gCId].sD = String(new Date().getTime());
          draft[gCId].p = [];
          draft[gCId].dS = startingValues.dS ?? draft[gCId].dS;
          draft[gCId].cT = 0;
          draft[gCId].pH = this.state()[gCId].p;
        });
      }),
    );
  }

  record() {
    this.clearPoints({dS: PL2.DataStatus.Record});
  }

  clear() {
    if (DCSU.isRealtime(this.state())) {
      this.clearPoints({dS: PL2.DataStatus.Setup});
      return;
    }
    this.destroyData();
  }

  destroyData() {
    this.setState({});
  }

  addDataConfigs(opts: PL2.DataConfigState) {
    this.setState(
      produce(this.state(), (draft: PL2.DataConfigState) => {
        Object.keys(this.state()).forEach((gCId) => {
          if (opts[gCId] === null) {
            delete draft[gCId];
          } else {
            draft[gCId].sD = String(new Date().getTime());
            draft[gCId].p = [];
            draft[gCId].cT = 0;
          }
        });
        const translations = this.translateService.instant('graph-config');
        DCSU.buildDataConfigs(opts, draft, translations);
      }),
    );
  }

  removeDataConfigs(gCIds: string[]) {
    this.addDataConfigs(
      gCIds.reduce((acc: PL2.DataConfigState, gCId: string) => {
        acc[gCId] = null;
        return acc;
      }, {}),
    );
  }

  sendFrequencyIndex(frequencyIdx: number) {
    this.setState(
      produce(this.state(), (draft: PL2.DataConfigState) => {
        Object.keys(draft).forEach((gCId) => {
          draft[gCId].sD = String(new Date().getTime());
          draft[gCId].fIdx = frequencyIdx;
          draft[gCId].p = [];
          draft[gCId].cT = 0;
        });
      }),
    );
  }

  dvc(gCId: string): PL2.DataViewConfig {
    return this.state()[gCId];
  }

  convertedPoints(graphConfig: GraphConfig): PL2.Point[] {
    const gCId = graphConfig.id;
    return this.dvc(gCId).p.map((p: PL2.Point) =>
      UU.convertPoint(graphConfig, p, this.dvc(gCId).uIdxs),
    );
  }

  yLabel(graphConfig: GraphConfig): string {
    const units = UU.currentUnitsForConfig(
      graphConfig,
      this.dvc(graphConfig.id).valIdx,
      this.dvc(graphConfig.id).uIdxs,
    );
    return CU.addUnits(this.dvc(graphConfig.id).yL, units);
  }

  sendThirdPartyReading(readings: PL2.Point[], gCId: string) {
    if (!this._inUpdatableState()) {
      return;
    }
    this.setState(
      produce(this.state(), (draft: PL2.DataConfigState) => {
        if (!U.isEmpty(draft[gCId])) {
          draft[gCId].p = readings;
        }
      }),
    );
  }

  setLocation(geolocation: PL2.Location) {
    if (!this._inUpdatableState()) {
      return;
    }
    this.setState(
      produce(this.state(), (draft: DataConfigStore) => {
        Object.values(draft).forEach(
          (dvc: PL2.DataViewConfig) => (dvc.loc = geolocation),
        );
      }),
    );
  }

  private _inUpdatableState(): boolean {
    if (U.isEmpty(this.state())) {
      return true;
    }
    const dvc = Object.values(this.state())[0] as PL2.DataViewConfig;
    return dvc.dS === PL2.DataStatus.Setup || dvc.dS === PL2.DataStatus.Record;
  }
}
