import {RouteState} from '@cloudlab/stores/route.store';
import {ConnectionState} from '@common/ble/dist/index.js';
import {PL2, TypeUtilsPL2 as TU} from '@common/utils/dist/index.js';
import {StoreEventsPipes as SEP} from '@cloudlab/utils/store-events-pipes';

export type VideoTutorialTrigger = {
  route?: Partial<SEP.StoreEvent<RouteState, SEP.RouteStoreEvents>>;
  user?: Partial<SEP.StoreEvent<PL2.User, SEP.UserStoreEvents>>;
  device?: TU.DeepPartial<
    SEP.StoreEvent<ConnectionState, SEP.ConnectionStoreEvents>
  >;
};

export type VideoTutorialCondition = {
  route?: Partial<RouteState>;
  user?: Partial<PL2.User>;
  device?: TU.DeepPartial<ConnectionState>;
};

export type TriggersAndConditions = {
  triggers: Array<VideoTutorialTrigger>;
  conditions: Array<VideoTutorialCondition>;
};

export interface VideoTutorial {
  id: string;
  videoUrl: string;
  title: string;
  activation: TriggersAndConditions;
  deactivation?: TriggersAndConditions;
  learnMoreUrl?: string;
}

export const videoTutorialConfig: VideoTutorial[] = [
  {
    id: 'assignment-tutorial',
    videoUrl:
      'https://www.loom.com/share/92873d3a911c4befa328d8fb2bfe277b?sid=f8223883-6223-4015-be7f-53c1f81951f7',
    title: 'How to Use the Assignment Dashboard',
    activation: {
      triggers: [
        {
          route: {
            event: SEP.RouteStoreEvents.RouteChanged,
            state: {pageName: 'assignment'},
          },
        },
        {
          user: {
            event: SEP.UserStoreEvents.UserUpdated,
            state: {tutorial: {'assignment-tutorial': undefined}},
          },
        },
      ],
      conditions: [
        {
          user: {role: PL2.Role.Pro},
          route: {pageName: 'assignment'},
        },
        {
          user: {role: PL2.Role.Lite},
          route: {pageName: 'assignment'},
        },
      ],
    },
    deactivation: {
      triggers: [
        {route: {event: SEP.RouteStoreEvents.RouteChanged, state: {}}},
      ],
      conditions: [],
    },
    learnMoreUrl: 'https://support.thepocketlab.com/knowledge/auto-scoring',
  },
  {
    id: 'odyssey-tutorial',
    videoUrl:
      'https://www.loom.com/share/f553cc3d66f94a37b622df0639205162?sid=d3a67681-2aef-400b-a54a-891eb8b0e748',
    title: 'Getting Started with Odyssey',
    activation: {
      triggers: [
        {
          device: {
            event: SEP.ConnectionStoreEvents.DeviceConnected,
            state: {deviceConfig: {id: 'odyssey'}},
          },
        },
        {
          user: {
            event: SEP.UserStoreEvents.UserUpdated,
            state: {tutorial: {'odyssey-tutorial': undefined}},
          },
        },
      ],
      conditions: [
        {
          user: {role: PL2.Role.Pro},
          device: {deviceConfig: {id: 'odyssey'}},
        },
        {
          user: {role: PL2.Role.Lite},
          device: {deviceConfig: {id: 'odyssey'}},
        },
      ],
    },
    deactivation: {
      triggers: [
        {route: {event: SEP.RouteStoreEvents.RouteChanged, state: {}}},
        {
          device: {
            event: SEP.ConnectionStoreEvents.DeviceDisconnected,
            state: null,
          },
        },
      ],
      conditions: [],
    },
    learnMoreUrl:
      'https://support.thepocketlab.com/knowledge/getting-started-with-pocketlab-odyssey',
  },
];
