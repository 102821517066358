import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {map, timeout, catchError, take} from 'rxjs/operators';

import {APIError} from '@cloudlab/utils/api-errors';
import {APIService} from '@services/api.service';
import {PendingOperation} from '@stores/entry-store-automaton/operation-state-machine';

import {EntryStore} from '@stores/entry.store';
import {APIStateStore} from '@stores/api-state.store';

import {
  APIMessages as APIM,
  PL2,
  KeyUtilsPL2 as KU,
  UtilsPL2 as U,
  ErrorUtils as ErrU,
} from '@common/utils/dist/index.js';

import {ReceiveEventAcknowledgementAction} from '@actions/receive-event-acknowledgement.action';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EntryApiService {
  private readonly _requestTimeout = 8000;

  constructor(
    private api: APIService,
    private entryStore: EntryStore,
    private receiveEventAcknowledgementAction: ReceiveEventAcknowledgementAction,
    private apiStateStore: APIStateStore,
  ) {}

  /* *****************************************************
   * fallback methods for users without access to websockets
   ********************************************************/
  fetch(
    pK: string,
    opts: {
      single?: boolean;
      uEPK?: string;
      ro?: boolean;
      watchCtx?: boolean;
      eSK?: {[key: string]: any};
    } = {},
  ): Observable<void> {
    const queryStringParameters: any = U.excludeEmptyProperties({
      mId: KU.pKFromString(pK).mId,
      sK: KU.pKFromString(pK).sK,
      idx: 'master',
      single: opts.single || null,
      uEPK: opts.uEPK,
      watchCtx: opts.watchCtx || null,
      eSK: opts.eSK ? JSON.stringify(opts.eSK) : null,
    });
    return this.api
      .get(environment.entryAPIGatewayName, '/q', {
        queryStringParameters: queryStringParameters,
      })
      .pipe(
        map((entries) => {
          this.apiStateStore.online();
          this.entryStore.modifyState(entries as PL2.AnyPartialEntryWithId[]);
        }),
      );
  }

  sendEvent(request: APIM.EntryEventRequest): Observable<void> {
    return this.api
      .post(environment.entryAPIGatewayName, '/event', {
        body: {
          requestData: request,
        },
      })
      .pipe(
        map(
          (res: {
            e: ErrU.ErrorPayload;
            c: {payload: Array<APIM.EntryChange>};
          }) =>
            this.receiveEventAcknowledgementAction.execute({
              ...res.c,
              error: res.e,
            }),
        ),
        map(() => void 0),
        timeout(this._requestTimeout),
        catchError((err) => {
          console.error(err);
          return of(APIError.buildError(err));
        }),
        take(1),
      );
  }
  /* *****************************************************
   * end of fallback methods
   ********************************************************/

  fetchPublic(pK: string): Observable<void> {
    const queryStringParameters: any = U.excludeEmptyProperties({
      mId: KU.pKFromString(pK).mId,
      sK: KU.pKFromString(pK).sK,
      idx: 'master',
    });
    return this.api
      .getPublic(environment.entryAPIGatewayName, '/p/q', {
        queryStringParameters: queryStringParameters,
      })
      .pipe(
        map((entries) =>
          this.entryStore.modifyState(entries as PL2.AnyPartialEntryWithId[]),
        ),
      );
  }

  fetchUserLibraryEntries(
    userId: string,
    type: PL2.EntryType,
    uEPK?: string,
  ): Observable<void> {
    const uLEId = KU.uLEId(userId, type);
    return this.api
      .get(environment.entryAPIGatewayName, '/q', {
        queryStringParameters: {
          uLEId: uLEId,
          idx: 'libraryEntries',
          uEPK: uEPK,
        },
      })
      .pipe(
        map((userEntries: Array<PL2.AnyPartialEntryWithId>) => {
          this.entryStore.modifyState(userEntries);
        }),
      );
  }

  fetchClass(pK: string, opts: {ro?: string} = {}): Observable<void> {
    const qs = {
      queryStringParameters: {
        mId: KU.pKFromString(pK).mId,
        cSK: KU.pKFromString(pK).sK,
        idx: 'class',
      },
    };
    let get: any;
    if (!U.isEmpty(opts.ro)) {
      get = this.api.getPublic(environment.entryAPIGatewayName, '/p/q', qs);
    } else {
      get = this.api.get(environment.entryAPIGatewayName, '/q', qs);
    }
    return get.pipe(
      map((clsIdxEntries: PL2.AnyPartialEntryWithId[]) => {
        this.entryStore.modifyState(clsIdxEntries);
      }),
    );
  }

  fetchClassEntry(classCode: string): Observable<Partial<PL2.ClassEntry>> {
    return this.api
      .getPublic(environment.entryAPIGatewayName, '/p/q', {
        queryStringParameters: {
          cC: classCode,
          idx: 'classCode',
        },
      })
      .pipe(map((result) => (!!result ? result[0] : null)));
  }

  fetchSourceEntry(srcId: string): Observable<Array<PL2.EntryId>> {
    return this.api
      .get(environment.entryAPIGatewayName, '/q', {
        queryStringParameters: {
          srcId: srcId,
          idx: 'source',
        },
      })
      .pipe(
        map((result: Array<PL2.EntryId>) =>
          U.isEmpty(result) ? null : result,
        ),
      );
  }

  fetchReferenceEntries(referenceable: PL2.Referenceable): Observable<void> {
    return this.api
      .get(environment.entryAPIGatewayName, '/q', {
        queryStringParameters: {
          ...referenceable,
          idx: 'reference',
        },
      })
      .pipe(
        map((clsIdxEntries: PL2.AnyPartialEntryWithId[]) => {
          this.entryStore.modifyState(clsIdxEntries);
        }),
      );
  }

  fetchLmsAssignment(
    lmsAssignable: PL2.LmsAssignable,
  ): Observable<PL2.EntryId> {
    return this.api
      .get(environment.entryAPIGatewayName, '/q', {
        queryStringParameters: {
          ...lmsAssignable,
          idx: 'lmsAssignment',
        },
      })
      .pipe(map((result) => (U.isEmpty(result) ? null : result[0])));
  }

  fetchClasses(): Observable<void> {
    return this.api
      .post(environment.entryAPIGatewayName, '/classes', {
        queryStringParameters: {
          sync: '1',
        },
      })
      .pipe(
        map((userEntries: Array<PL2.ClassEntry>) => {
          this.entryStore.modifyState(userEntries);
        }),
      );
  }

  batchGet(entryIds: PL2.EntryId[]): Observable<void> {
    return this.api
      .post(environment.entryAPIGatewayName, '/q', {
        body: {
          requestData: entryIds,
        },
      })
      .pipe(
        map((entries: PL2.EntryId[]) => this.entryStore.modifyState(entries)),
      );
  }

  queryOS<Q extends PL2.OpenSearchQuery>(
    qp: Q,
    opts?: {unauth?: boolean},
  ): Observable<void> {
    if (!!opts?.unauth) {
      return this._anonymousQueryOS(qp);
    }
    const queryStringParameters: any = U.excludeEmptyProperties(qp);
    return this.api
      .get(environment.opensearchAPIGatewayName, '/q', {
        queryStringParameters: queryStringParameters,
      })
      .pipe(
        map((entries) =>
          this.entryStore.modifyState(entries as PL2.AnyPartialEntryWithId[]),
        ),
      );
  }

  acceptInvitation(
    invite: PL2.EntryId | PL2.LmsAssignable,
  ): Observable<PL2.AnyEntry[]> {
    return this.api
      .put(environment.entryAPIGatewayName, '/invitation', {
        body: {
          requestData: {i: invite},
        },
      })
      .pipe(
        map((change: PL2.AnyEntry[]) => {
          this.entryStore.modifyState(change);
          return change;
        }),
      );
  }

  activate(request: APIM.EntryDeleteRequest): Observable<any> {
    request.undo = true;
    return this.api
      .delete(environment.directEntryAPIGatewayName, '/entry', {
        body: {
          requestData: request,
        },
      })
      .pipe(
        timeout(this._requestTimeout),
        catchError((err) => {
          console.error(err);
          return of(APIError.buildError(err));
        }),
        take(1),
        map((status) => {
          const input = APIError.instanceOfError(status)
            ? PendingOperation.None
            : PendingOperation.Activate;

          this.entryStore.advanceCachedEntryState(
            request.e as PL2.EntryId[],
            input,
          );
        }),
      );
  }

  private _anonymousQueryOS<Q extends PL2.OpenSearchQuery>(
    qp: Q,
  ): Observable<void> {
    const queryStringParameters: any = U.excludeEmptyProperties(qp);
    return this.api
      .getPublic(environment.opensearchAPIGatewayName, '/p/q', {
        queryStringParameters: queryStringParameters,
      })
      .pipe(
        map((entries) =>
          this.entryStore.modifyState(entries as PL2.AnyPartialEntryWithId[]),
        ),
      );
  }
}
