import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  APIMessages as APIM,
  PL2,
  UtilsPL2 as U,
} from '@common/utils/dist/index.js';

import {S3Service} from './s3.service';

@Injectable()
export class WSMessageReceiverService {
  constructor(private s3Service: S3Service) {}

  receive(msg: APIM.WebSocketMessage): Observable<APIM.WebSocketMessage> {
    const entries = msg.c;
    const metadata = (
      entries.length > 0 ? entries[0] : {}
    ) as PL2.MetadataEntry;
    let readMessage = () => of(msg);
    if (
      !U.isEmpty(entries) &&
      metadata.t === PL2.EntryType.Metadata &&
      !U.isEmpty(metadata.p?.s3k)
    ) {
      readMessage = () => this._receive(metadata.p.s3k);
    }
    return readMessage();
  }

  private _receive(key: string): Observable<APIM.WebSocketMessage> {
    return this.s3Service
      .getFile(key)
      .pipe(map((response: string) => JSON.parse(response)));
  }
}
