import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';

import {
  SharedDataTableType,
  activateViewPageName,
  trialsViewPageName,
  classViewPageName,
  assignmentViewPageName,
  userViewPageName,
  lessonViewPageName,
  sampleSharedData,
  sampleScatterPlotData,
  sampleBarSharedData,
  defaultOptSharedDataCells,
  sampleMapData,
  sheetNameIdPrefix,
} from '@constants/app.config';

import {RouteState} from '@stores/route.store';

export const numberRegex = /^-?([0-9]+)(\.\d+)?$/;
export const labReportRegex = /^.*(lab.report\/)(.*)([\w-])(\?ro=1)/;
export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export function nDP(n: number, num: number): number {
  const i = Math.pow(10, n || 1);
  if (typeof num === 'number') {
    if (n === -1) {
      return num;
    }
    return Math.round(num * i) / i;
  }
  return null;
}

export function to3DP(num: number): number {
  if (typeof num === 'number') {
    return Math.round(num * 1000) / 1000;
  }
  return null;
}

export function containerIdFromUrl(url: string): string {
  const q = url.split('/')[4];
  if (!U.isEmpty(q)) {
    const containerID = q.split('?')[0];
    return containerID.length === 16 ? containerID : null;
  } else {
    return null;
  }
}

export function scrollTo(id: string) {
  const container = document.getElementById(id);
  if (container) {
    container.scrollIntoView();
  }
  return;
}

export function lastElement<T>(arr: Array<T>): T {
  return arr[arr.length - 1];
}

export function removeLastElement<T>(arr: Array<T>): Array<T> {
  return arr.slice(0, -1);
}

export function generateDAField(columns: Array<PL2.DATableColumn>) {
  let cNum = 0;
  const le = lastElement(columns);
  if (le) {
    const lastCNum = parseInt(le.f.split('c')[1], 10);
    if (!isNaN(lastCNum)) {
      cNum = lastCNum;
    }
  }
  return `c${cNum + 1}`;
}

export function isTrialsView(routeState: RouteState): boolean {
  return routeState.pageName === trialsViewPageName;
}

export function isClassView(routeState: RouteState): boolean {
  return routeState.pageName === classViewPageName;
}

export function isAssignmentView(routState: RouteState): boolean {
  return routState.pageName === assignmentViewPageName;
}

export function isLessonView(routState: RouteState): boolean {
  return routState.pageName === lessonViewPageName;
}

export function isUserView(routeState: RouteState): boolean {
  return routeState.pageName === userViewPageName;
}

export function defaultDataTable(
  userId: string,
  dT: SharedDataTableType,
  sheetIdx: number,
): {[key: string]: PL2.DataTableCell} {
  let data: {[key: string]: PL2.DataTableCell} = {};
  switch (dT) {
    case SharedDataTableType.DataTable:
      data = sampleSharedData;
      break;
    case SharedDataTableType.BarGraph:
      data = sampleBarSharedData;
      break;
    case SharedDataTableType.ScatterPlot:
      data = sampleScatterPlotData;
      break;
    case SharedDataTableType.Map:
      data = sampleMapData;
      break;
    default:
      console.error('Weird shared table data type selected.');
  }
  const defaultData: {[key: string]: PL2.DataTableCell} = {};
  const ts = String(new Date().getTime());
  Object.keys(data).forEach((key) => {
    const id = `${key}_${sheetIdx}`;
    defaultData[id] = {...data[key], id: id, uId: userId, ts: ts, s: sheetIdx};
  });
  return defaultData;
}

export function isValidFloatAttr(value: string): boolean {
  return !U.isEmpty(value) && ['left', 'right'].includes(value);
}

export function defaultDataTableOpts(
  userId: string,
  dataSetTranslation: string,
): {[key: string]: PL2.DataTableCell} {
  const data: {[key: string]: PL2.DataTableCell} = {};
  const ts = String(new Date().getTime());
  Object.keys(defaultOptSharedDataCells).forEach((id) => {
    data[id] = {...defaultOptSharedDataCells[id], uId: userId, ts: ts};
  });
  data[sheetNameOptsId(0)].value = `${dataSetTranslation} 1`;
  return data;
}

export function sheetNameOptsId(sheetIdx: number): string {
  return `${sheetNameIdPrefix}${sheetIdx}`;
}

export function each<T>(
  obj: Array<T>,
  iteratee: (item: T, key: number, c: Array<T>) => void,
);
export function each<T>(
  obj: {[k: string]: T},
  iteratee: (item: T, key: string, c: {[k: string]: T}) => void,
);
export function each(obj: any, iteratee: (item, key, collection) => void) {
  let i, length;
  if (Array.isArray(obj)) {
    for (i = 0, length = obj.length; i < length; i++) {
      iteratee(obj[i], i, obj);
    }
  } else {
    const _keys = Object.keys(obj);
    for (i = 0, length = _keys.length; i < length; i++) {
      iteratee(obj[_keys[i]], _keys[i], obj);
    }
  }
  return obj;
}

export function enumFromValue<T extends Record<string, string>>(
  val: string,
  _enum: T,
) {
  const enumName = enumNameFromValue(val, _enum);
  // if (!enumName) throw Error() // here fail fast as an example
  return _enum[enumName];
}

export function enumNameFromValue<T extends Record<string, string>>(
  val: string,
  _enum: T,
) {
  const enumName = (Object.keys(_enum) as Array<keyof T>).find(
    (k) => _enum[k] === val,
  );
  return enumName;
}
