export const Role = {
    Lite: 0,
    Pro: 1,
    Student: 2,
    Unconfirmed: 3,
};
export const CategoryDevice = [
    'Voyager',
    'Weather',
    'Air',
    'Termo',
    'G-Force',
    'Odyssey',
];
export var CategoryPrefix;
(function (CategoryPrefix) {
    CategoryPrefix["Grade"] = "__grd__";
    CategoryPrefix["Sensor"] = "__sen__";
    CategoryPrefix["Alignment"] = "__alg__";
})(CategoryPrefix || (CategoryPrefix = {}));
export const sectionRange = ['20', '39'];
export const EntryType = {
    // 0 - 9 - top level entries
    Class: '00',
    LabReport: '01',
    StudentCopy: '02',
    Assignment: '03',
    ClassScope: '05',
    //GroupScope: '06',
    IndividualScope: '06',
    Course: '07',
    LocalSubset: '-0',
    ClassLocalSubset: '-1',
    Module: '-2',
    Curriculum: '08',
    // 10 - 19 - user entries
    StudentUser: '11',
    LabReportUser: '12',
    InvitedLabReportUser: '13',
    UninvitedLabReportUser: '14',
    ScopeUser: '15',
    // 20 - 39 - sections
    TrialSection: '20',
    VideoSection: '21',
    ImageSection: '22',
    TextSection: '23',
    DataAnalysisSection: '24',
    QuestionSection: '25',
    CollectDataSection: '26',
    GoogleDriveSection: '27',
    SharedDataTableSection: '28',
    IFrameSection: '29',
    PhetSection: '30',
    SharedDrawingSection: '32',
    DiscussionSection: '33',
    SpeechToTextSection: '34',
    SharedDragAndDropSection: '35',
    SharedLineMatchingSection: '36',
    // 40 - 49 - section children
    AnswerSection: '40',
    Comment: '41',
    Transcript: '42',
    // 50 - 59 - misc
    GlobalResource: '50',
    Trial: '51',
    Metadata: '#>',
    TeacherNotes: '53',
    License: '54',
    ClassPermission: '55',
};
export var SectionScope;
(function (SectionScope) {
    SectionScope[SectionScope["Class"] = 0] = "Class";
    SectionScope[SectionScope["Group"] = 1] = "Group";
    SectionScope[SectionScope["Individual"] = 2] = "Individual";
    SectionScope[SectionScope["Unknown"] = 3] = "Unknown";
})(SectionScope || (SectionScope = {}));
export var QuickFeedback;
(function (QuickFeedback) {
    QuickFeedback["WorkingOnIt"] = "wO";
    QuickFeedback["AlmostThere"] = "aT";
    QuickFeedback["YouGotIt"] = "yGI";
    QuickFeedback["AboveAndBeyond"] = "aB";
})(QuickFeedback || (QuickFeedback = {}));
export var DisplayMode;
(function (DisplayMode) {
    DisplayMode[DisplayMode["Standard"] = 0] = "Standard";
    DisplayMode[DisplayMode["Inverted"] = 1] = "Inverted";
})(DisplayMode || (DisplayMode = {}));
export var DataStatus;
(function (DataStatus) {
    DataStatus[DataStatus["Setup"] = 0] = "Setup";
    DataStatus[DataStatus["Record"] = 1] = "Record";
    DataStatus[DataStatus["Recorded"] = 2] = "Recorded";
    DataStatus[DataStatus["Play"] = 3] = "Play";
})(DataStatus || (DataStatus = {}));
export const EntryAction = {
    Create: 0,
    Read: 1,
    Update: 2,
    Delete: 3,
    UndoDelete: 4,
    DeepDelete: 5,
    BatchUpdate: 6,
    CreateUninitialized: 7,
    CreateTempInvite: 8,
};
export var OpenSearchQueryType;
(function (OpenSearchQueryType) {
    OpenSearchQueryType[OpenSearchQueryType["PKRange"] = 0] = "PKRange";
    OpenSearchQueryType[OpenSearchQueryType["UserLibrary"] = 1] = "UserLibrary";
    OpenSearchQueryType[OpenSearchQueryType["EntryType"] = 2] = "EntryType";
    OpenSearchQueryType[OpenSearchQueryType["CurriculumLessons"] = 3] = "CurriculumLessons";
    OpenSearchQueryType[OpenSearchQueryType["MyLessons"] = 4] = "MyLessons";
    OpenSearchQueryType[OpenSearchQueryType["AllContent"] = 5] = "AllContent";
    OpenSearchQueryType[OpenSearchQueryType["EditedLessons"] = 6] = "EditedLessons";
    OpenSearchQueryType[OpenSearchQueryType["Custom"] = 7] = "Custom";
})(OpenSearchQueryType || (OpenSearchQueryType = {}));
