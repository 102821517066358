import {Injectable} from '@angular/core';
import {APIStateStore, APIState} from '@cloudlab/stores/api-state.store';

@Injectable()
export class StorageWrapperService {
  constructor(private apiStateStore: APIStateStore) {}

  get(key: string, config?: any): Promise<String | Object> {
    return this._storage().then((storage) => {
      return storage.get(key, config).then(
        (response) => {
          this.apiStateStore.setAPIState(APIState.Default);
          return response;
        },
        (err) => {
          this._setApiState(err);
          return Promise.reject(err);
        },
      );
    });
  }

  put(key: string, object: any, config?: any): Promise<Object> {
    return this._storage().then((storage) => {
      return storage.put(key, object, config).then(
        (response) => {
          this.apiStateStore.setAPIState(APIState.Default);
          return response;
        },
        (err) => {
          this._setApiState(err);
          return Promise.reject(err);
        },
      );
    });
  }

  remove(key: string, config?: any): Promise<any> {
    return this._storage().then((storage) => {
      return storage.remove(key, config).then(
        (response) => {
          this.apiStateStore.setAPIState(APIState.Default);
          return response;
        },
        (err) => {
          this._setApiState(err);
          return Promise.reject(err);
        },
      );
    });
  }

  private _storage(): Promise<any> {
    return import('@aws-amplify/storage').then(
      ({Storage}) => {
        this.apiStateStore.setAPIState(APIState.Default);
        return Storage;
      },
      (err) => {
        this._setApiState(err);
        return Promise.reject(err);
      },
    );
  }

  private _setApiState(error?: any): void {
    console.debug(JSON.stringify(error));
    if (error.name === 'ChunkLoadError' || error.isAxiosError) {
      this.apiStateStore.setAPIState(APIState.Offline);
    } else {
      this.apiStateStore.setState(APIState.Default);
    }
  }
}
