import { ValidatorFactory, } from './validator-core.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { EntryValidatorFactory } from './entry-validator-factory.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { UserLibraryEntryIdxEntryValidatorFactory } from './user-library-entry-idx-entry-validator-factory.js';
import { LenientValidator } from './lenient-validator.js';
import { AuthIdxEntryValidatorFactory } from './auth-idx-entry-validator-factory.js';
import { ClassEntryValidatorFactory } from './class-entry-validator-factory.js';
export class ClassPermissionEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.classPermissionEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'mUId':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'cPT':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            default:
                if (EU.entryAtts.includes(attr)) {
                    return new EntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.userLibraryEntryIdxEntryAtts.includes(attr)) {
                    return new UserLibraryEntryIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.authIdxEntryAtts.includes(attr)) {
                    return new AuthIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.classEntryAtts.includes(attr)) {
                    return new ClassEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else {
                    throw Error(`Attr: ${attr} shouldn\'t exist`);
                }
        }
    }
}
