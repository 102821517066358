import { Role } from '../models/models-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { ClaimsUserToNotebookUserAdapter, UserPoolUserToNotebookUserAdapter, } from './user-utils-pl2.js';
export function buildStudent(name, other) {
    const student = {
        name: name,
        role: Role.Student,
    };
    if (!U.isEmpty(other)) {
        return { ...other, ...student };
    }
    else {
        return student;
    }
}
export function buildStudents(names) {
    return names.map((name) => buildStudent(name));
}
export function isSameStudentEntry(oUser, nUser) {
    return ((!U.isEmpty(oUser.mUId) && oUser.mUId === nUser.mUId) ||
        (!U.isEmpty(oUser.gPId) && oUser.gPId === nUser.gPId));
}
export function isPro(user) {
    return !U.isEmpty(user) && user.role === Role.Pro;
}
export function isStudent(user) {
    return !U.isEmpty(user) && user.role === Role.Student;
}
export function isUpgradable(user) {
    return !U.isEmpty(user) && user.role === Role.Lite;
}
export function isUnconfirmed(user) {
    return !U.isEmpty(user) && user.role === Role.Unconfirmed;
}
export function isTeacher(user) {
    return isPro(user) || isUpgradable(user);
}
export function isGoogleUser(user) {
    return !U.isEmpty(user) && !U.isEmpty(user.googleProfileId);
}
export function isUserpoolUser(user) {
    return (isTeacher(user) &&
        U.isEmpty(user.googleProfileId) &&
        U.isEmpty(user.classlinkId));
}
export function isClasslinkUser(user) {
    return isTeacher(user) && !U.isEmpty(user.classlinkId);
}
export function isEdUser(user) {
    return !U.isEmpty(user) && !U.isEmpty(user.edId);
}
export function extractUser(event) {
    console.log(event);
    const claimsUser = event.requestContext?.authorizer?.claims || {};
    const user = new ClaimsUserToNotebookUserAdapter().adaptTo(claimsUser);
    return U.excludeEmptyProperties(user);
}
export function extractUserFromToken(token) {
    console.log(`Token: ${token}`);
    const attributes = buildUserFromIdToken(token);
    const adapter = new UserPoolUserToNotebookUserAdapter();
    const user = adapter.adaptTo(attributes);
    return U.excludeEmptyProperties(user);
}
export function buildUserFromIdToken(idToken) {
    if (!idToken) {
        return null;
    }
    const base64Url = idToken.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const buff = Buffer.from(base64, 'base64');
    const payloadinit = buff.toString('ascii');
    return JSON.parse(payloadinit);
}
export function userIds(user) {
    return [
        user.userId,
        user.googleProfileId,
        user.classlinkId,
        user.sourcedId,
        user.edId,
        user.edlinkId,
    ].filter((id) => !U.isEmpty(id));
}
export function preferredIdForFetchLmsAId(user) {
    return (user.edId ??
        user.edlinkId ??
        user.googleProfileId ??
        user.sourcedId ??
        user.userId);
}
export function fixEmail(email) {
    if (U.isEmpty(email)) {
        return null;
    }
    return email.trim().toLowerCase();
}
export function preferredIdForFetchClasses(user) {
    if (U.isEmpty(user?.userId)) {
        // invalid user
        return null;
    }
    const ids = [];
    let id = user.edId ?? user.edlinkId;
    if (!!user.googleProfileId) {
        ids.push(user.googleProfileId);
    }
    id = id ?? user.userId;
    ids.push(id);
    return ids;
}
export * from '../adapters/user-attributes.js';
export * from '../adapters/user-adapters.js';
