import { AuthUtils as AU } from '../../index.js';
export function FilterChainFilterPolicy(filters) {
    return (entry, index, array, thisArg) => {
        return filters.some((f) => f(entry, index, array, thisArg));
    };
}
export function LicenseFilterPolicy(user) {
    return (ci) => {
        return AU.canReadWithLicense(user, ci);
    };
}
export function OwnerFilterPolicy(user) {
    return (oe) => {
        return AU.isEntryOwner(user, oe);
    };
}
export function pocketlabLibraryFilterPolicy(item) {
    return item.mId === '3ZsE6Df7' && item.sK.startsWith('0008UzW7');
}
export function CanReadLessonFilter(user) {
    return () => {
        return !!user?.auth?.lesson?.canRead;
    };
}
export function EntryPublicFilter() {
    return (lr) => {
        return AU.isPublic([lr]);
    };
}
export function buildPolicyChain(user) {
    return FilterChainFilterPolicy([
        OwnerFilterPolicy(user),
        LicenseFilterPolicy(user),
        pocketlabLibraryFilterPolicy,
    ]);
}
