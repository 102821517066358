export var APIVersionUtils;
(function (APIVersionUtils) {
    APIVersionUtils.version = 1;
    function isCurrent(v) {
        let nV;
        if (typeof v === 'string') {
            nV = Number(v);
        }
        else {
            nV = v;
        }
        return nV === APIVersionUtils.version;
    }
    APIVersionUtils.isCurrent = isCurrent;
})(APIVersionUtils || (APIVersionUtils = {}));
