import { all, any, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class ClassPermissionAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return all(this.userIsSetAsCreatorFor(entry), any(this.userIsClassAdminForTle, this.requestInitiatorIs(8 /* RequestInitiator.PermissionAdderCreateWrapper */), this.requestInitiatorIs(5 /* RequestInitiator.ExodusSnsActions */)))();
    }
    async canRead(_entry) {
        return any(this.userIsPrivilegedParticipantInAuthScope, !!this.user?.auth?.lesson?.canRead)();
    }
    async canUpdate(entry) {
        return all(this.userIsClassAdminForTle, hasNoBlacklistedAttsIn(entry))();
    }
    async canDelete(entry) {
        return any(this.requestInitiatorIs(5 /* RequestInitiator.ExodusSnsActions */), this.userIsClassAdminForTle, all(this.userIsTleAdmin, this.userIsMemberUserOf(entry)))();
    }
}
