import { ValidatorFactory, } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { LenientValidator } from './lenient-validator.js';
import { BaseLabReportEntryValidatorFactory } from './base-lab-report-entry-validator-factory.js';
import { DashboardIdxEntryValidatorFactory } from './dashboard-idx-entry-validator-factory.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { ThemableValidatorFactory } from './themable-validator-factory.js';
export class StudentCopyEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.studentCopyEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'lGI':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'aG':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'nIA':
            case 'tIAt':
                return [new LenientValidator(value, this.builderFactory(attr))];
            default:
                if (EU.baseLabReportEntryAtts.includes(attr)) {
                    return new BaseLabReportEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.themableAtts.includes(attr)) {
                    return new ThemableValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else {
                    return new DashboardIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
        }
    }
}
