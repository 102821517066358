import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
export function buildContainer(entries, skip = () => false, reset = true) {
    const history = [];
    if (entries.length === 0) {
        return null;
    }
    const rootNodeIdx = KU.parseSortKey(entries[0].sK).nodeIds.length - 1;
    entries.forEach((e, i) => {
        const sortKey = KU.parseSortKey(e.sK);
        const nodes = sortKey.nodeIds.slice(rootNodeIdx);
        if (skip({ e: e, i: i, es: entries, sortKey: sortKey, nodesToTLE: nodes })) {
            console.log(`[skipFunction]skipped: ${e.sK}`);
            return;
        }
        const eCont = { e: e, c: [] };
        // console.debug('Entries to copy: ' + e.sK);
        // console.debug('Node length: ' + nodes.length);
        if (i === 0) {
            // set tle container
            history[0] = eCont;
        }
        else {
            if (!!history[nodes.length - 2]) {
                // console.debug(`adding child to ${history[nodes.length - 2].e.t}`)
                history[nodes.length - 2].c.push(eCont);
            }
            history[nodes.length - 1] = eCont;
        }
        if (reset) {
            eCont.e = EU.resetEntry(e);
        }
    });
    return history[0];
}
