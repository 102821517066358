import {EntryState, EntryStore} from '@stores/entry.store';
import {ArrayBuilderHandler} from './array-builder-handler';
import {AssignmentUpdateHandler} from './assignment-update-handler';
import {EntryEventHandler, EntryEventChainOutput} from './entry-event-core';

export class EntryEventChain
  implements EntryEventHandler<EntryEventChainOutput, EntryEventChainOutput>
{
  nextHandler = this;

  private _firstHandler: EntryEventHandler<EntryState, any>;

  constructor(entryStore: EntryStore) {
    this._firstHandler = new ArrayBuilderHandler(entryStore);
    this._firstHandler.nextHandler = new AssignmentUpdateHandler(entryStore);
    this._firstHandler.nextHandler.nextHandler = this;
  }

  handle(input: EntryEventChainOutput): EntryEventChainOutput {
    return input;
  }

  execute(input: EntryState): EntryEventChainOutput {
    return this._firstHandler.handle(input);
  }
}
