import { ValidatorFactory, } from './validator-core.js';
export class FailAllValidatorFactory extends ValidatorFactory {
    constructor(builderFactory, cause) {
        super(builderFactory);
        this.builderFactory = builderFactory;
        this.cause = cause;
        this.failValidator = {
            isValid: () => false,
            reasons: () => [{ why: this.cause }],
        };
    }
    getInstance(o) {
        return Object.assign({}, ...Object.keys(o).map((k) => ({ [k]: [this.failValidator] })));
    }
    getAttributeValidators(attr, value) {
        return [this.failValidator];
    }
}
