import { all } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class UninvitedLabReportUserAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return all(this.userIsSetAsCreatorFor(entry), this.userIsTeacher)();
    }
    async canRead(_entry) {
        return this.userIsParticipantInAuthScope;
    }
    async canUpdate(entry) {
        return all(this.tleIsNotReadOnly, this.userIsCreatorOf(entry))();
    }
    async canDelete(entry) {
        return this.userIsCreatorOf(entry)();
    }
}
