import {Injectable, Inject} from '@angular/core';

import {Store} from '@stores/abstract-store';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

export interface RetryOperationState {
  operation?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RetryOperationStore extends Store<RetryOperationState> {
  readonly currentKey = 'retry-operation';

  constructor(@Inject('STORAGE') private localStorage: Storage) {
    super({});
  }

  shouldRetry(operation: string): boolean {
    return !U.isEmpty(this.state()) && this.state().operation === operation;
  }

  saveToStorage() {
    this.localStorage.setItem(this.currentKey, JSON.stringify(this.state()));
  }

  loadStorage() {
    let parsedState = null;
    try {
      parsedState = JSON.parse(
        this.localStorage.getItem(this.currentKey) || '{}',
      );
    } catch (err) {
      console.warn(err);
      parsedState = {};
    }

    if (!U.isEmpty(parsedState)) {
      this.setState(parsedState);
    }
  }
}
