import { EntryType } from '../models/models-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class UserEntryIndexManager {
    constructor(uId) {
        this.uId = uId;
    }
    setIndexAttributes(entry) {
        const output = entry;
        if (this._isIndexable(entry)) {
            output.uLEId = `${output.iId || output.mUId || this.uId}_${entry.t}`;
        }
        return output;
    }
    _isIndexable(entry) {
        switch (entry.t) {
            case EntryType.LabReport:
                return U.isEmpty(KU.parentSortKey(entry.sK));
            case EntryType.Trial:
            case EntryType.Class:
            case EntryType.LabReportUser:
            case EntryType.InvitedLabReportUser:
            case EntryType.Assignment:
            case EntryType.StudentUser:
            case EntryType.Curriculum:
            case EntryType.License:
            case EntryType.ClassPermission:
                return true;
            case EntryType.Course:
                return U.isEmpty(KU.parentSortKey(entry.sK));
            default:
                return false;
        }
    }
}
