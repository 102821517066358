import {Injectable} from '@angular/core';
import {NEVER, Observable} from 'rxjs';
import {APIMessages as APIM} from '@common/utils/dist/index.js';
import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';
import {ReceiveEventAcknowledgementAction} from '@cloudlab/actions/receive-event-acknowledgement.action';

@Injectable()
export class AcknowledgedMessageProcessor implements WebsocketMessageProcessor {
  constructor(
    private receiveEventAcknowledgementAction: ReceiveEventAcknowledgementAction,
  ) {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const msg = params.msg;
    if (msg.target === '/entry.ws') {
      params.wsAPI.acknowledge(msg.id);
      const request = msg.c as APIM.EntryChangeRequest;
      return this.receiveEventAcknowledgementAction.execute({
        payload: request.payload,
        error: msg.e,
      });
    }
    return NEVER;
  }
}
