import { DeviceConfigs, portSensitiveDevices, } from './device.config.js';
export var DeviceConfigUtils;
(function (DeviceConfigUtils) {
    function configWithId(deviceConfigId) {
        return DeviceConfigs.find((dConfig) => dConfig.id === deviceConfigId);
    }
    DeviceConfigUtils.configWithId = configWithId;
    function isAir(deviceConfig) {
        return deviceConfig.name === 'Air';
    }
    DeviceConfigUtils.isAir = isAir;
    function isPL1(sensorId) {
        return Object.keys(configWithId('pl1').sensorConfigs).some((c) => c === sensorId);
    }
    DeviceConfigUtils.isPL1 = isPL1;
    function getDeviceNames() {
        return Array.from(new Set(DeviceConfigs.map((dc) => dc.name)));
    }
    DeviceConfigUtils.getDeviceNames = getDeviceNames;
    function isThermo(sensorId) {
        return sensorId === 'pl4';
    }
    DeviceConfigUtils.isThermo = isThermo;
    function isNewThermo(fw) {
        return fw === 'TH.1.5';
    }
    DeviceConfigUtils.isNewThermo = isNewThermo;
    function isNewAir(fw) {
        return fw === 'A.1.0' || fw === 'A.1.1';
    }
    DeviceConfigUtils.isNewAir = isNewAir;
    function fwBytesToString(dv) {
        const bytes = new Uint8Array(dv.buffer);
        return String.fromCharCode(...bytes);
    }
    DeviceConfigUtils.fwBytesToString = fwBytesToString;
    function isPortSensitive(deviceConfig) {
        return portSensitiveDevices.includes(deviceConfig?.id);
    }
    DeviceConfigUtils.isPortSensitive = isPortSensitive;
})(DeviceConfigUtils || (DeviceConfigUtils = {}));
export function nDP(n, num) {
    const i = Math.pow(10, n || 1);
    if (typeof num === 'number') {
        if (n === -1) {
            return num;
        }
        return Math.round(num * i) / i;
    }
    return null;
}
function _isEmpty(obj) {
    if (obj === null || obj === undefined) {
        return true;
    }
    if (Array.isArray(obj) || typeof obj === 'string') {
        return obj.length === 0;
    }
    if (typeof obj !== 'object') {
        return false;
    }
    return obj.constructor === Object && Object.keys(obj).length === 0;
}
export function isEmpty(o) {
    return _isEmpty(o);
}
