import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';

import {nDP} from '@utils/utils';
import {ChartUtils as CU} from '@utils/chart-utils';

export interface TransformState {
  values: Array<number>;
  points: (PL2.IndependentVariable | number)[][];
  pointHistory: (PL2.IndependentVariable | number)[][];
  time: number;
  zero: boolean;
}

export type SingleMethodClass = new (...args: any[]) => Transform;

export class PointTransformerPipeline {
  private _transforms = new Array<Transform>();

  constructor(transforms: SingleMethodClass[] = []) {
    this._transforms = transforms.map((t) => new t());
  }

  execute(state: TransformState): TransformState {
    this._transforms.forEach((step) => (state = step.execute(state)));
    return state;
  }
}

export interface Transform {
  execute(state: TransformState): TransformState;
}

abstract class SliceValues implements Transform {
  constructor(
    private startIdx: number,
    private endIdx?: number,
  ) {}

  execute(state: TransformState): TransformState {
    state.values = state.values.slice(this.startIdx, this.endIdx);
    return state;
  }
}

export class Zero implements Transform {
  private _zeroedVales: Array<number>;

  execute(state: TransformState): TransformState {
    if (state.zero) {
      this._zeroedVales = state.values;
    }
    state.values = state.values.map(
      (v, i) => v - ((this._zeroedVales || [])[i] || 0),
    );
    return state;
  }
}

export class ZeroOneTwoValues extends SliceValues {
  constructor() {
    super(0, 3);
  }
}

export class ThreeFourFiveValues extends SliceValues {
  constructor() {
    super(3, 6);
  }
}

export class SixSevenEightValues extends SliceValues {
  constructor() {
    super(6, 9);
  }
}

export class FirstValue extends SliceValues {
  constructor() {
    super(0, 1);
  }
}

export class SecondValue extends SliceValues {
  constructor() {
    super(1, 2);
  }
}

export class ThirdValue extends SliceValues {
  constructor() {
    super(2, 3);
  }
}

export class ZeroOneValues extends SliceValues {
  constructor() {
    super(0, 2);
  }
}

export class EuclideanNorm implements Transform {
  execute(state: TransformState): TransformState {
    const v = state.values;
    state.values = [
      Math.sqrt(Math.pow(v[0], 2) + Math.pow(v[1], 2) + Math.pow(v[2], 2)),
    ];
    return state;
  }
}

export class EuclideanNormAcc implements Transform {
  execute(state: TransformState): TransformState {
    const v = state.values;
    state.values = [
      Math.sqrt(Math.pow(v[0], 2) + Math.pow(v[1], 2) + Math.pow(v[2], 2)) - 1,
    ];
    return state;
  }
}

export class Altitude implements Transform {
  execute(state: TransformState): TransformState {
    const v = state.values;
    // Convert to kPa first by dividing by 10
    state.values = [
      (Math.pow(((v[0] / 10) * 1000) / 101325.0, 1.0 / 5.25588) - 1) /
        -2.25577e-5,
    ];
    return state;
  }
}

export class DewPoint implements Transform {
  execute(state: TransformState): TransformState {
    const v = state.values;
    // Convert to kPa first by dividing by 10
    state.values = [
      (243.04 * (Math.log(v[2] / 100) + (17.625 * v[1]) / (243.04 + v[1]))) /
        (17.625 - Math.log(v[2] / 100) - (17.625 * v[1]) / (243.04 + v[1])),
    ];
    return state;
  }
}

export class HeatIndex implements Transform {
  execute(state: TransformState): TransformState {
    const v = state.values;
    const tempC = v[1];
    const temp = tempC * (9 / 5) + 32;
    const hum = v[2];
    let hi = 0.5 * (temp + 61.0 + (temp - 68.0) * 1.2 + hum * 0.094);
    if ((hi + temp) / 2 > 80) {
      hi =
        -42.379 +
        2.04901523 * temp +
        10.14333127 * hum -
        0.22475541 * temp * hum -
        0.00683783 * temp * temp -
        0.05481717 * hum * hum +
        0.00122874 * temp * temp * hum +
        0.00085282 * temp * hum * hum -
        0.00000199 * temp * temp * hum * hum;
      if (hum < 13 && 80 < temp && temp < 112) {
        hi =
          hi - ((13 - hum) / 4) * Math.sqrt((17 - Math.abs(temp - 95.0)) / 17);
      } else if (hum > 85 && 80 < temp && temp < 87) {
        hi = hi + ((hum - 85) / 10) * ((87 - temp) / 5);
      }
    } else if (tempC <= 0) {
      hi = temp;
    }
    state.values = [(hi - 32) * (5 / 9)];
    return state;
  }
}

export class Raf implements Transform {
  private _previousValue = [0, 0, 0];

  execute(state: TransformState): TransformState {
    const dTime =
      state.points.length > 0
        ? state.time - (state.points[state.points.length - 1][0] as number)
        : 1;
    const value = state.values;
    let vel = (value[0] - this._previousValue[0]) / dTime;
    vel = isFinite(vel) ? vel : 0;
    let acc = (vel - this._previousValue[1]) / dTime;
    acc = isFinite(acc) ? acc : 0;
    state.values = [value[0], vel, acc];
    this._previousValue = [...state.values];
    return state;
  }
}

export class VoyThr implements Transform {
  execute(state: TransformState): TransformState {
    const value = state.values;
    const vOut = value[0] * 0.0035;
    const vRef = 3.0;
    const rRef = 10000.0;
    const tRef = 298.15;
    const b = 3380.0;
    const rTherm = rRef * (1.0 / (vRef / vOut - 1.0));
    const l = Math.log(rRef / rTherm);
    const tCalc = (tRef * b) / l / (b / l - tRef);
    state.values = [tCalc - 273.15];
    return state;
  }
}

export class VoyTp implements Transform {
  execute(state: TransformState): TransformState {
    const value = state.values;
    const V_out = (value[0] * 3.0) / 1024;
    const R_p = (10000.0 * (V_out / 3.0)) / (1 - V_out / 3.0);
    const Cond = 1000.0 * (1 / R_p);
    state.values = [100 * (Cond / 10.14)];
    return state;
  }
}

export class TenMinuteAverage implements Transform {
  execute(state: TransformState): TransformState {
    const lastPoint = state.points[state.points.length - 1];
    const secondlastPoint = state.points[state.points.length - 2];
    const secondsInTenMin = 600;
    let tenMinPtIdx = 0;
    let historyPointsIndex = -1;
    if (secondlastPoint) {
      const frequency =
        1 / (CU.timeFromPoint(lastPoint) - CU.timeFromPoint(secondlastPoint));
      const numberOfPointsRequired = secondsInTenMin / frequency;
      tenMinPtIdx = Math.max(state.points.length - numberOfPointsRequired, 0);
      if (tenMinPtIdx === 0) {
        if (!U.isEmpty(state.pointHistory)) {
          const historyPointsRequired =
            numberOfPointsRequired - state.points.length;
          historyPointsIndex = Math.max(
            state.pointHistory.length - historyPointsRequired,
            0,
          );
        }
      }
    }
    const tenMinPoints = state.points.slice(tenMinPtIdx);
    if (historyPointsIndex !== -1) {
      tenMinPoints.push(...state.pointHistory.slice(historyPointsIndex));
    }
    const values = state.values;
    const sum = tenMinPoints.reduce(
      (a: Array<number>, cv) => a.map((na, i) => na + (cv[i + 1] as number)),
      values.map(() => 0),
    ) as Array<number>;
    state.values = [
      ...values,
      ...sum.map((s, i) => (s + values[i]) / (tenMinPoints.length + 1)),
    ];
    return state;
  }
}

export class Aqi implements Transform {
  execute(state: TransformState): TransformState {
    const value = state.values;
    let cRange;
    let aqiRange;

    const partics = nDP(1, value[1]);
    if (!partics || (0.0 <= partics && partics <= 12.0)) {
      cRange = [0.0, 12.0];
      aqiRange = [0.0, 50.0];
    } else if (12.1 <= partics && partics <= 35.4) {
      cRange = [12.1, 35.4];
      aqiRange = [51.0, 100.0];
    } else if (35.5 <= partics && partics <= 55.4) {
      cRange = [35.5, 55.4];
      aqiRange = [101.0, 150.0];
    } else if (55.5 <= partics && partics <= 150.4) {
      cRange = [55.5, 150.4];
      aqiRange = [151.0, 200.0];
    } else if (150.5 <= partics && partics <= 250.4) {
      cRange = [150.5, 250.4];
      aqiRange = [201.0, 300.0];
    } else if (250.5 <= partics && partics <= 350.4) {
      cRange = [250.5, 350.4];
      aqiRange = [301.0, 400.0];
      // } else if (350.5 <= partics && partics <= 500.4) { // an upper limit might cause an undefined
    } else if (350.5 <= partics) {
      cRange = [350.5, 500.4];
      aqiRange = [401.0, 500.0];
    }
    const particsIdx = nDP(
      1,
      ((aqiRange[1] - aqiRange[0]) / (cRange[1] - cRange[0])) *
        (partics - cRange[0]) +
        aqiRange[0],
    );
    state.values = [particsIdx];
    return state;
  }
}

export class Switch implements Transform {
  execute(state: TransformState): TransformState {
    state.values = state.values.reverse();
    return state;
  }
}

export class Abs implements Transform {
  execute(state: TransformState): TransformState {
    state.values = state.values.map((v) => Math.abs(v));
    return state;
  }
}

export class PascalsToMBar implements Transform {
  execute(state: TransformState): TransformState {
    state.values = state.values.map((v) => v / 100);
    return state;
  }
}

export class VoltageToTactilePressure implements Transform {
  execute(state: TransformState): TransformState {
    state.values = state.values.map((v) => {
      if (v <= 0.31) {
        return 772.1 * Math.exp(-1.073 * v);
      } else {
        return 1951.8 * Math.exp(-5.218 * v);
      }
    });
    return state;
  }
}

export class AmpsToMilliAmps implements Transform {
  execute(state: TransformState): TransformState {
    state.values = state.values.map((v) => v * 1000);
    return state;
  }
}
