import {Injectable, OnDestroy} from '@angular/core';

import {ReplaySubject, Observable, of} from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  timeout,
  catchError,
} from 'rxjs/operators';

import {HttpService} from './http.service';

import {ErrorReportStore, ErrorReport} from '@stores/error-report.store';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';
import {APIError} from '@utils/api-errors';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorReportApiService implements OnDestroy {
  private _destroyed$ = new ReplaySubject<boolean>();

  private readonly _requestTimeout = 8000;

  constructor(
    private httpService: HttpService,
    private errorReportStore: ErrorReportStore,
    private console: APIError.Console,
  ) {
    this.errorReportStore.debounceState$
      .pipe(
        filter((state) => !U.isEmpty(state)),
        mergeMap((state) => {
          const errs: ErrorReport[] = Object.values(state);
          return this.create(errs);
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  create(errors: ErrorReport[]): Observable<any> {
    if (!U.isEmpty(errors)) {
      const data = JSON.stringify(errors);
      return this.httpService
        .post(environment.errorReportAPIEndpointUrl, data)
        .pipe(
          timeout(this._requestTimeout),
          catchError((err) => {
            this.console.error(err);
            return of(APIError.buildError(err));
          }),
          take(1),
          map((status) => {
            if (status === 200) {
              this.errorReportStore.clear();
            }
          }),
        );
    }
  }
}
