import {Injectable, OnDestroy} from '@angular/core';

import {ReplaySubject} from 'rxjs';
import {
  takeUntil,
  distinctUntilChanged,
  skipWhile,
  auditTime,
  map,
  pairwise,
} from 'rxjs/operators';

import {UserStore} from '@cloudlab/stores/user.store';
import {RouteStore} from '@cloudlab/stores/route.store';
import {EntryStore} from '@cloudlab/stores/entry.store';
import {ConnectionStore} from '@cloudlab/stores/connection.store';
import {DataConfigStore} from '@cloudlab/stores/data-config.store';

import {PosthogWrapperService} from './posthog-wrapper.service';

import {
  UtilsPL2 as U,
  UserUtilsPL2 as UU,
  KeyUtilsPL2 as KU,
  PL2,
} from '@common/utils/dist/index.js';

import {LabReportUtils as LRU} from '@cloudlab/utils/lab-report-utils';

@Injectable({
  providedIn: 'root',
})
export class PosthogApiService implements OnDestroy {
  private _destroyed$ = new ReplaySubject<void>();

  private readonly _auditTime = 10000;

  constructor(
    private userStore: UserStore,
    private routeStore: RouteStore,
    private entryStore: EntryStore,
    private connectionStore: ConnectionStore,
    private dataConfigStore: DataConfigStore,
    private posthogWrapperService: PosthogWrapperService,
  ) {
    this.userStore.state$
      .pipe(
        skipWhile((u) => U.isEmpty(u)),
        distinctUntilChanged((a, b) => a?.userId === b?.userId),
        takeUntil(this._destroyed$),
      )
      .subscribe((user) => {
        if (U.isEmpty(user?.userId)) {
          this.posthogWrapperService.reset();
        } else {
          const profileProperties: {[key: string]: string | string[]} = {};
          if (UU.isStudent(user)) {
            profileProperties.role = 'student';
          } else {
            profileProperties.role = 'teacher';
            profileProperties.licenses = Object.keys(user.curriculums ?? {});
            profileProperties.domain = user.email?.split('@')[1];
            profileProperties.identityProvider =
              user.identityProvider ?? 'notebook';
          }
          this.posthogWrapperService.identify(user.userId, profileProperties);
        }
      });

    this.entryStore.state$
      .pipe(
        auditTime(this._auditTime),
        map((entryState) => {
          const lastAuditTime = String(new Date().getTime() - this._auditTime);
          const entries = Object.values(entryState);
          entries.forEach((cE) => {
            const entry = <PL2.AnyEntry>cE.e;
            if (U.isEmpty(entry)) {
              return;
            }
            if (entry.cAt > lastAuditTime) {
              if (KU.isType(PL2.EntryType.Assignment, entry)) {
                this._captureLessonLike(
                  'assignment_created',
                  entry,
                  entry.refId,
                );
              } else if (LRU.isVersion(entry, this.entryStore.state())) {
                this._captureLessonLike('version_created', entry, entry.refId);
              }
            } else if (cE._ts > lastAuditTime) {
              if (LRU.isVersionByAuthor(entry)) {
                if (
                  this.routeStore.state()?.containerId ===
                  KU.stringFromKey(entry)
                ) {
                  this._captureLessonLike('primary_viewed', entry, entry.srcId);
                }
              }
            }
          });
        }),
      )
      .subscribe();

    this.dataConfigStore.state$
      .pipe(
        auditTime(this._auditTime),
        map((dcs) => Object.keys(dcs ?? {})),
        pairwise(),
        map(([prevGCIds, newGCIds]) => {
          const distinctGCIds = newGCIds.filter(
            (gCId) => prevGCIds.indexOf(gCId) < 0,
          );
          if (distinctGCIds.length > 0) {
            this.posthogWrapperService.capture('graphs_used', {
              graphs: distinctGCIds,
              device: this.connectionStore.state()?.deviceConfig?.id,
              first: prevGCIds.length === 0 ? '1' : '0',
            });
          }
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _captureLessonLike(
    eventName: string,
    entry: PL2.LabReportEntry | PL2.AssignmentEntry,
    srcId: string,
  ) {
    this.posthogWrapperService.capture(
      eventName,
      U.excludeEmptyProperties({
        curriculumMId: entry.mCats?.[1],
        lessonName: entry.n,
        lessonSrcId: srcId,
      }),
    );
  }
}
