import { MessageType, ProcessorStatus, } from './connection-types.js';
import { PowerlabDataPacketPipeline } from './powerlab-data-packet-processor.js';
export class CrashModeDataProcessorStrategy {
    constructor(deviceConfig) {
        this._crashPacketId = 0x98;
        this._chunkInterval = 0.06;
        this.dataPacketPipeline = new PowerlabDataPacketPipeline(deviceConfig);
    }
    process(dataView) {
        if (dataView.getUint8(0) === 0x25) {
            if (dataView.byteLength < 3) {
                console.log('Erroneous packet: ');
                console.log(dataView);
                return [];
            }
            let status;
            switch (dataView.getUint8(2)) {
                case 0x00:
                    status = ProcessorStatus.CrashModeStatusIdle;
                    break;
                case 0x87:
                    status = ProcessorStatus.CrashModeStatusWaiting;
                    break;
                case 0x88:
                    status = ProcessorStatus.CrashModeStatusRecording;
                    break;
                case 0x89:
                    status = ProcessorStatus.CrashModeStatusComplete;
                    break;
                default:
                    status = ProcessorStatus.CrashModeStatusOff;
            }
            return [
                {
                    type: MessageType.Status,
                    status: status,
                },
            ];
        }
        else if (dataView.getUint8(0) !== this._crashPacketId) {
            return [];
        }
        return this.dataPacketPipeline.process(dataView, this._chunkInterval);
    }
}
