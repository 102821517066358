import { ValidatorFactory, } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { UserEntryValidatorFactory } from './user-entry-validator-factory.js';
import { UserLibraryEntryIdxEntryValidatorFactory } from './user-library-entry-idx-entry-validator-factory.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { AuthIdxEntryValidatorFactory } from './auth-idx-entry-validator-factory.js';
import { DashboardIdxEntryValidatorFactory } from './dashboard-idx-entry-validator-factory.js';
import { TurnInAbleValidatorFactory } from './turn-in-able-validator-factory.js';
export class LabReportUserEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.labReportUserEntryAtts, ...EU.turnInAbleAtts, 'iId');
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'clsN':
            case 'cN':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'lGI':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'pI':
            case 'sE':
            case 'iId':
            case 'lmsAId':
            case 'lmsCId':
            case 'lmsUId':
                return [new LenientValidator(value, this.builderFactory(attr))];
            default:
                if (EU.dashboardIdxEntryAtts.includes(attr)) {
                    return new DashboardIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.authIdxEntryAtts.includes(attr)) {
                    return new AuthIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.userLibraryEntryIdxEntryAtts.includes(attr)) {
                    return new UserLibraryEntryIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.turnInAbleAtts.includes(attr)) {
                    return new TurnInAbleValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else {
                    return new UserEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
        }
    }
}
