import {
  DeviceConfig,
  SensorReading,
  SensorInitialization,
} from '@common/ble/dist/index.js';

import {
  StateTransformer,
  PartialStateTransformer,
} from '@stores/abstract-store';

import {NotificationState} from '../notification.store';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

import {SensorMapperUtils as SMU} from '@utils/sensor-mapper-utils';

export class SensorReadingsTransformer
  implements StateTransformer<SensorReading[], NotificationState>
{
  constructor(private deviceConfig: DeviceConfig) {}

  transform(
    data: SensorReading[],
    notificationState: NotificationState,
  ): NotificationState {
    if (U.isEmpty(notificationState)) {
      return notificationState;
    }
    const modification = data.reduce((acc, reading) => {
      const sensor = this.deviceConfig.sensorConfigs[reading.sensorId];
      if (
        !U.isEmpty(sensor.incorrectSetupIndicator) &&
        reading.values?.[0]?.[1] !== sensor.incorrectSetupIndicator
      ) {
        const gCIds = SMU.getGraphConfigIdsForReading(reading);
        gCIds.forEach((gCId) => (acc[gCId] = undefined));
      }
      return acc;
    }, {} as NotificationState);
    return U.excludeUndefinedProperties({
      ...notificationState,
      ...modification,
    });
  }
}

export class SensorSetupInstructionTransformer
  implements PartialStateTransformer<SensorInitialization[], NotificationState>
{
  nextHandler: PartialStateTransformer<
    SensorInitialization[],
    NotificationState
  >;
  constructor(private deviceConfig: DeviceConfig) {}

  transform(
    data: SensorInitialization[],
    notificationState: NotificationState,
  ): NotificationState {
    const modification = data.reduce((acc, reading) => {
      const sensor = this.deviceConfig.sensorConfigs[reading.sensorId];
      if (U.isEmpty(sensor?.setupInstruction)) {
        return acc;
      }
      const gCIds = SMU.getGraphConfigIdsForReading(reading);
      gCIds.forEach((gCId) => (acc[gCId] = {content: sensor.setupInstruction}));
      return acc;
    }, {} as NotificationState);
    return this.nextHandler.transform(data, {
      ...notificationState,
      ...modification,
    });
  }
}

export class AdjustedSensorParamsTransformer
  implements PartialStateTransformer<SensorInitialization[], NotificationState>
{
  nextHandler: PartialStateTransformer<
    SensorInitialization[],
    NotificationState
  >;
  constructor(private frequencyIdxRequested: number) {}

  transform(
    inits: SensorInitialization[],
    notificationState: NotificationState,
  ): NotificationState {
    const prefix = 'connection-data-config.';
    const sensorsAdjusted = inits.some((i) => i.failed);
    const frequencyAdjusted = inits
      .filter((i) => !i.failed)
      .some((init) => init.frequencyIdx !== this.frequencyIdxRequested);
    if (frequencyAdjusted || sensorsAdjusted) {
      return {
        ...notificationState,
        snackbar: {
          content: sensorsAdjusted
            ? frequencyAdjusted
              ? `${prefix}both`
              : `${prefix}graphRemoved`
            : `${prefix}dataRateLowered`,
        },
      };
    } else {
      return notificationState;
    }
  }
}

export class SensorInitializationChainTransformer
  implements StateTransformer<SensorInitialization[], NotificationState>
{
  firstHandler: PartialStateTransformer<
    SensorInitialization[],
    NotificationState
  >;
  constructor(deviceConfig: DeviceConfig, frequencyIdxRequested: number) {
    this.firstHandler = new SensorSetupInstructionTransformer(deviceConfig);
    this.firstHandler.nextHandler = new AdjustedSensorParamsTransformer(
      frequencyIdxRequested,
    );
  }
  transform(
    inits: SensorInitialization[],
    notificationState: NotificationState,
  ): NotificationState {
    return this.firstHandler.transform(inits, notificationState);
  }
}
