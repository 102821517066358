import { AuthUtils as AU, all, any, hasNoFilteredBlacklistedAttsIn, } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class LicenseAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return all(this.userIsSetAsCreatorFor(entry), any(this.userHasAdminLicenseFor(entry), this.requestInitiatorIs(6 /* RequestInitiator.CreatePurchasedLicenseDomain */), this.user?.auth?.license?.canCreate, this._isValidKeslerDemoLicenseDefinedBy(entry)))();
    }
    async canRead(entry) {
        return any(this.userIsAssociatedWithFetched(entry), this.userHasAdminLicenseFor(entry), this.user?.auth?.license?.canCreate)();
    }
    async canUpdate(entry) {
        return all(hasNoFilteredBlacklistedAttsIn(entry, {
            alsoBlacklist: ['scC'],
            removeFromBlacklist: ['mUId'],
        }), any(this.userHasAdminLicenseFor(entry), this.user?.auth?.license?.canCreate))();
    }
    async canDelete(entry) {
        return any(this.userHasAdminLicenseFor(entry), this.user?.auth?.license?.canCreate, this.userIsAssociatedWith(entry))();
    }
    _isValidKeslerDemoLicenseDefinedBy(entry) {
        return all(entry.curPK === AU.keslerDemoCurriculumPK, AU.keslerDemoGroupLicensePK.startsWith(entry.mId));
    }
}
