import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { ValidatorFactory, } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { LenientValidator } from './lenient-validator.js';
export class DrawingObjectValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.drawingObjectAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'uId':
            case 'ts':
            case 't':
            case 'id':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'x':
            case 'y':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'w':
            case 'h':
            case 'a':
            case 'sX':
            case 'sY':
            case 'r':
            case 'x1':
            case 'x2':
            case 'y1':
            case 'y2':
            case 'c':
            case 'txt':
            case 'src':
            case 's':
            case 'p':
            case 'ff':
            case 'sW':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'fs':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'iA':
            case 'fX':
            case 'fY':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            default:
                throw Error(`Attr: ${attr} shouldn\'t exist`);
        }
    }
}
