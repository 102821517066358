import { Role } from '../models/models-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { AuthUtils as AuthU } from '../utils/auth-utils.js';
import { AdapterUtils as AU } from '../utils/adapter-utils.js';
class UserAttributesToNotebookUserAdapter {
    adaptTo(uDP) {
        const user = {};
        if (U.isEmpty(uDP)) {
            return user;
        }
        const licenses = AuthU.deserializeLicenses(uDP['custom:licenses']);
        user.userId = uDP['custom:user_id'];
        user.email = U.isEmpty(uDP.email) ? null : uDP.email.toLowerCase().trim();
        user.firstName = uDP['given_name'];
        user.lastName = uDP['family_name'];
        user.name =
            uDP['custom:user_name'] ||
                `${user.firstName || ''} ${user.lastName || ''}`.trim() ||
                user.email;
        user.organization = uDP['custom:organization'];
        user.title = uDP['custom:job_title'];
        user.role = parseInt(uDP['custom:role'] || String(Role.Unconfirmed), 10);
        user.auth = JSON.parse(uDP['custom:auth'] || '{}');
        user.accessToken = uDP['custom:access_token'];
        user.refreshToken = uDP['custom:refresh_token'];
        user.tutorial = JSON.parse(uDP['custom:tutorial'] || '{}');
        user.username = uDP['cognito:username'];
        user.sourcedId = uDP['custom:sourced_id'];
        user.tenantId = uDP['custom:tenant_id'];
        user.init = uDP['custom:init'];
        user.curriculums = licenses.curriculums;
        user.admin = licenses.admin;
        user.classPermissions = AuthU.deserializeClassPermissions(uDP['custom:class_permissions']);
        return U.excludeEmptyProperties(user);
    }
    adaptFrom(tU) {
        if (U.isEmpty(tU)) {
            return;
        }
        const attributes = {
            given_name: tU.firstName,
            family_name: tU.lastName,
            'custom:user_name': tU.name,
            'custom:organization': tU.organization,
            'custom:job_title': tU.title,
            email: tU.email,
            'custom:role': U.isEmpty(tU.role) ? undefined : String(tU.role),
            'custom:auth': U.isEmpty(tU.auth) ? undefined : JSON.stringify(tU.auth),
            'custom:user_id': tU.userId,
            'custom:tutorial': U.isEmpty(tU.tutorial)
                ? undefined
                : JSON.stringify(tU.tutorial),
            'custom:init': U.isEmpty(tU.init) ? undefined : tU.init,
            // 'cognito:username': tU.username,
        };
        return U.excludeEmptyProperties(attributes);
    }
}
export class UserPoolUserToNotebookUserAdapter extends UserAttributesToNotebookUserAdapter {
    adaptTo(uDP) {
        let user = super.adaptTo(uDP);
        if (!U.isEmpty(uDP) &&
            !U.isEmpty(uDP.identities) &&
            uDP.identities.length > 0) {
            const identity = uDP.identities[0];
            user = { ...user, ...AU.idProviderAttributes(identity) };
        }
        return user;
    }
    adaptFrom(user) {
        return super.adaptFrom(user);
    }
}
export class ClaimsUserToNotebookUserAdapter extends UserAttributesToNotebookUserAdapter {
    adaptTo(uDP) {
        let user = super.adaptTo(uDP);
        if (!U.isEmpty(uDP.identities)) {
            const identity = JSON.parse(uDP.identities || '{}');
            user = { ...user, ...AU.idProviderAttributes(identity) };
        }
        return U.excludeEmptyProperties(user);
    }
    adaptFrom(user) {
        return super.adaptFrom(user);
    }
}
export class TriggerUserToNotebookUserAdapter extends UserAttributesToNotebookUserAdapter {
    adaptTo(uDP) {
        let user = super.adaptTo(uDP);
        if (!U.isEmpty(uDP.identities)) {
            const identity = JSON.parse(uDP.identities || '[]')[0];
            user = { ...user, ...AU.idProviderAttributes(identity) };
        }
        user = AU.initializeRole(user, uDP);
        if (U.isEmpty(user.username)) {
            user.username = uDP.username;
        }
        return U.excludeEmptyProperties(user);
    }
    adaptFrom(user) {
        return super.adaptFrom(user);
    }
}
