import { any, all } from '../utils/auth-utils.js';
import { SectionAuthPolicy } from './section-auth-policy.js';
export class IFrameSectionAuthPolicy extends SectionAuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return any(this.requestInitiatorIs(1 /* RequestInitiator.CopyGatewayDomain */), this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */), all(this.tleIsNotReadOnly, this.user?.auth?.iframeSection?.canCreate, this.userIsSetAsCreatorFor(entry), this.userIsPrivilegedParticipantInAuthScope))();
    }
    async canRead(entry) {
        return super.canRead(entry);
    }
    async canUpdate(entry) {
        return super.canUpdate(entry);
    }
    async canDelete(entry) {
        return super.canDelete(entry);
    }
}
