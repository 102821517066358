import { MessageType, } from './connection-types.js';
import { ConnectionStrategyUtils as CSU } from './connection-strategy-utils.js';
export class ExclusivityBasedSensorAdjustmentHandler {
    constructor(deviceConfig) {
        this.deviceConfig = deviceConfig;
    }
    handle(params) {
        const initializations = this._paramsToSensorInitializations(params);
        const orderedSensors = CSU.sortByNewSensors(initializations, (this._previousSensors ?? []).filter((p) => !p.failed));
        orderedSensors.forEach((inits, index) => {
            if (this._isExcluded(inits.sensorId, initializations.slice(0, index))) {
                inits.failed = true;
            }
        });
        this._previousSensors = initializations;
        return this.next.handle(initializations);
    }
    _isExcluded(sId, initializationsSoFar) {
        const sC = this.deviceConfig.sensorConfigs[sId];
        return initializationsSoFar.some((inits) => {
            const aSC = this.deviceConfig.sensorConfigs[inits.sensorId];
            return aSC.exclude?.includes(sId) || sC.exclude?.includes(aSC.id);
        });
    }
    _paramsToSensorInitializations(params) {
        return (params?.sensorIds.map((sId) => ({
            type: MessageType.Initialization,
            sensorId: sId,
            frequencyIdx: params.frequencyIndex,
            maxFrequencyIdx: this.deviceConfig.frequencyConfigs.length - 1,
        })) ?? []);
    }
}
export class PacketNumberBasedFrequencyAdjustmentHandler {
    constructor(deviceConfig) {
        this.deviceConfig = deviceConfig;
    }
    handle(inits) {
        const fCs = this.deviceConfig.frequencyConfigs;
        if (!this.deviceConfig.maxNumSensorBytesInChunk) {
            return this.next.handle(inits);
        }
        if ((inits ?? []).length === 0) {
            return this.next.handle(inits);
        }
        const firstInvalidFrequencyIndex = fCs.findIndex((fC) => {
            if (CSU.isTooManyBytesInPacket(inits, fC, this.deviceConfig)) {
                return true;
            }
            return this._isIncludeOnlyed(fC, inits);
        });
        if (firstInvalidFrequencyIndex === 0) {
            return this.next.handle(inits.map((i) => ({ ...i, frequencyIdx: -1, maxFrequencyIdx: -1 })));
        }
        const maxFrequencyIdx = firstInvalidFrequencyIndex === -1
            ? fCs.length - 1
            : firstInvalidFrequencyIndex - 1;
        const frequencyIdx = Math.min(inits[0].frequencyIdx, maxFrequencyIdx);
        return this.next.handle(inits.map((i) => ({ ...i, frequencyIdx, maxFrequencyIdx })));
    }
    _isIncludeOnlyed(frequencyConfig, inits) {
        return (!!frequencyConfig.includeOnly &&
            inits
                .filter((i) => !i.failed)
                .some((i) => !frequencyConfig.includeOnly.includes(i.sensorId)));
    }
}
export class InvalidFrequencyBasedSensorAdjustmentHandler {
    constructor(deviceConfig) {
        this.deviceConfig = deviceConfig;
    }
    handle(inits) {
        if ((inits ?? []).length === 0) {
            return this.next.handle(inits);
        }
        if (inits[0].frequencyIdx !== -1) {
            return this.next.handle(inits);
        }
        inits.forEach((init, index) => {
            if (CSU.isTooManyBytesInPacket(inits.filter((i) => !i.failed).slice(0, index + 1), this.deviceConfig.frequencyConfigs[0], this.deviceConfig)) {
                init.failed = true;
            }
            init.frequencyIdx = 0;
            init.maxFrequencyIdx = 0;
        });
        return this.next.handle(inits);
    }
}
export class SensorInitializationsHandler {
    handle(inits) {
        return {
            sensorIds: inits.filter((i) => !i.failed).map((i) => i.sensorId),
            initializations: inits,
            maxFrequencyIndex: inits[0]?.maxFrequencyIdx,
            frequencyIndex: inits[0]?.frequencyIdx,
        };
    }
}
export class SensorParamsAdjustmentChain {
    constructor(deviceConfig) {
        this.deviceConfig = deviceConfig;
        this._first = new ExclusivityBasedSensorAdjustmentHandler(this.deviceConfig);
        this._first.next = new PacketNumberBasedFrequencyAdjustmentHandler(this.deviceConfig);
        this._first.next.next = new InvalidFrequencyBasedSensorAdjustmentHandler(this.deviceConfig);
        this._first.next.next.next = new SensorInitializationsHandler();
    }
    execute(i) {
        const params = this._first.handle(i);
        // a bit hacky but if there are no initializations we still need a frequencyIndex for now.
        params.frequencyIndex = params.frequencyIndex ?? i.frequencyIndex;
        return params;
    }
}
