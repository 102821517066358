import { any, all, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class AnswerAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return any(this.requestInitiatorIs(1 /* RequestInitiator.CopyGatewayDomain */), this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */), all(this.tleIsNotReadOnly, this.userIsSetAsCreatorFor(entry), this.userIsParticipantInAuthScope))();
    }
    async canRead(_entry) {
        return any(this.tleIsPublic, this.userIsParticipantInAuthScope)();
    }
    async canUpdate(entry) {
        return all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry), this.userIsCreatorOf(entry), this.userIsParticipantInAuthScope)();
    }
    async canDelete(entry) {
        return all(this.tleIsNotReadOnly, this.userIsPrivilegedParticipantOrParticipantCreatorOf(entry))();
    }
}
