import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { SharedObjectUtils as SOU } from '../utils/shared-object-utils.js';
export class LocalIdIndexManager {
    setIndexAttributes(entry) {
        const output = entry;
        if (U.isEmpty(output.lId) && this._isSortableEntry(entry)) {
            output.lId = SOU.generateCellId();
        }
        return output;
    }
    _isSortableEntry(e) {
        return EU.isSortable(e);
    }
}
