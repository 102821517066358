import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class IsValidObjectValidator {
    constructor(o, factory) {
        this.o = o;
        this.factory = factory;
        this.isDone = false;
        this.valid = true;
    }
    isValid() {
        if (this.isDone) {
            return this.valid;
        }
        const nonEmpty = new IsNonEmptyValidator(this.o, this.factory.getReasonBuilder('Object'));
        if (nonEmpty.isValid()) {
            this.validators = this.factory.getInstance(this.o);
            // TODO cast seems dangerous
            Object.keys({ ...this.o, ...this.validators }).forEach((field) => {
                // includes attribute and multi attribute validations
                const keyValidators = this.validators[field];
                // console.debug('validators for' + field + ':' + JSON.stringify(fieldValidators));
                if (U.isEmpty(keyValidators)) {
                    this.valid = false;
                    this.causes = (this.causes || []).concat({
                        what: field,
                        why: "shouldn't exist",
                    });
                    return;
                }
                keyValidators.forEach((validator) => {
                    const v = validator.isValid();
                    this.valid = this.valid && v;
                    if (!v) {
                        this.causes = (this.causes || []).concat(validator.reasons());
                    }
                });
            });
        }
        else {
            this.valid = false;
            this.causes = (this.causes || []).concat(nonEmpty.reasons());
        }
        this.isDone = true;
        return this.valid;
    }
    reasons() {
        if (!this.isDone) {
            this.isValid();
        }
        return this.causes;
    }
}
