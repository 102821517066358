import produce from 'immer';

import {Inject, Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {Store} from './abstract-store';
import {CachedEntry} from './entry.store';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

export interface ErrorReport {
  code: number;
  message?: string;
  entries?: CachedEntry[] | string[];
}

@Injectable({
  providedIn: 'root',
})
export class ErrorReportStore extends Store<ErrorReport[]> {
  readonly _localStorageKey = 'plnbe';

  debounceState$ = this.state$.pipe(debounceTime(2000)) as Observable<{}>;

  constructor(@Inject('STORAGE') private localStorage: any) {
    super([]);
  }

  addReport(report: ErrorReport) {
    this.setState(
      produce(this.state(), (draft) => {
        draft.push(report);
      }),
    );
  }

  clear() {
    this.setState([]);
  }

  loadStorage() {
    const storage = this.localStorage.getItem(this._localStorageKey);
    if (!U.isEmpty(storage)) {
      const pS = JSON.parse(storage);
      if (Array.isArray(pS)) {
        this.setState(pS);
      }
    }
  }

  saveToStorage() {
    this.localStorage.setItem(
      this._localStorageKey,
      JSON.stringify(this.state()),
    );
    this.clear();
  }
}
