import { all, any, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class StudentUserAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return any(this.requestInitiatorIs(5 /* RequestInitiator.ExodusSnsActions */), all(this.userIsPrivilegedParticipantInAuthScope, this.userIsSetAsCreatorFor(entry)))();
    }
    async canRead(_entry) {
        return this.userIsParticipantInAuthScope;
    }
    async canUpdate(entry) {
        return any(this.requestInitiatorIs(5 /* RequestInitiator.ExodusSnsActions */), all(this.userIsPrivilegedParticipantInAuthScope, hasNoBlacklistedAttsIn(entry)))();
    }
    async canDelete(_entry) {
        return any(this.requestInitiatorIs(5 /* RequestInitiator.ExodusSnsActions */), this.userIsPrivilegedParticipantInAuthScope)();
    }
}
