import { EntryType } from '../models/models-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { InvalidClassIdxKeyError } from './index-manager-error.js';
export class ClassIndexManager {
    setIndexAttributes(entry) {
        //console.log('Class idx input: ' + JSON.stringify(entry));
        const output = entry;
        if (this._isClassEntry(entry)) {
            output.cSK = entry.sK;
            if (U.isEmpty(output.mId)) {
                throw new InvalidClassIdxKeyError('Missing master id');
            }
        }
        return output;
    }
    _isClassEntry(entry) {
        switch (entry.t) {
            case EntryType.Class:
            case EntryType.Assignment:
            case EntryType.StudentUser:
            case EntryType.Module:
            case EntryType.ClassLocalSubset:
            case EntryType.Course:
            case EntryType.Curriculum:
            case EntryType.ClassPermission:
                return true;
            case EntryType.LabReport:
                return !!KU.parentSortKey(entry.sK) && entry.rC === 0;
            case EntryType.TextSection:
                return KU.isType(EntryType.Course, KU.parentSortKey(entry.sK));
            default:
                return false;
        }
    }
}
