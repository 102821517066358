import {
  PL2,
  AuthUtils as AU,
  ConfigConstants as CC,
} from '@common/utils/dist/index.js';

export const fontName = 'Roboto, Helvetica';

export const defaultDABarGraphColumns: Array<Array<PL2.DATableColumn>> = [
  [
    {f: 'ivv', t: 'Categories'},
    {f: 'c1', t: 'Category A'},
    {f: 'c2', t: 'Category B'},
  ],
];

export const defaultDABarGraphData: Array<Array<PL2.DATableRow>> = [
  [
    {id: 0, ivv: 'X Value 1', c1: 1, c2: 1},
    {id: 1, ivv: 'X Value 2', c1: 1, c2: 1},
  ],
];

export const defaultAppColors = {
  lightGrey: '#DDDDDD',
  mediumGrey: '#B4B4B4',
  darkGrey: '#979797',
  orange: '#FF8800',
  red: '#FF3F0D',
  green: '#145432',
  blue: '#1E9AE3',
  darkOrange: '#E57300',
  white: '#FFFFFF',
  black: '#000000',
};

export const actionColors = {
  primary: {
    'background-color': '#FFEAD6',
    'border-color': '#9E4F00',
  },
  secondary: {
    'background-color': '#DAF0F6',
    'border-color': '#00639E',
  },
  tertiary: {
    'background-color': '#CDF4E9',
    'border-color': '#057656',
  },
};

export const defaultDATableData: Array<Array<PL2.DATableRow>> = [
  [
    {id: 0, ivv: 'Description', c1: 1},
    {id: 1, ivv: 'Description', c1: 2},
  ],
];

export const defaultDATableColumns: Array<Array<PL2.DATableColumn>> = [
  [
    {f: 'ivv', t: 'Column A'},
    {f: 'c1', t: 'Column B'},
  ],
];

export const defaultDAScatterPlotData: Array<Array<PL2.DATableRow>> = [
  [
    {id: 0, ivv: '1', c1: 1},
    {id: 1, ivv: '2', c1: 2},
    {id: 2, ivv: '3', c1: 3},
    {id: 4, ivv: '', c1: ''},
    {id: 4, ivv: '', c1: ''},
  ],
];

export const defaultDAScatterPlotColumns: Array<Array<PL2.DATableColumn>> = [
  [
    {f: 'ivv', t: 'x'},
    {f: 'c1', t: 'Data Set 1'},
  ],
];

export const sampleBarSharedData: {[key: string]: PL2.DataTableCell} = {
  k1: {id: 'k1', value: 'Labels', r: 0, c: 0, s: 0, iA: true},
  k2: {id: 'k2', value: 'Category A', r: 0, c: 1, s: 0, iA: true},
  k3: {id: 'k3', value: 'Category B', r: 0, c: 2, s: 0, iA: true},
  k4: {id: 'k4', value: 'X Value 1', r: 1, c: 0, s: 0, iA: true},
  k5: {id: 'k5', value: '1', r: 1, c: 1, s: 0, iA: true},
  k6: {id: 'k6', value: '1', r: 1, c: 2, s: 0, iA: true},
  k7: {id: 'k7', value: 'X Value 2', r: 2, c: 0, s: 0, iA: true},
  k8: {id: 'k8', value: '1', r: 2, c: 1, s: 0, iA: true},
  k9: {id: 'k9', value: '1', r: 2, c: 2, s: 0, iA: true},
  k10: {id: 'k10', value: 'X Value 3', r: 3, c: 0, s: 0, iA: true},
  k11: {id: 'k11', value: '1', r: 3, c: 1, s: 0, iA: true},
  k12: {id: 'k12', value: '1', r: 3, c: 2, s: 0, iA: true},
};

export const xLabelId = 'xl';
export const yLabelId = 'yl';
export const yRangeId = 'yr';
export const sheetNameIdPrefix = 'sn';

export const defaultOptSharedDataCells: {[key: string]: PL2.DataTableCell} = {
  [xLabelId]: {id: xLabelId, value: null, iA: true},
  [yLabelId]: {id: yLabelId, value: null, iA: true},
  [yRangeId]: {id: yRangeId, value: null, iA: true},
  [`${sheetNameIdPrefix}0`]: {
    id: `${sheetNameIdPrefix}0`,
    value: null,
    iA: true,
  },
};

export const sampleSharedData: {[key: string]: PL2.DataTableCell} = {
  k1: {id: 'k1', value: 'Label', r: 0, c: 0, s: 0, iA: true},
  k2: {id: 'k2', value: 'Column 1', r: 0, c: 1, s: 0, iA: true},
  k3: {id: 'k3', value: 'Column 2', r: 0, c: 2, s: 0, iA: true},
  k4: {id: 'k4', value: 'Row 1', r: 1, c: 0, s: 0, iA: true},
  k5: {id: 'k5', value: null, r: 1, c: 1, s: 0, iA: true},
  k6: {id: 'k6', value: null, r: 1, c: 2, s: 0, iA: true},
  k7: {id: 'k7', value: 'Row 2', r: 2, c: 0, s: 0, iA: true},
  k8: {id: 'k8', value: null, r: 2, c: 1, s: 0, iA: true},
  k9: {id: 'k9', value: null, r: 2, c: 2, s: 0, iA: true},
  k10: {id: 'k10', value: 'Row 3', r: 3, c: 0, s: 0, iA: true},
  k11: {id: 'k11', value: null, r: 3, c: 1, s: 0, iA: true},
  k12: {id: 'k12', value: null, r: 3, c: 2, s: 0, iA: true},
};

export const sampleScatterPlotData: {[key: string]: PL2.DataTableCell} = {
  k1: {id: 'k1', value: 'x', r: 0, c: 0, s: 0, iA: true},
  k2: {id: 'k2', value: 'y', r: 0, c: 1, s: 0, iA: true},
  k3: {id: 'k3', value: '1', r: 1, c: 0, s: 0, iA: true},
  k4: {id: 'k4', value: '1', r: 1, c: 1, s: 0, iA: true},
  k5: {id: 'k5', value: '2', r: 2, c: 0, s: 0, iA: true},
  k6: {id: 'k6', value: '2', r: 2, c: 1, s: 0, iA: true},
  k7: {id: 'k7', value: '3', r: 3, c: 0, s: 0, iA: true},
  k8: {id: 'k8', value: '3', r: 3, c: 1, s: 0, iA: true},
  k9: {id: 'k9', value: null, r: 4, c: 0, s: 0, iA: true},
  k10: {id: 'k10', value: null, r: 4, c: 1, s: 0, iA: true},
  k11: {id: 'k11', value: null, r: 5, c: 0, s: 0, iA: true},
  k12: {id: 'k12', value: null, r: 5, c: 1, s: 0, iA: true},
};

export const sampleMapData: {[key: string]: PL2.DataTableCell} = {
  k1: {id: 'k1', value: 'lat', r: 0, c: 0, s: 0, iA: true},
  k2: {id: 'k2', value: 'lng', r: 0, c: 1, s: 0, iA: true},
  k3: {id: 'k3', value: 'title', r: 0, c: 2, s: 0, iA: true},
  k4: {id: 'k4', value: 'description', r: 0, c: 3, s: 0, iA: true},
  k5: {id: 'k5', value: 'images', r: 0, c: 4, s: 0, iA: true},
  k6: {id: 'k6', value: 'colorIdx', r: 0, c: 5, s: 0, iA: true},
};

/* not const since we want to use MapDataColumn[key] */
export enum MapDataColumn {
  lat = 'c0',
  lng = 'c1',
  title = 'c2',
  description = 'c3',
  images = 'c4',
  colorIdx = 'c5',
}

export enum ScatterPlotDataColumn {
  x = 'c0',
  y = 'c1',
}

export enum BarDataColumn {
  title = 'c0',
  prefix = 'c',
}

export const waypointColors = [
  '#979797',
  '#24E229',
  '#FFFD38',
  '#FD7E23',
  '#FC0D1B',
  '#8E4396',
  '#7D0425',
];

/* deprecated */
export enum DataAnalysisType {
  Undefined = 0,
  BarGraph = 1,
  DataTable = 2,
  ScatterPlot = 3,
}

export enum SharedDataTableType {
  Undefined = 0,
  BarGraph = 1,
  DataTable = 2,
  ScatterPlot = 3,
  Map = 4,
}

export enum Color {
  Red = 0,
  Blue = 1,
  Green = 2,
  Yellow = 3,
  Brown = 4,
  Orange = 5,
  Purple = 6,
  Pink = 7,
}

export const LabReportColors: Array<string> = [
  '#77C2EE',
  '#174AEB',
  '#32B48F',
  '#EF8FFF',
  '#FF8000',
];

export const AvatarColors: Array<string> = [
  '#F73A23', // Colors.Red
  '#144F9F', // Colors.Blue
  '#11BA64', // Colors.Green
  '#F0A922', // Colors.Yellow
  '#6E0B00', // Colors.Brown
  '#E2752B', // Colors.Orange
  '#4B2760', // Colors.Purple
  '#D43D9E', // Colors.Pink
  '#0098A1',
  '#BA2211',
  '#7D439F',
  '#36CCD3',
  '#45ED98',
  '#145432',
  '#BA62EA',
  '#3DE3EC',
];
export const DataColors: Array<string> = AvatarColors.slice(0, 8);

export const DataColorsProtanopia: Array<string> = [
  '#7F83EE',
  '#BC7749',
  '#BAC051',
  '#F0CC44',
  '#76A8DB',
  '#84B594',
  '#916FAF',
  '#4B6FD0',
];

export const DataColorsDeuteranopia: Array<string> = [
  '#708CE5',
  '#B08139',
  '#EBAF5D',
  '#FED6A2',
  '#949EE8',
  '#BFA4A5',
  '#A192AC',
  '#5B85E9',
];

export const DataColorsTritanopia: Array<string> = [
  '#9287B5',
  '#F95353',
  '#9BC4A0',
  '#FDD6A8',
  '#3DB7D2',
  '#48C1BF',
  '#E27697',
  '#4091BE',
];

export const DataColorsMonochromacy: Array<string> = [
  '#999999',
  '#555555',
  '#888888',
  '#444444',
  '#777777',
  '#333333',
  '#666666',
  '#222222',
];

export const activateViewPageName = 'pocketlab';
export const trialsViewPageName = 'trials';
export const classViewPageName = 'class';
export const classesViewPageName = 'classes';
export const assignmentViewPageName = 'assignment';
export const userViewPageName = 'user';
export const lessonViewPageName = 'lab-report';
export const sourcePageName = 'source';
export const assignmentsPageName = 'assignments';
export const lessonsPageName = 'lessons';
export const collectionsPageName = 'collections';
export const collectionPageName = 'course';
export const defaultPageName = 'default';
export const curriculumPageName = 'curriculum';
export const curriuculumsPageName = 'curriculums';
export const userStatsPageName = 'user-stats';
export const userDashboardPageName = 'user-dashboard';
export const licensePageName = 'license';
export const licensesPageName = 'licenses';
export const groupLicensesPageName = 'group-licenses';
export const submittedLessonsPageName = 'submitted-lessons';
export const slidesPageName = 'slides';
export const homePageName = '';
export const tosPageName = 'tos';
export const pocketlabLibraryPageName = 'pocketlab-library';
export const searchPageName = 'search';

export const commonTranslationKeys = {
  start: 'common.start',
  select: 'common.select',
  close: 'common.close',
  cancel: 'common.cancel',
  done: 'common.done',
  ok: 'common.ok',
  save: 'common.save',
  connecting: 'common.connecting',
  update: 'common.update',
  create: 'common.create',
  edit: 'common.edit',
  add: 'common.add',
  uploading: 'common.uploading',
  loading: 'common.loading',
  error: 'common.error',
  errorMessage: 'common.errorMessage',
  wait: 'common.wait',
};

export const dialogConfig = {
  minWidth: '300px',
  disableClose: true,
  width: '560px',
};

export const imgUploadFormats =
  '.png,.jpg,.jpeg,.bmp,.gif,.heic,.svg,image/png,image/jpg,image/jpeg,image/bpm,image/gif,image/heic,image/svg+xml';

export const imgEmbedDefaultFloat = '';

export const fatDialogConfig = {
  ...dialogConfig,
  panelClass: 'wide-border-dialog',
};

export const shareTranslationKeys = {
  wait: 'common.wait',
  title: 'share-lab-report-link-dialog.title',
  submitThanks: 'share-lab-report-link-dialog.submitThanks',
};

export const googleClientId =
  '606511070052-13fskhe71o18jjln09s1ljnv58rrt0g4.apps.googleusercontent.com';

export const basePhetUrl =
  'https://phet.colorado.edu/services/metadata/1.2/simulations';

export const svgMarker = {
  path: 'M14.3333 41L13.9543 41.3261L14.3333 41.7667L14.7124 41.3261L14.3333 \
41ZM14.3333 41C14.7124 41.3261 14.7125 41.3259 14.7126 41.3258L14.7132 \
41.3251L14.7152 41.3227L14.7229 41.3138L14.7524 41.2792C14.7782 41.2489 14.8162 \
41.204 14.8657 41.1452C14.9646 41.0277 15.1091 40.8545 15.2928 40.6306C15.6601 \
40.183 16.184 39.5327 16.8123 38.7198C18.0685 37.0946 19.7441 34.8169 21.4206 \
32.2078C23.0963 29.6 24.7785 26.6525 26.0432 23.6884C27.3053 20.7304 28.1667 \
17.7214 28.1667 15C28.1667 7.00713 22.0036 0.5 14.3333 0.5C6.66305 0.5 0.5 7.00713 \
0.5 15C0.5 17.7214 1.36136 20.7304 2.62344 23.6884C3.88812 26.6525 5.57035 29.6 \
7.24602 32.2078C8.92256 34.8169 10.5982 37.0946 11.8544 38.7198C12.4827 39.5327 \
13.0066 40.183 13.3739 40.6306C13.5575 40.8545 13.7021 41.0277 13.801 \
41.1452C13.8504 41.204 13.8885 41.2489 13.9143 41.2792L13.9438 41.3138L13.9514 \
41.3227L13.9535 41.3251L13.954 41.3258C13.9542 41.3259 13.9543 41.3261 14.3333 41Z',
  fillColor: 'grey',
  fillOpacity: 1,
  strokeWeight: 1,
  rotation: 0,
};

export const undoTime = 15000;
export const adminLicenseMaxNumMonths = 99;
export const adminLicenseDefaultNumMonths = 12;
export const entryDebounceTime = 2000;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
export const tagRegex = /^[A-Z0-9\.\:\,\-\_\&\(\) ]+$/i;
export const numberRegex = /^[0-9]*$/;

export enum ErrorReportCode {
  ExpiredEntries = 100,
  MissingTransition = 101,
}

export enum SectionSettings {
  CardInstructions,
  AltText,
  QuestionSettings,
  GroupMatchingSettings,
}

export const sectionTypeMap: {[type: string]: string} = {
  [PL2.EntryType.CollectDataSection]: 'Collect Data',
  [PL2.EntryType.DiscussionSection]: 'Discussion',
  [PL2.EntryType.GoogleDriveSection]: 'Google Drive File',
  [PL2.EntryType.IFrameSection]: 'iFrame',
  [PL2.EntryType.ImageSection]: 'Image',
  [PL2.EntryType.PhetSection]: 'PhET Simulation',
  [PL2.EntryType.QuestionSection]: 'Question / Discussion',
  [PL2.EntryType.SharedDataTableSection]: 'Data Table',
  [PL2.EntryType.SharedDrawingSection]: 'Shared Drawing',
  [PL2.EntryType.SpeechToTextSection]: 'Speech To Text',
  [PL2.EntryType.TextSection]: 'Text',
  [PL2.EntryType.VideoSection]: 'YouTube Video',
  [PL2.EntryType.SharedDragAndDropSection]: 'Shared Drag and Drop',
  [PL2.EntryType.SharedLineMatchingSection]: 'Shared Line Matching',
};

export const sectionSettingsMap: {
  [type: string]: {settings: SectionSettings[]};
} = {
  [PL2.EntryType.DataAnalysisSection]: {
    settings: [SectionSettings.CardInstructions],
  },
  [PL2.EntryType.IFrameSection]: {settings: [SectionSettings.CardInstructions]},
  [PL2.EntryType.ImageSection]: {
    settings: [SectionSettings.CardInstructions, SectionSettings.AltText],
  },
  [PL2.EntryType.PhetSection]: {settings: [SectionSettings.CardInstructions]},
  [PL2.EntryType.SharedDataTableSection]: {
    settings: [SectionSettings.CardInstructions],
  },
  [PL2.EntryType.SharedDrawingSection]: {
    settings: [SectionSettings.CardInstructions],
  },
  [PL2.EntryType.VideoSection]: {settings: [SectionSettings.CardInstructions]},
  [PL2.EntryType.QuestionSection]: {
    settings: [SectionSettings.QuestionSettings],
  },
  [PL2.EntryType.SharedDragAndDropSection]: {
    settings: [
      SectionSettings.CardInstructions,
      SectionSettings.GroupMatchingSettings,
    ],
  },
  [PL2.EntryType.SharedLineMatchingSection]: {
    settings: [
      SectionSettings.CardInstructions,
      SectionSettings.GroupMatchingSettings,
    ],
  },
  [PL2.EntryType.SpeechToTextSection]: {
    settings: [SectionSettings.QuestionSettings],
  },
};

export const sharedDataTableTypeMap: {[type: string]: string} = {
  [SharedDataTableType.BarGraph]: 'Bar Graph',
  [SharedDataTableType.DataTable]: 'Data Table',
  [SharedDataTableType.ScatterPlot]: 'Scatter Plot',
  [SharedDataTableType.Map]: 'Map',
};

export const filterSeparator = '+';
export const inactiveTempFilterPrefix = '^';

export const themableElements = {
  entryFormText: 'eft',
  entryFormRichText: 'efrt',
};

export interface SearchResultAttributes {
  icon: string;
  author: string;
}

export const curriculumResultPL: SearchResultAttributes = {
  icon: 'pocketlab-darker',
  author: 'PocketLab',
};
export const curriculumResultCA: SearchResultAttributes = {
  icon: 'conceptual_academy',
  author: 'Conceptual Academy',
};

export const curriculumResultAS: SearchResultAttributes = {
  icon: 'amoeba_sisters',
  author: 'Amoeba Sisters',
};

export const curriculumResultKS: SearchResultAttributes = {
  icon: 'kesler_science',
  author: 'Kesler Science',
};
export const curriculumResultHMH: SearchResultAttributes = {
  icon: 'hmh',
  author: 'HMH',
};
export const curriculumResultOSE: SearchResultAttributes = {
  icon: 'open_sci_ed',
  author: 'Open SciEd',
};

export const curriculumRegister: {[pK: string]: SearchResultAttributes} = {
  [CC.curriculumPKLookup.pocketlab]: curriculumResultPL,
  [CC.curriculumPKLookup.keslerPro]: curriculumResultKS,
  [CC.curriculumPKLookup.keslerDemo]: curriculumResultKS,
  [CC.curriculumPKLookup.conceptualAcademyChemistry]: curriculumResultCA,
  [CC.curriculumPKLookup.conceptualAcademyPhysics]: curriculumResultCA,
  [CC.curriculumPKLookup.conceptualAcademyIntegrated]: curriculumResultCA,
  [CC.curriculumPKLookup.conceptualAcademyIntegratedPhysicsChemistry]:
    curriculumResultCA,
  [CC.curriculumPKLookup.amoebaSisters]: curriculumResultAS,
  [CC.curriculumPKLookup.hmhK5en]: curriculumResultHMH,
  [CC.curriculumPKLookup.hmhK5es]: curriculumResultHMH,
  [CC.curriculumPKLookup.hmhK68en]: curriculumResultHMH,
  [CC.curriculumPKLookup.hmhK68es]: curriculumResultHMH,
  [CC.curriculumPKLookup.openSciEd]: curriculumResultOSE,
  [CC.curriculumPKLookup.keslerAlphabeticalProductList]: curriculumResultKS,
  [CC.curriculumPKLookup.keslerSpanglerScience]: curriculumResultKS,
};

export const curriculumAltNameKey = 'main';

export const providerLaunchLookup = {
  'ed-sign-in': PL2.IdentityProvider.Ed,
  'edlink-sign-in': PL2.IdentityProvider.EdLink,
};
