import { ProcessorUtils as PU } from './processor-utils.js';
export class BmeCoefficientCalculator {
    calculateForBme(value) {
        console.log('Calibrating');
        console.log(value);
        const vs1 = new Int8Array(value[0]);
        const vu1 = new Uint8Array(value[0]);
        const vs2 = new Int8Array(value[1]);
        const vu2 = new Uint8Array(value[1]);
        const vs3 = new Int8Array(value[2]);
        const vu3 = new Uint8Array(value[2]);
        const barCoefficients = [];
        // Tmp
        barCoefficients[0] = PU.shortSignedAtOffset(vu1, 0);
        barCoefficients[1] = PU.shortSignedAtOffset(vs1, 2);
        barCoefficients[2] = PU.shortSignedAtOffset(vs1, 4);
        // Bar
        barCoefficients[3] = PU.shortSignedAtOffset(vu2, 0);
        barCoefficients[4] = PU.shortSignedAtOffset(vs2, 2);
        barCoefficients[5] = PU.shortSignedAtOffset(vs2, 4);
        barCoefficients[6] = PU.shortSignedAtOffset(vs2, 6);
        barCoefficients[7] = PU.shortSignedAtOffset(vs2, 8);
        barCoefficients[8] = PU.shortSignedAtOffset(vs2, 10);
        barCoefficients[9] = PU.shortSignedAtOffset(vs2, 12);
        barCoefficients[10] = PU.shortSignedAtOffset(vs2, 14);
        barCoefficients[11] = PU.shortSignedAtOffset(vs2, 16);
        // Hum
        barCoefficients[12] = vu3[0];
        barCoefficients[13] = PU.shortSignedAtOffset(vs3, 1);
        barCoefficients[14] = vu3[3];
        barCoefficients[15] = (vs3[4] << 4) + (vs3[5] & 0x0f);
        barCoefficients[16] = (vs3[6] << 4) + ((vs3[5] >> 4) & 0x0f);
        barCoefficients[17] = vu3[7];
        return barCoefficients;
    }
    calculateForPL1(value) {
        const vs1 = new Int8Array(value[0]);
        const vu1 = new Uint8Array(value[0]);
        const vs2 = new Int8Array(value[1]);
        const barCoefficients = [];
        barCoefficients[0] = PU.shortSignedAtOffset(vu1, 0);
        barCoefficients[1] = PU.shortSignedAtOffset(vs1, 2);
        barCoefficients[2] = PU.shortSignedAtOffset(vs1, 4);
        barCoefficients[3] = PU.shortSignedAtOffset(vu1, 6);
        barCoefficients[4] = PU.shortSignedAtOffset(vs1, 8);
        barCoefficients[5] = PU.shortSignedAtOffset(vs1, 10);
        barCoefficients[6] = PU.shortSignedAtOffset(vs1, 12);
        barCoefficients[7] = PU.shortSignedAtOffset(vs1, 14);
        barCoefficients[8] = PU.shortSignedAtOffset(vs2, 0);
        barCoefficients[9] = PU.shortSignedAtOffset(vs2, 2);
        barCoefficients[10] = PU.shortSignedAtOffset(vs2, 4);
        barCoefficients[11] = PU.shortSignedAtOffset(vs2, 6);
        return barCoefficients;
    }
}
