import { EntryType } from '../models/models-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class ShortCodeIndexManager {
    setIndexAttributes(entry) {
        const output = entry;
        if (entry.t === EntryType.Assignment && entry?.tmp) {
            output.shC = U.generateCode();
        }
        return output;
    }
}
