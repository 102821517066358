import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import Amplify from '@aws-amplify/core';

import {APIState, APIStateStore} from '@stores/api-state.store';

import {Workbox} from 'workbox-window';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((module) => {
      registerServiceWorker('sw', module.injector.get(APIStateStore));
    })
    .catch((err) => console.log(err));
});

export const amplifyConfig = {
  Auth: {
    federationTarget: 'COGNITO_USER_POOLS',
    identityPoolId: environment.identityPoolId,
    region: 'us-east-1',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
    oauth: {
      domain: environment.oAuthDomain,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: `${environment.host}/`,
      redirectSignOut: `${environment.host}/`,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: environment.entryAPIGatewayName,
        endpoint: environment.entryAPIGatewayUrl,
        custom_header: async () => ({Authorization: 'token'}),
      },
      {
        name: environment.directEntryAPIGatewayName,
        endpoint: environment.directEntryAPIGatewayUrl,
        custom_header: async () => ({Authorization: 'token'}),
      },
      {
        name: environment.oneRosterAPIGatewayName,
        endpoint: environment.oneRosterAPIGatewayUrl,
        custom_header: async () => ({Authorization: 'token'}),
      },
      {
        name: environment.opensearchAPIGatewayName,
        endpoint: environment.opensearchAPIGatewayUrl,
        custom_header: async () => ({Authorization: 'token'}),
      },
      {
        name: environment.paymentAPIGatewayName,
        endpoint: environment.paymentAPIGatewayUrl,
        custom_header: async () => ({Authorization: 'token'}),
      },
      {
        name: environment.userStatsAPIGatewayName,
        endpoint: environment.userStatsAPIGatewayUrl,
        custom_header: async () => ({Authorization: 'token'}),
      },
      {
        name: environment.authAPIGatewayName,
        endpoint: environment.authAPIGatewayUrl,
        custom_header: async () => ({Authorization: 'token'}),
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: 'pocketlab-user-uploads', // environment.imageBucket?,
      region: 'us-east-1',
    },
  },
};

Amplify.configure(amplifyConfig);

function registerServiceWorker(swName: string, apiStateStore: APIStateStore) {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox(`/${swName}.js`);

    wb.addEventListener('installed', (event) => {
      if (event.isUpdate) {
        if (
          confirm(
            'PocketLab Notebook has a new version available. Click Okay to refresh the page and get the new version.',
          )
        ) {
          window.location.reload();
        }
      }
    });

    wb.addEventListener('message', (event) => {
      if (!apiStateStore) {
        return;
      }
      switch (event.data.status) {
        case 'offline':
          apiStateStore.setAPIState(APIState.Offline);
          break;
        case 'online':
          apiStateStore.setAPIState(APIState.Default);
          break;
      }
    });
    wb.register().catch((err) => {
      console.error('Failed to register service worker');
      console.error(err);
    });
  }
}
