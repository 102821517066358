import { DeviceConfigUtils as DCU } from './device-config-utils.js';
export class PrePowerlabDeviceConfigurator {
    constructor(characteristicCache, deviceConfig) {
        this.characteristicCache = characteristicCache;
        this.deviceConfig = deviceConfig;
    }
    writeName(name) {
        const nameWithPrefix = this.deviceConfig.namePrefix + name;
        const command = new Uint8Array(this.deviceConfig.renameCommand.length + nameWithPrefix.length);
        command.set(new Uint8Array(this.deviceConfig.renameCommand));
        // command.set(new TextEncoder('ascii').encode(nameWithPrefix), 1);
        command.set(new TextEncoder().encode(nameWithPrefix), this.deviceConfig.renameCommand.length);
        return this.characteristicCache
            .get(this.deviceConfig.nameConfig.id, 'config')
            .then((characteristic) => characteristic.writeValue(command.buffer));
    }
    readBatteryStatus() {
        return this.characteristicCache
            .get(this.deviceConfig.batteryConfig.id, 'config')
            .then((characteristic) => characteristic.readValue())
            .then((value) => new Uint8Array(value.buffer)[0]);
    }
    readFirmwareVersion() {
        return this.characteristicCache
            .get(this.deviceConfig.deviceInformationConfigs.firmwareinfo.id, 'config')
            .then((characteristic) => characteristic.readValue())
            .then((value) => DCU.fwBytesToString(value));
    }
    readHardwareVersion() {
        return this.characteristicCache
            .get(this.deviceConfig.deviceInformationConfigs['hardwareinfo'].id, 'config')
            .then((characteristic) => characteristic.readValue())
            .then((value) => DCU.fwBytesToString(value));
    }
    readManufacturerName() {
        return this.characteristicCache
            .get(this.deviceConfig.deviceInformationConfigs['manufacturerinfo'].id, 'config')
            .then((characteristic) => characteristic.readValue())
            .then((value) => DCU.fwBytesToString(value));
    }
    shutdown() {
        return Promise.reject('Not supported');
    }
    saveSensorSettings() {
        return Promise.reject('Not supported');
    }
}
