import { all, any } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class InvitedLabReportUserAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return all(this.userIsSetAsCreatorFor(entry), this.userIsPrivilegedParticipantInAuthScope)();
    }
    async canRead(entry) {
        return any(this.userIsAssociatedWithFetched(entry), this.userIsParticipantInAuthScope)();
    }
    async canUpdate(_entry) {
        return false;
    }
    async canDelete(_entry) {
        return any(this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */), this.userIsPrivilegedParticipantInAuthScope)();
    }
}
