import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CharacteristicCache } from './characteristic-cache.js';
import { CommandSensorCoordinator } from './command-sensor-coordinator.js';
import { CharacteristicDataProcessorBuilder } from './characteristic-data-processor-builder.js';
import { BytewiseCommandBuilder } from './bytewise-command-builder.js';
import { PrePowerlabMemoryConfigurator } from './pre-powerlab-memory-configurator.js';
import { PrePowerlabDeviceConfigurator } from './pre-powerlab-device-configurator.js';
import { connectionStateDefault, MemoryStatus, } from './connection-types.js';
import { BmeCoefficientCalculator } from './bme-coefficient-calculator.js';
import { ProcessorUtils as PU } from './processor-utils.js';
export class AirStrategy {
    constructor(gattServer, deviceConfig) {
        this.deviceConfig = deviceConfig;
        this._barCoefficients = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
        this._cozirName = 'cozir';
        this._processorMap = {
            plantower: (value) => {
                const v = new Int8Array(value);
                const pm10 = PU.accShortSignedAtOffset(v, 10);
                const pm2Point5 = PU.accShortSignedAtOffset(v, 8);
                const pm1 = PU.accShortSignedAtOffset(v, 6);
                return [pm10, pm2Point5, pm1];
            },
            cozir: (value) => {
                const v = new Int8Array(value);
                const filtered = (v[3] - 48) * 10000 +
                    (v[4] - 48) * 1000 +
                    (v[5] - 48) * 100 +
                    (v[6] - 48) * 10 +
                    (v[7] - 48);
                // const unfiltered = (v[11] - 48) * 10000 + (v[12] - 48) * 1000 + (v[13] - 48) * 100
                // + (v[14] - 48) * 10 + (v[15] - 48);
                return [filtered];
            },
            ozone: (value) => {
                const v = new Int8Array(value);
                const val = PU.accShortSignedAtOffset(v, 0);
                // Calculate ozone concentration (parts per billion)
                const offset = 511.509;
                const multiplier = -0.023978;
                // const ozone = (val < 0) ? 0 : (val - offset) * multiplier;
                const ozone = (val - offset) * multiplier;
                return [ozone];
            },
            airBar: (value) => PU.bme280(value, this._barCoefficients),
            airLig: (value) => PU.apds9301(value),
        };
        this._characteristicCache = new CharacteristicCache(gattServer, deviceConfig);
        this._sensorCoordinator = new CommandSensorCoordinator(deviceConfig, this._characteristicCache, new BytewiseCommandBuilder(deviceConfig));
        this._memoryConfigurator = new PrePowerlabMemoryConfigurator(this._characteristicCache, deviceConfig, this._sensorCoordinator, this._processorMap);
        this._deviceConfigurator = new PrePowerlabDeviceConfigurator(this._characteristicCache, deviceConfig);
    }
    init() {
        return this.calibrate(this.deviceConfig.calibrationConfig)
            .then((partialConnectionState) => {
            this._barCoefficients = partialConnectionState.barCoefficients;
            return {
                ...connectionStateDefault,
                barCoefficients: this._barCoefficients,
                deviceConfig: this.deviceConfig,
            };
        })
            .then((connectionState) => {
            if (!!this.deviceConfig.memoryConfig) {
                return this._memoryConfigurator
                    .retrieveMemoryState()
                    .then((status) => ({ ...connectionState, memoryStatus: status }));
            }
            else {
                return { ...connectionState, memoryStatus: MemoryStatus.NoMemory };
            }
        });
    }
    startSensors(params) {
        const builder = new CharacteristicDataProcessorBuilder(this._processorMap, this.deviceConfig.frequencyConfigs[params.frequencyIndex]?.f ?? 1);
        return from(this._sensorCoordinator.startSensors(params.sensorIds, params.frequencyIndex)).pipe(mergeMap((dCs) => builder.build(dCs)));
    }
    calibrate(sensorId) {
        switch (sensorId) {
            case this.deviceConfig.calibrationConfig:
                const values = [];
                let configCharacteristic = null;
                return this._characteristicCache
                    .get(this.deviceConfig.calibrationConfig, 'config')
                    .then((characteristic) => characteristic.startNotifications())
                    .then(() => this._characteristicCache.get(this.deviceConfig.calibrationConfig, 'config'))
                    .then((characteristic) => {
                    configCharacteristic = characteristic;
                    const buffer = new Int8Array([1]).buffer;
                    return characteristic.writeValue(buffer);
                })
                    .then(() => configCharacteristic.readValue())
                    .then((v) => {
                    values.push(v.buffer);
                    return configCharacteristic.writeValue(new Int8Array([2]).buffer);
                })
                    .then(() => configCharacteristic.readValue())
                    .then((v) => {
                    values.push(v.buffer);
                    return configCharacteristic.writeValue(new Int8Array([3]).buffer);
                })
                    .then(() => configCharacteristic.readValue())
                    .then((v) => {
                    values.push(v.buffer);
                    const processedValues = new BmeCoefficientCalculator().calculateForBme(values);
                    console.log('Calibration complete');
                    console.log(processedValues);
                    return { barCoefficients: processedValues };
                });
            case this._cozirName:
                return this._characteristicCache
                    .get('cozir', 'config')
                    .then((characteristic) => {
                    // const buffer: any = new Uint8Array([6, 71, 13, 10]).buffer;
                    const buffer = new Uint8Array([0x06, 0x47, 0x0d, 0x0a]).buffer;
                    return characteristic.writeValue(buffer).then(() => ({}));
                });
            default:
                return Promise.reject(`Sensor can\'t be calibrated: ${sensorId}`);
        }
    }
    memoryConfigurator() {
        return this._memoryConfigurator;
    }
    deviceConfigurator() {
        return this._deviceConfigurator;
    }
    crashModeConfigurator() {
        return null;
    }
    disconnect() {
        // do nothing
    }
}
