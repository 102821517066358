import { isTeacher } from '../utils/user-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { AuthUtils as AU, any, all, hasAttrIn } from '../utils/auth-utils.js';
export function userIsAdminOf(entry, user) {
    if (userHasClassPermissionFor(entry, user)) {
        return true;
    }
    return AU.isEntryOwner(user, entry);
}
export function userHasClassPermissionFor(entry, user) {
    return AU.hasClassPermission(user, entry);
}
export class AuthPolicy {
    get userIsTleAdmin() {
        return (AU.isTLEOwner(this.user, this.entries) ||
            (!U.isEmpty(this.entries) &&
                userHasClassPermissionFor(this.entries[0], this.user)));
    }
    get userIsClassAdminForTle() {
        return this.userHasClassAdminPermissionFor(this.entries[0]);
    }
    get userIsTeacher() {
        return isTeacher(this.user);
    }
    get userIsLrUser() {
        return AU.isLrUser(this.user, this.entries);
    }
    get userIsUninvitedLrUser() {
        return AU.isUninvitedLrUser(this.user, this.entries);
    }
    get tleIsReadOnly() {
        return this.readOnly;
    }
    get tleIsNotReadOnly() {
        return !this.tleIsReadOnly;
    }
    get tleIsPublic() {
        return AU.isPublic(this.entries);
    }
    get userIsPrivilegedParticipantInAuthScope() {
        return this.userIsTleAdmin;
    }
    get userIsParticipantInAuthScope() {
        return this.userIsTleAdmin || this.userIsLrUser;
    }
    constructor(entries, user, readOnly, fetchEntry, rI) {
        this.entries = entries;
        this.user = user;
        this.readOnly = readOnly;
        this.fetchEntry = fetchEntry;
        this.rI = rI;
    }
    canCreate(_entry) {
        return Promise.resolve(false);
    }
    canRead(_entry) {
        return Promise.resolve(false);
    }
    canUpdate(_entry) {
        return Promise.resolve(false);
    }
    canDelete(_entry) {
        return Promise.resolve(false);
    }
    userIsSetAsCreatorFor(entry) {
        return AU.isEntryOwner(this.user, entry);
    }
    userIsCreatorOfFetched(entry) {
        return this.userIsSetAsCreatorFor(entry);
    }
    userIsCreatorOf(entry) {
        return async () => {
            const fetchedEntry = await this.fetchEntry({
                mId: entry.mId,
                sK: entry.sK,
            });
            return this.userIsCreatorOfFetched(fetchedEntry);
        };
    }
    userIsAdminOf(entry) {
        if (userHasClassPermissionFor(entry, this.user)) {
            return true;
        }
        return this.userIsCreatorOf(entry);
    }
    userIsAdminOfFetched(entry) {
        if (userHasClassPermissionFor(entry, this.user)) {
            return true;
        }
        else {
            return this.userIsCreatorOfFetched(entry);
        }
    }
    userIsAssociatedWith(entry) {
        return async () => {
            const fetchedEntry = await this.fetchEntry({
                mId: entry.mId,
                sK: entry.sK,
            });
            return AU.isAssociatedWithEntry(fetchedEntry, this.user);
        };
    }
    userIsMemberUserOf(entry) {
        return async () => {
            const fetchedEntry = await this.fetchEntry({
                mId: entry.mId,
                sK: entry.sK,
            });
            return AU.isEntryMemberUser(this.user, fetchedEntry);
        };
    }
    userIsAssociatedWithFetched(entry) {
        return AU.isAssociatedWithEntry(entry, this.user);
    }
    userHasPermissionLicenseFor(entry) {
        return AU.canReadWithLicense(this.user, entry);
    }
    userHasAdminLicenseFor(entry) {
        return AU.canManageLicense(this.user, entry);
    }
    userHasClassAdminPermissionFor(entry) {
        return AU.hasClassAdminPermission(this.user, entry);
    }
    tleIsType(type) {
        return AU.hasTleType(type, this.entries);
    }
    tleIsNotType(type) {
        return !AU.hasTleType(type, this.entries);
    }
    requestInitiatorIs(rI) {
        return this.rI === rI;
    }
    requestInitiatorIsAny(...rIs) {
        return rIs.includes(this.rI);
    }
    userIsPrivilegedParticipantOrParticipantCreatorOf(entry) {
        return any(all(this.userIsCreatorOf(entry), this.userIsParticipantInAuthScope), this.userIsPrivilegedParticipantInAuthScope);
    }
    fetchedEntryHasNoAttr(attr, entry) {
        return async () => {
            const fetchedEntry = await this.fetchEntry({
                mId: entry.mId,
                sK: entry.sK,
            });
            return !hasAttrIn(fetchedEntry, attr);
        };
    }
}
