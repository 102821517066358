import { all, any, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class AssignmentAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return all(this.userIsSetAsCreatorFor(entry), this.userIsPrivilegedParticipantInAuthScope)();
    }
    async canRead(_entry) {
        return any(this.userIsPrivilegedParticipantInAuthScope, this.user?.auth?.lesson?.canRead)();
    }
    async canUpdate(entry) {
        return all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry, 'isP', 'tmp', 'shC'), this.userIsPrivilegedParticipantInAuthScope)();
    }
    async canDelete(_entry) {
        return this.userIsPrivilegedParticipantInAuthScope;
    }
}
