import { merge, empty, Observable, interval, ReplaySubject, from, } from 'rxjs';
import { map, takeUntil, mergeMap } from 'rxjs/operators';
import { DeviceConfigUtils as DCU } from './device-config-utils.js';
import { MemoryStatus, ProcessorStatus, MessageType, } from './connection-types.js';
import { ProcessorUtils as PU } from './processor-utils.js';
export class InternalSensorConnectionStrategy {
    constructor(window, deviceConfig) {
        this.window = window;
        this.deviceConfig = deviceConfig;
        this.allSensorsStopped$ = new ReplaySubject();
        this.disconnect$ = empty();
        this.reg = new Map();
        this._onboardmicName = 'onboardmic';
        this.processorMap = {
            onboardacc: (val) => [
                val.x / PU.gToMs2,
                val.y / PU.gToMs2,
                val.z / PU.gToMs2,
            ],
            onboardgyr: (val) => [
                val.x,
                val.y,
                val.z,
            ],
            onboardmag: (val) => [
                val.x,
                val.y,
                val.z,
            ],
            onboardlig: (val) => [val.illuminance],
            onboardmic: (val) => [isFinite(val) ? val : 0],
        };
    }
    init() {
        const deviceConfig = DCU.configWithId('onboard');
        return Promise.resolve({
            deviceConfig: deviceConfig,
            barCoefficients: new Array(),
            memoryStatus: MemoryStatus.NoMemory,
            frequencyIdx: deviceConfig.defaultFrequencyIndex,
            maxFrequencyIdx: Infinity,
            processorStatus: ProcessorStatus.SensorDataProcessing,
        });
    }
    startSensors(params) {
        this.stopAllSensors();
        this.allSensorsStopped$ = new ReplaySubject();
        const frequencyConfig = this.deviceConfig.frequencyConfigs[params.frequencyIndex];
        return merge(...params.sensorIds.map((sensorId) => {
            const config = this.deviceConfig.sensorConfigs[sensorId];
            if (!this.reg.has(sensorId)) {
                this.reg.set(sensorId, new Array());
            }
            // don't bother adding audio to the reg as there is a different method to stop it
            if (sensorId === this._onboardmicName) {
                return from(navigator.mediaDevices
                    .getUserMedia({ audio: true })
                    .then((stream) => {
                    // So audio internal sensors work on Safari
                    this.audioContext = new (this.window['AudioContext'] ||
                        this.window['webkitAudioContext'])();
                    // this.audioStream = stream;
                    this.audioNode =
                        this.audioContext.createMediaStreamSource(stream);
                    const analyser = this.audioContext.createAnalyser();
                    analyser.fftSize = 2048;
                    // analyser.smoothingTimeConstant = 0.0;
                    this.audioNode.connect(analyser);
                    return analyser;
                })).pipe(mergeMap((analyser) => {
                    const dataArray = new Float32Array(analyser.fftSize);
                    return interval(1000 / frequencyConfig.i).pipe(map(() => {
                        analyser.getFloatTimeDomainData(dataArray);
                        let peakInstantaneousPower = 0;
                        for (let i = 0; i < dataArray.length; i++) {
                            const power = dataArray[i] ** 2;
                            peakInstantaneousPower = Math.max(power, peakInstantaneousPower);
                        }
                        const peakInstantaneousPowerDecibels = 10 * Math.log10(peakInstantaneousPower);
                        return [
                            {
                                values: [
                                    [
                                        0,
                                        ...this.processorMap[sensorId](peakInstantaneousPowerDecibels + 60),
                                    ],
                                ],
                                sensorId: sensorId,
                                type: MessageType.Reading,
                                status: ProcessorStatus.SensorDataProcessing,
                                frequency: frequencyConfig.f,
                                chunkInterval: 1 / frequencyConfig.f,
                            },
                        ];
                    }), takeUntil(this.allSensorsStopped$));
                }));
            }
            else {
                const sensor = new this.window[config.class]({
                    frequency: frequencyConfig.i,
                });
                sensor.start();
                this.reg.get(config.id).push(sensor);
                return Observable.create((observer) => {
                    sensor.onreading = () => {
                        const reading = {
                            values: [[0, ...this.processorMap[sensorId](sensor)]],
                            sensorId: config.id,
                            type: MessageType.Reading,
                            frequency: frequencyConfig.f,
                            status: ProcessorStatus.SensorDataProcessing,
                            chunkInterval: 1 / frequencyConfig.f,
                        };
                        observer.next([reading]);
                    };
                    sensor.onerror = (event) => {
                        observer.error(`${event.error.name}: ${event.error.message}`);
                    };
                });
            }
        }));
    }
    disconnect() {
        this.stopAllSensors();
    }
    stopAllSensors() {
        Array.from(this.reg.values()).forEach((sensors) => sensors.forEach((s) => {
            if (typeof s.stop === 'function') {
                s.stop();
            }
        }));
        this.allSensorsStopped$.next(true);
        this.allSensorsStopped$.complete();
        this.reg.clear();
        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }
        if (this.audioNode) {
            this.audioNode.disconnect();
            this.audioNode = null;
        }
        return Promise.resolve();
    }
    calibrate(_sensorId) {
        return Promise.resolve({});
    }
    memoryConfigurator() {
        return null;
    }
    deviceConfigurator() {
        return null;
    }
    crashModeConfigurator() {
        return null;
    }
}
