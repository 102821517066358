export class ConcatReasonBuilder {
    static getInstance(what) {
        return new ConcatReasonBuilder(what);
    }
    constructor(what) {
        this.what = what;
    }
    build(msg) {
        return { what: this.what, why: `${this.what} ${msg}` };
    }
}
