import { ValidatorFactory, } from './validator-core.js';
import { UserEntryValidatorFactory } from './user-entry-validator-factory.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { AuthIdxEntryValidatorFactory } from './auth-idx-entry-validator-factory.js';
import { TurnInAbleValidatorFactory } from './turn-in-able-validator-factory.js';
export class ScopeUserEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.scopeUserEntryAtts, ...EU.authIdxEntryAtts, ...EU.turnInAbleAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        if (EU.authIdxEntryAtts.includes(attr)) {
            return new AuthIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
        else if (EU.turnInAbleAtts.includes(attr)) {
            return new TurnInAbleValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
        else {
            return new UserEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
    }
}
