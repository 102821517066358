import {NgModule} from '@angular/core';
import {PolicyDirective} from './policy.directive';
import {PolicyEmitterDirective} from './policy.emitter.directive';
import {PolicyPipe} from './policy.pipe';

@NgModule({
  declarations: [PolicyDirective, PolicyPipe, PolicyEmitterDirective],
  imports: [],
  providers: [],
  exports: [PolicyDirective, PolicyPipe, PolicyEmitterDirective],
})
export class PolicyModule {}
