import produce from 'immer';

import {Injectable} from '@angular/core';

import {DeviceConfig} from '@common/ble/dist/index.js';

import {
  ConnectionState,
  MemoryStatus,
  ProcessorStatus,
} from '@common/ble/dist/index.js';
import {Store} from './abstract-store';

@Injectable({
  providedIn: 'root',
})
export class ConnectionStore extends Store<ConnectionState> {
  constructor() {
    super(null);
  }

  connect(deviceConfig: DeviceConfig) {
    this.setState({
      deviceConfig: deviceConfig,
      barCoefficients: new Array<number>(),
      memoryStatus: MemoryStatus.NoMemory,
      processorStatus: ProcessorStatus.SensorDataProcessing,
      maxFrequencyIdx: Infinity,
      frequencyIdx: deviceConfig.defaultFrequencyIndex,
    });
  }

  disconnect() {
    this.setState(null);
  }

  setBarCoefficients(barCoefficients: Array<number>) {
    this.setState(
      produce(this.state(), (draft) => {
        draft.barCoefficients = barCoefficients;
      }),
    );
  }

  setMemoryStatus(status: MemoryStatus) {
    this.setState(
      produce(this.state(), (draft) => {
        draft.memoryStatus = status;
      }),
    );
  }

  setProcessorStatus(status: ProcessorStatus) {
    this.setState(
      produce(this.state(), (draft) => {
        draft.processorStatus = status;
      }),
    );
  }

  setFrequencyProperties(frequencyIdx: number, maxFrequencyIdx: number) {
    this.setState(
      produce(this.state(), (draft) => {
        draft.frequencyIdx = frequencyIdx;
        draft.maxFrequencyIdx = maxFrequencyIdx;
      }),
    );
  }
}
