import { KeyUtilsPL2 as KU } from './key-utils-pl2.js';
export var SharedObjectUtils;
(function (SharedObjectUtils) {
    function updateMergeFunc(e, change) {
        Object.keys(change.dat).forEach((k) => {
            if (change.dat[k] === null) {
                delete e.dat[k];
            }
            else {
                e.dat[k] = change.dat[k];
            }
        });
        e.uAt = String(new Date().getTime());
        return e;
    }
    SharedObjectUtils.updateMergeFunc = updateMergeFunc;
    function generateCellId(uId) {
        const r = KU.generateMId(!!uId ? 5 : 8).replace(/[\-_]/g, '0');
        const nextId = `a${!!uId ? uId.substring(0, 3) : ''}${r}`;
        return nextId;
    }
    SharedObjectUtils.generateCellId = generateCellId;
})(SharedObjectUtils || (SharedObjectUtils = {}));
