import { ContainerWalk } from '../entry-container/container-walk.js';
import { IndexManagerVisitor } from '../entry-container/index-manager-visitor.js';
// deprecated name
export class EntryIndexAttributeInitializer {
    constructor(params) {
        this.params = params;
    }
    init(request, userId) {
        const im = new IndexManagerVisitor(request.pSK, request.mId, userId, request.cT);
        return this.initWithCustomIndexManager(im, request.ec);
    }
    initWithCustomIndexManager(im, ec) {
        const entries = new Array();
        const response = new Array();
        ec.forEach((container) => {
            const walk = new ContainerWalk(im, this.params);
            const cEntries = walk.doWalk(container);
            entries.push(...cEntries);
            response.push(cEntries[0]);
        });
        return { response, entries };
    }
}
export class EntryAttributeInitializer extends EntryIndexAttributeInitializer {
}
