import {Injectable} from '@angular/core';

import {RouteStore} from '@stores/route.store';
import {
  RetryOperationStore,
  RetryOperationState,
} from '@stores/retry-operation.store';
import {UtilsPL2 as U} from '@common/utils/dist/index.js';

@Injectable({
  providedIn: 'root',
})
export class RetryOperationService {
  constructor(
    private retryOperationStore: RetryOperationStore,
    private routeStore: RouteStore,
  ) {}

  retryMaybe(key: string, canRetry: () => boolean, retry: () => void) {
    if (canRetry() && this.retryOperationStore.shouldRetry(key)) {
      retry();
      this.forgetOperation();
    }
  }

  retryAfterAuth(
    operation: string,
    isStudent: boolean,
    destinationUrl?: string,
    authPath?: string,
  ) {
    const dUrl = destinationUrl || this.routeStore.state().url;
    authPath = authPath || (isStudent ? 'class-code' : 'sign-in');
    this.retryAfter(operation, dUrl, () => this.routeStore.auth(authPath));
  }

  retryCurrentOperationAfterAuth(isStudent: boolean, destinationUrl?: string) {
    this.retryAfterAuth(
      this.retryOperationStore.state().operation,
      isStudent,
      destinationUrl,
    );
  }

  retryAfter(operation: string, destinationUrl: string, after: () => void) {
    this._setStateAndSave({operation: operation, url: destinationUrl});
    after();
  }

  retry() {
    this.retryOperationStore.loadStorage();
    const retryState = this.retryOperationStore.state();
    if (!U.isEmpty(retryState?.url)) {
      this.routeStore.url(retryState.url);
      this.forgetUrl();
    }
  }

  forgetOperation() {
    if (!U.isEmpty(this.retryOperationStore.state()?.operation)) {
      this._setStateAndSave({
        operation: null,
        url: this.retryOperationStore.state().url,
      });
    }
  }

  forgetUrl() {
    if (!U.isEmpty(this.retryOperationStore.state()?.url)) {
      this._setStateAndSave({
        operation: this.retryOperationStore.state().operation,
        url: null,
      });
    }
  }

  forget() {
    this._setStateAndSave({operation: null, url: null});
  }

  private _setStateAndSave(state: RetryOperationState) {
    this.retryOperationStore.setState(state);
    this.retryOperationStore.saveToStorage();
  }
}
