export const lessonVersionNameMaxLength = 21;
const curriculumPKLookupUnexported = {
    pocketlab: '3ZsE6Df70008UzW7',
    keslerPro: 'RP-R6wEl0008-BcR',
    keslerDemo: 'kUCQ5-Cw00086qzk',
    conceptualAcademyBiology: 'RYZABohq00088OyP',
    conceptualAcademyChemistry: 'F0UGST3n0008mBR9',
    conceptualAcademyPhysics: 'IpDI90490008byj0',
    conceptualAcademyIntegrated: 'zlNOkjm80008e92D',
    conceptualAcademyIntegratedPhysicsChemistry: 'ntu8vXPn0008wOcf',
    hmhK5en: 't1eL-WxL0008Qfpj',
    hmhK5es: 'WqmDAU81000836dj',
    hmhK68en: 'RTCGXlTK0008iGH5',
    hmhK68es: 'tFaqg-2Q0008e5vH',
    openSciEd: '_lgYICH10008jy-R',
    amoebaSisters: 'azGSVd8Y0008gn5T',
    keslerSpanglerScience: 'QxGrJQsu0008a7ek',
    keslerAlphabeticalProductList: 'ptGlUbw10008QEG1',
};
export const curriculumPKLookup = curriculumPKLookupUnexported;
