import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { indexManagerChainFactory, } from '../index-managers/index-manager-core.js';
export class IndexManagerVisitor {
    constructor(pSK, mId, uId, chainType, imc) {
        this.pSK = pSK;
        this.mId = mId;
        this.uId = uId;
        this.chainType = chainType;
        this._stack = [];
        this._currentParentNode = { sK: this.pSK, mId: this.mId };
        this._indexManagerChain =
            imc ?? indexManagerChainFactory({ uId: this.uId }, this.chainType);
    }
    visit(e, params = {}) {
        return this._indexManagerChain.setIndexAttributes(e, this._currentParentNode, params);
    }
    stepInto(e) {
        this._currentParentNode = { mId: e.mId, sK: e.sK };
        this._stack.push(e);
    }
    stepOut() {
        this._stack.pop();
        const pe = this._stack[this._stack.length - 1];
        if (U.isEmpty(pe)) {
            this._currentParentNode = { mId: this.mId, sK: this.pSK };
        }
        else {
            this._currentParentNode = { mId: pe.mId, sK: pe.sK };
        }
    }
}
