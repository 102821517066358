import { ValidatorFactory, } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { EntryValidatorFactory } from './entry-validator-factory.js';
import { DashboardIdxEntryValidatorFactory } from './dashboard-idx-entry-validator-factory.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { SortableEntryValidatorFactory } from './sortable-entry-validator-factory.js';
export class SectionEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.sectionEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'n':
            case 'pos':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'uN':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'isC':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'sS':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            default:
                if (EU.entryAtts.includes(attr)) {
                    return new EntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.sortableEntryAtts.includes(attr)) {
                    return new SortableEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else {
                    return new DashboardIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
        }
    }
}
