import { all, any, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { SectionAuthPolicy } from './section-auth-policy.js';
export class PhetSectionAuthPolicy extends SectionAuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return any(this.requestInitiatorIs(1 /* RequestInitiator.CopyGatewayDomain */), this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */), all(this.tleIsNotReadOnly, this.userIsTeacher, this.userIsSetAsCreatorFor(entry), this.userIsPrivilegedParticipantInAuthScope))();
    }
    async canRead(entry) {
        return super.canRead(entry);
    }
    async canUpdate(entry) {
        return all(hasNoBlacklistedAttsIn(entry), this.tleIsNotReadOnly, any(this.userIsPrivilegedParticipantInAuthScope, this._userIsNotSettingNameIfLrUserIn(entry)))();
    }
    async canDelete(entry) {
        return super.canDelete(entry);
    }
    _userIsNotSettingNameIfLrUserIn(entry) {
        return all(hasNoBlacklistedAttsIn(entry, 'n'), this.userIsLrUser);
    }
}
