import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {UserUtilsPL2 as UU} from '@common/utils/dist/index.js';

import {UserStore} from '@stores/user.store';

import {ActionPolicy} from '@directives/policy/action-policy';

@Injectable()
export class ActAsTeacherPolicyService implements ActionPolicy {
  constructor(private userStore: UserStore) {}

  can$(): Observable<boolean> {
    return this.userStore.state$.pipe(map((user) => UU.isTeacher(user)));
  }
}
