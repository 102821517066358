import {Injectable} from '@angular/core';

import {ConnectionService} from './connection.service';
import {BluetoothConnectionFactory} from '@common/ble/dist/index.js';

@Injectable()
export class BluetoothConnectionService {
  constructor(private connectionService: ConnectionService) {}

  initBluetoothDevice(...namePrefixes: Array<string>): Promise<void> {
    return new BluetoothConnectionFactory()
      .initBluetoothDevice(namePrefixes)
      .then((c) => this.connectionService.initConnection(c));
  }
}
