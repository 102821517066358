import { any, all, hasOnlyWhitelistedAttrIn } from '../utils/auth-utils.js';
import { LabReportAuthPolicy } from './lab-report-auth-policy.js';
export class StudentCopyAuthPolicy extends LabReportAuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI, fetchStudentEntry) {
        super(entries, user, readOnly, fetchEntry, rI, fetchStudentEntry);
    }
    async canCreate(entry) {
        return super.canCreate(entry);
    }
    async canRead(_entry) {
        return any(this.userIsParticipantInAuthScope, this.user?.auth?.lesson?.canRead)();
    }
    async canUpdate(entry) {
        // This is only necessary because due to how the the app side operation state machine works, update operations
        // can be registered which were meant to be on delayed create states (which would have just resulted in a
        // modification of the create operation) but because of timing, landed on a created student copy
        return all(hasOnlyWhitelistedAttrIn(entry, ['lGI', 'aG']), this.userIsPrivilegedParticipantInAuthScope)();
    }
    async canDelete(entry) {
        return super.canDelete(entry);
    }
}
