import {Observable, BehaviorSubject} from 'rxjs';

export interface StateTransformer<D, S> {
  transform(data: D, state: S): S;
  hasChanged?: boolean;
}

export interface PartialStateTransformer<D, S> extends StateTransformer<D, S> {
  nextHandler: PartialStateTransformer<D, S>;
}

export abstract class Store<S> {
  state$: Observable<S>;
  private _state$: BehaviorSubject<S>;

  protected constructor(initialState: S) {
    this._state$ = new BehaviorSubject(initialState);
    this.state$ = this._state$.asObservable();
  }

  state(): S {
    return this._state$.getValue();
  }

  setState(nextState: S) {
    this._state$.next(nextState);
  }

  modifyStateWithTransformer<D>(data: D, transformer: StateTransformer<D, S>) {
    const nextState = transformer.transform(data, this.state());
    if (nextState !== this.state() || !!transformer.hasChanged) {
      this.setState(nextState);
    }
  }
}
