import {UtilsPL2 as U} from '@common/utils/dist/index.js';

import {
  GraphConfig,
  GraphConfigs,
  Visualization,
} from '@constants/graph.config';
import {DeviceConfig} from '@common/ble/dist/index.js';

export module GraphConfigUtils {
  const gCMap: {[id: string]: GraphConfig} = {};
  GraphConfigs.forEach((gC) => {
    gCMap[gC.id] = gC;
  });

  export function configWithId(graphConfigId: string): GraphConfig {
    return gCMap[graphConfigId];
  }

  export function thridPartyConfigs(deviceConfig: DeviceConfig): GraphConfig[] {
    return deviceConfig.thirdPartyConfigs.map((gCId) => configWithId(gCId));
  }

  export function configsWithIds(graphConfigIds: string[]): GraphConfig[] {
    return graphConfigIds.map((gCId) => configWithId(gCId));
  }

  export function isImportedData(graphConfig: GraphConfig): boolean {
    return graphConfig.id === 'imported-data';
  }

  export function isButtonDisplay(
    graphConfig: GraphConfig,
    visIdx: number,
  ): boolean {
    return graphConfig.visualizations[visIdx] === Visualization.Button;
  }

  export function excludeThirdParty(
    gCIds: string[],
    includeImportedData?: boolean,
  ): GraphConfig[] {
    const configs = configsWithIds(gCIds);
    return configs.filter(
      (c) =>
        !U.isEmpty(c) &&
        (U.isEmpty(c.thirdPartyData) ||
          (includeImportedData && c?.id === 'imported-data')),
    );
  }

  export function extractThirdParty(configs: GraphConfig[]): GraphConfig[] {
    return configs.filter((c) => !U.isEmpty(c?.thirdPartyData));
  }

  export function translateLabels(
    labels: string[],
    graphTranslations: {[key: string]: string},
  ): Array<string> {
    labels = [...labels];
    if (!!labels) {
      return labels.map((l) => translateLabel(l, graphTranslations));
    }
    return labels;
  }

  export function translateLabel(
    label: string,
    graphTranslations: {[key: string]: string},
  ): string {
    if (!U.isEmpty(label) && !U.isEmpty(graphTranslations[label])) {
      return graphTranslations[label];
    } else {
      return label;
    }
  }
}
