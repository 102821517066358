import {Inject, Injectable, OnDestroy} from '@angular/core';

import {
  Observable,
  ReplaySubject,
  Subject,
  finalize,
  map,
  merge,
  takeUntil,
} from 'rxjs';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

import {TranslateService} from '@ngx-translate/core';

import {DataConfigStore} from '@stores/data-config.store';

import {ConnectionStore} from '@stores/connection.store';

import {Connection} from '@common/ble/dist/index.js';

import {ConnectionUtils as CU} from '@cloudlab/utils/connection-utils';

import {
  ConnectionState,
  MemoryStatus,
  ProcessorStatus,
  StartSensorsParams,
  SensorReading,
  MessageType,
} from '@common/ble/dist/index.js';
import {MemoryConfigurator} from '@common/ble/dist/index.js';
import {DeviceConfigurator} from '@common/ble/dist/index.js';
import {CrashModeConfigurator} from '@common/ble/dist/index.js';
import {NotificationStore} from '@stores/notification.store';
import {
  SensorReadingsTransformer,
  SensorInitializationChainTransformer,
} from '@stores/notification-transformers/sensor-readings-notification-transformers';
import {SensorInitializationDataConfigTransformer} from '@stores/data-config-transformers/sensor-initialization-data-config-transformers';
import {ReadingPipelineDataConfigTransformer} from '@stores/data-config-transformers/reading-pipeline-data-config-transformer';
import {UpdatePointsDataConfigStep} from '@stores/data-config-transformers/update-points-data-config-step';
import {SensorInitializingConnectionTransformer} from '@cloudlab/stores/connection-transformers/sensor-initializing-connection-transformer';
import {SensorInitializedConnectionTransformer} from '@cloudlab/stores/connection-transformers/sensor-initialized-connection-transformer';
import {SensorReadingConnectionTransformer} from '@cloudlab/stores/connection-transformers/sensor-reading-connection-transformer';

import {isUsingWindows} from '@utils/platform-utils';
import {
  SET_TRANSFER_INTERVAL,
  START_FLASH_TRANSFER,
} from '@common/ble/dist/pl-command-codes';

// Hina is my little b
@Injectable({
  providedIn: 'root',
})
export class ConnectionService implements OnDestroy {
  unexpectedDisconnect$ = new Subject<void>();

  private _connection: Connection;

  private _destroyed$ = new ReplaySubject<boolean>();
  private _sensorChanged$ = new ReplaySubject<boolean>();

  constructor(
    private connectionStore: ConnectionStore,
    private dataConfigStore: DataConfigStore,
    private notificationStore: NotificationStore,
    private translateService: TranslateService,
    @Inject('WINDOW')
    private window: any,
  ) {}

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
    this.unexpectedDisconnect$.complete();
    this.disconnect();
  }

  async initConnection(connection: Connection): Promise<void> {
    if (!U.isEmpty(this._connection)) {
      this._connection.disconnect();
    }
    this._connection = connection;
    return this._connection.init().then((connectionState) => {
      console.log('ConnectionService.initConnection', connectionState);
      this.connectionStore.setState({
        ...this.connectionStore.state(),
        ...connectionState,
      });
      this._connection.disconnect$
        .pipe(takeUntil(this._destroyed$))
        .subscribe(() => {
          this.disconnect();
          this.unexpectedDisconnect$.next();
        });
    });
  }

  startSensors(params: StartSensorsParams) {
    // the startSensor subscription is managed inside the connection service. The returned observable
    // is to inform the user that the sensors have been initialized
    const readingTransformer = new SensorReadingsTransformer(
      this.connectionStore.state().deviceConfig,
    );
    const initializationTransformer = new SensorInitializationChainTransformer(
      this.connectionStore.state().deviceConfig,
      params.frequencyIndex,
    );
    const updatePointsDataConfigStep = new UpdatePointsDataConfigStep();
    const readingConnectionTransformer =
      new SensorReadingConnectionTransformer();

    this._unsubscribeFromSensors();
    this._connection
      .startSensors(params)
      .pipe(takeUntil(merge(this._destroyed$, this._sensorChanged$)))
      .subscribe(
        (data) => {
          if (CU.isMessageType(MessageType.Initialization, data)) {
            this.connectionStore.modifyStateWithTransformer(
              data,
              new SensorInitializingConnectionTransformer(),
            );
            this.dataConfigStore.modifyStateWithTransformer(
              data,
              new SensorInitializationDataConfigTransformer(
                params.frequencyIndex,
              ),
            );
            this.notificationStore.modifyStateWithTransformer(
              data,
              initializationTransformer,
            );
            this.connectionStore.modifyStateWithTransformer(
              data,
              new SensorInitializingConnectionTransformer(),
            );
          } else if (
            CU.isMessageType(MessageType.CompletedInitialization, data)
          ) {
            this.connectionStore.modifyStateWithTransformer(
              data,
              new SensorInitializedConnectionTransformer(),
            );
          } else if (CU.isMessageType(MessageType.Reading, data)) {
            // Now we're rolling
            this.dataConfigStore.modifyStateWithTransformer(
              data as SensorReading[],
              updatePointsDataConfigStep,
            );
            this.notificationStore.modifyStateWithTransformer(
              data,
              readingTransformer,
            );
            this.connectionStore.modifyStateWithTransformer(
              data,
              readingConnectionTransformer,
            );
          }
        },
        (err) => console.warn(err),
      );
  }

  async calibrate(
    sensorId: string,
    calibrationId?: string,
    payload?: number[],
  ): Promise<ConnectionState> {
    return this._connection
      .calibrate(sensorId, calibrationId, payload)
      .then((connectionState) => {
        this.connectionStore.setState({
          ...this.connectionStore.state(),
          ...connectionState,
        });
        return this.connectionStore.state();
      });
  }

  disconnect() {
    if (!U.isEmpty(this._connection)) {
      this._connection.disconnect();
    }
    this._unsubscribeFromSensors();
    this.connectionStore.disconnect();
  }

  // Memory strategy stuff (no null checks because we want to see errors)

  initMemorySetup(): Promise<void> {
    if (
      this.connectionStore.state().memoryStatus === MemoryStatus.Empty ||
      this.connectionStore.state().memoryStatus === MemoryStatus.Recorded
    ) {
      this._unsubscribeFromSensors();
      return this.memoryConfigurator().enterMode();
    }
  }

  async startRecordingToMemory(params: StartSensorsParams): Promise<void> {
    return this._connection
      .memoryConfigurator()
      .startRecordingToMemory(params)
      .then(() => {
        this.connectionStore.setMemoryStatus(MemoryStatus.Recording);
      });
  }

  async stopRecordingToMemory(): Promise<void> {
    return this._connection
      .memoryConfigurator()
      .stopRecordingToMemory()
      .then(() => {
        this.connectionStore.setMemoryStatus(MemoryStatus.Recorded);
      });
  }

  startMemoryDownload(): Promise<Observable<void>> {
    this._unsubscribeFromSensors();
    const translations = this.translateService.instant('graph-config');
    const transformer = new ReadingPipelineDataConfigTransformer(
      this.connectionStore.state().deviceConfig,
      translations,
    );
    return this._stopIfRecording()
      .then(() => {
        if (isUsingWindows(this.window)) {
          // In Windows, it's required we change the transfer rate otherwise the memory download
          // will get stuck mainly when using Odyssey.
          // Also see: https://github.com/jrlitzenberger/pocketlab-forge/blob/f10a765398f64be8b1a046acdc80d8b7d6af4a75/src/app/components/test-and-validation-view/test-and-validation-view.component.ts#L1122
          return this._connection.memoryConfigurator().setTransferRate(180);
        }
      })
      .then(() => {
        this.connectionStore.setMemoryStatus(MemoryStatus.Downloading);
        return this._connection.memoryConfigurator().startMemoryDownload();
      })
      .then(
        (o) =>
          o.pipe(
            map((readings) =>
              this.dataConfigStore.modifyStateWithTransformer(
                readings,
                transformer,
              ),
            ),
            takeUntil(this._destroyed$),
            finalize(() =>
              this.connectionStore.setMemoryStatus(MemoryStatus.Recorded),
            ),
          ),
        (err) => {
          console.warn(err);
          this.connectionStore.setMemoryStatus(MemoryStatus.Recorded);
          return Promise.reject();
        },
      );
  }

  async stopMemoryDownload(): Promise<void> {
    return this._connection
      .memoryConfigurator()
      .stopMemoryDownload()
      .then(() => {
        this.connectionStore.setMemoryStatus(MemoryStatus.Recorded);
      });
  }

  async clearMemory(): Promise<void> {
    return this._stopIfRecording()
      .then(() => this.memoryConfigurator().clearMemory())
      .then(() => {
        this.connectionStore.setMemoryStatus(MemoryStatus.Empty);
      });
  }

  exitMemoryMode(): Promise<void> {
    return this._connection.memoryConfigurator().exitMode();
  }

  readName(): string {
    return this._connection.readName();
  }

  writeName(name: string): Promise<void> {
    return this._connection.deviceConfigurator().writeName(name);
  }

  readBatteryStatus(): Promise<number> {
    return this._connection.deviceConfigurator().readBatteryStatus();
  }

  readFirmwareVersion(): Promise<string> {
    return this._connection.deviceConfigurator().readFirmwareVersion();
  }

  shutdownDevice(): Promise<void> {
    return this._connection.deviceConfigurator().shutdown();
  }

  saveSensorSettings(): Promise<void> {
    return this._connection.deviceConfigurator().saveSensorSettings();
  }

  initCrashMode(): Promise<void> {
    this._unsubscribeFromSensors();
    this.connectionStore.setProcessorStatus(
      ProcessorStatus.CrashModeStatusIdle,
    );
    return this._connection.crashModeConfigurator().initMode();
  }

  exitCrashMode(): Promise<void> {
    return this._connection.crashModeConfigurator().exitMode();
  }

  async startRecordingInCrashMode(): Promise<Observable<void>> {
    const translations = this.translateService.instant('graph-config');
    const transformer = new ReadingPipelineDataConfigTransformer(
      this.connectionStore.state().deviceConfig,
      translations,
    );
    return this._connection
      .crashModeConfigurator()
      .record()
      .then((o) =>
        o.pipe(
          map((readings) => {
            if (readings.length > 0) {
              const processingStatus = readings[0].status;
              if (
                processingStatus !==
                this.connectionStore.state().processorStatus
              ) {
                this.connectionStore.setProcessorStatus(readings[0].status);
              }
              this.dataConfigStore.modifyStateWithTransformer(
                readings,
                transformer,
              );
            }
          }),
          takeUntil(this._destroyed$),
        ),
      );
  }

  memoryConfigurator(): MemoryConfigurator {
    return this._connection.memoryConfigurator();
  }

  deviceConfigurator(): DeviceConfigurator {
    return this._connection.deviceConfigurator();
  }

  crashModeConfigurator(): CrashModeConfigurator {
    return this._connection.crashModeConfigurator();
  }

  // hardware dev feature

  writeCommand(command: number[]): Promise<void> {
    return this._connection.writeCommand(command);
  }

  readCommand(): Promise<any> {
    return this._connection.readCommand();
  }

  private _unsubscribeFromSensors() {
    if (!U.isEmpty(this._sensorChanged$) && !this._sensorChanged$.isStopped) {
      this._sensorChanged$.next(true);
      this._sensorChanged$.complete();
    }
    this._sensorChanged$ = new ReplaySubject<boolean>();
  }

  private _stopIfRecording(): Promise<void> {
    if (this.connectionStore.state().memoryStatus === MemoryStatus.Recording) {
      return this._connection.memoryConfigurator().stopRecordingToMemory();
    } else {
      return Promise.resolve();
    }
  }
}
