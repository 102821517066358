import {Injectable} from '@angular/core';

import {Observable, from, EMPTY, catchError, throwError} from 'rxjs';

import {UtilsPL2} from '@common/utils/dist/index.js';
import {StorageWrapperService} from './storage-wrapper.service';

import {FileUtils as FU} from '@utils/file-utils';
import {SignOutAction} from '@cloudlab/actions/sign-out.action';

@Injectable()
export class S3Service {
  constructor(
    private storage: StorageWrapperService,
    private signOutAction: SignOutAction,
  ) {}

  uploadFile(file: File, userId: string): Observable<{key?: string}> {
    return this._upload(file, userId, file.type).pipe(
      catchError((err: any) => this.handleS3Error(err)),
    );
  }

  uploadString(
    body: string,
    userId: string,
    contentType: string,
    metadata?: Record<string, string>,
  ): Observable<{key?: string}> {
    return this._upload(body, userId, contentType, metadata).pipe(
      catchError((err: any) => this.handleS3Error(err)),
    );
  }

  uploadDataUri(dataUri: string, userId: string): Observable<{key?: string}> {
    const blob = FU.convertDataURItoBlob(dataUri);
    const file = new File([blob], this._generateKey(blob.type, userId), {
      type: blob.type,
      lastModified: new Date().getTime(),
    });
    return this._upload(file, userId, file.type).pipe(
      catchError((err: any) => this.handleS3Error(err)),
    );
  }

  getFile(key: string): Observable<string> {
    return from<Promise<string>>(
      new Promise<string>((resolve, reject) => {
        this.storage.get(key, {download: true}).then(
          (data) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = () => reject(reader.error.message);
            reader.readAsText((data as any).Body);
          },
          (err) => reject(err),
        );
      }),
    ).pipe(catchError((err: any) => this.handleS3Error(err)));
  }

  getFileUrl(key: string): Observable<Object | string> {
    if (key) {
      return from(this.storage.get(key)).pipe(
        catchError((err: any) => this.handleS3Error(err)),
      );
    } else {
      return EMPTY;
    }
  }

  removeFile(key: string): Observable<Object> {
    return from(this.storage.remove(key)).pipe(
      catchError((err: any) => this.handleS3Error(err)),
    );
  }

  private _upload(
    body: string | File,
    userId: string,
    contentType: string,
    metadata?: Record<string, string>,
  ): Observable<{key?: string}> {
    return from(
      this.storage.put(this._generateKey(contentType, userId), body, {
        contentType: contentType,
        metadata: metadata,
      }),
    ).pipe(catchError((err: any) => this.handleS3Error(err)));
  }

  private _generateKey(contentType: string, userId: string): string {
    const ext = contentType.split('/')[1];
    return `${userId}/${UtilsPL2.generateId()}.${ext.split('+')[0]}`;
  }

  private handleS3Error(error: any): Observable<never> {
    if (
      error.name === 'ExpiredToken' ||
      error.name === 'AccessDenied' ||
      error.name === 'InvalidSessionException'
    ) {
      // this.userStore
      //   .signOut()
      //   .pipe(catchError(() => EMPTY))
      //   .subscribe();

      this.signOutAction.execute().subscribe();

      console.error(`S3 authorization error (${error.name}):`, error);
    } else {
      console.error('Unexpected S3 error:', error);
    }

    return throwError(error);
  }
}
