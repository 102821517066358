import { EntryType } from '../models/models-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class ClassCodeIndexManager {
    setIndexAttributes(entry) {
        const output = entry;
        if (entry.t === EntryType.Class &&
            U.isEmpty(output.gCId) &&
            U.isEmpty(output.cCId) &&
            U.isEmpty(output.edCId)) {
            output.cC = U.generateCode();
        }
        return output;
    }
}
