import {Injectable, OnDestroy} from '@angular/core';

import {Store} from '@stores/abstract-store';
import {interval, takeUntil, ReplaySubject} from 'rxjs';

export enum ActiveAPIService {
  WebSocketService,
  EntryAPIService,
}

@Injectable({
  providedIn: 'root',
})
export class APIServiceSwitchStore
  extends Store<ActiveAPIService>
  implements OnDestroy
{
  private _destroyed$ = new ReplaySubject<boolean>();

  constructor() {
    super(ActiveAPIService.WebSocketService);

    interval(900_000)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.setState(ActiveAPIService.WebSocketService));
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
