import { from, fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CharacteristicCache } from './characteristic-cache.js';
import { PowerlabMemoryConfigurator } from './powerlab-memory-configurator.js';
import { PowerlabCrashModeConfigurator } from './powerlab-crash-mode-configurator.js';
import { CommandQueue } from './command-queue.js';
import { NotificationConfigurator } from './notification-configurator.js';
import { PowerlabDataProcessorBuilder } from './powerlab-data-processor-builder.js';
import { PowerlabSensorCoordinator } from './powerlab-sensor-coordinator.js';
import { BitwiseCommandBuilder } from './bitwise-command-builder.js';
import { PowerlabDeviceConfigurator } from './powerlab-device-configurator.js';
import { RealtimeDataProcessorStrategy } from './realtime-data-processor-strategy.js';
import { connectionStateDefault, } from './connection-types.js';
import { PowerlabCalibratorFactory } from './powerlab-calibrator-factory.js';
export class PowerlabStrategy {
    constructor(gattServer, deviceConfig) {
        this.deviceConfig = deviceConfig;
        this._characteristicCache = new CharacteristicCache(gattServer, this.deviceConfig);
        this._commandQueue = new CommandQueue(this._characteristicCache, deviceConfig);
        this._notificationConfigurator = new NotificationConfigurator(this._characteristicCache);
        this._sensorCoordinator = new PowerlabSensorCoordinator(this.deviceConfig, this._notificationConfigurator, new BitwiseCommandBuilder(deviceConfig), this._commandQueue);
        this._deviceConfigurator = new PowerlabDeviceConfigurator(this._characteristicCache, this.deviceConfig, this._commandQueue);
    }
    async init() {
        return this._initNotifications()
            .then(() => {
            this._memoryConfigurator = new PowerlabMemoryConfigurator(this.deviceConfig, this._sensorCoordinator, this._commandQueue, this._data$);
            this._crashModeConfigurator = new PowerlabCrashModeConfigurator(this.deviceConfig, this._sensorCoordinator, this._commandQueue, this._characteristicCache, this._data$);
        })
            .then(() => this._memoryConfigurator.retrieveMemoryState())
            .then((memoryStatus) => ({
            ...connectionStateDefault,
            deviceConfig: this.deviceConfig,
            memoryStatus: memoryStatus,
        }))
            .catch((err) => {
            console.error(err);
            return Promise.reject(err);
        });
    }
    startSensors(params) {
        console.log('PowerlabStrategy.startSensors', params);
        const uniqueSensorIds = [...new Set(params.sensorIds)];
        const builder = new PowerlabDataProcessorBuilder(this._data$);
        return from(this._startPolling().then(() => this._sensorCoordinator.enableSensors(uniqueSensorIds, params.frequencyIndex))).pipe(switchMap(() => builder.build(uniqueSensorIds.map((sensorId) => ({ sensorId })), new RealtimeDataProcessorStrategy(this.deviceConfig, params))));
    }
    async calibrate(sensorId, calibrationId, payload) {
        const factory = new PowerlabCalibratorFactory();
        return factory
            .build(sensorId)
            .calibrate(this._commandQueue, calibrationId, payload)
            .then(() => ({}));
    }
    memoryConfigurator() {
        return this._memoryConfigurator;
    }
    deviceConfigurator() {
        return this._deviceConfigurator;
    }
    crashModeConfigurator() {
        return this._crashModeConfigurator;
    }
    disconnect() {
        this._commandQueue.close();
    }
    writeCommand(command) {
        return this._commandQueue.addWithoutValidation(command);
    }
    readCommand() {
        return this._commandQueue.addRead();
    }
    getDataCharacteristic() {
        const anySensor = Object.values(this.deviceConfig.sensorConfigs)[0];
        return this._characteristicCache.get(anySensor.id, 'data');
    }
    getBatteryCharacteristic() {
        return this._characteristicCache.get('powerlabbattery', 'config');
    }
    _startPolling() {
        return this._commandQueue.addWithoutValidation([0x60, 0x01]);
    }
    async _initNotifications() {
        const anySensor = Object.values(this.deviceConfig.sensorConfigs)[0];
        return this._notificationConfigurator
            .enableNotifications([anySensor.id])
            .then(() => this._characteristicCache.get(anySensor.id, 'data'))
            .then((characteristic) => {
            this._data$ = fromEvent(characteristic, 'characteristicvaluechanged');
        });
    }
}
