import produce from 'immer';

import {SensorInitialization} from '@common/ble/dist/index.js';

import {StateTransformer} from '../abstract-store';

import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';

import {SensorMapperUtils as SMU} from '@cloudlab/utils/sensor-mapper-utils';

export class SensorInitializationDataConfigTransformer
  implements StateTransformer<SensorInitialization[], PL2.DataConfigState>
{
  constructor(private requestedFrequencyIdx: number) {}

  transform(
    inits: SensorInitialization[],
    dataConfigState: PL2.DataConfigState,
  ): PL2.DataConfigState {
    return produce(dataConfigState, (draft) => {
      inits.forEach((init) => {
        if (init.failed) {
          SMU.getGraphConfigIdsForReading(init).forEach((gCId) => {
            delete draft[gCId];
          });
        }
      });
      if (
        !U.isEmpty(inits) &&
        this.requestedFrequencyIdx !== inits[0].frequencyIdx
      ) {
        Object.values(draft).forEach((dvc) => {
          dvc.fIdx = inits[0].frequencyIdx;
        });
      }
    });
  }
}
