import { ServiceConfigs } from './service.config.js';
import { BmeCoefficientCalculator } from './bme-coefficient-calculator.js';
export class PlSensorConfigurator {
    constructor(deviceConfig, characteristicCache) {
        this.deviceConfig = deviceConfig;
        this.characteristicCache = characteristicCache;
    }
    calibrate(sensorId) {
        switch (sensorId) {
            case this.deviceConfig.calibrationConfig:
                const values = [];
                let calibrationCharacteristic = null;
                let configCharacteristic = null;
                return this.characteristicCache
                    .get(this.deviceConfig.calibrationConfig, 'config')
                    .then((characteristic) => {
                    configCharacteristic = characteristic;
                    const buffer = new Int8Array([2]).buffer;
                    return characteristic.writeValue(buffer);
                })
                    .then(() => this.characteristicCache.get(this.deviceConfig.calibrationConfig, 'calibration'))
                    .then((characteristic) => {
                    calibrationCharacteristic = characteristic;
                    return characteristic.readValue();
                })
                    .then((v) => {
                    values.push(v.buffer);
                    return configCharacteristic.writeValue(new Int8Array([3]).buffer);
                })
                    .then(() => calibrationCharacteristic.readValue())
                    .then((v) => {
                    values.push(v.buffer);
                    const processedValues = new BmeCoefficientCalculator().calculateForPL1(values);
                    console.log('Calibration complete');
                    console.log(processedValues);
                    return { barCoefficients: processedValues };
                });
            case undefined:
                return Promise.resolve({});
            default:
                return Promise.reject(`Sensor can\'t be calibrated: ${sensorId}`);
        }
    }
    startSensor(sensorId, frequencyIndex) {
        return this.configureSensor(sensorId, true).then(() => this._configurePeriod(sensorId, frequencyIndex));
    }
    configureSensor(sensorId, toOn) {
        let configValue = this.deviceConfig.sensorConfigs[sensorId].configValue;
        if (toOn) {
            if (!configValue) {
                configValue = 1;
            }
        }
        else {
            configValue = 0;
        }
        return this.characteristicCache
            .get(sensorId, 'config')
            .then((characteristic) => {
            if (characteristic) {
                const buffer = new Int8Array([configValue]).buffer;
                return characteristic.writeValue(buffer);
            }
            else {
                return Promise.reject('No characteristic found');
            }
        });
    }
    _configurePeriod(sensorId, frequencyIndex) {
        // 1 unit = 10ms
        // On the PL1 the gyroscope could only go up to 16hz and so we need to add this exception
        let rate;
        if (this.deviceConfig.sensorConfigs[sensorId].id === ServiceConfigs.pl1gyr.id) {
            rate = 6.25;
        }
        else {
            rate = this.deviceConfig.frequencyConfigs[frequencyIndex]?.i ?? 0;
        }
        const buffer = new Int8Array([rate]).buffer;
        return this.characteristicCache
            .get(sensorId, 'period')
            .then((characteristic) => characteristic.writeValue(buffer));
    }
}
