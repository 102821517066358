import { merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageType, } from './connection-types.js';
export class PowerlabDataProcessorBuilder {
    constructor(eventObs) {
        this.eventObs = eventObs;
    }
    build(configs, strategy) {
        if (configs.length === 0) {
            return of();
        }
        return merge(of(configs.map((e) => ({
            sensorId: e.sensorId,
            type: MessageType.CompletedInitialization,
        }))), this.eventObs.pipe(map((event) => {
            const dataView = new DataView(event.target.value.buffer);
            if (dataView.byteLength === 0) {
                return [];
            }
            return strategy.process(dataView);
        })));
    }
}
