import seedrandom from 'seedrandom';
import { nanoid } from 'nanoid';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export var KeyGenerators;
(function (KeyGenerators) {
    const urlAlphabet = 'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict';
    class SeedRandomNumberGenerator {
        constructor(seed) {
            this.rng = seedrandom(seed);
        }
        generate() {
            return this.rng();
        }
    }
    KeyGenerators.SeedRandomNumberGenerator = SeedRandomNumberGenerator;
    class SeedRandomStringGenerator {
        constructor(seed) {
            this.numberGenerator = new SeedRandomNumberGenerator(seed);
        }
        generate(length, seed) {
            if (!U.isEmpty(seed)) {
                this.numberGenerator = new SeedRandomNumberGenerator(seed);
            }
            let id = '';
            let i = length;
            while (i--) {
                id += urlAlphabet[(this.numberGenerator.generate() * 64) | 0];
            }
            return id;
        }
    }
    KeyGenerators.SeedRandomStringGenerator = SeedRandomStringGenerator;
    class NanoidStringGenerator {
        generate(length) {
            return nanoid(length);
        }
    }
    KeyGenerators.NanoidStringGenerator = NanoidStringGenerator;
    class StringGeneratorFactory {
        build(seed) {
            if (U.isEmpty(seed)) {
                return new NanoidStringGenerator();
            }
            else {
                return new SeedRandomStringGenerator(seed);
            }
        }
    }
    KeyGenerators.StringGeneratorFactory = StringGeneratorFactory;
    function generateString(length, seed) {
        const generator = new StringGeneratorFactory().build(seed);
        return generator.generate(length);
    }
    KeyGenerators.generateString = generateString;
    function generateNode(t, seed) {
        const pT = t.toString().padStart(KU.typeLength, '0');
        return pT + generateString(KU.nodeIdLength, seed);
    }
    KeyGenerators.generateNode = generateNode;
    function generateMId(seed) {
        return generateString(KU.masterIdLength, seed);
    }
    KeyGenerators.generateMId = generateMId;
    function generatePK(t, seed) {
        return `${generateMId(seed)}${generateSortKey(null, t, seed)}`;
    }
    KeyGenerators.generatePK = generatePK;
    function generateSortKey(pSK, t, seed) {
        const ppSK = (pSK || '').length > 0 ? pSK : '00';
        return ppSK + generateNode(t, seed);
    }
    KeyGenerators.generateSortKey = generateSortKey;
    function generateSortKeyWithGenerator(pSK, t, generator, seed) {
        const ppSK = (pSK || '').length > 0 ? pSK : '00';
        return ppSK + t + generator.generate(KU.nodeIdLength, seed);
    }
    KeyGenerators.generateSortKeyWithGenerator = generateSortKeyWithGenerator;
})(KeyGenerators || (KeyGenerators = {}));
