import { ValidatorFactory, } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryIdValidatorFactory } from './entry-id-validator-factory.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
export class EntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.entryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 't':
            case 'uId':
            case 'rC':
            case 'cAt':
            case 'uAt':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'n':
            case 'isA':
                return [new LenientValidator(value, this.builderFactory(attr))];
            default:
                return new EntryIdValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
    }
}
