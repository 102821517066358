import produce from 'immer';

import {
  MessageType,
  ProcessorStatus,
  ConnectionState,
  SensorMessage,
} from '@common/ble/dist/index.js';

import {StateTransformer} from '../abstract-store';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

export class SensorInitializedConnectionTransformer
  implements StateTransformer<SensorMessage[], ConnectionState>
{
  constructor() {}

  transform(
    sensorMessages: SensorMessage[],
    connectionState: ConnectionState,
  ): ConnectionState {
    if (U.isEmpty(sensorMessages)) {
      return connectionState;
    }
    if (sensorMessages[0].type !== MessageType.CompletedInitialization) {
      return connectionState;
    }
    return produce(connectionState, (draft) => {
      draft.processorStatus = ProcessorStatus.SensorDataInitialized;
    });
  }
}
