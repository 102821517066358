export function nMonthsBefore(months, end) {
    const endDate = end ? end : new Date();
    const startDate = new Date(endDate.getTime());
    startDate.setUTCDate(1);
    startDate.setUTCHours(0, 0, 0, 0);
    startDate.setUTCMonth(endDate.getUTCMonth() - months);
    return { start: startDate, end: endDate };
}
export function monthDiff(start, end) {
    return (end.getMonth() -
        start.getMonth() +
        12 * (end.getFullYear() - start.getFullYear()));
}
export function twoDigitDate(epoch) {
    const parts = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        year: '2-digit',
    }).formatToParts(epoch);
    return parts
        .map((p) => ({ [`${p.type}`]: p.value }))
        .reduce((p, c) => Object.assign(p, c), {});
}
export function formatTimestamp(ts, showComplete = false) {
    const epoch = parseInt(ts, 10);
    let formattedDate;
    if (showComplete) {
        const date = new Date(epoch);
        const options = {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // 24-hour format
        };
        formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    }
    else {
        formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            year: 'numeric',
            day: 'numeric',
        }).format(epoch);
    }
    return showComplete ? formattedDate.replace(/,/g, '') : formattedDate;
}
export function millisAtMidnight(millis) {
    const date = new Date(parseInt(millis, 10));
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
}
