import produce from 'immer';
import {
  SensorMessage,
  MessageType,
  UserSpaceReading,
  DeviceConfig,
} from '@common/ble/dist/index.js';

import {StateTransformer} from '../abstract-store';

import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';

import {deserializeUserSpaceData} from '@utils/user-space-data-utils';

import {DataConfigStateUtils as DCSU} from '@utils/data-config-state-utils';

export class UserSpaceDataConfigStep
  implements StateTransformer<SensorMessage[], PL2.DataConfigState>
{
  constructor(
    private deviceConfig: DeviceConfig,
    private translations: {[key: string]: string},
  ) {}

  transform(
    sensorMessage: SensorMessage[],
    dataConfigState: PL2.DataConfigState,
  ): PL2.DataConfigState {
    if (U.isEmpty(sensorMessage)) {
      return dataConfigState;
    }
    if (sensorMessage[0].type !== MessageType.UserSpace) {
      return dataConfigState;
    }
    const data = (<UserSpaceReading>sensorMessage[0]).userSpaceData;
    const graphParams = deserializeUserSpaceData(data, this.deviceConfig);
    if (graphParams.gCIds?.length < 1) {
      return dataConfigState;
    }
    const opts = graphParams.gCIds.reduce((acc, gCId) => {
      acc[gCId] = {
        f: this.deviceConfig.frequencyConfigs[graphParams.frequencyIndex].f,
        sD: graphParams.startDate,
        rT: false,
        dS: PL2.DataStatus.Setup,
        cT: 0,
      };
      return acc;
    }, {});
    return produce(dataConfigState, (draft) => {
      DCSU.buildDataConfigs(opts, draft, this.translations);
    });
  }
}
