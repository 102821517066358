import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

import {ServiceCommsWrapperService} from './service-comms-wrapper.service';

const initParams = {
  response: true,
};

@Injectable()
export class APIService {
  constructor(private serviceCommsWrapperService: ServiceCommsWrapperService) {}

  getPublic<T>(apiName: string, url: string, params: any): Observable<T> {
    const that = this;
    return new Observable<T>(function subscribe(subscriber) {
      that.serviceCommsWrapperService
        .get(apiName, url, {
          ...params,
          ...initParams,
        })
        .then(
          (response: any) => {
            subscriber.next(response.data);
          },
          (err) => {
            subscriber.error(err);
          },
        )
        .finally(() => subscriber.complete());
    });
  }

  get<T>(apiName: string, url: string, params: any): Observable<T> {
    const that = this;
    return new Observable<T>(function subscribe(subscriber) {
      that._auth().then((session) => {
        that.serviceCommsWrapperService
          .get(apiName, url, {
            ...params,
            ...initParams,
            ...that._headers(session),
          })
          .then(
            (response: any) => {
              subscriber.next(response.data);
            },
            (err) => {
              subscriber.error(err);
            },
          )
          .finally(() => subscriber.complete());
      });
    });
  }

  post<T>(apiName: string, url: string, params: any): Observable<T> {
    const that = this;
    return new Observable<T>(function subscribe(subscriber) {
      that._auth().then((session) => {
        that.serviceCommsWrapperService
          .post(apiName, url, {
            ...params,
            ...that._headers(session),
          })
          .then(
            (response: T) => {
              subscriber.next(response);
            },
            (err) => {
              console.warn(err);
              subscriber.error(err);
            },
          )
          .finally(() => subscriber.complete());
      });
    });
  }

  postPublic<T>(apiName: string, url: string, params: any): Observable<T> {
    const that = this;
    return new Observable<T>(function subscribe(subscriber) {
      that.serviceCommsWrapperService
        .post(apiName, url, {
          ...params,
        })
        .then(
          (response: T) => {
            subscriber.next(response);
          },
          (err) => {
            console.warn(err);
            subscriber.error(err);
          },
        )
        .finally(() => subscriber.complete());
    });
  }

  put<T>(apiName: string, url: string, params: any): Observable<T> {
    const that = this;
    return new Observable<T>(function subscribe(subscriber) {
      that._auth().then((session) => {
        that.serviceCommsWrapperService
          .put(apiName, url, {
            ...params,
            ...that._headers(session),
          })
          .then(
            (response: T) => {
              subscriber.next(response);
            },
            (err) => {
              console.warn(err);
              subscriber.error(err);
            },
          )
          .finally(() => subscriber.complete());
      });
    });
  }

  delete<T>(apiName: string, url: string, params: any): Observable<T> {
    const that = this;
    return new Observable<T>(function subscribe(subscriber) {
      that._auth().then((session) => {
        that.serviceCommsWrapperService
          .del(apiName, url, {
            ...params,
            ...that._headers(session),
          })
          .then(
            (response: T) => {
              subscriber.next(response);
            },
            (err) => {
              console.warn(err);
              subscriber.error(err);
            },
          )
          .finally(() => subscriber.complete());
      });
    });
  }

  private _headers(session?: {
    getIdToken: () => {getJwtToken(): () => string};
  }): {} {
    return U.isEmpty(session)
      ? {}
      : {headers: {Authorization: session.getIdToken().getJwtToken()}};
  }

  private _auth(): Promise<{getIdToken: () => {getJwtToken(): () => string}}> {
    return this.serviceCommsWrapperService.currentSession().catch(() => {
      console.warn('Refresh token expired or revoked.');
      return this.serviceCommsWrapperService
        .signOut()
        .then(() => Promise.reject());
    });
  }
}
