import {Injectable} from '@angular/core';
import {PostHog} from 'posthog-js';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PosthogWrapperService {
  identify(userId: string, atts: {[key: string]: string | string[]}) {
    this.initPosthog().then((posthog) => posthog?.identify(userId, atts));
  }

  reset() {
    this.initPosthog().then((posthog) => posthog?.reset());
  }

  capture(eventName: string, atts: {[key: string]: string | string[]}) {
    this.initPosthog().then((posthog) => posthog?.capture(eventName, atts));
  }

  private async initPosthog(): Promise<PostHog> {
    const apiKey = environment.posthogApiKey;
    if (U.isEmpty(apiKey)) {
      return;
    }
    return import('posthog-js').then((posthog) => {
      posthog.default.init(apiKey, {
        // eslint-disable-next-line
        api_host: 'https://us.i.posthog.com',
        // eslint-disable-next-line
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      });
      return posthog.default;
    });
  }
}
