import { BmeCoefficientCalculator } from './bme-coefficient-calculator.js';
export class VoyagerSensorConfigurator {
    constructor(deviceConfig, characteristicCache) {
        this.deviceConfig = deviceConfig;
        this.characteristicCache = characteristicCache;
    }
    calibrate(sensorId) {
        switch (sensorId) {
            case this.deviceConfig.calibrationConfig:
                const values = new Array();
                let configCharacteristic = null;
                return this.characteristicCache
                    .get(this.deviceConfig.calibrationConfig, 'config')
                    .then((characteristic) => characteristic.startNotifications())
                    .then(() => this.characteristicCache.get(this.deviceConfig.calibrationConfig, 'config'))
                    .then((characteristic) => {
                    configCharacteristic = characteristic;
                    const buffer = new Int8Array([2]).buffer;
                    return characteristic.writeValue(buffer);
                })
                    .then(() => configCharacteristic.readValue())
                    .then((v) => {
                    values.push(v.buffer);
                    return configCharacteristic.writeValue(new Int8Array([3]).buffer);
                })
                    .then(() => configCharacteristic.readValue())
                    .then((v) => {
                    values.push(v.buffer);
                    return configCharacteristic.writeValue(new Int8Array([4]).buffer);
                })
                    .then(() => configCharacteristic.readValue())
                    .then((v) => {
                    values.push(v.buffer);
                    const processedValues = new BmeCoefficientCalculator().calculateForBme(values);
                    console.log('Calibration complete');
                    console.log(processedValues);
                    return { barCoefficients: processedValues };
                });
            case undefined:
                return Promise.resolve({});
            default:
                return Promise.reject(`Sensor can\'t be calibrated: ${sensorId}`);
        }
    }
    startSensor(sensorId, frequencyIndex) {
        const frequency = this.deviceConfig.frequencyConfigs[frequencyIndex]?.i ?? 0;
        return this.configureSensor(sensorId, true, frequency).then(() => this._configurePeriod(sensorId, frequency));
    }
    configureSensor(sensorId, toOn, frequency) {
        const configValue = [];
        if (toOn) {
            configValue.push(1, frequency);
        }
        else {
            configValue.push(0);
        }
        return this.characteristicCache
            .get(sensorId, 'config')
            .then((characteristic) => {
            if (characteristic) {
                console.log(`configuring sensor: ${sensorId} - ${toOn}`);
                const buffer = new Int8Array(configValue).buffer;
                return characteristic.writeValue(buffer);
            }
        });
    }
    _configurePeriod(sensorId, frequency) {
        /* Only needed because apparently ntc10k doesn't respond to config
         * characteristic. Setting it in another sensor sets it in ntc as well.
         * Note both ntc and bme are present in voyager and weather.
         */
        if (sensorId === 'ntc10k') {
            return this.characteristicCache
                .get('bme280', 'config')
                .then((characteristic) => {
                if (characteristic) {
                    const buffer = new Int8Array([1, frequency]).buffer;
                    return characteristic.writeValue(buffer);
                }
                else {
                    return Promise.reject('No characteristic found');
                }
            });
        }
        else {
            return Promise.resolve();
        }
    }
}
