import {Injectable, OnDestroy} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';

import {ReplaySubject, forkJoin, of, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class HttpService implements OnDestroy {
  private _destroyed$ = new ReplaySubject<boolean>();

  constructor(private httpClient: HttpClient) {}

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  getAll(urls: Array<string>, queryParams?: {}): Observable<Array<any>> {
    if (!urls || urls.length === 0) {
      return of([]);
    }
    const params = new HttpParams({
      fromObject: queryParams,
    });
    return forkJoin(
      urls.map((url) => this.httpClient.get(url, {params: params})),
    );
  }

  get(url: string, queryParams?: {}): Observable<any> {
    return this.getAll([url], queryParams).pipe(map((response) => response[0]));
  }

  post(url: string, data: any): Observable<any> {
    return this.httpClient.post(url, data);
  }
}
