import {
  Directive,
  Inject,
  ViewContainerRef,
  TemplateRef,
  Input,
  Injector,
  OnInit,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

import {ReplaySubject, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {PL2} from '@common/utils/dist/index.js';

import {ActionPolicy} from './action-policy';

@Directive({
  selector: '[plIfCan]',
})
export class PolicyDirective implements OnInit, OnDestroy {
  @Input()
  plIfCan: string;

  @Input()
  plIfCanInput: string | PL2.EntryType;

  private _actionPolicy: ActionPolicy;

  private _destroyed$ = new ReplaySubject<boolean>();
  private _subscription: Subscription;

  constructor(
    @Inject(Injector) private injector: Injector,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this._actionPolicy = this.injector.get(this.plIfCan);
    this._init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['plIfCanInput'] && !changes['plIfCanInput'].isFirstChange()) {
      this._init();
    }
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  private _init() {
    if (!!this._subscription) {
      this._subscription.unsubscribe();
    }

    this._subscription = this._actionPolicy
      .can$(this.plIfCanInput)
      .pipe(
        map((can) => {
          if (can) {
            if (this.viewContainer.length === 0) {
              this.viewContainer.createEmbeddedView(this.templateRef);
            }
          } else {
            this.viewContainer.clear();
          }
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }
}
