import { OdysseySystemConfig } from './odyssey-system-config.js';
import { SYSTEM_CONFIG } from './pl-command-codes.js';
export class SecondaryConfigPipeline {
    execute(sensorIds, frequencyIndex, deviceConfig, commandQueue) {
        const strategies = new Array();
        strategies.push(new SensorCommandConfigStep());
        strategies.push(new FrequencyCommandConfigStep());
        strategies.push(new SystemConfigStep());
        strategies.forEach((strategy) => {
            strategy.execute({
                sensorIds,
                frequencyIndex,
                deviceConfig,
                commandQueue,
            });
        });
    }
}
export class SensorCommandConfigStep {
    execute(context) {
        context.sensorIds.forEach((sensorId) => {
            const sensorConfig = context.deviceConfig.sensorConfigs[sensorId];
            const secondaryConfig = sensorConfig.secondaryConfig;
            if (!!secondaryConfig?.command) {
                context.commandQueue.addWithoutValidation(secondaryConfig.command);
            }
        });
        return context;
    }
}
export class FrequencyCommandConfigStep {
    execute(context) {
        const frequencyConfig = context.deviceConfig?.frequencyConfigs[context.frequencyIndex]?.sC;
        if (!!frequencyConfig) {
            context.commandQueue.addWithoutValidation(frequencyConfig);
        }
        return context;
    }
}
export class SystemConfigStep {
    execute(context) {
        const odysseySystemConfig = new OdysseySystemConfig();
        const newSystemConfigs = {};
        context.sensorIds.forEach((sensorId) => {
            const sensorConfig = context.deviceConfig.sensorConfigs[sensorId];
            const secondaryConfig = sensorConfig.secondaryConfig;
            if (!!secondaryConfig?.system) {
                const register = odysseySystemConfig.registers[secondaryConfig.system.registerIdx];
                const key = `${register.configCommand}${register.configInterface}`;
                if (!newSystemConfigs[key]) {
                    newSystemConfigs[key] = Number(register.defaultRegisterValues);
                }
                const mask = Number(register.content[secondaryConfig.system.enableBitIdx].mask);
                newSystemConfigs[key] = newSystemConfigs[key] | mask;
            }
        });
        for (const [key, cfg] of Object.entries(newSystemConfigs)) {
            const configCommand = parseInt(key[0], 10);
            const configInterface = parseInt(key[1], 10);
            const config = new Uint8Array(new Uint32Array([cfg]).buffer);
            context.commandQueue.addWithoutValidation([
                SYSTEM_CONFIG << 1,
                configCommand,
                configInterface,
                ...config,
            ]);
        }
        return context;
    }
}
