import {Injectable, Inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {S3Service} from './s3.service';

@Injectable({providedIn: 'root'})
export class WSMessagePublisherService {
  constructor(
    private s3Service: S3Service,
    @Inject('BUCKET') private bucket: string,
    @Inject('MAX_WS_MESSAGE_SIZE') private maxWSMessageSize: number,
  ) {}

  publish(message: string, uId: string): Observable<string> {
    if (this._getBinarySize(message) > this.maxWSMessageSize) {
      console.debug('uploading json to s3');

      const metadata = {
        'x-amz-meta-ttl': '180',
      };

      return this.s3Service
        .uploadString(message, uId, 'application/json', metadata)
        .pipe(
          tap((response) => console.debug('uploaded', response)),
          map((response) =>
            JSON.stringify({
              Bucket: this.bucket,
              Key: `public/${response.key}`,
            }),
          ),
        );
    } else {
      return of(message);
    }
  }

  private _getBinarySize(str: string) {
    return new TextEncoder().encode(str).length;
  }
}
