import { EntryType, } from '../models/models-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { all, any, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class LabReportAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI, fetchStudentEntry = () => Promise.resolve(null)) {
        super(entries, user, readOnly, fetchEntry, rI);
        this.fetchStudentEntry = fetchStudentEntry;
    }
    async canCreate(entry) {
        return any(all(this.userIsSetAsCreatorFor(entry), this.userIsPrivilegedParticipantInAuthScope), all(this.tleIsNotType(EntryType.Course), this.userIsSetAsCreatorFor(entry)))();
    }
    async canRead(entry) {
        return any(this.tleIsPublic, this.userIsPrivilegedParticipantInAuthScope, this.userHasPermissionLicenseFor(entry), this.user?.auth?.lesson?.canRead, this._userIsTeacherToCreatorOfFetched(entry))();
    }
    async canUpdate(entry) {
        return any(all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry, 'isP'), this.userIsPrivilegedParticipantInAuthScope), all(this.tleIsNotReadOnly, this.userIsTeacher, hasNoBlacklistedAttsIn(entry), this.userIsPrivilegedParticipantInAuthScope))();
    }
    async canDelete(_entry) {
        return all(this.tleIsNotReadOnly, this.userIsPrivilegedParticipantInAuthScope)();
    }
    _userIsTeacherToCreatorOfFetched(entry) {
        return async () => {
            if (U.isEmpty(this.fetchStudentEntry)) {
                return false;
            }
            const sE = await this.fetchStudentEntry();
            return all(!U.isEmpty(this.user), this.user?.userId === sE?.uId, entry?.uId === sE?.mUId)();
        };
    }
}
