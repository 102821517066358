import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {TranslateModule} from '@ngx-translate/core';

import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';

import {ConfirmationDialogComponent} from './confirmation-dialog.component';
import {NewReleaseDialogComponent} from './new-release-dialog.component';
import {NotificationDialogComponent} from './notification-dialog.component';
import {UrlDialogComponent} from './url-dialog.component';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    NewReleaseDialogComponent,
    NotificationDialogComponent,
    UrlDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    RouterModule,
  ],
  providers: [],
  exports: [TranslateModule],
})
export class DialogsModule {
  constructor() {}
}
