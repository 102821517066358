import {Observable} from 'rxjs';
import {LabReportUtils as LRU} from './lab-report-utils';

export module VideoUtils {
  export function embedYoutube(url: string, iframe: HTMLIFrameElement) {
    const id = LRU.youtubeId(url);
    const host = LRU.youtubeHostWithWWW(url);
    const embedUrl =
      (this.embedUrl = `https://${host}/embed/${id}?modestbranding=1&rel=0&origin=https://app.thepocketlab.com`);
    iframe.src = embedUrl;
  }

  export function embedLoom(url: string, iframe: HTMLIFrameElement) {
    const videoUrl = url.replace('share/', 'embed/');

    if (videoUrl && iframe) {
      const url = new URL(videoUrl);
      url.searchParams.set('hide_share', 'true');
      url.searchParams.set('hideEmbedTopBar', 'true');
      url.searchParams.set('hide_title', 'true');
      url.searchParams.set('hide_owner', 'true');
      iframe.src = url.toString();
    }
  }

  function embedOther(url: string, iframe: HTMLIFrameElement) {
    iframe.src = url;
  }

  export function embed(
    url: string,
    iframe: HTMLIFrameElement,
  ): Observable<void> {
    return new Observable<void>((observer) => {
      const parsedUrl = new URL(url);
      switch (parsedUrl.hostname) {
        case 'www.youtube.com':
        case 'youtube.com':
          embedYoutube(url, iframe);
          break;
        case 'www.loom.com':
        case 'loom.com':
          embedLoom(url, iframe);
          break;
        default:
          embedOther(url, iframe);
      }

      iframe.onload = () => {
        observer.next();
        observer.complete();
      };
      iframe.onerror = () => {
        observer.error(url);
        observer.complete();
      };
    });
  }

  export function clear(iframe: HTMLIFrameElement) {
    iframe.src = '';
  }
}
