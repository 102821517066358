import {Inject, Injectable, InjectionToken, NgZone} from '@angular/core';
import {Observable, BehaviorSubject, NEVER} from 'rxjs';
import {filter} from 'rxjs/operators';

import {PipeUtils as PU} from '@utils/pipe-utils';

export interface BroadcastMessage {
  type: string;
  payload: any;
}

export const CHANNEL_TOKEN = new InjectionToken<string>('channelToken', {
  factory: () => 'pl-ch',
});

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  private broadcastChannel: BroadcastChannel;
  private onMessage = new BehaviorSubject<any>({});

  constructor(
    @Inject(CHANNEL_TOKEN) private channel: string,
    private ngZone: NgZone,
  ) {
    try {
      this.broadcastChannel = new BroadcastChannel(channel);
    } catch (err) {
      console.warn(err);
    }
    if (this.broadcastChannel) {
      this.broadcastChannel.onmessage = (message) =>
        this.onMessage.next(message.data);
    }
  }

  broadcast(message: BroadcastMessage): void {
    if (this.broadcastChannel) {
      this.broadcastChannel.postMessage(message);
    }
  }

  receive(messageType: string): Observable<BroadcastMessage> {
    if (this.broadcastChannel) {
      return this.onMessage.pipe(
        PU.runInZone<BroadcastMessage>(this.ngZone),
        filter((message: BroadcastMessage) => message.type === messageType),
      );
    } else {
      return NEVER;
    }
  }
}
