import {Inject, Injectable, OnDestroy} from '@angular/core';

import {ReplaySubject, from, Observable, of} from 'rxjs';
import {takeUntil, map, mergeMap, catchError, tap, take} from 'rxjs/operators';

import {Router} from '@angular/router';

import {ServiceCommsWrapperService} from '@services/service-comms-wrapper.service';
import {BroadcastService} from '@services/broadcast-service';

import {
  PL2,
  UtilsPL2 as U,
  UserUtilsPL2 as UU,
} from '@common/utils/dist/index.js';
import {Store} from './abstract-store';
import produce from 'immer';

interface BroadcastEvent {
  type: string;
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class UserStore extends Store<PL2.User> implements OnDestroy {
  private _destroyed$ = new ReplaySubject<boolean>();
  private _userAdapter = new UU.UserPoolUserToNotebookUserAdapter();

  constructor(
    @Inject('WINDOW') private window: any,
    private serviceCommsWrapperService: ServiceCommsWrapperService,
    private broadcastService: BroadcastService,
    private router: Router,
  ) {
    super(null);

    this.broadcastService
      .receive('auth')
      .pipe(
        tap((msg) => console.log(msg)),
        map((message) => message.payload),
        map((event: BroadcastEvent) => {
          switch (event.type) {
            case 'signOut':
              this._signOut().subscribe();
              break;
            case 'signIn':
              if (this._isSameUser(event)) {
                this.refreshAttributes().subscribe();
              } else {
                this.window.location.href = '/';
              }
              break;
          }
        }),
        catchError((err) => {
          console.warn(err);
          return of(void 0);
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    this.serviceCommsWrapperService
      .listen('auth')
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (payload: {event: string; data: string | UU.UserAttributes}) => {
          switch (payload.event) {
            case 'signIn':
              this._buildSession(payload.data);
              this._broadcastSignin(this.state()?.userId);
              break;
            case 'signUp':
              this._buildSession(payload.data);
              break;
            case 'signOut':
              this.setState(null);
              if (!U.isEmpty(this.window?.location?.href)) {
                this.window.location.href = '/';
              }
              break;
            case 'signIn_failure':
              this.setState(null);
              break;
            case 'configured':
              break;
            case 'tokenRefresh':
              break;
            case 'tokenRefresh_failure':
              this.signOut().pipe(take(1)).subscribe();
              break;
            case 'customOAuthState':
              console.log(payload);
              console.log(payload.data);
              this.router.navigateByUrl(payload.data as string);
              break;
            default:
              break;
          }
        },
      );
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  isLoggedIn(): Observable<boolean> {
    if (U.isEmpty(this.state())) {
      return this._currentAuthenticatedUser().pipe(
        map((uDP) => {
          this._buildSession(uDP);
          return !U.isEmpty(this.state());
        }),
      );
    } else {
      return of(true);
    }
  }

  signUp(user: Partial<PL2.User>, password: string): Observable<any> {
    const attributes = U.pickNoEmptyValue(
      user,
      'userId',
      'name',
      'email',
      'firstName',
      'lastName',
      'title',
      'organization',
      'role',
    );
    return from(
      this.serviceCommsWrapperService.signUp({
        attributes: this._userAdapter.adaptFrom(attributes),
        username: user.username,
        password: password,
      }),
    );
  }

  confirmSignUp(code: string, email?: string): Observable<boolean> {
    return from(
      this.serviceCommsWrapperService.confirmSignUp(email, code, {
        forceAliasCreation: false,
      }),
    );
  }

  resendSignUpCode(email: string): Observable<{}> {
    return from(this.serviceCommsWrapperService.resendSignUp(email));
  }

  forgotPassword(email: string): Observable<void> {
    return from(this.serviceCommsWrapperService.forgotPassword(email));
  }

  forgotPasswordSubmit(
    email: string,
    code: string,
    password: string,
  ): Observable<void> {
    return from(
      this.serviceCommsWrapperService.forgotPasswordSubmit(
        email,
        code,
        password,
      ),
    );
  }

  signOut(): Observable<void> {
    return this._signOut().pipe(
      map(() =>
        this.broadcastService.broadcast({
          type: 'auth',
          payload: {type: 'signOut'},
        }),
      ),
    );
  }

  signIn(username: string, password: string): Observable<void> {
    return from(this.serviceCommsWrapperService.signIn(username, password));
  }

  signInWithGoogle(
    provider:
      | {provider: PL2.IdentityProvider.Google}
      | {customProvider: PL2.IdentityProvider.GoogleStudent},
  ): Observable<void> {
    return from(this.serviceCommsWrapperService.federatedSignIn(provider));
  }

  signInWithClasslink(
    provider:
      | {customProvider: PL2.IdentityProvider.Classlink}
      | {customProvider: PL2.IdentityProvider.ClasslinkStudent},
  ): Observable<void> {
    return from(this.serviceCommsWrapperService.federatedSignIn(provider));
  }

  signInWithEdLink(provider: {
    customProvider: PL2.IdentityProvider.EdLink;
  }): Observable<void> {
    return from(this.serviceCommsWrapperService.federatedSignIn(provider));
  }

  signInWithTemp(): Observable<void> {
    return this.signInWithCustomOidcProvider(PL2.IdentityProvider.Temp);
  }

  signInWithCustomOidcProvider(
    provider:
      | PL2.IdentityProvider.Ed
      | PL2.IdentityProvider.EdLink
      | PL2.IdentityProvider.Temp,
    redirectUrl?: string,
  ): Observable<void> {
    const federatedSignInParams = U.excludeEmptyProperties({
      customProvider: provider,
      customState: redirectUrl,
    });
    return from(
      this.serviceCommsWrapperService.federatedSignIn(federatedSignInParams),
    );
  }

  deleteUser(): Observable<any> {
    return this._currentAuthenticatedUser().pipe(
      mergeMap((user: any) => {
        if (U.isEmpty(user)) {
          return of(void 0);
        }
        const info = (f) => {
          f({message: 'error'}, null);
        };
        user.deleteUser = user.deleteUser || info;
        return new Observable<any>((observer) => {
          user.deleteUser((err, data) => {
            if (err) {
              observer.error(err);
            } else {
              observer.next(data);
              this.setState(null);
            }
            observer.complete();
          });
        });
      }),
    );
  }

  updateUser(changes: Partial<PL2.User>): Observable<PL2.User> {
    const pickedChanges = U.pick(
      changes,
      'name',
      'firstName',
      'lastName',
      'organization',
      'title',
      'tutorial',
      'role',
    );
    const attributes = this._userAdapter.adaptFrom(pickedChanges);
    return this._currentAuthenticatedUser().pipe(
      mergeMap((user) =>
        from(
          this.serviceCommsWrapperService.updateUserAttributes(
            user,
            attributes as {[attr: string]: string},
          ),
        ).pipe(
          map(() => {
            this.setState({...this.state(), ...changes});
            return this.state();
          }),
        ),
      ),
    );
  }

  updateUserAndForget(changes: Partial<PL2.User>) {
    this.updateUser(changes).subscribe();
  }

  deleteUserAttribute(attribute: string): Observable<PL2.User> {
    return this._currentAuthenticatedUser().pipe(
      mergeMap(() =>
        from(
          this.serviceCommsWrapperService.deleteUserAttribute(attribute),
        ).pipe(
          map(() => {
            this.setState(
              produce(this.state(), (draft) => {
                delete draft[attribute];
                return draft;
              }),
            );
            return this.state();
          }),
        ),
      ),
    );
  }

  refreshAttributes(): Observable<void> {
    return this._currentAuthenticatedUser({bypassCache: true}).pipe(
      map((uDU) => this._buildSession(uDU)),
    );
  }

  private _signOut(): Observable<void> {
    return from(this.serviceCommsWrapperService.signOut()).pipe();
  }

  private _currentAuthenticatedUser(opts?: {
    bypassCache: boolean;
  }): Observable<UU.UserPoolUser> {
    return from(
      this.serviceCommsWrapperService
        .currentAuthenticatedUser(opts)
        .catch(() => null),
    );
  }

  private _buildSession(
    uPU?: {signInUserSession: {idToken: {payload: UU.UserPoolUser}}} | any,
  ) {
    const attributes = uPU?.signInUserSession?.idToken?.payload;
    if (!U.isEmpty(attributes)) {
      const user = new UU.UserPoolUserToNotebookUserAdapter().adaptTo(
        attributes,
      );
      if (U.isEmpty(user?.userId)) {
        this.setState(null);
      } else {
        this.setState(user);
      }
    } else {
      this.setState(null);
    }
  }

  private _broadcastSignin(userId: string) {
    this.broadcastService.broadcast({
      type: 'auth',
      payload: {type: 'signIn', data: {uId: userId}},
    });
  }

  private _isSameUser(event: BroadcastEvent): boolean {
    return (
      !U.isEmpty(this.state()?.userId) &&
      this.state()?.userId === event.data?.uId
    );
  }
}
