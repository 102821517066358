import { takeWhile } from 'rxjs/operators';
import { PowerlabDataProcessorBuilder } from './powerlab-data-processor-builder.js';
import { CrashModeDataProcessorStrategy } from './crash-mode-data-processor-strategy.js';
import { ProcessorStatus } from './connection-types.js';
export class PowerlabCrashModeConfigurator {
    constructor(deviceConfig, sensorCoordinator, commandQueue, characteristicCache, eventObs) {
        this.deviceConfig = deviceConfig;
        this.sensorCoordinator = sensorCoordinator;
        this.commandQueue = commandQueue;
        this.characteristicCache = characteristicCache;
        this.eventObs = eventObs;
    }
    initMode() {
        return (this._stopPolling()
            // -1 frequency ignored
            .then(() => this.sensorCoordinator.startSensors(this.deviceConfig.crashModeSensors, -1))
            .then(() => this.commandQueue.add([0x70, 0x3c, 0x00, 0x03, 0x00]))
            .then(() => this.commandQueue.add([0x74, 0x01])));
    }
    async record() {
        return this.commandQueue.addWithoutValidation([0x4a, 0x87]).then(() => this.commandQueue.addOperation(async () => {
            console.log('Recording crash mode operation');
            return this.characteristicCache
                .get(this.deviceConfig.crashModeConfig.id, 'data')
                .then((characteristic) => {
                console.log('Subscribing to crash mode data events');
                return new PowerlabDataProcessorBuilder(this.eventObs)
                    .build([
                    {
                        sensorId: this.deviceConfig.memoryConfig.id,
                        c: characteristic,
                    },
                ], new CrashModeDataProcessorStrategy(this.deviceConfig))
                    .pipe(takeWhile((readings) => (readings[0] || {}).status !==
                    ProcessorStatus.CrashModeStatusComplete, true));
            });
        }));
    }
    async exitMode() {
        return this.commandQueue
            .addWithoutValidation([0x74, 0x00])
            .then(() => this.commandQueue.addWithoutValidation([0x70, 0x78, 0x00, 0x06, 0x00]))
            .then(() => this._startPolling());
    }
    _startPolling() {
        return this.commandQueue.addWithoutValidation([0x60, 0x01]);
    }
    _stopPolling() {
        return this.commandQueue.add([0x60, 0x00]);
    }
}
