import { AltitudeCalibratorStrategy, 
// DisplacementCalibratorStrategy,
ImuCalibratorStrategy, OrientationCalibratorStrategy, PhCalibratorStrategy, RangefinderCalibratorStrategy, } from './powerlab-calibrator-strategies.js';
export class PowerlabCalibratorFactory {
    build(sensorId) {
        switch (sensorId) {
            case 'accelerationXYZ_v2':
            case 'gyroscopeXYZ_v2':
                return new ImuCalibratorStrategy();
            case 'altitude':
                return new AltitudeCalibratorStrategy();
            // case 'displacement':
            // return new DisplacementCalibratorStrategy();
            case 'heading':
            case 'pitch':
            case 'roll':
            case 'quaternion':
                return new OrientationCalibratorStrategy();
            case 'rangefinder':
                return new RangefinderCalibratorStrategy();
            case 'ph':
                return new PhCalibratorStrategy();
            default:
                console.warn(`Powerlab calibration does nothing: ${sensorId}`);
                return null;
        }
    }
}
