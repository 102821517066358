import {Injectable} from '@angular/core';

import {TimeoutError as RxjsTimeoutError} from 'rxjs';

export module APIError {
  export interface Error {
    discriminator: 'error';
    status: number;
    message: any;
  }

  export function buildError(response: any): APIError.Error {
    const error: Error = {
      message: response?.message ?? response ?? 'Unknown error',
      status: 500,
      discriminator: 'error',
    };
    const status = Number(response?.status ?? 500);
    if (response instanceof RxjsTimeoutError) {
      error.status = 504;
    } else if (status >= 400) {
      error.status = status;
    } else if (response?.message === 'Network Error') {
      error.status = 503;
    }
    return error;
  }

  export function instanceOfError(object: any): boolean {
    return object?.discriminator === 'error';
  }

  @Injectable({
    providedIn: 'root',
  })
  export class Console {
    error(...args: any): void {
      console.error(args);
    }

    info(...args: any): void {
      console.log(args);
    }
  }
}
