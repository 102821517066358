import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {UserStore} from '@cloudlab/stores/user.store';
import {RouteStore} from '@stores/route.store';

import {Action} from './action';

@Injectable({
  providedIn: 'root',
})
export class SignOutAction implements Action<void, void> {
  constructor(
    private userStore: UserStore,
    private routeStore: RouteStore,
  ) {}

  execute(): Observable<void> {
    return this.userStore.signOut().pipe(
      map(() => {
        this.routeStore.home();
      }),
    );
  }
}
