import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuardService as AuthGuard} from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'pocketlab',
    loadChildren: () =>
      import('./components/data-view/data-view.module').then(
        (m) => m.DataViewModule,
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./components/cloudlab-auth/cloudlab-auth.module').then(
        (m) => m.CloudlabAuthModule,
      ),
  },
  {path: 'student', redirectTo: 'auth/class-code', pathMatch: 'full'},
  {path: 'teacher', redirectTo: 'auth/sign-in', pathMatch: 'full'},
  {
    path: 'nar',
    loadChildren: () =>
      import('./components/nar/nar.module').then((m) => m.NarModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./components/payment-request/payment-request.module').then(
        (m) => m.PaymentRequestModule,
      ),
  },

  {
    path: 'demo/:containerId',
    redirectTo: 'auth/register-temp-user/:containerId',
    pathMatch: 'full',
  },
  {
    path: 'kesler-demo',
    loadChildren: () =>
      import(
        './components/kesler-demo-license-create/kesler-demo-license-create.module'
      ).then((m) => m.KeslerDemoLicenseCreateModule),
  },
  {
    path: 'lab-report/:containerId/:view',
    loadChildren: () =>
      import('./components/simple-lab-report/simple-lab-report.module').then(
        (m) => m.SimpleLabReportModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./cloudlab.module').then((m) => m.CloudlabModule),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
})
export class AppRoutingModule {}
