import { EntryType } from '../models/models-pl2.js';
import { ConcatReasonBuilder } from './concat-reason-builder.js';
import { IsValidObjectValidator } from './is-valid-object-validator.js';
import { IsValidAttributeValidator } from './is-valid-attribute-validator.js';
import { FailAllValidatorFactory } from './fail-all-validator-factory.js';
import { LabReportUserEntryValidatorFactory } from './lab-report-user-entry-validator-factory.js';
import { InvitedLabReportUserEntryValidatorFactory } from './invited-lab-report-user-entry-validator-factory.js';
import { StudentUserEntryValidatorFactory } from './student-user-entry-validator-factory.js';
import { TrialSectionEntryValidatorFactory } from './trial-section-entry-validator-factory.js';
import { TrialEntryValidatorFactory } from './trial-entry-validator-factory.js';
import { AssignmentEntryValidatorFactory } from './assignment-entry-validator-factory.js';
import { StudentCopyEntryValidatorFactory } from './student-copy-entry-validator-factory.js';
import { LabReportEntryValidatorFactory } from './lab-report-entry-validator-factory.js';
import { VideoSectionEntryValidatorFactory } from './video-section-entry-validator-factory.js';
import { ImageSectionEntryValidatorFactory } from './image-section-entry-validator-factory.js';
import { TextSectionEntryValidatorFactory } from './text-section-entry-validator-factory.js';
import { ClassEntryValidatorFactory } from './class-entry-validator-factory.js';
import { CourseEntryValidatorFactory } from './course-entry-validator-factory.js';
import { QuestionSectionEntryValidatorFactory } from './question-section-entry-validator-factory.js';
import { DataAnalysisSectionEntryValidatorFactory } from './data-analysis-section-entry-validator-factory.js';
import { IFrameSectionEntryValidatorFactory } from './iframe-section-entry-validator-factory.js';
import { PhetSectionEntryValidatorFactory } from './phet-section-entry-validator-factory.js';
import { CommentEntryValidatorFactory } from './comment-entry-validator-factory.js';
import { AnswerEntryValidatorFactory } from './answer-entry-validator-factory.js';
import { SharedDataTableSectionEntryValidatorFactory } from './shared-data-table-section-entry-validator-factory.js';
import { GlobalResourceEntryValidatorFactory } from './global-resource-entry-validator-factory.js';
import { GoogleDriveSectionEntryValidatorFactory } from './google-drive-section-entry-validator-factory.js';
import { CollectDataSectionEntryValidatorFactory } from './collect-data-section-entry-validator-factory.js';
import { IsValidPartialValidator } from './is-valid-partial-validator.js';
import { ConfigurableValidatorFactory } from './configurable-validator-factory.js';
import { DefaultReasonBuilderFactory } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { LenientValidator } from './lenient-validator.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { UninvitedLabReportUserEntryValidatorFactory } from './uninvited-lab-report-user-entry-validator-factory.js';
import { SharedDrawingSectionEntryValidatorFactory } from './shared-drawing-section-entry-validator-factory.js';
import { ModuleEntryValidatorFactory } from './module-entry-validator-factory.js';
import { LocalSubsetEntryValidatorFactory } from './local-subset-entry-validator-factory.js';
import { ClassLocalSubsetEntryValidatorFactory } from './class-local-subset-entry-validator-factory.js';
import { ScopeEntryValidatorFactory } from './scope-entry-validator-factory.js';
import { ScopeUserEntryValidatorFactory } from './scope-user-entry-validator-factory.js';
import { CurriculumEntryValidatorFactory } from './curriculum-entry-validator-factory.js';
import { LicenseEntryValidatorFactory } from './license-entry-validator-factory.js';
import { TeacherNotesEntryValidatorFactory } from './teacher-notes-entry-validator-factory.js';
import { SharedDragAndDropSectionEntryValidatorFactory } from './shared-drag-and-drop-section-entry-validator-factory.js';
import { ClassPermissionEntryValidatorFactory } from './class-permission-entry-validator-factory.js';
export var ValidatorUtilsPL2;
(function (ValidatorUtilsPL2) {
    function getValidator(o) {
        const factory = validatorFactoryWithSortKey(o.sK, (o, f) => new IsValidObjectValidator(o, f));
        return new IsValidObjectValidator(o, factory);
    }
    ValidatorUtilsPL2.getValidator = getValidator;
    function getPartialValidator(o) {
        const factory = validatorFactoryWithSortKey(o.sK, (o, f) => new IsValidPartialValidator(o, f));
        return new IsValidPartialValidator(o, factory);
    }
    ValidatorUtilsPL2.getPartialValidator = getPartialValidator;
    function getPartialValidatorWithType(o, type) {
        const factory = validatorFactory(type, (o, f) => new IsValidPartialValidator(o, f));
        return new IsValidPartialValidator(o, factory);
    }
    ValidatorUtilsPL2.getPartialValidatorWithType = getPartialValidatorWithType;
    function buildObjectValidator(o, attributes = ['mId', 'sK', 't', 'cAt', 'uAt']) {
        const prevalidator = new IsValidObjectValidator(o, new ConfigurableValidatorFactory(DefaultReasonBuilderFactory, [
            {
                attributes: attributes,
                validatorFactories: [
                    (value, rb) => new IsNonEmptyValidator(value, rb),
                ],
            },
        ], (value, rb) => new LenientValidator(value, rb)));
        return prevalidator;
    }
    ValidatorUtilsPL2.buildObjectValidator = buildObjectValidator;
    function getAttributeValidator(attr, value, type) {
        const factory = validatorFactory(type, (o, f) => new IsValidPartialValidator(o, f));
        return new IsValidAttributeValidator(attr, value, factory);
    }
    ValidatorUtilsPL2.getAttributeValidator = getAttributeValidator;
    function getAttributeValidatorWithSortKey(attr, value, sK) {
        const entryType = KU.parseSortKey(sK).nodeId.t;
        return getAttributeValidator(attr, value, entryType);
    }
    ValidatorUtilsPL2.getAttributeValidatorWithSortKey = getAttributeValidatorWithSortKey;
    function validatorFactoryWithSortKey(sK, validatorBuilder) {
        const entryType = KU.parseSortKey(sK).nodeId.t;
        return validatorFactory(entryType, validatorBuilder);
    }
    ValidatorUtilsPL2.validatorFactoryWithSortKey = validatorFactoryWithSortKey;
    function validatorFactory(entryType, validatorBuilder) {
        let factory = null;
        const reasonBuilderFactory = (what) => new ConcatReasonBuilder(what);
        switch (entryType) {
            case EntryType.Class:
                factory = new ClassEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.Course:
                factory = new CourseEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.LabReport:
                factory = new LabReportEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.StudentCopy:
                factory = new StudentCopyEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.Assignment:
                factory = new AssignmentEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.StudentUser:
                factory = new StudentUserEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.LabReportUser:
                factory = new LabReportUserEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.InvitedLabReportUser:
                factory = new InvitedLabReportUserEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.UninvitedLabReportUser:
                factory = new UninvitedLabReportUserEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.ScopeUser:
                factory = new ScopeUserEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.Trial:
                factory = new TrialEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.TrialSection:
                factory = new TrialSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.VideoSection:
                factory = new VideoSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.ImageSection:
                factory = new ImageSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.TextSection:
                factory = new TextSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.DataAnalysisSection:
                factory = new DataAnalysisSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.QuestionSection:
            case EntryType.DiscussionSection:
            case EntryType.SpeechToTextSection:
                factory = new QuestionSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.CollectDataSection:
                factory = new CollectDataSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.GoogleDriveSection:
                factory = new GoogleDriveSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.SharedDataTableSection:
                factory = new SharedDataTableSectionEntryValidatorFactory(reasonBuilderFactory, validatorBuilder);
                break;
            case EntryType.IFrameSection:
                factory = new IFrameSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.PhetSection:
                factory = new PhetSectionEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.AnswerSection:
            case EntryType.Transcript:
                factory = new AnswerEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.Comment:
                factory = new CommentEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.TeacherNotes:
                factory = new TeacherNotesEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.GlobalResource:
                factory = new GlobalResourceEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.SharedDrawingSection:
                factory = new SharedDrawingSectionEntryValidatorFactory(reasonBuilderFactory, validatorBuilder);
                break;
            case EntryType.Module:
                factory = new ModuleEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.LocalSubset:
                factory = new LocalSubsetEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.ClassLocalSubset:
                factory = new ClassLocalSubsetEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.ClassScope:
            case EntryType.IndividualScope:
                factory = new ScopeEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.Curriculum:
                factory = new CurriculumEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.License:
                factory = new LicenseEntryValidatorFactory(reasonBuilderFactory);
                break;
            case EntryType.SharedDragAndDropSection:
            case EntryType.SharedLineMatchingSection:
                factory = new SharedDragAndDropSectionEntryValidatorFactory(reasonBuilderFactory, validatorBuilder);
                break;
            case EntryType.ClassPermission:
                factory = new ClassPermissionEntryValidatorFactory(reasonBuilderFactory);
                break;
            default:
                factory = new FailAllValidatorFactory(reasonBuilderFactory, 'Missing entry type: ' + entryType);
        }
        return factory;
    }
    ValidatorUtilsPL2.validatorFactory = validatorFactory;
})(ValidatorUtilsPL2 || (ValidatorUtilsPL2 = {}));
