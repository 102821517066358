import {
  ConnectionState,
  SensorMessage,
  MessageType,
} from '@common/ble/dist/index.js';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

export module ConnectionUtils {
  export function isMessageType(
    type: MessageType,
    sensorMessages: SensorMessage[],
  ): boolean {
    return !U.isEmpty(sensorMessages) && sensorMessages[0].type === type;
  }

  export function isOnboardConnection(
    connectionState: ConnectionState,
  ): boolean {
    return !!connectionState && connectionState.deviceConfig.id === 'onboard';
  }

  export function frequencyLabels(connectionState: ConnectionState): string[] {
    if (U.isEmpty(connectionState?.deviceConfig)) {
      return [];
    }
    const maxFrequencyIdx = (connectionState.maxFrequencyIdx ?? Infinity) + 1;
    return connectionState.deviceConfig.frequencyConfigs
      .slice(0, maxFrequencyIdx)
      .map((fc) => fc.l);
  }
}
