import { isEmpty } from './device-config-utils.js';
export class CommandQueue {
    constructor(characteristicCache, deviceConfig) {
        this.characteristicCache = characteristicCache;
        this.deviceConfig = deviceConfig;
        this._closedError = '$$closed$$';
        this._queue = Promise.resolve();
        this._closed = false;
    }
    async add(command) {
        return this._addOperation(() => this._writeBlockingCommandWithRetries(command));
    }
    addWithoutValidation(command, withDelay = 100, data) {
        return this._addOperation(() => this._writeCommand(command, withDelay, data));
    }
    addRead(command) {
        return this._addOperation(() => this._readCommandWithRetry(command));
    }
    addReadWithoutValidation(command) {
        return this._addOperation(() => this._readCommand(command));
    }
    addOperation(operation) {
        return this._addOperation(operation);
    }
    _addOperation(operation) {
        if (this._closed) {
            return Promise.reject('Queue already closed');
        }
        return new Promise((resolve, reject) => {
            this._queue = this._queue
                .then((args) => {
                if (this._closed) {
                    return Promise.reject(this._closedError);
                }
                return operation(args);
            })
                .then((result) => resolve(result))
                .catch((e) => {
                if (e === this._closedError) {
                    console.warn('Queue closed with pending commands');
                    reject(e);
                    return Promise.reject(this._closedError);
                }
                else {
                    reject(e);
                }
            });
        });
    }
    close() {
        this._closed = true;
    }
    async _writeCommandWithValidations(command) {
        const readCommand = command[0] + 1;
        return this._writeCommand(command)
            .then(() => this._readCommand(readCommand))
            .then((response) => {
            // Max safe integer will never match the command
            const code = response.byteLength === 0
                ? Number.MAX_SAFE_INTEGER
                : response.getUint8(0);
            console.log(`Code ${(command[0] >> 1).toString(16)} ${code.toString(16)}`);
            if (code === command[0] >> 1) {
                return response;
            }
            else {
                return null;
            }
        });
    }
    async _writeBlockingCommandWithRetries(command) {
        return new Promise((resolve, reject) => {
            this._writeCommandWithRetries(command, 0).then((result) => resolve(result), (err) => reject(err));
        });
    }
    async _writeCommandWithRetries(command, retryCount = 0) {
        return this._writeCommandWithValidations(command).then((result) => {
            if (!isEmpty(result)) {
                return result;
            }
            else if (retryCount > 5) {
                return Promise.reject(`Retry write failed: ${command.map((v) => v.toString(16)).join(' ')}`);
            }
            else {
                const rC = retryCount + 1;
                return this._writeCommandWithRetries(command, rC);
            }
        });
    }
    async _writeCommand(command, withDelay = 100, data) {
        console.log(`Write: ${command.map((v) => v.toString(16)).join(' ')}`);
        const dataLength = data?.length ?? 0;
        const typedArray = new Uint8Array(command.length + 4 * dataLength);
        typedArray.set(command, 0);
        for (let i = 0; i < dataLength; i++) {
            typedArray.set(new Uint8Array(new Uint32Array([data[i]]).buffer), command.length + 4 * i);
        }
        return this.characteristicCache
            .get(this.deviceConfig.nameConfig.id, 'config')
            .then((characteristic) => characteristic.writeValue(typedArray.buffer))
            .then(() => {
            if (withDelay > 0) {
                return new Promise((resolve) => setTimeout(resolve, withDelay));
            }
            else {
                return;
            }
        });
    }
    async _readCommand(command) {
        let promise = Promise.resolve();
        if (!isEmpty(command)) {
            promise = promise.then(() => this._writeCommand([command]));
        }
        return promise.then(() => this.characteristicCache
            .get(this.deviceConfig.nameConfig.id, 'data')
            .then((characteristic) => characteristic.readValue()));
    }
    async _readCommandWithDelay() {
        return this._readCommand().then((value) => new Promise((resolve) => setTimeout(() => resolve(value), 100)));
    }
    async _readCommandWithRetry(command, retryCount = 0) {
        let promise = Promise.resolve();
        if (!isEmpty(command)) {
            promise = promise.then(() => this._writeCommand([command]));
        }
        return promise.then(() => this._readCommandWithDelay().then((result) => {
            if (!isEmpty(command)) {
                // Max safe integer will never match the command
                const code = result.byteLength === 0
                    ? Number.MAX_SAFE_INTEGER
                    : (result.getUint8(0) << 1) + 1;
                if (command === code) {
                    return result;
                }
                else if (retryCount > 5) {
                    return Promise.reject(`Retry read failed: ${code.toString(16)}`);
                }
                else {
                    const rC = retryCount + 1;
                    return this._readCommandWithRetry(command, rC);
                }
            }
            else {
                return result;
            }
        }));
    }
}
