import { nanoid } from 'nanoid';
import { EntryType, } from '../models/models-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export var KeyUtilsPL2;
(function (KeyUtilsPL2) {
    KeyUtilsPL2.nodeIdLength = 4;
    KeyUtilsPL2.nodeLength = 6;
    KeyUtilsPL2.revisionLength = 2;
    KeyUtilsPL2.typeLength = 2;
    KeyUtilsPL2.masterIdLength = 8;
    KeyUtilsPL2.resourcePages = {
        home: { pK: 'home000000500000' },
        resources: { pK: 'gr00000000500000' },
        submittedLessons: { pK: 'sl00000000500000' },
    };
    function stringFromKey(key) {
        return `${key.mId}${key.sK}`;
    }
    KeyUtilsPL2.stringFromKey = stringFromKey;
    function keyFromEntry(entry) {
        return { mId: entry.mId, sK: entry.sK };
    }
    KeyUtilsPL2.keyFromEntry = keyFromEntry;
    function uLEId(userId, type) {
        return `${userId}_${type}`;
    }
    KeyUtilsPL2.uLEId = uLEId;
    function generateMId(size) {
        return nanoid(size || KeyUtilsPL2.masterIdLength);
    }
    KeyUtilsPL2.generateMId = generateMId;
    function generateNId(t) {
        const pT = t.toString().padStart(KeyUtilsPL2.typeLength, '0');
        return pT + nanoid(KeyUtilsPL2.nodeIdLength);
    }
    KeyUtilsPL2.generateNId = generateNId;
    class InvalidSortKeyError extends Error {
    }
    KeyUtilsPL2.InvalidSortKeyError = InvalidSortKeyError;
    function parseSortKey(sk) {
        const parsedSk = {
            r: '00',
            sK: '00',
            nodeIds: [],
            pNodeId: null,
            nodeId: null,
            tleIdx: 0,
        };
        if (U.isEmpty(sk)) {
            throw new InvalidSortKeyError('Empty sort key');
        }
        parsedSk.r = sk.substring(0, KeyUtilsPL2.revisionLength);
        if (parsedSk.r.length !== KeyUtilsPL2.revisionLength) {
            throw new InvalidSortKeyError('Invalid revision: ' + parsedSk.r);
        }
        const sNodeIds = sk.slice(KeyUtilsPL2.revisionLength);
        if (sNodeIds.length % KeyUtilsPL2.nodeLength !== 0) {
            throw new InvalidSortKeyError('Invalid nodeId length: ' + sNodeIds);
        }
        var re = new RegExp(`.{1,${KeyUtilsPL2.nodeLength}}`, 'g');
        const aNodeIds = sNodeIds.match(re) || [];
        aNodeIds.forEach((nId, i) => {
            const nodeId = {
                id: nId,
                t: nId.substring(0, KeyUtilsPL2.typeLength),
                a: nId.substring(KeyUtilsPL2.typeLength),
            };
            parsedSk.nodeIds.push(nodeId);
            if (isTLEType(nodeId.t)) {
                parsedSk.tleIdx = i;
            }
        });
        parsedSk.nodeId = parsedSk.nodeIds[parsedSk.nodeIds.length - 1];
        if (parsedSk.nodeIds.length > 1) {
            parsedSk.pNodeId = parsedSk.nodeIds[parsedSk.nodeIds.length - 2];
        }
        parsedSk.sK = sk;
        return parsedSk;
    }
    KeyUtilsPL2.parseSortKey = parseSortKey;
    function generateChildSortKey(pSK, t) {
        const ppSK = pSK
            ? parseSortKey(pSK)
                .nodeIds.map((nId) => nId.id)
                .join('')
            : '';
        return '00' + ppSK + generateNId(t);
    }
    KeyUtilsPL2.generateChildSortKey = generateChildSortKey;
    function findTLESK(sK) {
        const oSK = parseSortKey(sK);
        const idx = oSK.nodeIds.reverse().findIndex((nId) => isTLE(nId));
        const tleIdx = oSK.nodeIds.length - 1 - idx;
        return sK.substring(0, (tleIdx + 1) * KeyUtilsPL2.nodeLength + KeyUtilsPL2.revisionLength);
    }
    KeyUtilsPL2.findTLESK = findTLESK;
    function findParentTLESK(sK) {
        return sK.length === KeyUtilsPL2.revisionLength + KeyUtilsPL2.nodeLength
            ? sK
            : findTLESK(sK.substring(0, sK.length - KeyUtilsPL2.nodeLength));
    }
    KeyUtilsPL2.findParentTLESK = findParentTLESK;
    function pKFromString(pK) {
        return {
            mId: pK.substring(0, KeyUtilsPL2.masterIdLength),
            sK: pK.substring(KeyUtilsPL2.masterIdLength),
        };
    }
    KeyUtilsPL2.pKFromString = pKFromString;
    function parentSortKey(sK) {
        if (sK.length > KeyUtilsPL2.nodeLength + KeyUtilsPL2.revisionLength) {
            return sK.substring(0, sK.length - KeyUtilsPL2.nodeLength);
        }
        else {
            return null;
        }
    }
    KeyUtilsPL2.parentSortKey = parentSortKey;
    function isTLE(e) {
        return isTLEType(e.t || type(e.sK));
    }
    KeyUtilsPL2.isTLE = isTLE;
    function isTopLevelEntry(sK) {
        const t = type(sK);
        return isTLEType(t);
    }
    KeyUtilsPL2.isTopLevelEntry = isTopLevelEntry;
    function isUserEntry(sK) {
        const t = type(sK);
        return isUserEntryType(t);
    }
    KeyUtilsPL2.isUserEntry = isUserEntry;
    // Type range defintion utils
    function isTLEType(t) {
        return '00' <= t && t <= '09';
    }
    KeyUtilsPL2.isTLEType = isTLEType;
    function isUserEntryType(t) {
        return '10' <= t && t <= '19';
    }
    KeyUtilsPL2.isUserEntryType = isUserEntryType;
    function isSectionType(t) {
        return '20' <= t && t <= '39';
    }
    KeyUtilsPL2.isSectionType = isSectionType;
    function isKeyLike(e) {
        if (U.isEmpty(e)) {
            return false;
        }
        return Object.keys(e).length <= 3 && !U.isEmpty(e.mId) && !U.isEmpty(e.sK);
    }
    KeyUtilsPL2.isKeyLike = isKeyLike;
    function isType(t, keyOrEntry) {
        return type(keyOrEntry) === t;
    }
    KeyUtilsPL2.isType = isType;
    function isLocalSubsetType(t) {
        switch (t) {
            case EntryType.LocalSubset:
            case EntryType.Module:
            case EntryType.ClassLocalSubset:
                return true;
            default:
                return false;
        }
    }
    KeyUtilsPL2.isLocalSubsetType = isLocalSubsetType;
    function type(pKOrSKOrEntry) {
        if (typeof pKOrSKOrEntry !== 'string') {
            if (!U.isEmpty(pKOrSKOrEntry.t)) {
                // entry with type attribute
                return pKOrSKOrEntry.t;
            }
            // parse type for other entries (e.g. copy request, etc.)
            const pos = pKOrSKOrEntry.sK.length - KeyUtilsPL2.nodeLength;
            return pKOrSKOrEntry.sK.slice(pos, pos + KeyUtilsPL2.typeLength);
        }
        const pos = pKOrSKOrEntry.length - KeyUtilsPL2.nodeLength;
        return pKOrSKOrEntry.slice(pos, pos + KeyUtilsPL2.typeLength);
    }
    KeyUtilsPL2.type = type;
    function parentPK(pK) {
        if (pK?.length > KeyUtilsPL2.masterIdLength + KeyUtilsPL2.revisionLength + KeyUtilsPL2.nodeLength) {
            return pK.slice(0, pK.length - KeyUtilsPL2.nodeLength);
        }
        else {
            return null;
        }
    }
    KeyUtilsPL2.parentPK = parentPK;
    function parentTLEPK(mId, sK) {
        const tleSK = findParentTLESK(sK);
        if (!tleSK) {
            return null;
        }
        return stringFromKey({ mId: mId, sK: tleSK });
    }
    KeyUtilsPL2.parentTLEPK = parentTLEPK;
    function getParentTLEPKs(pKs) {
        const tlePKs = new Array();
        pKs.forEach((pK) => {
            const pPK = findParentTLESK(pKFromString(pK).sK);
            if (pPK && !tlePKs.find((tlePK) => tlePK === pPK)) {
                tlePKs.push(pPK);
            }
        });
        return tlePKs;
    }
    KeyUtilsPL2.getParentTLEPKs = getParentTLEPKs;
    function studentUId(classCode, name) {
        return `${classCode}_${name}`;
    }
    KeyUtilsPL2.studentUId = studentUId;
    function studentPassword(classCode, name, userCode) {
        let uId = `${studentUId(classCode, name)}_${userCode}`;
        if (!U.isEmpty(userCode)) {
            uId = `${uId}_${userCode}`;
        }
        return uId;
    }
    KeyUtilsPL2.studentPassword = studentPassword;
    function changeType(sK, type) {
        return `${sK.substring(0, sK.length - KeyUtilsPL2.nodeLength)}${type}${sK.substring(sK.length - KeyUtilsPL2.nodeLength + KeyUtilsPL2.typeLength)}`;
    }
    KeyUtilsPL2.changeType = changeType;
    function level(sK) {
        return ((sK || '').length - KeyUtilsPL2.revisionLength) / KeyUtilsPL2.nodeLength;
    }
    KeyUtilsPL2.level = level;
    function rootEntrySK(pKSKOrEntry) {
        if (typeof pKSKOrEntry === 'string') {
            if (isSortKey(pKSKOrEntry)) {
                return pKSKOrEntry.substring(0, KeyUtilsPL2.revisionLength + KeyUtilsPL2.nodeLength);
            }
            else {
                return pKSKOrEntry.substring(KeyUtilsPL2.masterIdLength, KeyUtilsPL2.masterIdLength + KeyUtilsPL2.revisionLength + KeyUtilsPL2.nodeLength);
            }
        }
        else {
            return pKSKOrEntry.sK.substring(0, KeyUtilsPL2.revisionLength + KeyUtilsPL2.nodeLength);
        }
    }
    KeyUtilsPL2.rootEntrySK = rootEntrySK;
    function rootEntryType(pKSKOrEntry) {
        return type(rootEntrySK(pKSKOrEntry));
    }
    KeyUtilsPL2.rootEntryType = rootEntryType;
    function isRootEntryType(t, pKSKOrEntry) {
        return rootEntryType(pKSKOrEntry) === t;
    }
    KeyUtilsPL2.isRootEntryType = isRootEntryType;
    function isRootEntry(pKSKOrEntry) {
        if (typeof pKSKOrEntry === 'string') {
            if (isSortKey(pKSKOrEntry)) {
                return pKSKOrEntry.length === KeyUtilsPL2.revisionLength + KeyUtilsPL2.nodeLength;
            }
            else {
                return (pKSKOrEntry.length === KeyUtilsPL2.masterIdLength + KeyUtilsPL2.revisionLength + KeyUtilsPL2.nodeLength);
            }
        }
        else {
            return pKSKOrEntry.sK.length === KeyUtilsPL2.revisionLength + KeyUtilsPL2.nodeLength;
        }
    }
    KeyUtilsPL2.isRootEntry = isRootEntry;
    function isSortKey(pKOrSK) {
        return (pKOrSK.length - KeyUtilsPL2.revisionLength) % KeyUtilsPL2.nodeLength === 0;
    }
    KeyUtilsPL2.isSortKey = isSortKey;
    function getTLELineagePKs(pKOrSKOrEntry) {
        let sK = '';
        let mId = '';
        if (typeof pKOrSKOrEntry === 'string') {
            if (isSortKey(pKOrSKOrEntry)) {
                sK = pKOrSKOrEntry;
            }
            else {
                sK = pKOrSKOrEntry.toString().substring(KeyUtilsPL2.masterIdLength);
                mId = pKOrSKOrEntry.toString().substring(0, KeyUtilsPL2.masterIdLength);
            }
        }
        else {
            sK = pKOrSKOrEntry.sK;
            mId = pKOrSKOrEntry.mId;
        }
        const parsedSK = parseSortKey(sK);
        const lineage = [];
        parsedSK.nodeIds.forEach((nId, idx) => {
            if (isTLEType(nId.t)) {
                lineage.push(mId + sK.substring(0, (idx + 1) * KeyUtilsPL2.nodeLength + KeyUtilsPL2.revisionLength));
            }
        });
        return lineage;
    }
    KeyUtilsPL2.getTLELineagePKs = getTLELineagePKs;
})(KeyUtilsPL2 || (KeyUtilsPL2 = {}));
