import {Injectable} from '@angular/core';

import {UserUtilsPL2 as UU} from '@common/utils/dist/index.js';

import {Observable, Observer} from 'rxjs';

@Injectable()
export class ServiceCommsWrapperService {
  get(apiName: string, url: string, params: any): Promise<any> {
    return this.api().then((api) => api.get(apiName, url, params));
  }
  post(apiName: string, url: string, params: any): Promise<any> {
    return this.api().then((api) => api.post(apiName, url, params));
  }
  put(apiName: string, url: string, params: any): Promise<any> {
    return this.api().then((api) => api.put(apiName, url, params));
  }
  del(apiName: string, url: string, params: any): Promise<any> {
    return this.api().then((api) => api.del(apiName, url, params));
  }
  currentUserInfo(): Promise<any> {
    return this.auth().then((auth) => auth.currentUserInfo());
  }
  currentAuthenticatedUser(opts?: {bypassCache: boolean}): Promise<any> {
    return this.auth().then((auth) => auth.currentAuthenticatedUser(opts));
  }
  signIn(email: string, password: string): Promise<any> {
    return this.auth().then((auth) => auth.signIn(email, password));
  }
  signUp(user: {
    password: string;
    username: string;
    attributes: Partial<Omit<UU.UserPoolUser, 'username'>>;
  }): Promise<any> {
    return this.auth().then((auth) => auth.signUp(user));
  }
  updateUserAttributes(
    user: any,
    attributes: {[attr: string]: string},
  ): Promise<any> {
    return this.auth().then((auth) =>
      auth.updateUserAttributes(user, attributes),
    );
  }
  async deleteUserAttribute(attributes: string): Promise<any> {
    return this.auth().then((auth) => auth.deleteUserAttributes(attributes));
  }
  confirmSignUp(
    email: string,
    code: string,
    opts: {forceAliasCreation: boolean},
  ): Promise<any> {
    return this.auth().then((auth) => auth.confirmSignUp(email, code, opts));
  }
  resendSignUp(email: string): Promise<any> {
    return this.auth().then((auth) => auth.resendSignUp(email));
  }
  forgotPassword(email: string): Promise<any> {
    return this.auth().then((auth) => auth.forgotPassword(email));
  }
  forgotPasswordSubmit(
    email: string,
    code: string,
    password: string,
  ): Promise<any> {
    return this.auth().then((auth) =>
      auth.forgotPasswordSubmit(email, code, password),
    );
  }
  signOut(): Promise<any> {
    return this.auth().then((auth) => auth.signOut());
  }
  federatedSignIn(
    opts:
      | {customProvider: string; customState?: string}
      | {provider: string; customState?: string},
  ): Promise<any> {
    return this.auth().then((auth) => auth.federatedSignIn(opts));
  }
  currentCredentials(): Promise<any> {
    return this.auth().then((auth) => auth.currentCredentials());
  }
  listen(category: string): Observable<{event: string; data?: any}> {
    return new Observable((observer: Observer<{event: string; data?: any}>) => {
      this.hub().then((hub) =>
        hub.listen(category, (data: {payload: {event: string; data?: any}}) => {
          observer.next(data.payload);
        }),
      );
    });
  }
  currentSession(): Promise<any> {
    return this.auth().then((auth) => auth.currentSession());
  }
  currentUserCredentials(): Promise<any> {
    return this.auth().then((auth) => auth.currentUserCredentials());
  }

  private api(): Promise<any> {
    return import('@aws-amplify/api').then(({API}) => API);
  }

  private auth(): Promise<any> {
    return import('@aws-amplify/auth').then(({Auth}) => Auth);
  }

  private hub(): Promise<any> {
    return import('@aws-amplify/core').then(({Hub}) => Hub);
  }
}
