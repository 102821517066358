import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ProcessorUtils as PU } from './processor-utils.js';
import { CharacteristicCache } from './characteristic-cache.js';
import { CharacteristicSensorCoordinator } from './characteristic-sensor-coordinator.js';
import { CharacteristicDataProcessorBuilder } from './characteristic-data-processor-builder.js';
import { VoyagerSensorConfigurator } from './voyager-sensor-configurator.js';
import { PrePowerlabDeviceConfigurator } from './pre-powerlab-device-configurator.js';
import { PrePowerlabMemoryConfigurator } from './pre-powerlab-memory-configurator.js';
import { connectionStateDefault, } from './connection-types.js';
export class VoyagerStrategy {
    constructor(gattServer, deviceConfig) {
        this.deviceConfig = deviceConfig;
        this.MPU9250_ACC_RANGE = 16.0;
        this.MPU9250_GYR_RANGE = 2000.0;
        this._barCoefficients = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
        this._processorMap = {
            mpu9250: (value) => {
                const v = new Int8Array(value);
                let x = PU.accShortSignedAtOffset(v, 2);
                let y = PU.accShortSignedAtOffset(v, 0);
                let z = PU.accShortSignedAtOffset(v, 4);
                const all = [
                    x / (32768.0 / this.MPU9250_ACC_RANGE),
                    (-1 * y) / (32768.0 / this.MPU9250_ACC_RANGE),
                    z / (32768.0 / this.MPU9250_ACC_RANGE),
                ];
                x = PU.accShortSignedAtOffset(v, 8) * (this.MPU9250_GYR_RANGE / 32768.0);
                y =
                    PU.accShortSignedAtOffset(v, 6) *
                        (this.MPU9250_GYR_RANGE / 32768.0) *
                        -1;
                z = PU.accShortSignedAtOffset(v, 10) * (this.MPU9250_GYR_RANGE / 32768.0);
                all.push(...[x, y, z]);
                x = PU.shortSignedAtOffset(v, 12) * 0.15 * 4;
                y = PU.shortSignedAtOffset(v, 14) * -0.15 * 4;
                z = PU.shortSignedAtOffset(v, 16) * -0.15 * 4;
                all.push(...[x, y, z]);
                return all;
            },
            bme280: (value) => PU.bme280(value, this._barCoefficients),
            apds9301: (value) => PU.apds9301(value),
            vl53l0x: (value) => {
                const v = new Uint8Array(value);
                const mm = PU.accShortSignedAtOffset(v, 0);
                let m = mm / 1000;
                if (m === 0 || m > 2.3) {
                    m = 2.3;
                }
                return [m];
            },
            ntc10k: (value) => {
                const v = new Uint8Array(value);
                return [PU.accShortSignedAtOffset(v, 0)];
            },
        };
        this._characteristicCache = new CharacteristicCache(gattServer, deviceConfig);
        this._sensorConfigurator = new VoyagerSensorConfigurator(deviceConfig, this._characteristicCache);
        this._sensorCoordinator = new CharacteristicSensorCoordinator(this._sensorConfigurator, deviceConfig, this._characteristicCache);
        this._memoryConfigurator = new PrePowerlabMemoryConfigurator(this._characteristicCache, deviceConfig, this._sensorCoordinator, this._processorMap);
        this._deviceConfigurator = new PrePowerlabDeviceConfigurator(this._characteristicCache, deviceConfig);
    }
    init() {
        return this.calibrate(this.deviceConfig.calibrationConfig)
            .then((partialConnectionState) => {
            this._barCoefficients = partialConnectionState.barCoefficients;
            return {
                ...connectionStateDefault,
                barCoefficients: this._barCoefficients,
                deviceConfig: this.deviceConfig,
            };
        })
            .then((connectionState) => this._memoryConfigurator
            .retrieveMemoryState()
            .then((status) => ({ ...connectionState, memoryStatus: status })));
    }
    startSensors(params) {
        const builder = new CharacteristicDataProcessorBuilder(this._processorMap, this.deviceConfig.frequencyConfigs[params.frequencyIndex]?.f ?? 1);
        return from(this._sensorCoordinator.startSensors(params.sensorIds, params.frequencyIndex)).pipe(mergeMap((dCs) => builder.build(dCs)));
    }
    calibrate(sensorId) {
        return this._sensorConfigurator.calibrate(sensorId);
    }
    memoryConfigurator() {
        return this._memoryConfigurator;
    }
    deviceConfigurator() {
        return this._deviceConfigurator;
    }
    crashModeConfigurator() {
        return null;
    }
    disconnect() {
        // do nothing
    }
}
