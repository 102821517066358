export interface UnitConfig {
  name: string;
  unit: Array<string>;
  convert: Array<(v: number, i?: number) => number>;
  combo?: Array<UnitConfig>;
}

export class UnitConfigs {
  static readonly len: UnitConfig = {
    name: 'Length/Altitude',
    unit: ['m', 'ft'],
    convert: [(v) => v, (v) => v * 3.28],
  };

  static readonly angularVelocity: UnitConfig = {
    name: 'Angular Velocity',
    unit: ['&#xb0;/s', 'RPM', 'radian/s'],
    convert: [(v) => v, (v) => v / 6, (v) => v * 0.01745329],
  };

  static readonly pressure: UnitConfig = {
    name: 'Pressure',
    unit: ['mBar', 'kPa'],
    convert: [(v) => v, (v) => v / 10],
  };

  static readonly temperature: UnitConfig = {
    name: 'Temperature',
    unit: ['&#x2103;', '&#x2109;', '&#x212a;'],
    convert: [(v) => v, (v) => (9 * v) / 5 + 32, (v) => v + 273.15],
  };

  static readonly velocity: UnitConfig = {
    name: 'Velocity',
    unit: ['m/s', 'MPH'],
    convert: [(v) => v, (v) => v * 2.24],
  };

  static readonly lightIntesity: UnitConfig = {
    name: 'Light Intensity',
    unit: ['lux'],
    convert: [(v) => v],
  };

  static readonly humidity: UnitConfig = {
    name: 'Humidity',
    unit: ['%RH'],
    convert: [(v) => v],
  };

  static readonly acceleration: UnitConfig = {
    name: 'Acceleration',
    unit: ['g', 'm/s&#178;'],
    convert: [(v) => v, (v) => v * 9.80665],
  };

  static readonly accelerationEnc: UnitConfig = {
    name: 'Acceleration',
    unit: ['m/s&#178;', 'g'],
    convert: [(v) => v, (v) => v / 9.80665],
  };

  static readonly magneticField: UnitConfig = {
    name: 'Magnetic Field',
    unit: ['uT'],
    convert: [(v) => v],
  };

  static readonly adc: UnitConfig = {
    name: 'ADC',
    unit: ['ADC'],
    convert: [(v) => v],
  };

  static readonly particulateMatter: UnitConfig = {
    name: 'Particulate Matter',
    unit: ['ug/m&#179;'],
    convert: [(v) => v],
  };

  static readonly carbonDioxide: UnitConfig = {
    name: 'Carbon Dioxide',
    unit: ['ppm'],
    convert: [(v) => v],
  };

  static readonly carbonMonoxide: UnitConfig = {
    name: 'Carbon Monoxide',
    unit: ['ppm'],
    convert: [(v) => v],
  };

  static readonly ozone: UnitConfig = {
    name: 'Ozone',
    unit: ['ppb'],
    convert: [(v) => v],
  };

  static readonly airQualityIndex: UnitConfig = {
    name: 'Air Quality Index',
    unit: [''],
    convert: [(v) => v],
  };

  static readonly arbitraryUnits: UnitConfig = {
    name: 'Arbitrary Units',
    unit: ['AU'],
    convert: [(v) => v],
  };

  static readonly none: UnitConfig = {
    name: '',
    unit: [''],
    convert: [(v) => v],
  };

  static readonly angle: UnitConfig = {
    name: 'Angle',
    unit: ['degrees', 'radians'],
    convert: [(v) => v, (v) => v * 0.01745329],
  };

  static readonly voltage: UnitConfig = {
    name: 'Voltage',
    unit: ['V'],
    convert: [(v) => v],
  };

  static readonly current: UnitConfig = {
    name: 'Current',
    unit: ['mA'],
    convert: [(v) => v],
  };

  static readonly resistance: UnitConfig = {
    name: 'Resistance',
    unit: ['&#937;'],
    convert: [(v) => v],
  };

  static readonly electricalConductivity: UnitConfig = {
    name: 'Electrical Conductivity',
    unit: ['uS/cm'],
    convert: [(v) => v],
  };

  static readonly siPressure: UnitConfig = {
    name: 'Pressure',
    unit: ['kPa', 'mBar'],
    convert: [(v) => v, (v) => v * 10],
  };
}

export function all(): Array<UnitConfig> {
  return Object.keys(UnitConfigs)
    .filter((v) => UnitConfigs[v].name)
    .map((v) => UnitConfigs[v]);
}

export function unitConfigKey(unitConfig: UnitConfig): string {
  let configKey: string = null;
  Object.keys(UnitConfigs).some((key) => {
    if (UnitConfigs[key] === unitConfig) {
      configKey = key;
      return true;
    }
  });
  return configKey;
}
