import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class OneOrTheOtherValidator {
    constructor(o, attrs, reasonBuilder) {
        this.o = o;
        this.attrs = attrs;
        this.reasonBuilder = reasonBuilder;
    }
    isValid() {
        const present = this.attrs.filter((attr) => !U.isEmpty(this.o[attr]));
        const valid = present.length === 1;
        if (!valid) {
            this.causes = [this.reasonBuilder.build('one or the other')];
        }
        return valid;
    }
    reasons() {
        return this.causes;
    }
}
