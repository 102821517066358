import { EntryType, sectionRange, } from '../models/models-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { AnswerAuthPolicy } from './answer-auth-policy.js';
import { SectionAuthPolicy } from './section-auth-policy.js';
import { FeedbackAuthPolicy } from './feedback-auth-policy.js';
import { IFrameSectionAuthPolicy } from './iframe-section-auth-policy.js';
import { TrialAuthPolicy } from './trial-auth-policy.js';
import { StudentUserAuthPolicy } from './student-user-auth-policy.js';
import { LabReportUserAuthPolicy } from './lab-report-user-auth-policy.js';
import { LabReportAuthPolicy } from './lab-report-auth-policy.js';
import { StudentCopyAuthPolicy } from './student-copy-auth-policy.js';
import { AssignmentAuthPolicy } from './assignment-auth-policy.js';
import { ClassAuthPolicy } from './class-auth-policy.js';
import { GlobalEntriesAuthPolicy } from './global-entries-auth-policy.js';
import { SharedDataTableSectionAuthPolicy } from './shared-data-table-section-auth-policy.js';
import { InvitedLabReportUserAuthPolicy } from './invited-lab-report-user-auth-policy.js';
import { PhetSectionAuthPolicy } from './phet-section-auth-policy.js';
import { UninvitedLabReportUserAuthPolicy } from './uninvited-lab-report-user-auth-policy.js';
import { SharedDrawingSectionAuthPolicy } from './shared-drawing-section-auth-policy.js';
import { LocalSubsetAuthPolicy } from './local-subset-auth-policy.js';
import { ScopeAuthPolicy } from './scope-auth-policy.js';
import { CourseAuthPolicy } from './course-auth-policy.js';
import { CurriculumAuthPolicy } from './curriculum-auth-policy.js';
import { LicenseAuthPolicy } from './license-auth-policy.js';
import { TeacherNotesAuthPolicy } from './teacher-notes-auth-policy.js';
import { ClassPermissionAuthPolicy } from './class-permission-auth-policy.js';
export function authPolicyFactory(sK, authEs, user, readOnly, fetchEntry, rI, fetchStudentEntry) {
    let entryType = KU.type(sK);
    return authPolicyFactoryWithType(entryType, authEs, user, readOnly, fetchEntry, rI, fetchStudentEntry);
}
export function authPolicyFactoryWithType(t, authEs, user, readOnly, fetchEntry, rI, fetchStudentEntry) {
    let authPolicy;
    switch (t) {
        case EntryType.AnswerSection:
        case EntryType.Transcript:
            authPolicy = new AnswerAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.Assignment:
            authPolicy = new AssignmentAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.StudentCopy:
            authPolicy = new StudentCopyAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.LabReport:
            authPolicy = new LabReportAuthPolicy(authEs, user, readOnly, fetchEntry, rI, fetchStudentEntry);
            break;
        case EntryType.StudentUser:
            authPolicy = new StudentUserAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.InvitedLabReportUser:
            authPolicy = new InvitedLabReportUserAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.UninvitedLabReportUser:
            authPolicy = new UninvitedLabReportUserAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.LabReportUser:
        case EntryType.ScopeUser:
            authPolicy = new LabReportUserAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.Class:
            authPolicy = new ClassAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.SharedDataTableSection:
            authPolicy = new SharedDataTableSectionAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.GlobalResource:
            authPolicy = new GlobalEntriesAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.Comment:
            authPolicy = new FeedbackAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.TeacherNotes:
            authPolicy = new TeacherNotesAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.IFrameSection:
            authPolicy = new IFrameSectionAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.PhetSection:
            authPolicy = new PhetSectionAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.Trial:
            authPolicy = new TrialAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.SharedDrawingSection:
        case EntryType.SharedDragAndDropSection:
        case EntryType.SharedLineMatchingSection:
            authPolicy = new SharedDrawingSectionAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.Module:
        case EntryType.LocalSubset:
        case EntryType.ClassLocalSubset:
            authPolicy = new LocalSubsetAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.ClassScope:
        case EntryType.IndividualScope:
            authPolicy = new ScopeAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.Course:
            authPolicy = new CourseAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.Curriculum:
            authPolicy = new CurriculumAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.License:
            authPolicy = new LicenseAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        case EntryType.ClassPermission:
            authPolicy = new ClassPermissionAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
            break;
        default:
            if (sectionRange[0] <= t && t <= sectionRange[1]) {
                try {
                    authPolicy = new SectionAuthPolicy(authEs, user, readOnly, fetchEntry, rI);
                }
                catch (e) {
                    console.error(e);
                    throw e;
                }
            }
            else {
                throw Error(`Auth policy not found for type: ${t}`);
            }
    }
    return authPolicy;
}
