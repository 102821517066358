import { EntryType, } from '../models/models-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class AcceptInviteMasterIndexManager {
    constructor(uId, invite) {
        this.uId = uId;
        this.invite = invite;
    }
    setIndexAttributes(entry, parent) {
        const e = entry;
        e.mId = this.invite.mId;
        const inviteSK = KU.parseSortKey(this.invite.sK);
        switch (e.t) {
            case EntryType.IndividualScope:
                // We use the invite node id to create the individual scope to prevent collisions in group assignments
                e.sK = `${parent.sK}${EntryType.IndividualScope}${inviteSK.nodeId.a}`;
                e.uId = this.invite.uId;
                break;
            case EntryType.ScopeUser:
                e.sK = `${parent.sK}${EntryType.ScopeUser}${inviteSK.nodeId.a}`;
                e.uId = this.uId;
                e.mUId = this.uId;
                break;
            case EntryType.LabReportUser:
                e.sK = KU.changeType(this.invite.sK, EntryType.LabReportUser);
                e.uId = this.uId;
                e.mUId = this.invite.mUId;
                break;
            case EntryType.LocalSubset:
                // If the local subset doesn't exist we create one using the invite node id
                e.sK = `${parent.sK}${e.t}${inviteSK.nodeId.a}`;
                e.uId = this.uId;
                break;
            default:
                e.sK = `${parent.sK}${e.t}${KU.parseSortKey(e.sK).nodeId.a}`;
                e.uId = this.invite.uId;
                break;
        }
        const ts = U.timestamp();
        e.cAt = ts;
        e.uAt = ts;
        e.rC = e.rC || 0;
        e.isA = 'y';
        return e;
    }
}
