import { ValidatorFactory, } from './validator-core.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
export class TleMetadataEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.tleMetadataAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'cat':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'dsc':
            case 'aut':
            case 'ico':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'ord':
                return [new LenientValidator(value, this.builderFactory(attr))];
            default:
                throw Error(`Attr: ${attr} shouldn\'t exist`);
        }
    }
}
