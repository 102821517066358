import { ValidatorFactory, } from './validator-core.js';
import { EntryValidatorFactory } from './entry-validator-factory.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
export class CommentEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.commentEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'cmt':
            case 'uN':
            case 'qF':
                return [new LenientValidator(value, this.builderFactory(attr))];
            default:
                return new EntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
    }
}
