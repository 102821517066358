import {Injectable} from '@angular/core';

import {EntryStore} from '@stores/entry.store';
import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';
import {NEVER, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WSMessageReceiverService} from '@services/ws-message-receiver.service';

@Injectable()
export class EntryFetchMessageProcessor implements WebsocketMessageProcessor {
  constructor(
    private entryStore: EntryStore,
    private wsMessageReceiverService: WSMessageReceiverService,
  ) {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const msg = params.msg;
    if (msg.target === '/entry.store.fetch') {
      return this.wsMessageReceiverService
        .receive(msg)
        .pipe(map((message) => this.entryStore.modifyState(message.c)));
    }
    return NEVER;
  }
}
