import {
  TriggersAndConditions,
  VideoTutorial,
  VideoTutorialCondition,
  VideoTutorialTrigger,
  videoTutorialConfig,
} from '@constants/video-tutorial.config';
import {UtilsPL2 as U, PL2} from '@common/utils/dist/index.js';
import {Observable, OperatorFunction, combineLatest, merge} from 'rxjs';
import {concatMap, map, mapTo} from 'rxjs/operators';

export module VideoTutorialUtils {
  export type AnyTrigger = VideoTutorialTrigger[keyof VideoTutorialTrigger];
  export type TriggerName = keyof VideoTutorialTrigger;
  export type Trigger =
    | {name: 'route'; value: VideoTutorialTrigger['route']}
    | {name: 'user'; value: VideoTutorialTrigger['user']}
    | {name: 'device'; value: VideoTutorialTrigger['device']}
    | {name: 'resetAll'; value: undefined};

  function routeMatchesState(
    trigger: VideoTutorialCondition['route'],
    state: VideoTutorialCondition['route'],
    ifEmpty?: boolean,
  ): boolean;
  function routeMatchesState(
    trigger: VideoTutorialTrigger['route'],
    state: VideoTutorialTrigger['route'],
    ifEmpty?: boolean,
  ): boolean;
  function routeMatchesState(
    trigger: VideoTutorialTrigger['route'] | VideoTutorialCondition['route'],
    state: VideoTutorialTrigger['route'] | VideoTutorialCondition['route'],
    ifEmpty: boolean = true,
  ): boolean {
    if (!trigger) {
      return ifEmpty;
    }

    return U.isFirstSubsetOfSecond(trigger, state);
  }

  function userMatchesState(
    trigger: VideoTutorialCondition['user'],
    state: VideoTutorialCondition['user'],
    ifEmpty?: boolean,
  ): boolean;
  function userMatchesState(
    trigger: VideoTutorialTrigger['user'],
    state: VideoTutorialTrigger['user'],
    ifEmpty?: boolean,
  ): boolean;
  function userMatchesState(
    trigger: VideoTutorialTrigger['user'] | VideoTutorialCondition['user'],
    state: VideoTutorialTrigger['user'] | VideoTutorialCondition['user'],
    ifEmpty: boolean = true,
  ): boolean {
    if (!trigger) {
      return ifEmpty;
    }

    return U.isFirstSubsetOfSecond(trigger, state);
  }

  function deviceMatchesState(
    trigger: VideoTutorialCondition['device'],
    state: VideoTutorialCondition['device'],
    ifEmpty?: boolean,
  ): boolean;
  function deviceMatchesState(
    trigger: VideoTutorialTrigger['device'],
    state: VideoTutorialTrigger['device'],
    ifEmpty?: boolean,
  ): boolean;
  function deviceMatchesState(
    trigger: VideoTutorialTrigger['device'] | VideoTutorialCondition['device'],
    state: VideoTutorialTrigger['device'] | VideoTutorialCondition['device'],
    ifEmpty: boolean = true,
  ): boolean {
    if (!trigger) {
      return ifEmpty;
    }

    return U.isFirstSubsetOfSecond(trigger, state);
  }

  function conditionsMatchesState(
    trigger: VideoTutorialCondition,
    states: VideoTutorialCondition,
  ): boolean {
    return (
      routeMatchesState(trigger.route, states.route) &&
      userMatchesState(trigger.user, states.user) &&
      deviceMatchesState(trigger.device, states.device)
    );
  }

  function triggerMatchesState(
    tutorialTrigger: VideoTutorialTrigger,
    trigger: Trigger,
  ): boolean {
    if (!trigger.value) {
      return false;
    }
    switch (trigger.name) {
      case 'route':
        return routeMatchesState(tutorialTrigger.route, trigger.value, false);
      case 'user':
        return userMatchesState(tutorialTrigger.user, trigger.value, false);
      case 'device':
        return deviceMatchesState(tutorialTrigger.device, trigger.value, false);
      default:
        console.warn('Unknown trigger name');
        return false;
    }
  }

  function matches(
    tutorialId: string,
    tutorialConditions: TriggersAndConditions | undefined,
    trigger: Trigger,
    states: VideoTutorialCondition,
    user: PL2.User,
  ): boolean {
    if (!tutorialConditions) {
      return false;
    }

    let triggerMatches = false;
    if (trigger.name === 'resetAll') {
      triggerMatches = tutorialConditions.triggers.some((tutorialTrigger) => {
        const mappedTriggers: VideoTutorialCondition = Object.entries(
          tutorialTrigger,
        ).reduce(
          (acc, [key, value]) => Object.assign(acc, {[key]: value.state}),
          {},
        );
        return conditionsMatchesState(mappedTriggers, states);
      });
    } else {
      triggerMatches = tutorialConditions.triggers.some((tutorialTrigger) =>
        triggerMatchesState(tutorialTrigger, trigger),
      );
    }
    if (!triggerMatches) {
      return false;
    }

    const tutorialWasDismissed = !!(user.tutorial ?? {})[tutorialId];
    if (tutorialWasDismissed) {
      return false;
    }

    const hasConditions = !!tutorialConditions.conditions.length;
    const conditionsMets =
      !hasConditions ||
      tutorialConditions.conditions.some((condition) =>
        conditionsMatchesState(condition, states),
      );
    return triggerMatches && conditionsMets;
  }

  export function findCorrectTutorial(
    trigger: Trigger,
    states: VideoTutorialCondition,
  ) {
    const user = states.user ?? {};
    return videoTutorialConfig.find((tutorial) => {
      return matches(tutorial?.id, tutorial.activation, trigger, states, user);
    });
  }

  export function shouldDeactivate(
    tutorialId: string,
    trigger: Trigger,
    states: VideoTutorialCondition,
  ) {
    if (!tutorialId) {
      return false;
    }

    const user = states.user ?? {};
    const tutorial = videoTutorialConfig.find((t) => t.id === tutorialId);
    if (!tutorial) {
      return false;
    }
    if (user.tutorial?.[tutorialId] === 1) {
      return true;
    }
    return matches(tutorial.id, tutorial.deactivation, trigger, states, user);
  }

  export function namedValue<T>(
    name: string,
  ): OperatorFunction<T, {name: string; value: T}> {
    return map((value) => ({name, value}));
  }

  export function distinctCombineLatest<
    R extends Record<string, Observable<T>>,
    T,
  >(observables: R): Observable<{last: keyof R; values: Record<keyof R, T>}> {
    const orderedKeys = Object.keys(observables);
    const orderedObservables = orderedKeys.map((key) => observables[key]);
    return new Observable<{last: keyof R; values: Record<keyof R, T>}>(
      (observer) => {
        const lastValues: Record<keyof R, T> = {} as Record<keyof R, T>;
        const distinctObservables = Object.entries(orderedObservables).map(
          ([_, value], index) => value.pipe(map((v) => ({index, value: v}))),
        );

        const combinedListener = combineLatest(distinctObservables);
        const combinedSubscription = combinedListener.subscribe(
          (values) => {
            values.forEach(({index, value}) => {
              lastValues[orderedKeys[index] as keyof R] = value;
            });
          },
          (err) => observer.error(err),
          () => {
            console.log('Combined listener completed');
            observer.complete();
            combinedSubscription.unsubscribe();
          },
        );

        const subscription = merge(...distinctObservables)
          .pipe(
            concatMap(({index, value}) =>
              combinedListener.pipe(mapTo({index, value})),
            ),
          )
          .subscribe(
            ({index}) => {
              observer.next({
                last: orderedKeys[index] as keyof R,
                values: lastValues,
              });
            },
            (err) => {
              observer.error(err);
            },
            () => {
              observer.next({
                last: orderedKeys[orderedKeys.length - 1] as keyof R,
                values: lastValues,
              });
              observer.complete();
              subscription.unsubscribe();
              console.log('Subscription completed');
            },
          );
      },
    );
  }
}
