import {MonoTypeOperatorFunction, OperatorFunction, pipe} from 'rxjs';
import {filter, distinctUntilChanged, map} from 'rxjs/operators';

import {PL2} from '@common/utils/dist/index.js';

import {Visualization, GraphConfig} from '@constants/graph.config';

import {ConnectionState} from '@common/ble/dist/index.js';

import {GraphConfigUtils as GCU} from '@utils/graph-config-utils';
import {UnitUtils as UU} from '@utils/unit-utils';

export function filterDisconnect(): MonoTypeOperatorFunction<ConnectionState> {
  let previousState: ConnectionState;
  return filter((state) => {
    const disconnect =
      !!previousState && !!previousState.deviceConfig && !state;
    previousState = state;
    return disconnect;
  });
}

export function filterConnect(): MonoTypeOperatorFunction<ConnectionState> {
  let previousState: ConnectionState;
  return filter((state) => {
    const connect = !previousState && !!state;
    previousState = state;
    return connect;
  });
}

export function userChangeFilter(): MonoTypeOperatorFunction<PL2.User> {
  let previousUser: PL2.User;
  return filter((user) => {
    let change = false;
    if (typeof previousUser !== 'undefined') {
      user = user || {};
      change =
        user.userId !== previousUser.userId || user.role !== previousUser.role;
      previousUser = user;
    } else {
      change = true;
      previousUser = user || {};
    }
    return change;
  });
}

export function memoryStatusChangeFilter(): MonoTypeOperatorFunction<ConnectionState> {
  return distinctUntilChanged((a, b) => {
    if (a === b) {
      return true;
    }
    if (a && b && a.memoryStatus === b.memoryStatus) {
      return true;
    }
    return false;
  });
}

export function arrangeConfigs(): OperatorFunction<
  PL2.DataConfigState,
  {graphConfigs: GraphConfig[]; buttonConfigs: GraphConfig[]}
> {
  return map((state) => {
    const gCIds = Object.keys(state).sort((a, b) =>
      state[a].pos > state[b].pos ? 1 : -1,
    );
    const ddC = new Array<GraphConfig>();
    const buttonC = new Array<GraphConfig>();
    gCIds.forEach((gCId) => {
      const graphConfig = GCU.configWithId(gCId);
      const dataConfig = state[gCId];
      if (
        graphConfig.visualizations[dataConfig.visIdx] === Visualization.Button
      ) {
        buttonC.push(graphConfig);
      } else {
        ddC.push(graphConfig);
      }
    });
    return {
      graphConfigs: ddC,
      buttonConfigs: buttonC,
    };
  });
}

export function dvcPoints(
  graphConfig: GraphConfig,
): OperatorFunction<PL2.DataConfigState, PL2.Point[]> {
  let prevNumPoints = 0;
  return pipe(
    map((state) => {
      if (!state[graphConfig.id]) {
        return [];
      }
      const diff = state[graphConfig.id].p.length - prevNumPoints;
      prevNumPoints = state[graphConfig.id].p.length;
      const uIdxs =
        state[graphConfig.id].uIdxs || graphConfig.unitConfigs.map(() => 0);
      if (diff > 0) {
        return state[graphConfig.id].p
          .slice(prevNumPoints - diff)
          .map((p) => UU.convertPoint(graphConfig, p, uIdxs));
      } else if (diff === 0) {
        return [];
      } else {
        return state[graphConfig.id].p.map((p) =>
          UU.convertPoint(graphConfig, p, uIdxs),
        );
      }
    }),
    filter((p) => p.length > 0),
  );
}

export function dvcConfig(
  gCId: string,
): OperatorFunction<PL2.DataConfigState, PL2.DataViewConfig> {
  return pipe(
    map((state) => state[gCId]),
    filter((dvc) => !!dvc),
  );
}
