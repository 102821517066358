import {Injectable, OnDestroy} from '@angular/core';
import {
  VideoTutorial,
  videoTutorialConfig,
} from '@constants/video-tutorial.config';
import {UserStore} from './user.store';
import {BehaviorSubject, Observable, ReplaySubject, merge} from 'rxjs';
import {RouteStore} from './route.store';
import {distinctUntilChanged, map, take, takeUntil, tap} from 'rxjs/operators';
import {UtilsPL2 as U} from '@common/utils/dist/index.js';
import {VideoTutorialUtils as VTU} from '@utils/video-tutorial-utils';
import {ConnectionStore} from './connection.store';
import {StoreEventsPipes as SEP} from '@cloudlab/utils/store-events-pipes';

@Injectable({
  providedIn: 'root',
})
export class VideoTutorialStore implements OnDestroy {
  private _destroyed$ = new ReplaySubject<boolean>();
  private _resetAll$: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined,
  );

  private _video$: Observable<VideoTutorial | undefined>;

  private _currentTutorial: VideoTutorial | undefined;

  constructor(
    private userStore: UserStore,
    private routeStore: RouteStore,
    private connectionStore: ConnectionStore,
  ) {
    this._video$ = merge(
      this.userStore.state$.pipe(SEP.userStoreEvents(), VTU.namedValue('user')),
      this.routeStore.state$.pipe(
        SEP.routeStoreEvents(),
        VTU.namedValue('route'),
      ),
      this.connectionStore.state$.pipe(
        SEP.connectionStoreEvents(),
        VTU.namedValue('device'),
      ),
      this._resetAll$.pipe(
        map(() => ({name: 'resetAll'})), // Ensure consistent trigger object
      ),
    ).pipe(
      takeUntil(this._destroyed$),
      map((trigger) => {
        const vtuTrigger = trigger as VTU.Trigger;
        const shouldDeactivate = VTU.shouldDeactivate(
          this._currentTutorial?.id,
          vtuTrigger,
          {
            device: this.connectionStore.state(),
            route: this.routeStore.state(),
            user: this.userStore.state(),
          },
        );

        let tutorial: VideoTutorial | undefined = shouldDeactivate
          ? undefined
          : this._currentTutorial;

        const newVideo = VTU.findCorrectTutorial(vtuTrigger, {
          device: this.connectionStore.state(),
          route: this.routeStore.state(),
          user: this.userStore.state(),
        });

        return tutorial ?? newVideo;
      }),
      tap((t) => (this._currentTutorial = t)),
      distinctUntilChanged(),
    );
  }

  video$() {
    return this._video$;
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  close() {
    this.userStore
      .updateUser({
        tutorial: {
          ...this.userStore.state().tutorial,
          [this._currentTutorial?.id]: 1,
        },
      })
      .pipe(take(1))
      .subscribe((s) => console.log(s));
  }

  resetAll() {
    this.userStore
      .updateUser({
        tutorial: U.exclude(
          this.userStore.state().tutorial,
          ...videoTutorialConfig.map((t) => t.id),
        ),
      })
      .pipe(take(1))
      .subscribe(() => {
        this._resetAll$.next();
      });
  }
}
