import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoTutorialComponent} from './video-tutorial.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [VideoTutorialComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, TranslateModule],
  providers: [],
  exports: [VideoTutorialComponent],
})
export class VideoTutorialModule {}
