import {NgZone} from '@angular/core';
import {Observable, OperatorFunction} from 'rxjs';
import {
  PL2,
  KeyUtilsPL2 as KU,
  EntryUtilsPL2 as EU,
  UtilsPL2 as U,
} from '@common/utils/dist/index.js';

import {EntryState, CachedEntry} from '@stores/entry.store';

export module PipeUtils {
  export function sortSectionPKs(
    sections: Array<string>,
    entryState: EntryState,
  ): Array<string> {
    return sections
      .map((s) => entryState[s].e as PL2.SectionEntry)
      .sort((a, b) => parseFloat(a.pos) - parseFloat(b.pos))
      .map((e) => `${e.mId}${e.sK}`);
  }

  export function sortSections(sections: Partial<PL2.SectionEntry>[]) {
    sections.sort((a, b) => parseFloat(a.pos) - parseFloat(b.pos));
  }

  export function filterType(type: PL2.EntryType, cPKs: string[]): string[] {
    return cPKs.filter((childPK) => {
      const cSK = KU.parseSortKey(KU.pKFromString(childPK).sK);
      return cSK.nodeId.t === type;
    });
  }

  export function arrEquals(arr1: any[], arr2: any[]): boolean {
    return (
      !arr1 &&
      !arr2 &&
      arr1.length === arr2.length &&
      arr1.every((e, i) => e === arr2[i])
    );
  }

  export function childEntries(
    parent: CachedEntry,
    entryState: EntryState,
    ...types: PL2.EntryType[]
  ): Partial<PL2.AnyEntry>[] {
    const entries = new Array<Partial<PL2.AnyEntry>>();
    (parent.c || []).forEach((cPK) => {
      const cSK = KU.parseSortKey(KU.pKFromString(cPK).sK);
      if (U.isEmpty(types) || types.some((t) => t === cSK.nodeId.t)) {
        entries.push(entryState[cPK].e);
      }
    });
    return entries;
  }

  export function childSectionEntries(
    parent: CachedEntry,
    entryState: EntryState,
  ): Partial<PL2.SectionEntry>[] {
    const entries = new Array<Partial<PL2.SectionEntry>>();
    parent.c.forEach((cPK) => {
      if (EU.isSection(cPK)) {
        entries.push(entryState[cPK].e);
      }
    });
    return entries;
  }

  export function buildEntryMap(
    ids: string[],
    types: PL2.EntryType[],
    entryState: EntryState,
    limit?: number,
    predicate?: (e: Partial<PL2.AnyEntry>) => boolean,
  ): Map<string, Array<Partial<PL2.AnyEntry>>> {
    limit = limit || 1000;
    predicate = predicate || (() => true);
    const entryMap = new Map<PL2.EntryType, Partial<PL2.AnyEntry>[]>();
    types.forEach((type) => entryMap.set(type, []));
    const es = Object.values(entryState).map((ce) => ce.e);
    es.sort(U.sortBy('cAt', U.oBy.desc));
    let limitCounter = 0;
    es.some((e) => {
      if (
        EU.isNotActive(e) ||
        U.isEmpty((e as PL2.UserLibraryEntryIdxEntry).uLEId)
      ) {
        return;
      }
      const hasMUId = !U.isEmpty((e as PL2.UserEntry).mUId);
      if (
        (hasMUId && ids.includes((e as PL2.UserEntry).mUId)) ||
        (!hasMUId && ids.includes(e.uId))
      ) {
        const typeEntries = entryMap.get(e.t);
        if (typeEntries && typeEntries.length < limit && predicate(e)) {
          typeEntries.push(e);
          if (typeEntries.length === limit) {
            limitCounter++;
          }
          if (limitCounter === types.length) {
            return true;
          }
        }
        return;
      }
    });
    return entryMap;
  }

  type MapOfTypes = Map<PL2.EntryType, Array<Partial<PL2.AnyEntry>>>;

  export function buildEntryMapWithFilter(
    entryState: EntryState,
    filter?: (e: Partial<PL2.AnyEntry>) => boolean,
    sort?: (a: Partial<PL2.AnyEntry>, b: Partial<PL2.AnyEntry>) => number,
  ): MapOfTypes {
    sort = sort ?? U.sortBy('cAt', U.oBy.desc);
    const entryMap = new Map<PL2.EntryType, Partial<PL2.AnyEntry>[]>();
    const es = Object.values(entryState).map((ce) => ce.e);
    const reduce = (p: MapOfTypes, c: MapOfTypes) => {
      c.forEach((v, k) => {
        p.set(k, [...(p.get(k) ?? []), ...v]);
      });
      return p;
    };
    return es
      .filter((e) => filter(e))
      .sort(sort)
      .map(
        (e) =>
          new Map<PL2.EntryType, Array<Partial<PL2.AnyEntry>>>([
            [KU.type(e), [e]],
          ]),
      )
      .reduce((p, c) => reduce(p, c), entryMap);
  }

  export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
    return (source) =>
      new Observable<T>((observer) => {
        const onNext = (value: T) => zone.run(() => observer.next(value));
        const onError = (e: any) => zone.run(() => observer.error(e));
        const onComplete = () => zone.run(() => observer.complete());
        return source.subscribe(onNext, onError, onComplete);
      });
  }
}
