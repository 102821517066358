import moment from 'moment';

import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';
import {ChartUtils as CU} from '@utils/chart-utils';

import {GraphConfig} from '@constants/graph.config';

import {DataColors, Color} from '@constants/app.config';

export module ChartUtils {
  export function replaceCodePoint(s: string): string {
    return s
      ? s.replace(/&#(.+);/g, function (match, dec) {
          return String.fromCodePoint(Number('0' + dec));
        })
      : '';
  }

  export function legendTimeFromSeconds(v: number, frequency: number) {
    if (frequency === 0) {
      return v;
    }
    if (v === 0) {
      return '0';
    }
    const h = Math.floor(v / 3600);
    const m = Math.floor((v - h * 3600) / 60);
    const s = v - h * 3600 - m * 60;
    let t = '';
    if (h > 0) {
      t += `${h.toString().padStart(2, '0')}:`;
    }
    if (h === 0 && m < 0) {
      t += `${m}:`;
    } else {
      t += `${m.toString().padStart(2, '0')}:`;
    }
    if (frequency > 10) {
      t += `${s.toFixed(2).padStart(5, '0')}`;
    } else if (frequency > 1) {
      t += `${s.toFixed(1).padStart(4, '0')}`;
    } else {
      t += `${s.toFixed(0).padStart(2, '0')}`;
    }
    return t;
  }

  export function showGridLines(state: PL2.DataStatus): boolean {
    return state === PL2.DataStatus.Recorded || state === PL2.DataStatus.Play
      ? true
      : false;
  }

  export function generateLegend(
    datasets: Chart.ChartDataSets[],
    configDatasets: Chart.ChartDataSets[],
    points: Chart.ChartPoint[],
    config: GraphConfig,
    dsVisibilities: boolean[],
    frequency: number,
    palette: string[],
    uIdx: number,
  ): Chart.ChartLegendLabelItem[] {
    const colors = CU.labelColors(
      configDatasets.length,
      config.colors,
      palette,
    );
    let labels: Chart.ChartLegendLabelItem[];
    const dsVis = dataSetVisibilities(datasets.length, dsVisibilities);
    if (points.length === 0 || !points[0]) {
      labels = [
        {
          text: '',
          strokeStyle: 'transparent',
          fillStyle: 'transparent',
          lineWidth: 0,
        },
      ];
      let j = 0;
      dsVis.forEach((vis, i) => {
        if (vis && datasets[i]) {
          labels.push({
            text: replaceCodePoint(datasets[j++].label),
            strokeStyle: 'transparent',
            fillStyle: colors[i],
            lineWidth: 0,
          });
        }
      });
      return labels;
    }
    labels = [
      {
        text: points[0]
          ? `Time: ${legendTimeFromSeconds(points[0].x as number, frequency)}\n`
          : '',
        strokeStyle: 'transparent',
        fillStyle: 'transparent',
        lineWidth: 0,
      },
    ];

    let k = 0;
    dsVis.forEach((vis, i) => {
      if (vis && datasets[i]) {
        const xy = points[k++];
        const dp = config.decimalPlaces[k % config.decimalPlaces.length];
        let y: string;
        if (!xy || typeof xy.y !== 'number') {
          y = '0';
        } else if (dp >= 0) {
          y = (xy.y as number).toFixed(dp);
        } else {
          y = String(xy.y);
        }
        if (config.labels.length === 1 && !U.isEmpty(uIdx)) {
          const unit = replaceCodePoint(config.unitConfigs[0]?.unit?.[uIdx]);
          if (!U.isEmpty(unit)) {
            y = `${y} ${unit}`;
          }
        }
        labels.push({
          text: `${replaceCodePoint(datasets[i].label)} ${y}`,
          strokeStyle: 'transparent',
          fillStyle: colors[i],
          lineWidth: 0,
        });
      }
    });
    return labels;
  }

  export function addUnits(yLabel: string, currentUnits: string): string {
    let yL = replaceCodePoint(yLabel);
    if (currentUnits) {
      const units = replaceCodePoint(currentUnits);
      yL += ' (' + units + ')';
    }
    return yL;
  }

  export function setYRange(
    useConfigMinMax: boolean,
    config: GraphConfig,
    chart: Chart,
    dsV: Array<boolean>,
    unitIdx: number,
    xRange?: number[],
  ): [number, number] {
    let max = -Infinity;
    let min = Infinity;
    chart.data.datasets.forEach((ds, i) => {
      if (!dsV[i % config.labels.length]) {
        return;
      }
      let points = ds.data as Chart.ChartPoint[];
      if (xRange) {
        points = (ds.data as Chart.ChartPoint[]).slice(
          xRange[0],
          xRange[1] + 1,
        );
      }
      points.forEach((p) => {
        if (typeof p.y === 'number') {
          if (max < p.y) {
            max = p.y;
          }
          if (min > p.y) {
            min = p.y;
          }
        }
      });
    });
    const delta = max - min;
    if (xRange) {
      const padding = delta * 0.1;
      min = min - padding;
      max = max + padding;
      return [min, max];
    }
    // min range is always the same for data configs which can use the line chart
    const minZoomDiff = delta - config.minRange[0];
    if (minZoomDiff < 0) {
      const absMinZoomDiff = Math.abs(minZoomDiff);
      min = min - absMinZoomDiff / 2;
      max = max + absMinZoomDiff / 2;
    } else {
      const padding = delta * 0.1;
      min = min - padding;
      max = max + padding;
    }

    const minTick = chart.options.scales.yAxes[0].ticks.min;
    const maxTick = chart.options.scales.yAxes[0].ticks.max;

    let minConfig = min;
    let maxConfig = max;

    if (useConfigMinMax && config.minValues) {
      minConfig =
        minTick < config.minValues[unitIdx]
          ? minTick
          : config.minValues[unitIdx];
    }
    if (useConfigMinMax && config.maxValues) {
      maxConfig =
        maxTick > config.maxValues[unitIdx]
          ? maxTick
          : config.maxValues[unitIdx];
    }

    max = useConfigMinMax && maxConfig > max ? maxConfig : max;
    min = useConfigMinMax && minConfig < min ? minConfig : min;

    return [min, max];
  }

  export function timeFromPoint(
    point: Array<number | PL2.IndependentVariable>,
  ): number {
    if (!point) {
      return null;
    }
    return typeof point[0] === 'number' ? point[0] : point[0]['n'];
  }

  export function pointsPerPage(
    graphConfig: GraphConfig,
    frequency: number,
  ): number {
    const ppp = graphConfig.pointsPerPage;
    if (ppp) {
      return ppp;
    } else if (frequency > 25) {
      return 200;
    } else if (frequency > 10) {
      return 200;
    } else {
      return 100;
    }
  }

  export function labelColors(
    num: number,
    colors: Color[],
    palette: string[] = DataColors,
  ): string[] {
    const cs = new Array<string>();
    for (let i = 0; i < num; i++) {
      const shade = Math.floor(i / colors.length) * 50;
      const color = palette[colors[i % colors.length]];
      cs.push(
        '#' +
          color
            .replace(/^#/, '')
            .replace(/../g, (c) =>
              (
                '0' +
                Math.min(255, Math.max(0, parseInt(c, 16) + shade)).toString(16)
              ).substr(-2),
            )
            .toUpperCase(),
      );
    }
    return cs;
  }

  export function startDate(sD: string): string {
    return sD || String(new Date().getTime());
  }

  export function toDate(startDate: string): Date {
    if (startDate) {
      return moment(parseInt(startDate, 10)).toDate();
    } else {
      return null;
    }
  }

  export function dataSetVisibilities(num: number, dsV: boolean[]): boolean[] {
    const vis = new Array<boolean>();
    for (let i = 0; i < num; i++) {
      vis.push(dsV[i % dsV.length]);
    }
    return vis;
  }
}
