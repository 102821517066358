import * as FileSaver from 'file-saver';

import {Injectable} from '@angular/core';

import {Observable, fromEvent} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {FileUtils as FU} from '@utils/file-utils';

@Injectable()
export class FileService {
  constructor() {}

  save(text: string, fileName: string) {
    const data = new Blob([text], {type: 'text/csv;charset=utf-8'});
    FileSaver.saveAs(data, fileName);
  }

  read(file: File): Observable<string> {
    const reader = new FileReader();
    reader.readAsText(file);
    return fromEvent(reader, 'load').pipe(
      map(() => reader.result as string),
      take(1),
    );
  }

  saveScreenshot(element: HTMLElement, fileName: string) {
    import('html2canvas').then((html2canvas) => {
      html2canvas.default(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const data = FU.convertDataURItoBlob(imgData);
        FileSaver.saveAs(data, fileName);
      });
    });
  }
}
