import { EntryType } from '../models/models-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
export class SourceIdIndexManager {
    setIndexAttributes(entry) {
        const output = entry;
        if (this._isSourceable(entry)) {
            output.srcId = U.generateId();
        }
        return output;
    }
    _isSourceable(e) {
        const pSK = KU.parentSortKey(e.sK);
        return (KU.isType(EntryType.LabReport, e.sK) &&
            !U.isEmpty(pSK) &&
            KU.isRootEntryType(EntryType.Curriculum, e.sK) &&
            (e.rC === 0 || U.isEmpty(e.srcId)));
    }
}
