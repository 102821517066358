import { any, all, isTurnedIn, hasNoBlacklistedAttsIn, } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class LabReportUserAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return all(this.userIsSetAsCreatorFor(entry), this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */))();
    }
    async canRead(_entry) {
        return this.userIsParticipantInAuthScope;
    }
    async canUpdate(entry) {
        return all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry), any(this._userIsCreatorIfTurningIn(entry), this._userIsTleAdminIfNotTurningIn(entry)))();
    }
    async canDelete(_entry) {
        return this.userIsPrivilegedParticipantInAuthScope;
    }
    _userIsCreatorIfTurningIn(entry) {
        return all(this._isTurningIn(entry), this.userIsCreatorOf(entry));
    }
    _userIsTleAdminIfNotTurningIn(entry) {
        return all(!this._isTurningIn(entry), this.userIsPrivilegedParticipantInAuthScope);
    }
    _isTurningIn(entry) {
        return isTurnedIn(entry);
    }
}
