import { EntryType, } from '../models/models-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { curriculumPKLookup } from '../config/constants.js';
import { userIds } from '../utils/user-utils-pl2.js';
export var AuthUtils;
(function (AuthUtils) {
    AuthUtils.keslerDemoPermissionName = 'Kesler Science - Demo';
    AuthUtils.keslerDemoGroupLicensePK = 'E3FmPn630054jbsj';
    AuthUtils.keslerDemoCurriculumPK = curriculumPKLookup.keslerDemo;
    function isAssociatedWithEntry(entry, user) {
        const uE = entry;
        return (!U.isEmpty(user) &&
            (user.userId === uE.uId ||
                (!U.isEmpty(uE.mUId) &&
                    (userIds(user).some((uId) => uId === uE.mUId) ||
                        user.email === uE.mUId))));
    }
    AuthUtils.isAssociatedWithEntry = isAssociatedWithEntry;
    function isLrUser(user, entries) {
        return (!U.isEmpty(user) &&
            entries.some((e) => e.uId === user.userId &&
                (KU.type(e) === EntryType.LabReportUser ||
                    KU.type(e) === EntryType.ScopeUser)));
    }
    AuthUtils.isLrUser = isLrUser;
    function isUninvitedLrUser(user, entries) {
        return (!U.isEmpty(user) &&
            entries.some((e) => KU.type(e) === EntryType.UninvitedLabReportUser &&
                e.uId === user.userId));
    }
    AuthUtils.isUninvitedLrUser = isUninvitedLrUser;
    function isTLEOwner(user, entries) {
        if (U.isEmpty(entries)) {
            return false;
        }
        return !U.isEmpty(user) && (entries[0] || {}).uId === user.userId;
    }
    AuthUtils.isTLEOwner = isTLEOwner;
    function isEntryOwner(user, entry) {
        return !U.isEmpty(user) && !!entry && user.userId === entry.uId;
    }
    AuthUtils.isEntryOwner = isEntryOwner;
    // WARNING this should only be used when when auth entries aren't partial (i.e canRead)
    function isPublic(entries) {
        return !!(entries[0] || {}).isP;
    }
    AuthUtils.isPublic = isPublic;
    function userOwnsByEntryMUId(user, entry) {
        const mUIdComparators = [
            user?.email,
            user?.userId,
            user?.googleProfileId,
            user?.classlinkId,
            user?.edId,
            user?.edlinkId,
        ];
        return (!U.isEmpty(user) &&
            !U.isEmpty(entry) &&
            mUIdComparators.some((mUId) => mUId === entry.mUId));
    }
    AuthUtils.userOwnsByEntryMUId = userOwnsByEntryMUId;
    function isEntryMemberUser(user, entry) {
        return (!U.isEmpty(user) &&
            !U.isEmpty(entry.mUId) &&
            userOwnsByEntryMUId(user, entry));
    }
    AuthUtils.isEntryMemberUser = isEntryMemberUser;
    function hasTleType(t, entries) {
        if (U.isEmpty(entries)) {
            return false;
        }
        return KU.isType(t, entries[0]);
    }
    AuthUtils.hasTleType = hasTleType;
    function canManageLicense(user, entry) {
        return Object.keys(user?.admin || {}).some((lPK) => {
            const pK = KU.pKFromString(lPK);
            return (pK.mId === entry.mId &&
                entry.sK.startsWith(pK.sK) &&
                entry.sK.length > pK.sK.length);
        });
    }
    AuthUtils.canManageLicense = canManageLicense;
    function canReadWithLicense(user, entry) {
        const uc = user?.curriculums || {};
        const scC = entry.scC;
        if (!U.isEmpty(uc[entry.mId])) {
            return U.isEmpty(uc[entry.mId].scC) || uc[entry.mId].scC === scC;
        }
        return false;
    }
    AuthUtils.canReadWithLicense = canReadWithLicense;
    function canReadWithLicenseOrOwnership(user, entry) {
        return (isTLEOwner(user, [entry]) || canReadWithLicense(user, entry) || entry.isP);
    }
    AuthUtils.canReadWithLicenseOrOwnership = canReadWithLicenseOrOwnership;
    function licenseTypeWithParent(parentPK) {
        if (U.isEmpty(parentPK)) {
            return 0 /* LicenseType.Group */;
        }
        switch (KU.parseSortKey(KU.pKFromString(parentPK).sK).nodeIds.length) {
            case 1:
                return 1 /* LicenseType.Admin */;
            case 2:
                return 2 /* LicenseType.Permission */;
            default:
                throw Error('Invalid license type');
        }
    }
    AuthUtils.licenseTypeWithParent = licenseTypeWithParent;
    function getLicenseType(pK) {
        if (U.isEmpty(pK) || !KU.isType(EntryType.License, pK)) {
            return null;
        }
        switch (KU.parseSortKey(KU.pKFromString(pK).sK).nodeIds.length) {
            case 1:
                return 0 /* LicenseType.Group */;
            case 2:
                return 1 /* LicenseType.Admin */;
            case 3:
                return 2 /* LicenseType.Permission */;
            default:
                return null;
        }
    }
    AuthUtils.getLicenseType = getLicenseType;
    function isPermissionLicense(licPK) {
        return getLicenseType(licPK) === 2 /* LicenseType.Permission */;
    }
    AuthUtils.isPermissionLicense = isPermissionLicense;
    function isAdminLicense(licPK) {
        return getLicenseType(licPK) === 1 /* LicenseType.Admin */;
    }
    AuthUtils.isAdminLicense = isAdminLicense;
    function isGroupLicense(licPK) {
        return getLicenseType(licPK) === 0 /* LicenseType.Group */;
    }
    AuthUtils.isGroupLicense = isGroupLicense;
    function hasGroups(user) {
        return Object.keys(user.admin || {}).some((l) => isGroupLicense(l));
    }
    AuthUtils.hasGroups = hasGroups;
    function serializeLicense(lE) {
        const licPK = KU.stringFromKey(lE);
        const isPermissionLic = isPermissionLicense(licPK);
        return [
            isPermissionLic ? '' : licPK,
            isPermissionLic ? lE.curPK : '',
            // TODO Used to be abiId, Remove once everyone has the browser update
            '',
            isPermissionLic ? lE.scC : '',
        ].join(':');
    }
    AuthUtils.serializeLicense = serializeLicense;
    function serializeLicenses(lEs) {
        return lEs.map((lE) => serializeLicense(lE)).join('|');
    }
    AuthUtils.serializeLicenses = serializeLicenses;
    function deserializeLicenses(lEStrs) {
        if (U.isEmpty(lEStrs)) {
            return {};
        }
        const licenses = {};
        lEStrs.split('|').forEach((lEStr) => {
            const split = lEStr.split(':');
            if (split.length < 3) {
                throw Error('Invalid license');
            }
            const license = U.excludeEmptyProperties({
                licPK: split[0],
                curPK: split[1],
                // TODO Remove once everyone has the browser update
                scC: split[2] || split[3],
            });
            if (!U.isEmpty(license.curPK)) {
                if (licenses.curriculums === undefined) {
                    licenses.curriculums = {};
                }
                licenses.curriculums[KU.pKFromString(license.curPK).mId] = {
                    scC: license.scC,
                    curPK: license.curPK,
                };
            }
            else if (!U.isEmpty(license.licPK)) {
                if (licenses.admin === undefined) {
                    licenses.admin = {};
                }
                licenses.admin[license.licPK] = { licPK: license.licPK };
            }
        });
        return licenses;
    }
    AuthUtils.deserializeLicenses = deserializeLicenses;
    function serializeClassPermission(cPE) {
        const isAdmin = isClassPermissionAdmin(cPE);
        return `${isAdmin ? cPE.mId : ':'}${isAdmin ? ':' : cPE.mId}`;
    }
    AuthUtils.serializeClassPermission = serializeClassPermission;
    function serializeClassPermissions(cPEs) {
        return cPEs.map((cPE) => serializeClassPermission(cPE)).join('|');
    }
    AuthUtils.serializeClassPermissions = serializeClassPermissions;
    function isClassPermissionAdmin(cPE) {
        return cPE.cPT === 1 /* ClassPermissionType.Admin */;
    }
    AuthUtils.isClassPermissionAdmin = isClassPermissionAdmin;
    function deserializeClassPermissions(cPEStrs) {
        if (U.isEmpty(cPEStrs)) {
            return {};
        }
        const classPermissions = {};
        cPEStrs.split('|').forEach((cPStr) => {
            const split = cPStr.split(':');
            if (split.length < 2) {
                throw Error('Invalid class permission');
            }
            const classPermission = {
                clsMId: split[0] === '' ? split[1] : split[0],
                isAdmin: U.isEmpty(split[1]),
            };
            if (U.isEmpty(classPermissions[classPermission.clsMId]) ||
                !classPermissions[classPermission.clsMId].isAdmin) {
                classPermissions[classPermission.clsMId] = classPermission;
            }
        });
        return classPermissions;
    }
    AuthUtils.deserializeClassPermissions = deserializeClassPermissions;
    function hasClassPermission(user, entry) {
        return !U.isEmpty(user?.classPermissions?.[entry?.mId]);
    }
    AuthUtils.hasClassPermission = hasClassPermission;
    function hasClassAdminPermission(user, entry) {
        return !!user?.classPermissions?.[entry?.mId]?.isAdmin;
    }
    AuthUtils.hasClassAdminPermission = hasClassAdminPermission;
    AuthUtils.keslerCurPK = curriculumPKLookup.keslerPro;
    AuthUtils.pKSCCToKeyMap = {
        [curriculumPKLookup.keslerDemo]: 'kld',
        [`${AuthUtils.keslerCurPK}core`]: 'klc',
        [AuthUtils.keslerCurPK]: 'klp',
        [curriculumPKLookup.openSciEd]: 'ose',
        [curriculumPKLookup.conceptualAcademyBiology]: 'cab',
        [curriculumPKLookup.conceptualAcademyPhysics]: 'cap',
        [curriculumPKLookup.conceptualAcademyIntegrated]: 'cac',
        [curriculumPKLookup.conceptualAcademyIntegratedPhysicsChemistry]: 'caipc',
        [curriculumPKLookup.amoebaSisters]: 'as',
        actAsPro: 'pro',
    };
    function curriculumKeyFromPK(pK, subCurriculumCode = '') {
        return AuthUtils.pKSCCToKeyMap[pK + subCurriculumCode];
    }
    AuthUtils.curriculumKeyFromPK = curriculumKeyFromPK;
    function isKeslerLicense(licenseEntry) {
        const cK = curriculumKeyFromPK(licenseEntry.curPK ?? '', licenseEntry?.scC);
        return cK === 'klc' || cK === 'klp';
    }
    AuthUtils.isKeslerLicense = isKeslerLicense;
})(AuthUtils || (AuthUtils = {}));
/**
 * Works similar to Array.every, but allows async functions and executes each check sequentially so if
 * the first check fails it will not execute any further function checks. Therefore booleans and faster async checks
 * should be passed first to avoid extra processing time.
 *
 * @param checks - An array of booleans, async functions
 * @returns true if all checks are true, false otherwise
 */
export function all(...checks) {
    return async () => {
        let can = true;
        let i = 0;
        while (can && i < checks.length) {
            if (checks[i] instanceof Function) {
                can = !!(await checks[i]());
            }
            else {
                can = !!checks[i];
            }
            i++;
        }
        return can;
    };
}
/**
 * Works similar to Array.some, but allows async functions and executes each check sequentially so if
 * the first check passes it will not execute any further function checks. Therefore booleans and faster async checks
 * should be passed first to avoid extra processing time.
 *
 * @param checks - An array of booleans, async functions
 * @returns true if all checks are true, false otherwise
 */
export function any(...checks) {
    return async () => {
        let can = false;
        let i = 0;
        while (!can && i < checks.length) {
            if (checks[i] instanceof Function) {
                can = await checks[i]();
            }
            else {
                can = !!checks[i];
            }
            i++;
        }
        return can;
    };
}
const blackList = ['uId', 'mUId', 't', 'isA'];
const defaultWhiteList = ['mId', 'sK', 'uAt'];
export function hasNoBlacklistedAttsIn(entry, ...additionalBlacklistedAttrs) {
    return !hasBlacklistedAttsIn(entry, ...additionalBlacklistedAttrs);
}
export function hasNoFilteredBlacklistedAttsIn(entry, opts) {
    const moreBlacklistedAttrs = opts?.alsoBlacklist ?? [];
    const lessBlacklistedAttrs = opts?.removeFromBlacklist ?? [];
    const allBlacklistedAttrs = [...blackList, ...moreBlacklistedAttrs];
    const filteredBlacklisted = allBlacklistedAttrs.filter((attr) => !lessBlacklistedAttrs.includes(attr));
    return Object.keys(entry).every((k) => !filteredBlacklisted.includes(k));
}
export function hasOnlyWhitelistedAttrIn(entry, whitelist) {
    const fullWhitelist = [...defaultWhiteList, ...whitelist];
    if (Object.keys(entry).some((k) => !fullWhitelist.includes(k))) {
        return false;
    }
    else {
        return true;
    }
}
export function hasBlacklistedAttsIn(entry, ...additionalBlacklistedAttrs) {
    if (Object.keys(entry).some((k) => [...blackList, ...additionalBlacklistedAttrs].includes(k))) {
        return true;
    }
    else {
        return false;
    }
}
export function isTurnedIn(lrUser) {
    return !U.isEmpty(lrUser) && !U.isEmpty(lrUser.tIAt);
}
export function isNotTurnedIn(lrUser) {
    return !isTurnedIn(lrUser);
}
export function isPublic(entry) {
    return !!entry.isP;
}
export function hasAttrIn(entry, attr) {
    return !U.isEmpty(entry?.[attr]);
}
export function hasNoAttrIn(entry, attr) {
    return !hasAttrIn(entry, attr);
}
