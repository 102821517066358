import { ConcatReasonBuilder } from './concat-reason-builder.js';
export const multiAttrValidatorKey = '_multi_';
export class ValidatorFactory {
    constructor(reasonBuilderFactory) {
        this.reasonBuilderFactory = reasonBuilderFactory;
    }
    getMultiAttributeValidators(o) {
        return [];
    }
    getReasonBuilder(why) {
        return this.reasonBuilderFactory(why);
    }
    loadFactory(factory, o, ...keys) {
        keys.forEach((key) => (factory[key] = this.getAttributeValidators(key, o[key])));
        const multiAttrValidators = this.getMultiAttributeValidators(o);
        if (multiAttrValidators.length > 0) {
            factory[multiAttrValidatorKey] = multiAttrValidators;
        }
    }
}
export const DefaultReasonBuilderFactory = (what) => ConcatReasonBuilder.getInstance(what);
