import {
  APIMessages as APIM,
  PL2,
  KeyUtilsPL2 as KU,
} from '@common/utils/dist/index.js';
import {EntryState, EntryStore} from '@stores/entry.store';
import {PendingOperation} from '@stores/entry-store-automaton/operation-state-machine';
import {EntryEventChainOutput, EntryEventHandler} from './entry-event-core';

export class ArrayBuilderHandler
  implements EntryEventHandler<EntryState, EntryEventChainOutput>
{
  nextHandler: EntryEventHandler<EntryEventChainOutput, any>;

  private readonly _actionMap = {
    [PendingOperation.Create]: PL2.EntryAction.Create,
    [PendingOperation.Delete]: PL2.EntryAction.DeepDelete,
  };

  constructor(private entryStore: EntryStore) {}

  handle(entryState: EntryState): EntryEventChainOutput {
    const expired = new Array<string>();
    const changes = new Array<APIM.EntryChange>();
    Object.keys(entryState).forEach((pK) => {
      const ce = entryState[pK];

      switch (ce._pO) {
        case PendingOperation.Create:
        case PendingOperation.Delete:
          changes.push({
            act: this._actionMap[ce._pO],
            e: ce.e as PL2.AnyPartialEntryWithId,
          });
          break;
        case PendingOperation.Update:
          changes.push({
            act: PL2.EntryAction.Update,
            e: ce._payload[ce._payload.length - 1],
          });
          break;
        case PendingOperation.DeleteFailed:
        case PendingOperation.CreateFailed:
        case PendingOperation.UpdateFailed:
          const ceTs = parseInt(ce._ts, 10);
          const currentTs = new Date().getTime();
          const input =
            ce._pO === PendingOperation.DeleteFailed
              ? PendingOperation.Delete
              : ce._pO === PendingOperation.CreateFailed
                ? PendingOperation.Create
                : PendingOperation.Update;
          if (currentTs - ceTs > 30_000) {
            this.entryStore.advanceCachedEntryState(
              [KU.pKFromString(pK)],
              input,
            );
          }
          break;
        case PendingOperation.Ignore:
          expired.push(pK);
          break;
        default:
        // do nothing
      }
    });

    return this.nextHandler.handle({
      expired,
      changes,
    });
  }
}
