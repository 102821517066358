import {Injectable} from '@angular/core';

import {
  CacheExpirationStrategyService,
  CacheExpirationEvent,
} from './cache-expiration-strategy.service';

import {EntryStore, CachedEntry, EntryState} from '@stores/entry.store';
import {PendingOperation} from '@stores/entry-store-automaton/operation-state-machine';

import {
  PL2,
  UtilsPL2 as U,
  KeyUtilsPL2 as KU,
} from '@common/utils/dist/index.js';

export interface DefaultCacheExpirationStrategyOptions {
  maxAge?: number; // seconds
  maxEntries?: number;
  maxErrors?: number;
  // maxSize?: number; // MB
}

@Injectable()
export class DefaultCacheExpirationStrategyService
  implements CacheExpirationStrategyService
{
  lastCount = 0;
  lastOldestAge = Date.now().valueOf().toString();
  maxEntries: number;
  maxErrors: number;
  maxAge: number;
  errors: {[pK: string]: {count: number; last: string}} = {};
  lastSuccess = U.timestamp();

  constructor(private opts: DefaultCacheExpirationStrategyOptions = {}) {
    this.maxEntries = this.opts.maxEntries || Infinity;
    this.maxErrors = U.isEmpty(this.opts.maxErrors)
      ? Infinity
      : this.opts.maxErrors;
    this.maxAge = U.isEmpty(this.opts.maxAge)
      ? Infinity
      : this.opts.maxAge * 1000;
  }
  expire(
    event: CacheExpirationEvent,
    draft?: EntryState,
    changes?: Array<Partial<CachedEntry>>,
  ): void;
  expire(event: CacheExpirationEvent, draft?: any, changes?: Array<any>): void {
    const entries = Object.values(draft as EntryState);
    switch (event) {
      case CacheExpirationEvent.LoadCache:
        const extra = entries.length - this.maxEntries;
        this.lastCount = entries.length;
        if (extra > 0) {
          this.lastCount = this._expireExtraEntries(extra, entries, draft);
        } else {
          this.lastOldestAge = this._deleteExpiredEntries(entries, draft);
        }
        this._resetSentOperations(entries, draft);
        this._deleteUndoableDeleteOperations(entries, draft);
        this._ignoreNonRetryableEntries(entries, draft);
        break;
    }
  }

  deepExpire(
    event: CacheExpirationEvent,
    store: EntryStore,
    errors?: Array<Partial<PL2.AnyEntry>>,
  ): void;
  deepExpire(
    event: CacheExpirationEvent,
    store: any,
    errors?: Array<any>,
  ): void {
    switch (event) {
      case CacheExpirationEvent.Success:
        this.lastSuccess = U.timestamp();
        break;
      case CacheExpirationEvent.Error:
        // Set non-retryable entries from errors[]
        const ignored = (errors || []).filter(
          (e) =>
            !this._canRetry(KU.stringFromKey(e as PL2.AnyPartialEntryWithId)),
        );
        (store as EntryStore).advanceCachedEntryState(
          ignored,
          PendingOperation.Ignore,
        );
        break;
    }
  }

  private _canRetry(pK: string): boolean {
    // determine if entry has reached max errors threshold
    const ts = U.timestamp();
    if (U.isEmpty(this.errors[pK])) {
      this.errors[pK] = {count: 0, last: '0'};
    }
    if (this.errors[pK].last < this.lastSuccess) {
      this.errors[pK] = {count: this.errors[pK].count + 1, last: ts};
      if (this.errors[pK].count > this.maxErrors) {
        return false;
      }
    }
    return true;
  }

  private _ignoreNonRetryableEntries(
    entries: Array<Partial<CachedEntry>>,
    draft: EntryState,
  ) {
    entries.forEach((ce) => {
      const pK = KU.stringFromKey(ce.e as PL2.EntryId);
      if (!this._canRetry(pK) && draft[pK]) {
        draft[pK]._pO = PendingOperation.Ignore;
      }
    });
  }

  private _expireExtraEntries(
    extra: number,
    entries: Array<Partial<CachedEntry>>,
    draft: EntryState,
  ): number {
    const sorted = entries.sort(U.sortBy('_ts', U.oBy.asc));
    const deleted = [];
    for (let i = 0; i < extra; i++) {
      const pK = KU.stringFromKey(sorted[i].e as PL2.EntryId);
      if (!this._isLocked(pK)) {
        deleted.push(draft[pK]);
        delete draft[pK];
      }
    }
    return entries.length - deleted.length;
  }

  private _deleteExpiredEntries(
    entries: Array<Partial<CachedEntry>>,
    draft: EntryState,
  ): string {
    const now = Date.now().valueOf();
    const maxAge = (now - this.maxAge).toString();
    let oldestAge = now.toString();
    const removableEntries = entries.filter((ce) => {
      if (ce._ts < maxAge) {
        return true;
      } else {
        oldestAge = ce._ts < oldestAge ? ce._ts : oldestAge;
        return false;
      }
    });
    removableEntries.forEach((ce) => {
      const pK = KU.stringFromKey(ce.e as PL2.EntryId);
      if (!this._isLocked(pK)) {
        delete draft[pK];
      }
    });
    return oldestAge;
  }

  private _resetSentOperations(
    entries: Array<Partial<CachedEntry>>,
    draft: EntryState,
  ) {
    entries.forEach((ce) => {
      switch (ce._pO) {
        case PendingOperation.UpdateSent:
          draft[KU.stringFromKey(ce.e as PL2.EntryId)]._pO =
            PendingOperation.Update;
          break;
        case PendingOperation.CreateSent:
          draft[KU.stringFromKey(ce.e as PL2.EntryId)]._pO =
            PendingOperation.Create;
          break;
        case PendingOperation.DeleteSent:
          draft[KU.stringFromKey(ce.e as PL2.EntryId)]._pO =
            PendingOperation.Delete;
          break;
        default:
        // do nothing
      }
    });
  }

  private _deleteUndoableDeleteOperations(
    entries: Array<Partial<CachedEntry>>,
    draft: EntryState,
  ) {
    entries.forEach((ce) => {
      switch (ce._pO) {
        case PendingOperation.UndoableDelete:
        case PendingOperation.UndoableDeleteFromUpdate:
        case PendingOperation.UndoableDeleteFromCreateSent:
          draft[KU.stringFromKey(ce.e as PL2.EntryId)]._pO =
            PendingOperation.Delete;
          break;
        case PendingOperation.UndoableDeleteFromCreate:
          delete draft[KU.stringFromKey(ce.e as PL2.EntryId)];
          break;
        default:
        // do nothing
      }
    });
  }

  private _isLocked(pK: string): boolean {
    if (!U.isEmpty(KU.parentPK(pK))) {
      return true;
    }
    return false;
  }
}
