export class BytewiseCommandBuilder {
    constructor(deviceConfig) {
        this.deviceConfig = deviceConfig;
    }
    build(sensorIds, frequency) {
        const configValues = [0, frequency, 0, 0, 0, 0, 0];
        sensorIds.forEach((sensorId) => {
            const sensorConfig = this.deviceConfig.sensorConfigs[sensorId];
            if (!sensorConfig) {
                throw Error(`Sensor with id ${sensorId} does not exist`);
            }
            configValues[sensorConfig.configValue] = 1;
        });
        return new Int8Array(configValues).buffer;
    }
}
