import { ValidatorFactory, } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { EntryValidatorFactory } from './entry-validator-factory.js';
import { AuthIdxEntryValidatorFactory } from './auth-idx-entry-validator-factory.js';
import { UserLibraryEntryIdxEntryValidatorFactory } from './user-library-entry-idx-entry-validator-factory.js';
import { ClassIdxEntryValidatorFactory } from './class-idx-entry-validator-factory.js';
import { LenientValidator } from './lenient-validator.js';
import { OneOrTheOtherValidator } from './one-or-the-other-validator.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
export class ClassEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.classEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'cC':
            case 'per':
            case 'sub':
            case 'rm':
            case 'gCId':
            case 'cCId':
            case 'edCId':
            case 'eCId':
            case 'code':
            case 'exUrl':
            case 'sN':
            case 'lmsCId':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'uSP':
            case 'tmp':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'n':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            default:
                if (EU.entryAtts.includes(attr)) {
                    return new EntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.authIdxEntryAtts.includes(attr)) {
                    return new AuthIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.userLibraryEntryIdxEntryAtts.includes(attr)) {
                    return new UserLibraryEntryIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else {
                    return new ClassIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
        }
    }
    getMultiAttributeValidators(o) {
        return [
            new OneOrTheOtherValidator(o, ['cC', 'gCId', 'cCId', 'edCId'], this.builderFactory('cC_gCId_cCId_edCId')),
        ];
    }
}
