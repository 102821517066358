import {NgModule} from '@angular/core';
import {AcknowledgedMessageProcessor} from './acknowledged-message-processor';
import {EntryMessageProcessor} from './entry-message-processor';
import {LicenseMessageProcessor} from './license-message-processor';
import {PongMessageProcessor} from './pong-message-processor';
import {VoidMessageProcessor} from './void-message-processor';
import {WebsocketMessageProcessor} from './websocket-message-processor';
import {EntryFetchMessageProcessor} from './entry-fetch-message-processor';
import {WSMessageReceiverService} from '@services/ws-message-receiver.service';
import {ErrorMessageProcessor} from './error-message-processor';
import {ReceiveEventAcknowledgementAction} from '@cloudlab/actions/receive-event-acknowledgement.action';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    VoidMessageProcessor,
    PongMessageProcessor,
    EntryMessageProcessor,
    LicenseMessageProcessor,
    AcknowledgedMessageProcessor,
    WSMessageReceiverService,
    EntryFetchMessageProcessor,
    ErrorMessageProcessor,
    ReceiveEventAcknowledgementAction,
    {
      provide: 'wsMessageProcessors',
      useFactory: (...mps: Array<WebsocketMessageProcessor>) => mps,
      deps: [
        VoidMessageProcessor,
        AcknowledgedMessageProcessor,
        EntryMessageProcessor,
        PongMessageProcessor,
        LicenseMessageProcessor,
        EntryFetchMessageProcessor,
        ErrorMessageProcessor,
      ],
    },
  ],
})
export class DispatcherModule {}
