import {MonoTypeOperatorFunction} from 'rxjs';
import {filter} from 'rxjs/operators';
import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';
import {GraphConfigUtils as GCU} from '@utils/graph-config-utils';
import {
  GraphConfig,
  Visualization,
  AxesVisibility,
} from '@constants/graph.config';

export module DataConfigStateUtils {
  export function gCIds(dataConfigState: PL2.DataConfigState): string[] {
    return Object.keys(dataConfigState);
  }

  export function getDefaultFIdx(
    defaultFIdx: number,
    gCId: string,
  ): {fIdx: number} {
    const gC = GCU.configWithId(gCId);
    return {
      fIdx: gC.thirdPartyData ? null : defaultFIdx,
    };
  }

  export function isRealtime(dataConfigState: PL2.DataConfigState): boolean {
    const gCId = sensorGraphConfigs(dataConfigState)[0]?.id;
    if (!gCId) {
      return false;
    }
    return dataConfigState[gCId]?.rT;
  }

  export function isAccessibleDataView(dcs: PL2.DataConfigState): boolean {
    if (U.isEmpty(dcs)) {
      return false;
    }
    const configs = Object.keys(dcs).map(
      (cfgId) =>
        (!U.isEmpty(dcs[cfgId].dM) &&
          dcs[cfgId].dM !== PL2.DisplayMode.Standard) ||
        (!U.isEmpty(dcs[cfgId].zR) && dcs[cfgId].zR !== 0) ||
        (!U.isEmpty(dcs[cfgId].cA) &&
          dcs[cfgId].cA !== PL2.ColorAdjustment.None),
    );
    return configs.some((accOpts) => accOpts === true);
  }

  export function dataStatus(
    dataConfigState: PL2.DataConfigState,
  ): PL2.DataStatus {
    return Object.values(dataConfigState)[0]?.dS;
  }

  export function isInDataState(
    status: PL2.DataStatus,
    dataConfigState: PL2.DataConfigState,
  ): boolean {
    const dvc = Object.values(dataConfigState)[0];
    if (U.isEmpty(dvc)) {
      return status === PL2.DataStatus.Setup;
    }
    return dvc?.dS === status;
  }

  export function sensorGraphConfigs(
    dataConfigState: PL2.DataConfigState,
  ): GraphConfig[] {
    const gCs = Object.keys(dataConfigState).map((gCId) =>
      GCU.configWithId(gCId),
    );
    return gCs.filter((gC) => U.isEmpty(gC.thirdPartyData));
  }

  export function buildDataConfigs(
    opts: PL2.DataConfigState,
    draft: PL2.DataConfigState,
    translations: {[key: string]: string},
  ) {
    Object.keys(opts)
      .filter((gCId) => opts[gCId] !== null)
      .forEach((gCId) => {
        const gC = GCU.configWithId(gCId);
        const lastDvcId = lastDvc(draft);
        const numNonButtonConfigs = countNonButtonConfigs(draft);
        const buttonIdx = gC.visualizations.findIndex(
          (v) => v === Visualization.Button,
        );
        const dvc = opts[gCId];
        const visIdx =
          typeof dvc.visIdx === 'number'
            ? dvc.visIdx
            : numNonButtonConfigs >= 4
              ? buttonIdx
              : 0;
        const pos =
          typeof dvc.pos === 'number'
            ? dvc.pos
            : (lastDvcId ? draft[lastDvcId].pos : 0) + 1;
        draft[gC.id] = {
          cfgId: gC.id,
          n: GCU.translateLabel(gC.name, translations),
          yL: GCU.translateLabel(gC.yLabel || gC.name, translations),
          xL: GCU.translateLabel(gC.xLabel, translations),
          l: GCU.translateLabels(gC.labels, translations),
          btnL: GCU.translateLabels(gC.buttonLabels, translations),
          dsV: GCU.isImportedData(gC)
            ? opts[gCId].l.map(() => true)
            : dataSetVisibilities(gC),
          visIdx: visIdx || 0,
          pos: pos,
          uIdxs: gC.labels.map(() => 0),
          valIdx: gC.defaultAxis || 0,
          p: [],
          sL: true,
          aS: true,
          cT: 0,
          sD: U.timestamp(),
          ...dvc,
        };
      });
  }

  export function countNonButtonConfigs(state: PL2.DataConfigState): number {
    return Object.keys(state).filter((gCId) => {
      const graphConfig = GCU.configWithId(gCId);
      return (
        graphConfig.visualizations[state[gCId].visIdx] !== Visualization.Button
      );
    }).length;
  }

  export function dataSetVisibilities(gC: GraphConfig): boolean[] {
    const dsV = new Array<boolean>();
    gC.labels.forEach((_l, i) => {
      if (gC.axesVisibility === AxesVisibility.Single) {
        if (gC.axesModulus) {
          dsV[i] = i % gC.axesModulus === 0;
        } else {
          dsV[i] = i === 0;
        }
      } else {
        dsV[i] = true;
      }
    });
    return dsV;
  }

  export function lastDvc(draft: PL2.DataConfigState): string {
    const configs = Object.keys(draft)
      .filter(
        (gCId) =>
          GCU.configWithId(gCId).visualizations[draft[gCId].visIdx] !==
          Visualization.Button,
      )
      .sort((a, b) => (draft[a].pos > draft[b].pos ? 1 : -1));
    return configs[configs.length - 1];
  }
}

export function dataStatusChangeFilter(): MonoTypeOperatorFunction<PL2.DataConfigState> {
  let previousStatus: PL2.DataStatus;
  return filter((dataConfigState) => {
    const change = !DataConfigStateUtils.isInDataState(
      previousStatus,
      dataConfigState,
    );
    if (change) {
      previousStatus = Object.values(dataConfigState)[0]?.dS;
      return true;
    } else {
      return false;
    }
  });
}
