import produce from 'immer';

import {Injectable} from '@angular/core';

import {Store} from '@stores/abstract-store';

export interface NotebookNotification {
  title?: string;
  content: string;
  interpolation?: {[key: string]: string};
  action?: {key: string; handler: string; postHandler?: string};
}

export interface NotificationState {
  [key: string]: NotebookNotification;
  dialog?: NotebookNotification;
  snackbar?: NotebookNotification;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationStore extends Store<NotificationState> {
  constructor() {
    super({});
  }

  add(key: string, notification: NotebookNotification) {
    this.setState(
      produce(this.state(), (draft) => {
        draft[key] = notification;
      }),
    );
  }

  close(key: string) {
    this.setState(
      produce(this.state(), (draft) => {
        delete draft[key];
      }),
    );
  }

  closeDialog() {
    this.close('dialog');
  }

  closeSnackbar(key?: string) {
    this.close(key || 'snackbar');
  }

  showDialog(
    title: string,
    content: string,
    interpolation?: {[key: string]: string},
  ) {
    this.add('dialog', {
      title,
      content,
      interpolation,
    });
  }

  showSnackbar(
    content: string,
    options?: Partial<NotebookNotification>,
    key?: string,
  ) {
    key = key || 'snackbar';
    this.add(key, {
      content,
      ...options,
    });
  }
}
