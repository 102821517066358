import {UtilsPL2 as U} from '@common/utils/dist/index.js';

import {
  SensorMessage,
  StartSensorsParams,
  DeviceConfig,
  SensorReading,
} from '@common/ble/dist/index.js';

import {GraphConfigUtils as GCU} from '@utils/graph-config-utils';

import {GraphToSensorMap} from '@constants/sensor-map.config';

import {serializeUserSpaceData} from './user-space-data-utils';

export interface GraphParams {
  gCIds: string[];
  frequencyIndex: number;
  startDate: string;
}

export module SensorMapperUtils {
  export const sensorToGraphMap: {[sensorId: string]: string[]} = Object.keys(
    GraphToSensorMap,
  ).reduce(
    (acc, gCId) => {
      const sensorId = GraphToSensorMap[gCId];
      if (!acc[sensorId]) {
        acc[sensorId] = [];
      }
      acc[sensorId].push(gCId);
      return acc;
    },
    {} as {[sensorId: string]: string[]},
  );

  export function getStartSensorsParams(
    gCIds: string[],
    frequencyIndex: number,
  ): StartSensorsParams {
    return {
      sensorIds: Array.from(getSensorIds(gCIds)),
      frequencyIndex: frequencyIndex,
      userSpaceData: serializeUserSpaceData(gCIds, frequencyIndex),
    };
  }

  export function getGraphConfigIdsForDevice(
    deviceConfig: DeviceConfig,
    canUpdateDevice: boolean,
  ): string[] {
    const sensorIds = Object.keys(deviceConfig.sensorConfigs);
    const gCIds = new Array<string>();
    sensorIds.forEach((sId) => {
      if (!U.isEmpty(getGCId(sId))) {
        (getGCId(sId) || []).forEach((gCId) => {
          if (!gCIds.includes(gCId)) {
            const gC = GCU.configWithId(gCId);
            if (
              !gC.underDevelopment ||
              (gC.underDevelopment && canUpdateDevice)
            ) {
              gCIds.push(gCId);
            }
          }
        });
      } else {
        console.error(`Sensor ${sId} not mapped`);
      }
    });
    return gCIds;
  }

  export function getGraphConfigIdsForReading(
    message: SensorMessage,
  ): string[] {
    if (U.isEmpty((<SensorReading>message).sensorId)) {
      return [];
    }
    const reading = <SensorReading>message;
    if (!U.isEmpty(sensorToGraphMap[reading.sensorId])) {
      return sensorToGraphMap[reading.sensorId];
    } else {
      console.error(`Sensor ${reading.sensorId} not mapped`);
      return [];
    }
  }

  export function getGraphConfigIdsForReadings(
    readings: SensorMessage[],
  ): string[] {
    return [].concat(
      ...readings.map((reading) => getGraphConfigIdsForReading(reading)),
    );
  }

  export function getSensorId(gCId: string): string {
    return GraphToSensorMap[gCId];
  }

  export function getGCId(sensorId: string): string[] {
    return sensorToGraphMap[sensorId];
  }

  function getSensorIds(gCIds: string[]): string[] {
    const sensorIds = new Set<string>();
    gCIds.forEach((gCId) => {
      sensorIds.add(GraphToSensorMap[gCId]);
    });
    return Array.from(sensorIds).filter((sId) => typeof sId !== 'undefined');
  }
}
