import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule} from '@angular/forms';

import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {TranslateModule} from '@ngx-translate/core';

import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

import {DialogsModule} from '@components/dialogs/dialogs.module';

import {DebugConsoleComponent} from './debug-console.component';

@NgModule({
  declarations: [DebugConsoleComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    DialogsModule,
    ClipboardModule,
    TranslateModule,
    MatInputModule,
    MatTooltipModule,
  ],
  providers: [],
  exports: [DebugConsoleComponent, TranslateModule],
})
export class DebugConsoleModule {
  constructor() {}
}
