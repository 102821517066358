export class IsValidAttributeValidator {
    constructor(attr, value, factory) {
        this.attr = attr;
        this.value = value;
        this.factory = factory;
        this.isDone = false;
        this.valid = true;
    }
    isValid() {
        const validators = this.factory.getAttributeValidators(this.attr, this.value);
        validators.forEach((validator) => {
            const v = validator.isValid();
            this.valid = this.valid && v;
            if (!v) {
                this.causes = (this.causes || []).concat(validator.reasons());
            }
        });
        return this.valid;
    }
    reasons() {
        if (!this.isDone) {
            this.isValid();
        }
        return this.causes;
    }
}
