<div class='container flex-column gap-1'
     [attr.aria-label]='"video-tutorial.containerLabel" | translate'
     [@slideUpOnAppear]='loaded ? "up" : "down"'>
  <div class='header flex-row justify-start align-center'>
    <span class='title flex'>{{video?.title}}</span>
    <button mat-icon-button
            class='close-icon'
            [attr.aria-label]='"common.close" | translate'
            (click)='close()'>
      <mat-icon class='icon'
                svgIcon='close'></mat-icon>
    </button>
  </div>
  <iframe #videoFrame
          class='video'
          frameborder='0'
          allow='fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'></iframe>
  <a mat-button
     [target]='video?.id'
     [href]='video?.learnMoreUrl'
     [attr.aria-label]='"video-tutorial.openLearnMore" | translate'
     class='learn-more'>
    {{'video-tutorial.learnMore' | translate}}
    <mat-icon svgIcon='open_in_new'></mat-icon>
  </a>
</div>
