export var MemoryStatus;
(function (MemoryStatus) {
    MemoryStatus[MemoryStatus["NoMemory"] = 0] = "NoMemory";
    MemoryStatus[MemoryStatus["Empty"] = 1] = "Empty";
    MemoryStatus[MemoryStatus["Recording"] = 2] = "Recording";
    MemoryStatus[MemoryStatus["Recorded"] = 3] = "Recorded";
    MemoryStatus[MemoryStatus["Downloading"] = 4] = "Downloading";
})(MemoryStatus || (MemoryStatus = {}));
export var MessageType;
(function (MessageType) {
    MessageType[MessageType["UserSpace"] = 0] = "UserSpace";
    MessageType[MessageType["Reading"] = 1] = "Reading";
    MessageType[MessageType["Status"] = 2] = "Status";
    MessageType[MessageType["Initialization"] = 3] = "Initialization";
    MessageType[MessageType["CompletedInitialization"] = 4] = "CompletedInitialization";
})(MessageType || (MessageType = {}));
export var ConnectionStates;
(function (ConnectionStates) {
    ConnectionStates[ConnectionStates["NotConnected"] = 0] = "NotConnected";
    ConnectionStates[ConnectionStates["Connected"] = 1] = "Connected";
    ConnectionStates[ConnectionStates["Connecting"] = 2] = "Connecting";
    ConnectionStates[ConnectionStates["ConnectionAttemptFailed"] = 3] = "ConnectionAttemptFailed";
    ConnectionStates[ConnectionStates["ConnectionLost"] = 4] = "ConnectionLost";
})(ConnectionStates || (ConnectionStates = {}));
export var ProcessorStatus;
(function (ProcessorStatus) {
    ProcessorStatus[ProcessorStatus["MemoryDownloadComplete"] = 0] = "MemoryDownloadComplete";
    ProcessorStatus[ProcessorStatus["CrashModeStatusIdle"] = 1] = "CrashModeStatusIdle";
    ProcessorStatus[ProcessorStatus["CrashModeStatusWaiting"] = 2] = "CrashModeStatusWaiting";
    ProcessorStatus[ProcessorStatus["CrashModeStatusRecording"] = 3] = "CrashModeStatusRecording";
    ProcessorStatus[ProcessorStatus["CrashModeStatusComplete"] = 4] = "CrashModeStatusComplete";
    ProcessorStatus[ProcessorStatus["CrashModeStatusOff"] = 5] = "CrashModeStatusOff";
    ProcessorStatus[ProcessorStatus["SensorDataProcessing"] = 6] = "SensorDataProcessing";
    ProcessorStatus[ProcessorStatus["SensorDataIgnoring"] = 7] = "SensorDataIgnoring";
    ProcessorStatus[ProcessorStatus["MemoryDataProcessingWithUserSpaceData"] = 8] = "MemoryDataProcessingWithUserSpaceData";
    ProcessorStatus[ProcessorStatus["SensorDataInitialized"] = 9] = "SensorDataInitialized";
})(ProcessorStatus || (ProcessorStatus = {}));
export var CommProtocol;
(function (CommProtocol) {
    CommProtocol[CommProtocol["I2C_Internal"] = 0] = "I2C_Internal";
    CommProtocol[CommProtocol["I2C_External"] = 1] = "I2C_External";
    CommProtocol[CommProtocol["UART"] = 2] = "UART";
    CommProtocol[CommProtocol["Custom"] = 3] = "Custom";
})(CommProtocol || (CommProtocol = {}));
export var DigitalPowerMode;
(function (DigitalPowerMode) {
    DigitalPowerMode[DigitalPowerMode["DIGITAL_RAIL_5V"] = 0] = "DIGITAL_RAIL_5V";
    DigitalPowerMode[DigitalPowerMode["DIGITAL_RAIL_3V3"] = 1] = "DIGITAL_RAIL_3V3";
})(DigitalPowerMode || (DigitalPowerMode = {}));
export var Baudrate;
(function (Baudrate) {
    Baudrate[Baudrate["BAUDRATE_1200"] = 0] = "BAUDRATE_1200";
    Baudrate[Baudrate["BAUDRATE_2400"] = 1] = "BAUDRATE_2400";
    Baudrate[Baudrate["BAUDRATE_4800"] = 2] = "BAUDRATE_4800";
    Baudrate[Baudrate["BAUDRATE_9600"] = 3] = "BAUDRATE_9600";
    Baudrate[Baudrate["BAUDRATE_14400"] = 4] = "BAUDRATE_14400";
    Baudrate[Baudrate["BAUDRATE_19200"] = 5] = "BAUDRATE_19200";
    Baudrate[Baudrate["BAUDRATE_28800"] = 6] = "BAUDRATE_28800";
    Baudrate[Baudrate["BAUDRATE_31250"] = 7] = "BAUDRATE_31250";
    Baudrate[Baudrate["BAUDRATE_38400"] = 8] = "BAUDRATE_38400";
    Baudrate[Baudrate["BAUDRATE_56000"] = 9] = "BAUDRATE_56000";
    Baudrate[Baudrate["BAUDRATE_57600"] = 10] = "BAUDRATE_57600";
    Baudrate[Baudrate["BAUDRATE_76800"] = 11] = "BAUDRATE_76800";
    Baudrate[Baudrate["BAUDRATE_115200"] = 12] = "BAUDRATE_115200";
    Baudrate[Baudrate["BAUDRATE_230400"] = 13] = "BAUDRATE_230400";
    Baudrate[Baudrate["BAUDRATE_250000"] = 14] = "BAUDRATE_250000";
    Baudrate[Baudrate["BAUDRATE_460800"] = 15] = "BAUDRATE_460800";
    Baudrate[Baudrate["BAUDRATE_921600"] = 16] = "BAUDRATE_921600";
    Baudrate[Baudrate["BAUDRATE_1000000"] = 17] = "BAUDRATE_1000000";
})(Baudrate || (Baudrate = {}));
export var I2CClockSpeed;
(function (I2CClockSpeed) {
    I2CClockSpeed[I2CClockSpeed["I2C_CLK_100K"] = 0] = "I2C_CLK_100K";
    I2CClockSpeed[I2CClockSpeed["I2C_CLK_250K"] = 1] = "I2C_CLK_250K";
    I2CClockSpeed[I2CClockSpeed["I2C_CLK_400K"] = 2] = "I2C_CLK_400K";
})(I2CClockSpeed || (I2CClockSpeed = {}));
export const connectionStateDefault = {
    barCoefficients: [],
    deviceConfig: null,
    memoryStatus: MemoryStatus.NoMemory,
    processorStatus: ProcessorStatus.SensorDataProcessing,
    frequencyIdx: 0,
    maxFrequencyIdx: Infinity,
};
export const userSpaceDataValidationId = 0x666;
