import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {VideoTutorialStore} from '@cloudlab/stores/video-tutorial.store';
import {VideoTutorial} from '@constants/video-tutorial.config';

import {EMPTY, ReplaySubject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {VideoUtils as VU} from '@utils/video-utils';

@Component({
  selector: 'video-tutorial',
  templateUrl: './video-tutorial.component.html',
  styleUrls: ['./video-tutorial.component.scss'],
  animations: [
    trigger('slideUpOnAppear', [
      state('down', style({transform: 'translateY(100%)', opacity: 0})),
      transition('down => up', [
        style({transform: 'translateY(100%)', opacity: 0}),
        animate(
          '300ms ease-in',
          style({transform: 'translateY(0%)', opacity: 1}),
        ),
      ]),
      transition(':leave, up => down', [
        style({transform: 'translateY(0%)', opacity: 1}),
        animate(
          '300ms ease-out',
          style({transform: 'translateY(100%)', opacity: 0}),
        ),
      ]),
    ]),
  ],
})
export class VideoTutorialComponent implements OnInit, OnDestroy {
  @ViewChild('videoFrame', {read: ElementRef, static: true})
  iframeEl: ElementRef<HTMLIFrameElement>;

  video: VideoTutorial | undefined;
  loaded = false;

  private _destroyed$ = new ReplaySubject<boolean>();

  constructor(public videoTutorialStore: VideoTutorialStore) {}

  ngOnInit(): void {
    this.videoTutorialStore
      .video$()
      .pipe(
        takeUntil(this._destroyed$),
        switchMap((currentVideoTutorial) => {
          this.video = currentVideoTutorial;

          if (this.video?.videoUrl && this.iframeEl) {
            return VU.embed(this.video?.videoUrl, this.iframeEl.nativeElement);
          } else {
            this.loaded = false;
            VU.clear(this.iframeEl.nativeElement);
          }
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.loaded = true;
      });
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  close() {
    this.loaded = false;
    this.videoTutorialStore.close();
  }
}
