import { any, all, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class LocalSubsetAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(_entry) {
        return any(this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */), this.userIsPrivilegedParticipantInAuthScope)();
    }
    async canRead(_entry) {
        return this.userIsParticipantInAuthScope;
    }
    async canUpdate(entry) {
        return all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry), any(this.userIsPrivilegedParticipantInAuthScope, this.userIsCreatorOf(entry)))();
    }
    async canDelete(entry) {
        return all(this.tleIsNotReadOnly, any(this.userIsPrivilegedParticipantInAuthScope, this.userIsCreatorOf(entry)))();
    }
}
