import { EntryType, } from '../models/models-pl2.js';
import { SectionEntryValidatorFactory } from './section-entry-validator-factory.js';
import { ValidatorFactory, } from './validator-core.js';
import { LenientValidator } from './lenient-validator.js';
import { IsEmptyValidator } from './is-empty-validator.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
export class TextSectionEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.textSectionEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'con':
            case 'cSK':
                return [new LenientValidator(value, this.builderFactory(attr))];
            default:
                return new SectionEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
    }
    getMultiAttributeValidators(o) {
        if (!U.isEmpty(o.sK) &&
            KU.isType(EntryType.Course, KU.parentSortKey(o.sK))) {
            return [
                new IsNonEmptyValidator(o.cSK, this.builderFactory('cSK')),
            ];
        }
        else {
            return [new IsEmptyValidator(o.cSK, this.builderFactory('cSK'))];
        }
    }
}
