import produce from 'immer';

import {
  SensorMessage,
  MessageType,
  SensorReading,
} from '@common/ble/dist/index.js';

import {StateTransformer} from '../abstract-store';

import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';

import {SensorMapperUtils as SMU} from '@cloudlab/utils/sensor-mapper-utils';

import {GraphConfigUtils as GCU} from '@utils/graph-config-utils';

import {
  PointTransformerPipeline,
  Zero,
} from '@constants/point-transformer-pipeline';

export class UpdatePointsDataConfigStep
  implements StateTransformer<SensorMessage[], PL2.DataConfigState>
{
  private _pointTransformers: {[configId: string]: PointTransformerPipeline} =
    {};

  constructor() {}

  transform(
    sensorMessage: SensorMessage[],
    dataConfigState: PL2.DataConfigState,
  ): PL2.DataConfigState {
    if (U.isEmpty(sensorMessage)) {
      return dataConfigState;
    }
    if (sensorMessage[0].type !== MessageType.Reading) {
      return dataConfigState;
    }
    const readings = <SensorReading[]>sensorMessage;
    return produce(dataConfigState, (draft) => {
      readings.forEach((reading) => {
        const graphConfigIds = SMU.getGraphConfigIdsForReading(reading);
        graphConfigIds.forEach((gCId) => {
          const dvc = dataConfigState[gCId];
          if (
            !U.isEmpty(dvc) &&
            (dvc.dS === PL2.DataStatus.Setup ||
              dvc.dS === PL2.DataStatus.Record)
          ) {
            if (!this._pointTransformers[gCId]) {
              this._buildPointTransformer(gCId);
            }
            this._executePointTransformation(gCId, reading, draft);
          }
        });
      });
    });
  }

  private _buildPointTransformer(gCId: string) {
    const config = GCU.configWithId(gCId);
    this._pointTransformers[config.id] = new PointTransformerPipeline(
      config.transform,
    );
  }

  private _executePointTransformation(
    gCId: string,
    reading: SensorReading,
    draft: PL2.DataConfigState,
  ) {
    const config = GCU.configWithId(gCId);
    reading.values.forEach((values) => {
      const time = draft[gCId].cT + values[0];

      const transformState = this._pointTransformers[gCId].execute({
        values: values.slice(1),
        // This is the points from the beginning of the batch so average calculations won't work for batched values
        points: draft[gCId].p ?? [],
        time: time,
        zero: draft[gCId].zero,
        pointHistory: draft[gCId].pH,
      });

      const deviceLocation = draft[gCId].loc;
      const ivv = config.useCurrentLocation
        ? {n: time, ...deviceLocation}
        : time;

      draft[gCId].p.push([ivv, ...transformState.values]);
    });

    draft[gCId].cT = draft[gCId].cT + reading.chunkInterval;
    // this check ensures the zero get's through to the visualization if it's
    // a visualization zero and not a transform zero
    if ((config.transform ?? []).includes(Zero) && !!draft[gCId].zero) {
      draft[gCId].zero = false;
    }
    draft[gCId].f = reading.frequency;
  }
}
