import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {UtilsPL2 as U, UserUtilsPL2 as UU} from '@common/utils/dist/index.js';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {UserStore} from '@stores/user.store';
import {RouteStore} from '@stores/route.store';

import {RetryOperationService} from '@services/retry-operation.service';

import {
  assignmentsPageName,
  collectionPageName,
  curriculumPageName,
} from '@constants/app.config';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private userStore: UserStore,
    private retryOperationService: RetryOperationService,
    private routeStore: RouteStore,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.userStore.isLoggedIn().pipe(
      map((isLoggedIn) => {
        if (isLoggedIn) {
          if (UU.isUnconfirmed(this.userStore.state())) {
            this.routeStore.auth('role-confirm');
            return false;
          } else {
            return true;
          }
        } else {
          if (U.isEmpty(route.queryParamMap.get('ro'))) {
            if (!this._isPossiblyPublicDestination(state.url)) {
              this.retryNavigation(state);
            }
            return false;
          } else {
            return true;
          }
        }
      }),
    );
  }

  retryNavigation(state: RouterStateSnapshot) {
    this.retryOperationService.retryCurrentOperationAfterAuth(
      this._isStudentDestination(state.url),
      state.url,
    );
  }

  private _isStudentDestination(url: string): boolean {
    return (url || '').includes(assignmentsPageName);
  }

  private _isPossiblyPublicDestination(url: string): boolean {
    const possiblePublicDestinations = [curriculumPageName, collectionPageName];
    return possiblePublicDestinations.some((d) => (url || '').includes(d));
  }
}
