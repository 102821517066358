import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { any, hasNoBlacklistedAttsIn, all } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class GlobalEntriesAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return any(this.user?.auth?.resource?.canCreate, KU.resourcePages.submittedLessons.pK.startsWith(entry.mId))();
    }
    async canRead(_entry) {
        return true;
    }
    async canUpdate(entry) {
        return all(hasNoBlacklistedAttsIn(entry), this.user?.auth?.resource?.canUpdate)();
    }
    async canDelete(_entry) {
        return !!this.user?.auth?.resource?.canDelete;
    }
}
