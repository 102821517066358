// Voyager and Weather specific command codes
// Telemetry Commands
export const READ_ACCEL = 0x05;
export const READ_GYRO = 0x06;
export const READ_MAGNETOMETER = 0x07;
export const READ_COMPASS = 0x08;
export const READ_QUATERNION = 0x09;
export const READ_EXTSENSOR = 0x0a;
export const READ_TEMPERATURE = 0x0b;
export const READ_PRESSURE = 0x0c;
export const READ_HUMIDITY = 0x0d;
export const READ_ALTITUDE = 0x0e;
export const READ_DEWPOINT = 0x0f;
export const READ_HEATINDEX = 0x40;
export const READ_RANGEFINDER = 0x41;
export const READ_UVLIGHT = 0x42;
export const READ_AMBIENTLIGHT = 0x43;
export const READ_IRLIGHT = 0x44;
// Control Commands
export const RUN_COMPASS_CAL = 0x24;
export const RUN_RANGEFINDER_CAL = 0x2a;
export const RUN_TPH_CAL = 0x2b;
export const RUN_ALS_CAL = 0x2c;
// Configuration Commands
export const VL_XSHUT_PIN = 0x3b;
export const EXTERNAL_SENSOR_MODE = 0x3c;
export const TPH_MODE = 0x3d;
export const IMU_MODE = 0x3e;
