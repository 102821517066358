import { KeyGenerators as KG } from '../key-generators/key-generators.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class MasterIndexManager {
    constructor(uId) {
        this.uId = uId;
    }
    setIndexAttributes(entry, parent, params = {}) {
        if (!U.isEmpty(params.seed) || U.isEmpty(this.stringGenerator)) {
            this.stringGenerator = new KG.StringGeneratorFactory().build(params.seed);
        }
        const e = entry;
        e.mId =
            parent?.mId ??
                this.stringGenerator.generate(KU.masterIdLength, params.seed);
        const type = e.t || KU.type(e);
        e.sK = KG.generateSortKeyWithGenerator(parent?.sK, type, this.stringGenerator, params.seed);
        e.uId = this.uId;
        const ts = U.timestamp();
        e.cAt = !!params?.preserve?.cAt ? (e.cAt ?? ts) : ts;
        e.uAt = ts;
        e.rC = e.rC || 0;
        if (!!params?.preserve?.isA) {
            // ok
        }
        else {
            e.isA = 'y';
        }
        return e;
    }
}
