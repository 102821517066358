import {Injectable} from '@angular/core';

import {Observable, of, catchError} from 'rxjs';
import {take, map} from 'rxjs/operators';

import {APIMessages as APIM} from '@common/utils/dist/index.js';

import {EntryStore} from '@stores/entry.store';
import {WebsocketService} from '@services/websocket.service';
import {EntryApiService} from '@services/entry-api.service';
import {
  APIServiceSwitchStore,
  ActiveAPIService,
} from '@stores/api-service-switch.store';
import {
  CacheExpirationEvent,
  CacheExpirationStrategyService,
} from '@services/cache-expiration-strategy.service';
import {
  Action as AdvanceAction,
  AdvanceWithActionTransformer,
} from '@cloudlab/stores/entry-store-transformers/advance-with-action.transformer';
import {APIError} from '@cloudlab/utils/api-errors';
import {Action} from './action';

@Injectable({
  providedIn: 'root',
})
export class SendChangesAction
  implements Action<APIM.EntryChangeRequest, void>
{
  constructor(
    private wss: WebsocketService,
    private eas: EntryApiService,
    private apiServiceSwitchStore: APIServiceSwitchStore,
    private entryStore: EntryStore,
    private advanceWithActionTransformer: AdvanceWithActionTransformer,
    private cacheExpirationStrategy: CacheExpirationStrategyService,
    private console: APIError.Console,
  ) {}

  execute(request: APIM.EntryChangeRequest): Observable<void> {
    this.entryStore.modifyStateWithTransformer(
      {
        payload: request.payload,
        action: AdvanceAction.Sent,
      },
      this.advanceWithActionTransformer,
    );

    let send$: Observable<any>;
    const activeService = this.apiServiceSwitchStore.state();
    console.debug('activeService', activeService);
    switch (activeService) {
      case ActiveAPIService.EntryAPIService:
        send$ = this.eas.sendEvent(request);
        break;
      case ActiveAPIService.WebSocketService:
      default:
        send$ = this.wss.sendEvent(request).pipe(
          catchError((err) => {
            this.console.error(err);
            this.cacheExpirationStrategy.deepExpire(
              CacheExpirationEvent.Error,
              this.entryStore,
              request.payload.map((c) => c.e),
            );
            return of(APIError.buildError(err));
          }),
          take(1),
        );
    }

    return send$.pipe(
      map((status) => {
        const error = APIError.instanceOfError(status);
        if (error) {
          this.entryStore.modifyStateWithTransformer(
            {
              payload: request.payload,
              action: AdvanceAction.Failed,
              error: status as APIError.Error,
            },
            this.advanceWithActionTransformer,
          );
        }
        return void 0;
      }),
    );
  }
}
