import {Injectable} from '@angular/core';
import {NEVER, Observable, of} from 'rxjs';
import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';
import {EntryStore} from '@stores/entry.store';
import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';
import {PendingOperation} from '@cloudlab/stores/entry-store-automaton/operation-state-machine';
import {APIError} from '@cloudlab/utils/api-errors';

@Injectable()
export class ErrorMessageProcessor implements WebsocketMessageProcessor {
  constructor(private entryStore: EntryStore) {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const msg = params.msg;
    if (msg.target === '/error') {
      const metadata: PL2.MessageMetadataEntry = msg.c[0];
      const error = APIError.buildError({
        message: metadata.err?.msg ?? msg.e?.message,
        status: metadata.err?.code ?? msg.e?.code,
      });
      const entries = (msg.c as Array<PL2.AnyPartialEntryWithId>).filter(
        (e) => !(U.isEmpty(e.mId) || U.isEmpty(e.sK)),
      );
      /*
       * TODO handle error using the state machine or the component.
       * Just move failed entries to Ignored state
       */
      if (metadata.act !== PL2.EntryAction.Read) {
        this.entryStore.advanceCachedEntryState(
          entries,
          PendingOperation.Ignore,
          error,
        );
      }

      if (!U.isEmpty(params.wsAPI?.error)) {
        params.wsAPI.error(msg);
      }
      return of(true);
    }
    return NEVER;
  }
}
