export const environment = {
  production: true,
  imageBucket: 'pocketlab-uploads',
  entryAPIGatewayUrl: 'https://app-staging.thepocketlab.com/api/entry',
  websocketAPIGatewayUrl:
    'wss://kensvkrkl1.execute-api.us-east-1.amazonaws.com/StagingStage',
  host: 'https://app-staging.thepocketlab.com',
  entryAPIGatewayName: 'EntryGatewayStaging',
  directEntryAPIGatewayUrl:
    'https://app-staging.thepocketlab.com/api/direct-entry',
  directEntryAPIGatewayName: 'DirectEntryGatewayStaging',
  errorReportAPIEndpointUrl: 'https://app-staging.thepocketlab.com/api/error',
  identityPoolId: 'us-east-1:cfb64eb4-1b25-4513-9d13-23d95b37a5f5',
  userPoolId: 'us-east-1_FXhxb65Pd',
  userPoolWebClientId: '77e66afl1ps75lb3s5j8mrlh06',
  oAuthDomain: 'notebook-staging.auth.us-east-1.amazoncognito.com',
  oneRosterAPIGatewayName: 'OneRosterGatewayStaging',
  oneRosterAPIGatewayUrl: 'https://app-staging.thepocketlab.com/api/oneroster',
  opensearchAPIGatewayName: 'OpensearchGatewayStaging',
  opensearchAPIGatewayUrl:
    'https://app-staging.thepocketlab.com/api/opensearch',
  paymentAPIGatewayName: 'PaymentGatewayStaging',
  paymentAPIGatewayUrl: 'https://app-staging.thepocketlab.com/api/payment',
  userStatsAPIGatewayName: 'UserStatsGatewayStaging',
  userStatsAPIGatewayUrl: 'https://app-staging.thepocketlab.com/api/stats',
  authAPIGatewayName: 'AuthGatewayStaging',
  authAPIGatewayUrl: 'https://auth-staging.thepocketlab.com',
  posthogApiKey: 'phc_VlKsZNFMHZoZr0fDWFQAGcdFaa7OTcazD8pVjAq6VpF',
};
