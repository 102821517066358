import { NotificationConfigurator } from './notification-configurator.js';
// Hina's the best wife
export class CommandSensorCoordinator {
    constructor(deviceConfig, characteristicCache, commandBuilder) {
        this.deviceConfig = deviceConfig;
        this.characteristicCache = characteristicCache;
        this.commandBuilder = commandBuilder;
        this._sensorRegistry = new Set();
        this._notificationConfigurator = new NotificationConfigurator(this.characteristicCache);
    }
    enableSensors(sensorIds, frequencyIndex) {
        console.log('enableSensors()');
        console.log('Registry state pre-config:');
        console.log(new Array(...this._sensorRegistry).join(', '));
        console.log('New indicies:');
        console.log(sensorIds);
        console.log('Frequency index:');
        console.log(frequencyIndex);
        this._sensorRegistry.clear();
        const anySensor = Object.values(this.deviceConfig.sensorConfigs)[0];
        const frequency = this.deviceConfig.frequencyConfigs[frequencyIndex]?.i ?? 0;
        return this.characteristicCache
            .get(anySensor.id, 'config')
            .then((characteristic) => {
            const command = this.commandBuilder.build(sensorIds, frequency);
            return characteristic.writeValue(command);
        })
            .then(() => {
            sensorIds.forEach((sensorId) => {
                this._sensorRegistry.add(sensorId);
            });
            return sensorIds;
        });
    }
    startSensors(sensorIds, frequencyIndex) {
        const uniqueSensorIds = [...new Set(sensorIds)];
        return this.enableSensors(uniqueSensorIds, frequencyIndex).then(() => this._notificationConfigurator.enableNotifications(uniqueSensorIds));
    }
    stopAllNotifications() {
        return this._notificationConfigurator.stopAllNotifications(this.deviceConfig);
    }
    stopAllSensors() {
        return this.enableSensors([]);
    }
}
