import { MessageType, ProcessorStatus, } from './connection-types.js';
import { ConnectionStrategyUtils as CSU } from './connection-strategy-utils.js';
export class MemoryDataProcessorStrategy {
    constructor(frequencyInstruction, chunkLength, dataPacketPipeline, _hasUserSpaceData, memoryPacketId) {
        this.chunkLength = chunkLength;
        this.dataPacketPipeline = dataPacketPipeline;
        this._hasUserSpaceData = _hasUserSpaceData;
        this.memoryPacketId = memoryPacketId;
        this._memoryStatusInstruction = 0x11;
        this._memoryDownloadComplete = 0x10;
        this._chunkInterval = 0;
        this._chunkInterval = CSU.chunkInterval(frequencyInstruction);
    }
    process(dataView) {
        const cl = this.chunkLength < 1 ? dataView.byteLength : this.chunkLength;
        if (dataView.getUint8(0) === this._memoryStatusInstruction) {
            if (this._isErroneous(dataView, 3)) {
                return [];
            }
            if (dataView.getUint8(2) & this._memoryDownloadComplete) {
                return [
                    {
                        type: MessageType.Status,
                        status: ProcessorStatus.MemoryDownloadComplete,
                    },
                ];
            }
            else {
                return [];
            }
        }
        else if (dataView.getUint8(0) !== this.memoryPacketId) {
            return [];
        }
        if (this._isErroneous(dataView, 4)) {
            return [];
        }
        const readings = new Array();
        let index = 0;
        while (index < dataView.byteLength) {
            const slice = new DataView(dataView.buffer, index, cl);
            if (slice.getUint8(0) !== this.memoryPacketId) {
                break;
            }
            const dvReadings = this.dataPacketPipeline.process(slice, this._chunkInterval);
            readings.push(...dvReadings);
            index = index + cl;
        }
        return readings.map((reading) => {
            if (this._hasUserSpaceData &&
                reading.status === ProcessorStatus.SensorDataProcessing) {
                reading.status = ProcessorStatus.MemoryDataProcessingWithUserSpaceData;
            }
            return reading;
        });
    }
    _isErroneous(dataView, requiredBytes) {
        if (dataView.byteLength < requiredBytes) {
            console.log('Erroneous packet: ');
            console.log(dataView);
            return true;
        }
        return false;
    }
}
