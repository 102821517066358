import { IndexManagerChainType } from '../models/api-messages.js';
import { AcceptInviteMasterIndexManager } from './accept-invite-master-index-manager.js';
import { AuthIndexManager } from './auth-index-manager.js';
import { ClassCodeIndexManager } from './class-code-index-manager.js';
import { ClassIndexManager } from './class-index-manager.js';
import { LabReportUserEntryIndexManager } from './lab-report-user-entry-index-manager.js';
import { LocalIdIndexManager } from './local-id-index-manager.js';
import { MasterIndexManager } from './master-index-manager.js';
import { ShortCodeIndexManager } from './short-code-index-manager.js';
import { SourceIdIndexManager } from './source-id-index-manager.js';
import { UserEntryIndexManager } from './user-entry-index-manager.js';
export class IndexManagerChain {
    // Order is important!
    constructor(managers) {
        this.managers = managers;
    }
    setIndexAttributes(entry, parent, params) {
        let output = entry;
        try {
            this.managers.forEach((im) => {
                output = im.setIndexAttributes(output, parent, params);
            });
        }
        catch (e) {
            console.error('setIndexAttributes error: ' + e);
            throw Error(e);
        }
        return output;
    }
}
export function buildIndexManagerChain(params) {
    const chain = new Array(new MasterIndexManager(params.uId));
    chain.push(new ClassCodeIndexManager());
    chain.push(new ClassIndexManager());
    chain.push(new UserEntryIndexManager(params.uId));
    chain.push(new AuthIndexManager());
    chain.push(new LocalIdIndexManager());
    chain.push(new SourceIdIndexManager());
    chain.push(new ShortCodeIndexManager());
    return new IndexManagerChain(chain);
}
export function buildAcceptInviteIndexManagerChain(params) {
    const chain = new Array(new AcceptInviteMasterIndexManager(params.uId, params.invite));
    chain.push(new LabReportUserEntryIndexManager(params.invite, params.classScope));
    chain.push(new UserEntryIndexManager(params.invite.mUId));
    chain.push(new AuthIndexManager());
    return new IndexManagerChain(chain);
}
export function buildIndexManagerChainWoSrcId(params) {
    const chain = new Array(new MasterIndexManager(params.uId));
    chain.push(new ClassCodeIndexManager());
    chain.push(new ClassIndexManager());
    chain.push(new UserEntryIndexManager(params.uId));
    chain.push(new AuthIndexManager());
    chain.push(new LocalIdIndexManager());
    chain.push(new ShortCodeIndexManager());
    return new IndexManagerChain(chain);
}
export function indexManagerChainFactory(params, chainType) {
    chainType = chainType ?? IndexManagerChainType.Default;
    switch (chainType) {
        case IndexManagerChainType.AcceptInvite:
            return buildAcceptInviteIndexManagerChain(params);
        case IndexManagerChainType.WithoutSrcId:
            return buildIndexManagerChainWoSrcId(params);
        case IndexManagerChainType.Default:
            return buildIndexManagerChain(params);
        default:
            throw new Error(`Unknown chain type ${chainType}`);
    }
}
