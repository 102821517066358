import {Injectable, OnDestroy} from '@angular/core';

import {Observable, ReplaySubject, of, merge, interval, Subject} from 'rxjs';
import {catchError, concatMap, map, takeUntil} from 'rxjs/operators';

import {EntryStore} from '@stores/entry.store';

import {ExpireEntriesAction} from '@actions/expire-entries.action';

import {APIMessages as APIM} from '@common/utils/dist/index.js';

import {undoTime} from '@constants/app.config';
import {EntryEventChain} from './entry-event-handlers/entry-event-chain';
import {SendChangesAction} from '@cloudlab/actions/send-changes.action';

@Injectable({
  providedIn: 'root',
})
export class EntryWSAPIService implements OnDestroy {
  private _flush$ = new Subject<boolean>();

  private _destroyed$ = new ReplaySubject<boolean>();

  constructor(
    private entryStore: EntryStore,
    private expireEntriesAction: ExpireEntriesAction,
    private sendChangesAction: SendChangesAction,
  ) {
    merge(
      merge(interval(undoTime), this.entryStore.debounceState$),
      this._flush$,
    )
      .pipe(
        map(() => this.entryStore.state()),
        concatMap((entryState) => {
          const chain = new EntryEventChain(this.entryStore);
          const events = chain.execute(entryState);
          return merge(
            ...(events.changes.length > 0
              ? [this.change({payload: events.changes})]
              : []),
            ...(events.expired.length > 0 ? [this.expire(events.expired)] : []),
          );
        }),
        takeUntil(this._destroyed$),
        catchError((err) => {
          console.error(err);
          return of();
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  change(request: APIM.EntryChangeRequest): Observable<void> {
    return this.sendChangesAction.execute(request);
  }

  expire(pKs: string[]): Observable<any> {
    return this.expireEntriesAction.execute(pKs);
  }

  flush() {
    this._flush$.next(true);
  }
}
