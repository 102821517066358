import {AuthError} from '@models/auth-error';

import {
  PL2,
  KeyUtilsPL2 as KU,
  UtilsPL2 as U,
} from '@common/utils/dist/index.js';

import {EntryState} from '@stores/entry.store';

export function authError(
  err: {message: string; code: string},
  translations: {[key: string]: string},
  currentPath?: string,
): AuthError {
  switch (err.code) {
    case 'PasswordResetRequiredException':
      return {message: translations['resetPassword']};
    case 'UserNotFoundException':
      return {message: translations['notFound']};
    case 'LimitExceededException':
      return {message: translations['tooMany']};
    case 'UserNotConfirmedException':
      return {message: translations['notVerified']};
    case 'UsernameExistsException':
      return {message: translations['emailAlreadyExists']};
    case 'InvalidParameterException':
      return {message: translations['invalidEmail']};
    case 'NotAuthorizedException':
      if (!!currentPath && currentPath.includes('verify')) {
        return {message: translations['alreadyVerified']};
      } else {
        return {message: translations['loginFailed']};
      }
    case 'CodeMismatchException':
      return {message: translations['incorrectCode']};
    case 'InvalidParameterException':
      return {message: translations['alreadyVerified']};
    case 'NetworkError':
      return {message: translations['networkError']};
    default:
      return {
        message: translations['unknownError'],
        note: `${err.code}: ${err.message}`,
      };
  }
}

export function authEntries(
  mId: string,
  sK: string,
  entryState: EntryState,
): PL2.AnyEntry[] {
  const aEs = new Array<PL2.AnyEntry>();
  const tleSK = KU.findTLESK(sK);
  let tLE = entryState[KU.stringFromKey({mId: mId, sK: tleSK})];
  if (U.isEmpty(tLE)) {
    tLE = entryState[KU.stringFromKey({mId: mId, sK: sK})];
    if (U.isEmpty(tLE)) {
      console.warn(
        `Can't find auth entries for non-existent entry: ${mId}${sK}`,
      );
      return [];
    }
  }
  aEs.push(tLE.e as PL2.AnyEntry);
  tLE.c.forEach((tLEC) => {
    const cSK = KU.parseSortKey(KU.pKFromString(tLEC).sK);
    if (
      cSK.nodeId.t === PL2.EntryType.LabReportUser ||
      cSK.nodeId.t === PL2.EntryType.UninvitedLabReportUser ||
      cSK.nodeId.t === PL2.EntryType.ScopeUser
    ) {
      if (!U.isEmpty(entryState[tLEC])) {
        aEs.push(entryState[tLEC].e as PL2.AnyEntry);
      }
    }
  });
  return aEs;
}
