import {EntryStore} from '@stores/entry.store';
import {
  APIMessages as APIM,
  PL2,
  UtilsPL2 as U,
  KeyUtilsPL2 as KU,
} from '@common/utils/dist/index.js';
import {EntryEventChainOutput, EntryEventHandler} from './entry-event-core';

export class AssignmentUpdateHandler
  implements EntryEventHandler<EntryEventChainOutput, EntryEventChainOutput>
{
  nextHandler: EntryEventHandler<EntryEventChainOutput, any>;

  constructor(private entryStore: EntryStore) {}

  handle(input: EntryEventChainOutput): EntryEventChainOutput {
    if (input.changes.length === 0) {
      return this.nextHandler.handle(input);
    }

    const entryState = this.entryStore.state();
    const assignmentUpdates: {[pK: string]: Partial<PL2.AssignmentEntry>} = {};
    const ts = U.timestamp();
    input.changes.forEach((c) => {
      if (!this._isUserEntryAddRemove(c)) {
        return;
      }
      const pK = KU.stringFromKey(c.e as PL2.EntryId);
      const lrUserEntry = <PL2.LabReportUserEntry>entryState[pK]?.e;
      const aPK = KU.parentPK(KU.parentPK(pK));
      const aE = <PL2.AssignmentEntry>entryState[aPK]?.e;
      if (!U.isEmpty(aE)) {
        assignmentUpdates[aPK] = assignmentUpdates[aPK] ?? {
          mId: aE.mId,
          sK: aE.sK,
          lmsUIds: U.isEmpty(aE.lmsUIds) ? [] : aE.lmsUIds,
          uAt: ts,
        };
        if (c.act === PL2.EntryAction.Create) {
          assignmentUpdates[aPK].lmsUIds.push(lrUserEntry.mUId);
        } else if (
          c.act === PL2.EntryAction.Delete ||
          c.act === PL2.EntryAction.DeepDelete
        ) {
          assignmentUpdates[aPK].lmsUIds = assignmentUpdates[
            aPK
          ].lmsUIds.filter((lmsUId) => lmsUId !== lrUserEntry.mUId);
        }
      }
    });
    input.changes.push(
      ...Object.values(assignmentUpdates).map((update) => ({
        act: PL2.EntryAction.Update,
        e: update as PL2.AnyPartialEntryWithId,
      })),
    );
    return this.nextHandler.handle(input);
  }

  private _isUserEntryAddRemove(ce: APIM.EntryChange): boolean {
    return (
      (KU.isType(PL2.EntryType.LabReportUser, ce.e) ||
        KU.isType(PL2.EntryType.InvitedLabReportUser, ce.e)) &&
      (ce.act === PL2.EntryAction.Create ||
        ce.act === PL2.EntryAction.Delete ||
        ce.act === PL2.EntryAction.DeepDelete)
    );
  }
}
