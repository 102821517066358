import {UtilsPL2 as U} from '@common/utils/dist/index.js';

import {ElementRef} from '@angular/core';

export function isUsingIOS(window: Window): boolean {
  return !!window?.navigator?.userAgent?.match(
    /PocketLab Notebook.+\(like Safari\)/i,
  );
}

export function isUsingPlayStoreApp(window: Window): boolean {
  return (
    !U.isEmpty(window.matchMedia) &&
    window.matchMedia('(display-mode: standalone)').matches
  );
}

export function hasBluetooth(window: Window): boolean {
  return (
    !U.isEmpty(window?.navigator) && !U.isEmpty(window?.navigator.bluetooth)
  );
}

export function fullScreenDataView(
  documentDataView: any,
  elementRef: ElementRef,
  fullScreen: boolean,
): boolean {
  if (fullScreen) {
    if (documentDataView.fullscreenElement) {
      documentDataView.exitFullscreen();
    }
  } else {
    const dataView = elementRef.nativeElement;
    if (dataView) {
      dataView.requestFullscreen();
    }
  }
  return !fullScreen;
}

export type OperatingSystem =
  | 'Windows'
  | 'MacOS'
  | 'UNIX'
  | 'Linux'
  | 'Unknown';

export function getOS(window: Window): OperatingSystem {
  const userAgent = window?.navigator?.userAgent || '';
  let os: OperatingSystem = 'Unknown';

  if (userAgent.indexOf('Win') !== -1) {
    os = 'Windows';
  } else if (userAgent.indexOf('Mac') !== -1) {
    os = 'MacOS';
  } else if (userAgent.indexOf('X11') !== -1) {
    os = 'UNIX';
  } else if (userAgent.indexOf('Linux') !== -1) {
    os = 'Linux';
  }

  return os;
}

export function isUsingWindows(window: Window): boolean {
  return getOS(window) === 'Windows';
}

export function isUsingMac(window: Window): boolean {
  return getOS(window) === 'MacOS';
}

export function isUsingLinux(window: Window): boolean {
  return getOS(window) === 'Linux';
}
