export class ThermoCommandBuilder {
    constructor() {
        this.rateInstruction = 0x57;
    }
    build(sensorIds, frequency) {
        const configValues = [this.rateInstruction, 0];
        if (sensorIds.length > 0) {
            configValues[1] = frequency;
        }
        return new Int8Array(configValues).buffer;
    }
}
