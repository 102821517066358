import { EntryType, Role, } from '../models/models-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export var TestUtilsPL2;
(function (TestUtilsPL2) {
    TestUtilsPL2.aDate = '1550421001523';
    TestUtilsPL2.youtubePreview = {
        items: [{ snippet: { title: 'title', thumbnails: { default: {}, high: {} } } }],
    };
    TestUtilsPL2.youtubeAttachment = 'https://www.youtube.com/watch?v=-PDBiUzv2uc';
    TestUtilsPL2.imageAttachment = 'image/path';
    TestUtilsPL2.sId = 'studentId';
    TestUtilsPL2.lId = 'liteId';
    TestUtilsPL2.pId = 'proId';
    TestUtilsPL2.sUId = 'superUserId';
    TestUtilsPL2.mId = 'masterId';
    TestUtilsPL2.rC = 0;
    TestUtilsPL2.uId = 'userId';
    TestUtilsPL2.mUId = 'memberUserId';
    TestUtilsPL2.t = EntryType.LabReport;
    TestUtilsPL2.pCId = 'parentContainerId';
    TestUtilsPL2.cId = 'containerId';
    TestUtilsPL2.cAt = TestUtilsPL2.aDate;
    TestUtilsPL2.uAt = TestUtilsPL2.aDate;
    TestUtilsPL2.n = 'name';
    TestUtilsPL2.cN = 'className';
    TestUtilsPL2.isA = 'y';
    TestUtilsPL2.sC = 'studentCode';
    TestUtilsPL2.uN = 'userName';
    TestUtilsPL2.ans = 'sample short answer';
    TestUtilsPL2.sN = 'searchName';
    TestUtilsPL2.lGI = 0;
    TestUtilsPL2.iAN = 'imageAttachmentName';
    TestUtilsPL2.qs = 'question';
    TestUtilsPL2.xL = 'xLabel';
    TestUtilsPL2.yL = 'yLabel';
    TestUtilsPL2.dAT = 0;
    TestUtilsPL2.cC = 'classCode';
    TestUtilsPL2.cmt = 'comment';
    TestUtilsPL2.pos = '0';
    TestUtilsPL2.plotConfig = 'pl1acc-acc';
    TestUtilsPL2.isP = false;
    TestUtilsPL2.clsN = 'clsN';
    TestUtilsPL2.srcId = 'srcId';
    TestUtilsPL2.refId = 'srcId';
    TestUtilsPL2.teacherAdminEmail = 'user@thepocketlab.co';
    TestUtilsPL2.cSK0 = '00001234';
    TestUtilsPL2.aSK0 = `${TestUtilsPL2.cSK0}030000`;
    TestUtilsPL2.sCSK0 = `${TestUtilsPL2.aSK0}020000`;
    TestUtilsPL2.sCUESK0 = `${TestUtilsPL2.sCSK0}121000`;
    TestUtilsPL2.sCSSK0 = `${TestUtilsPL2.sCSK0}0000`;
    TestUtilsPL2.sCSSK1 = `${TestUtilsPL2.sCSK0}0001`;
    TestUtilsPL2.sCSSK2 = `${TestUtilsPL2.sCSK0}0002`;
    TestUtilsPL2.sCSCSK0 = `${TestUtilsPL2.sCSSK0}0000`;
    TestUtilsPL2.sCSASK0 = `${TestUtilsPL2.sCSSK0}0001`;
    TestUtilsPL2.sSK0 = `${TestUtilsPL2.cSK0}0001`;
    TestUtilsPL2.lRSK0 = '00010000';
    TestUtilsPL2.lRSSK0 = `${TestUtilsPL2.lRSK0}0000`;
    // export const lRSSK1 = `${lRSK0}0001`;
    // export const lRSSK2 = `${lRSK0}0002`;
    TestUtilsPL2.textSectionInSCSK0 = `${TestUtilsPL2.aSK0}020000230000`;
    TestUtilsPL2.questionSectionInSCSK0 = `${TestUtilsPL2.aSK0}020000250000`;
    TestUtilsPL2.lRSASK0 = `${TestUtilsPL2.lRSSK0}0001`;
    TestUtilsPL2.uLEId = KU.uLEId(TestUtilsPL2.uId, TestUtilsPL2.t);
    // export const aSK = sCSSK0;
    TestUtilsPL2.cSK = 'y';
    TestUtilsPL2.cellId = 'auseN4Mmv';
    function randomNode() {
        return Math.floor(Math.random() * 9999)
            .toString()
            .padStart(4, '0');
    }
    function sK(tt, pSK, random) {
        return (pSK ? pSK : '00') + tt + (random ? randomNode() : '0000');
    }
    TestUtilsPL2.sK = sK;
    function pK(tt, pPK, random) {
        let masterId = TestUtilsPL2.mId;
        let pSK;
        if (!U.isEmpty(pPK)) {
            const parsedParent = KU.pKFromString(pPK);
            masterId = parsedParent.mId;
            pSK = parsedParent.sK;
        }
        return KU.stringFromKey({
            mId: masterId,
            sK: sK(tt, pSK, random),
        });
    }
    TestUtilsPL2.pK = pK;
    function buildUser() {
        return {
            userId: TestUtilsPL2.uId,
            role: Role.Lite,
            name: TestUtilsPL2.n,
        };
    }
    TestUtilsPL2.buildUser = buildUser;
    function buildLiteUser() {
        return {
            ...buildUser(),
            userId: TestUtilsPL2.lId,
        };
    }
    TestUtilsPL2.buildLiteUser = buildLiteUser;
    function buildSuperUser() {
        return {
            ...buildPro(),
            auth: {
                iframeSection: { canCreate: true },
                resource: { canCreate: true, canUpdate: true, canDelete: true },
            },
            userId: TestUtilsPL2.sUId,
        };
    }
    TestUtilsPL2.buildSuperUser = buildSuperUser;
    function buildStudent(attr = {}) {
        return {
            ...buildUser(),
            userId: TestUtilsPL2.sId,
            role: Role.Student,
            ...attr,
        };
    }
    TestUtilsPL2.buildStudent = buildStudent;
    function buildPro() {
        return {
            ...buildUser(),
            userId: TestUtilsPL2.pId,
            role: Role.Pro,
        };
    }
    TestUtilsPL2.buildPro = buildPro;
    function buildEntry(tt, pSK) {
        return {
            mId: TestUtilsPL2.mId,
            sK: sK(tt, pSK),
            rC: TestUtilsPL2.rC,
            uId: TestUtilsPL2.uId,
            t: tt,
            cAt: TestUtilsPL2.cAt,
            uAt: TestUtilsPL2.uAt,
            isA: TestUtilsPL2.isA,
        };
    }
    TestUtilsPL2.buildEntry = buildEntry;
    function buildSection(tt, pSK) {
        return {
            ...buildEntry(tt, pSK),
            uN: TestUtilsPL2.uN,
            pos: TestUtilsPL2.pos,
            n: TestUtilsPL2.n,
        };
    }
    TestUtilsPL2.buildSection = buildSection;
    function buildNotatableSection(tt, pSK) {
        return {
            ...buildSection(tt, pSK),
            isN: true,
            con: 'content',
        };
    }
    TestUtilsPL2.buildNotatableSection = buildNotatableSection;
    function buildLr(attrs = {}) {
        return {
            ...buildEntry(EntryType.LabReport),
            uLEId: KU.uLEId(TestUtilsPL2.uId, EntryType.LabReport),
            isP: TestUtilsPL2.isP,
            n: TestUtilsPL2.n,
            sN: TestUtilsPL2.sN,
            aSK: TestUtilsPL2.lRSK0,
            ...attrs,
        };
    }
    TestUtilsPL2.buildLr = buildLr;
    function buildAnswer(attributes = {}) {
        return {
            ...buildEntry(EntryType.AnswerSection, TestUtilsPL2.questionSectionInSCSK0),
            n: TestUtilsPL2.n,
            uN: TestUtilsPL2.uN,
            ans: TestUtilsPL2.ans,
            ...attributes,
        };
    }
    TestUtilsPL2.buildAnswer = buildAnswer;
    function buildTranscript() {
        return {
            ...buildEntry(EntryType.Transcript, TestUtilsPL2.questionSectionInSCSK0),
            uN: TestUtilsPL2.uN,
        };
    }
    TestUtilsPL2.buildTranscript = buildTranscript;
    function buildLrUserEntry(attrs = {}) {
        return {
            ...buildEntry(EntryType.LabReportUser, TestUtilsPL2.sCSK0),
            lGI: TestUtilsPL2.lGI,
            cN: TestUtilsPL2.cN,
            mUId: TestUtilsPL2.mUId,
            aSK: TestUtilsPL2.aSK0,
            uLEId: KU.uLEId(TestUtilsPL2.mUId, EntryType.LabReportUser),
            n: TestUtilsPL2.n,
            clsN: TestUtilsPL2.clsN,
            ...attrs,
        };
    }
    TestUtilsPL2.buildLrUserEntry = buildLrUserEntry;
    function buildInvitedLrUserEntry() {
        return {
            ...buildLrUserEntry(),
            t: EntryType.InvitedLabReportUser,
            mUId: 'iId',
            uLEId: `iId_${EntryType.InvitedLabReportUser}`,
            sK: sK(EntryType.InvitedLabReportUser, TestUtilsPL2.sCSK0),
        };
    }
    TestUtilsPL2.buildInvitedLrUserEntry = buildInvitedLrUserEntry;
    function buildUninvitedLrUserEntry() {
        return {
            ...buildLrUserEntry(),
            t: EntryType.UninvitedLabReportUser,
            sK: sK(EntryType.UninvitedLabReportUser, TestUtilsPL2.sCSK0),
        };
    }
    TestUtilsPL2.buildUninvitedLrUserEntry = buildUninvitedLrUserEntry;
    function buildScopeUserEntry(attrs = {}) {
        return {
            ...buildEntry(EntryType.ScopeUser, TestUtilsPL2.sCSK0),
            mUId: TestUtilsPL2.mUId,
            aSK: TestUtilsPL2.aSK0,
            n: TestUtilsPL2.n,
            ...attrs,
        };
    }
    TestUtilsPL2.buildScopeUserEntry = buildScopeUserEntry;
    function buildTextSection(isSC = false) {
        return {
            ...buildSection(EntryType.TextSection, isSC ? TestUtilsPL2.sCSK0 : TestUtilsPL2.lRSK0),
        };
    }
    TestUtilsPL2.buildTextSection = buildTextSection;
    function buildVideoSection(isSC = false) {
        return {
            ...buildSection(EntryType.VideoSection, isSC ? TestUtilsPL2.sCSK0 : TestUtilsPL2.lRSK0),
            yA: TestUtilsPL2.youtubeAttachment,
        };
    }
    TestUtilsPL2.buildVideoSection = buildVideoSection;
    function buildImageSection(isSC = false) {
        return {
            ...buildSection(EntryType.ImageSection, isSC ? TestUtilsPL2.sCSK0 : TestUtilsPL2.lRSK0),
            iAN: TestUtilsPL2.iAN,
            iA: TestUtilsPL2.imageAttachment,
            cpt: 'caption',
            alt: 'alt text',
        };
    }
    TestUtilsPL2.buildImageSection = buildImageSection;
    function buildDataAnalysisSection(isSC = false) {
        return {
            ...buildSection(EntryType.DataAnalysisSection, isSC ? TestUtilsPL2.sCSK0 : TestUtilsPL2.lRSK0),
            dAT: TestUtilsPL2.dAT,
            col: [
                [
                    {
                        t: 'x',
                        f: 'ivv',
                    },
                    {
                        t: 'y',
                        f: 'c1',
                    },
                    {
                        t: 'z',
                        f: 'c2',
                    },
                ],
            ],
            dat: [
                [
                    { id: 1, ivv: '1', c1: '11', c2: '21' },
                    { id: 2, ivv: '2', c1: '12', c2: '22' },
                ],
            ],
            xL: TestUtilsPL2.xL,
            yL: TestUtilsPL2.yL,
        };
    }
    TestUtilsPL2.buildDataAnalysisSection = buildDataAnalysisSection;
    function buildQuestionSection(attributes = {}) {
        return {
            ...buildSection(EntryType.QuestionSection, TestUtilsPL2.sCSK0),
            qs: TestUtilsPL2.qs,
            ...attributes,
        };
    }
    TestUtilsPL2.buildQuestionSection = buildQuestionSection;
    function buildSpeechToTextSection(attrs = {}) {
        return {
            ...buildSection(EntryType.SpeechToTextSection, TestUtilsPL2.sCSK0),
            qs: TestUtilsPL2.qs,
            ...attrs,
        };
    }
    TestUtilsPL2.buildSpeechToTextSection = buildSpeechToTextSection;
    function buildLiveTrial(isSC = false) {
        return {
            ...buildSection(EntryType.CollectDataSection, isSC ? TestUtilsPL2.sCSK0 : TestUtilsPL2.lRSK0),
        };
    }
    TestUtilsPL2.buildLiveTrial = buildLiveTrial;
    function buildDataViewConfig(_gCId, opts) {
        return {
            n: '',
            l: [''],
            xL: '',
            yL: '',
            pos: 0,
            visIdx: 0,
            sD: '1550421001523',
            p: [],
            uIdxs: [0],
            ...opts,
        };
    }
    TestUtilsPL2.buildDataViewConfig = buildDataViewConfig;
    function buildDataConfigState(dcvs) {
        const dcs = {};
        Object.keys(dcvs).forEach((gCId) => (dcs[gCId] = buildDataViewConfig(gCId, dcvs[gCId])));
        return dcs;
    }
    TestUtilsPL2.buildDataConfigState = buildDataConfigState;
    function buildDataConfigStatePreconfig(dcvs) {
        const dcsp = {};
        Object.keys(dcvs).forEach((deviceName) => (dcsp[deviceName] = buildDataConfigState({ [deviceName]: {} })));
        return dcsp;
    }
    TestUtilsPL2.buildDataConfigStatePreconfig = buildDataConfigStatePreconfig;
    function buildTrial() {
        return {
            ...buildEntry(EntryType.Trial),
            n: TestUtilsPL2.n,
            cfgIds: [TestUtilsPL2.plotConfig],
            sD: TestUtilsPL2.aDate,
            f: 1,
            pP: 'userId/contId.json',
            uLEId: KU.uLEId(TestUtilsPL2.uId, EntryType.Trial),
        };
    }
    TestUtilsPL2.buildTrial = buildTrial;
    function buildTrialSection() {
        return {
            ...buildTrial(),
            ...buildSection(EntryType.TrialSection, TestUtilsPL2.lRSK0),
        };
    }
    TestUtilsPL2.buildTrialSection = buildTrialSection;
    function buildGoogleDriveSection(isSC = false) {
        return {
            ...buildSection(EntryType.GoogleDriveSection, isSC ? TestUtilsPL2.sCSK0 : TestUtilsPL2.lRSK0),
            url: 'www.pocketlab.com',
        };
    }
    TestUtilsPL2.buildGoogleDriveSection = buildGoogleDriveSection;
    function buildGlobalResource() {
        return {
            ...buildEntry(EntryType.GlobalResource),
            url: 'http://sexpistols.org',
            n: 'Global Resource',
        };
    }
    TestUtilsPL2.buildGlobalResource = buildGlobalResource;
    function buildDataTableSection(atts = {}) {
        return {
            ...buildSection(EntryType.SharedDataTableSection, TestUtilsPL2.sCSK0),
            dat: {
                k1: { id: 'k1', value: 'name', r: 0, c: 0 },
                k2: { id: 'k2', value: 'weight', r: 0, c: 1 },
                k3: { id: 'k3', value: 'Hydrogen', r: 1, c: 0 },
                k4: { id: 'k4', value: '1.009', r: 1, c: 1 },
                k5: { id: 'k5', value: 'Helium', r: 2, c: 0 },
                k6: { id: 'k6', value: '4.006', r: 2, c: 1 },
            },
            ...atts,
        };
    }
    TestUtilsPL2.buildDataTableSection = buildDataTableSection;
    function buildIFrameSection(isSC = false) {
        return {
            ...buildSection(EntryType.IFrameSection, isSC ? TestUtilsPL2.sCSK0 : TestUtilsPL2.lRSK0),
            url: 'https://phet.colorado.edu/sims/html/graphing-quadratics/latest/graphing-quadratics_en.html',
        };
    }
    TestUtilsPL2.buildIFrameSection = buildIFrameSection;
    function buildPhetSection(isSC = false) {
        return {
            ...buildSection(EntryType.PhetSection, isSC ? TestUtilsPL2.sCSK0 : TestUtilsPL2.lRSK0),
            sUrl: 'https://phet.colorado.edu/sims/html/graphing-quadratics/latest/graphing-quadratics_en.html',
        };
    }
    TestUtilsPL2.buildPhetSection = buildPhetSection;
    function buildAssignment(attrs = {}) {
        return {
            ...buildEntry(EntryType.Assignment, TestUtilsPL2.cSK0),
            n: TestUtilsPL2.n,
            aSK: TestUtilsPL2.cSK0,
            sCCt: 0,
            cSK: TestUtilsPL2.cSK,
            uLEId: KU.uLEId(TestUtilsPL2.uId, EntryType.Assignment),
            lId: TestUtilsPL2.cellId,
            refId: TestUtilsPL2.srcId,
            ...attrs,
        };
    }
    TestUtilsPL2.buildAssignment = buildAssignment;
    function buildClass(attrs = {}) {
        return {
            ...buildEntry(EntryType.Class),
            sN: TestUtilsPL2.sN,
            n: TestUtilsPL2.n,
            cC: TestUtilsPL2.cC,
            cSK: TestUtilsPL2.cSK,
            uLEId: KU.uLEId(TestUtilsPL2.uId, EntryType.Class),
            aSK: TestUtilsPL2.cSK0,
            ...attrs,
        };
    }
    TestUtilsPL2.buildClass = buildClass;
    function buildFeedback() {
        return {
            ...buildEntry(EntryType.Comment, TestUtilsPL2.textSectionInSCSK0),
            cmt: TestUtilsPL2.cmt,
            uN: TestUtilsPL2.uN,
        };
    }
    TestUtilsPL2.buildFeedback = buildFeedback;
    function buildTeacherNotes(attrs = {}) {
        return {
            ...buildEntry(EntryType.TeacherNotes, TestUtilsPL2.textSectionInSCSK0),
            nts: 'Teacher notes',
            tgs: ['v1.0', 'v1.1'],
            ...attrs,
        };
    }
    TestUtilsPL2.buildTeacherNotes = buildTeacherNotes;
    function buildStudentCopy(attrs = {}) {
        return {
            ...buildEntry(EntryType.StudentCopy, TestUtilsPL2.aSK0),
            n: TestUtilsPL2.n,
            tIAt: TestUtilsPL2.aDate,
            aSK: TestUtilsPL2.sCSK0,
            refId: TestUtilsPL2.srcId,
            ...attrs,
        };
    }
    TestUtilsPL2.buildStudentCopy = buildStudentCopy;
    function buildStudentUser(attrs = {}) {
        return {
            ...buildEntry(EntryType.StudentUser, TestUtilsPL2.cSK0),
            pCC: TestUtilsPL2.cC,
            mUId: TestUtilsPL2.sId,
            mUR: 'region',
            cN: TestUtilsPL2.cN,
            cSK: 'y',
            uLEId: KU.uLEId(TestUtilsPL2.sId, EntryType.StudentUser),
            ...attrs,
        };
    }
    TestUtilsPL2.buildStudentUser = buildStudentUser;
    function buildPlot(config = TestUtilsPL2.plotConfig, points = new Array()) {
        return {
            cfgId: config,
            p: points,
            sD: TestUtilsPL2.aDate,
            f: 1,
            loc: null,
            cAt: TestUtilsPL2.aDate,
        };
    }
    TestUtilsPL2.buildPlot = buildPlot;
    function buildLocalSubset(attrs = {}) {
        return {
            ...buildEntry(EntryType.LocalSubset, TestUtilsPL2.cSK0),
            dat: {},
            ...attrs,
        };
    }
    TestUtilsPL2.buildLocalSubset = buildLocalSubset;
    function buildModule(attrs = {}) {
        return {
            ...buildEntry(EntryType.Module, TestUtilsPL2.cSK0),
            n: TestUtilsPL2.n,
            dat: {},
            cSK: TestUtilsPL2.cSK,
            lId: TestUtilsPL2.cellId,
            ...attrs,
        };
    }
    TestUtilsPL2.buildModule = buildModule;
    function buildClassLocalSubset(attrs = {}) {
        return {
            ...buildEntry(EntryType.ClassLocalSubset, TestUtilsPL2.cSK0),
            cSK: TestUtilsPL2.cSK,
            dat: {},
            ...attrs,
        };
    }
    TestUtilsPL2.buildClassLocalSubset = buildClassLocalSubset;
    function stringifyReasons(validators) {
        return validators.map((v) => JSON.stringify(v.reasons())).join(',');
    }
    TestUtilsPL2.stringifyReasons = stringifyReasons;
    function buildSharedDrawingSection(attrs) {
        return {
            ...buildSection(EntryType.SharedDrawingSection, TestUtilsPL2.sCSK0),
            dat: null,
            ...attrs,
        };
    }
    TestUtilsPL2.buildSharedDrawingSection = buildSharedDrawingSection;
    function buildClassScope(attrs = {}) {
        return {
            ...buildEntry(EntryType.ClassScope, TestUtilsPL2.aSK0),
            aSK: TestUtilsPL2.sCSK0,
            ...attrs,
        };
    }
    TestUtilsPL2.buildClassScope = buildClassScope;
    function buildIndividualScope(attrs = {}) {
        return {
            ...buildEntry(EntryType.IndividualScope, TestUtilsPL2.aSK0),
            aSK: TestUtilsPL2.sCSK0,
            ...attrs,
        };
    }
    TestUtilsPL2.buildIndividualScope = buildIndividualScope;
    function buildMetadataEntry(attrs = {}) {
        return {
            t: EntryType.Metadata,
            p: { lEK: {} },
            ...attrs,
        };
    }
    TestUtilsPL2.buildMetadataEntry = buildMetadataEntry;
    function buildMessageMetadataEntry(attrs = {}) {
        return {
            ...buildMetadataEntry(),
            ...attrs,
        };
    }
    TestUtilsPL2.buildMessageMetadataEntry = buildMessageMetadataEntry;
    function buildCourse(attrs) {
        return {
            ...buildEntry(EntryType.Course),
            cSK: TestUtilsPL2.cSK,
            uLEId: KU.uLEId(TestUtilsPL2.uId, EntryType.Course),
            aSK: TestUtilsPL2.cSK0,
            n: TestUtilsPL2.n,
            isP: true,
            ...attrs,
        };
    }
    TestUtilsPL2.buildCourse = buildCourse;
    function buildCurriculum(attrs) {
        return {
            ...buildEntry(EntryType.Curriculum),
            n: TestUtilsPL2.n,
            aSK: sK(EntryType.Curriculum),
            cSK: TestUtilsPL2.cSK,
            uLEId: KU.uLEId(TestUtilsPL2.uId, EntryType.Curriculum),
            ...attrs,
        };
    }
    TestUtilsPL2.buildCurriculum = buildCurriculum;
    function buildLicense(attrs) {
        const pSK = sK(EntryType.License);
        return {
            ...buildEntry(EntryType.License, pSK),
            mUId: TestUtilsPL2.teacherAdminEmail,
            n: TestUtilsPL2.n,
            nG: 5,
            curPK: 'aethiopesEMBERS',
            uLEId: `${TestUtilsPL2.teacherAdminEmail}_${EntryType.License}`,
            ...attrs,
        };
    }
    TestUtilsPL2.buildLicense = buildLicense;
    function buildClassPermission(attrs) {
        return {
            ...buildEntry(EntryType.ClassPermission),
            mUId: TestUtilsPL2.uId,
            cPT: 1 /* ClassPermissionType.Admin */,
            uLEId: `${TestUtilsPL2.uId}_${EntryType.ClassPermission}`,
            aSK: TestUtilsPL2.aSK0,
            cSK: TestUtilsPL2.cSK0,
            ...attrs,
        };
    }
    TestUtilsPL2.buildClassPermission = buildClassPermission;
    function buildSharedDragAndDropSection(attrs) {
        return {
            ...buildSection(EntryType.SharedDragAndDropSection, TestUtilsPL2.sCSK0),
            dat: null,
            ...attrs,
        };
    }
    TestUtilsPL2.buildSharedDragAndDropSection = buildSharedDragAndDropSection;
    function buildSharedLineMatchingSection(attrs) {
        return {
            ...buildSection(EntryType.SharedLineMatchingSection, TestUtilsPL2.sCSK0),
            dat: null,
            ...attrs,
        };
    }
    TestUtilsPL2.buildSharedLineMatchingSection = buildSharedLineMatchingSection;
    function buildMasterIndexQuery(attrs) {
        return {
            mId: TestUtilsPL2.mId,
            sK: sK(EntryType.LabReport),
            idx: 'master',
            ...attrs,
        };
    }
    TestUtilsPL2.buildMasterIndexQuery = buildMasterIndexQuery;
    // return a fetchEntry function as used in auth policies
    function reF(defaultBuilder) {
        return (e) => {
            e = e || defaultBuilder();
            return () => Promise.resolve(e);
        };
    }
    TestUtilsPL2.reF = reF;
    function expectP(p, v, done) {
        return p
            .then((r) => {
            expect(r).toBe(v);
        })
            .catch((err) => {
            console.error(err);
        })
            .finally(() => done());
    }
    TestUtilsPL2.expectP = expectP;
    function expectNTimes(times, expectation) {
        if (times < 0) {
            expectation = expectation.atLeast(times * -1);
        }
        else if (times === 0) {
            expectation = expectation.never();
        }
        else {
            expectation = expectation.exactly(times);
        }
        return expectation;
    }
    TestUtilsPL2.expectNTimes = expectNTimes;
})(TestUtilsPL2 || (TestUtilsPL2 = {}));
