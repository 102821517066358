import { EntryValidatorFactory } from './entry-validator-factory.js';
import { UserLibraryEntryIdxEntryValidatorFactory } from './user-library-entry-idx-entry-validator-factory.js';
import { ValidatorFactory, } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
export class TrialEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.trialEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'sD':
            case 'vS':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'loc':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'nts':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'f':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'dM':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'zR':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'cA':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'pP':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'n':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'cfgIds':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            default:
                if (EU.userLibraryEntryIdxEntryAtts.includes(attr)) {
                    return new UserLibraryEntryIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else {
                    return new EntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
        }
    }
}
