export class BitwiseCommandBuilder {
    constructor(deviceConfig) {
        this.deviceConfig = deviceConfig;
    }
    build(sensorIds, frequency) {
        let packetConfig = 0;
        sensorIds.forEach((sensorId) => {
            const sensorConfig = this.deviceConfig.sensorConfigs[sensorId];
            if (!sensorConfig) {
                throw Error(`Sensor with id ${sensorId} does not exist`);
            }
            packetConfig = sensorConfig.configValue | packetConfig;
        });
        const aB = new ArrayBuffer(4);
        const dV = new DataView(aB);
        dV.setUint32(0, packetConfig, true);
        const configValues = [
            0x6e,
            dV.getUint8(0),
            dV.getUint8(1),
            dV.getInt8(2),
            dV.getInt8(3),
        ];
        return new Uint8Array(configValues).buffer;
    }
}
