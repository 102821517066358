export interface GraphToSensorMapping {
  [sensorId: string]: string;
}

export const GraphToSensorMap: GraphToSensorMapping = {
  // Odyssey
  odyph: 'ph',
  odyorp: 'orp',
  odydisox: 'dissolvedoxygen',
  odyvol1: 'voltage1',
  odycur1: 'current1',
  odyres1: 'resistance1',
  odypro1: 'temperature1',
  odyvol2: 'voltage2',
  odycur2: 'current2',
  odyres2: 'resistance2',
  odypro2: 'temperature2',
  odyco1: 'digital1',
  odyco2: 'digital1',
  odyox1: 'digital2',
  odyox2: 'digital2',
  odyele: 'electricalconductivity',
  odypre: 'absolutepressure',

  // v2 specific
  imuacc_v2: 'accelerationXYZ_v2',
  imuacc_sca_v2: 'accelerationXYZ_v2',
  imugyr_v2: 'gyroscopeXYZ_v2',

  // Powerlab
  encdis: 'displacement',
  encspe: 'velocity',
  encvel: 'velocity',
  encacc: 'acceleration',

  imuacc: 'accelerationXYZ',
  imuacc_sca: 'accelerationXYZ',
  imugyr: 'gyroscopeXYZ',
  imumag: 'magnetometerXYZ',
  imumag_mag: 'magnetometerXYZ',
  imuhea: 'heading',
  imupit: 'pitch',
  imurol: 'roll',
  imuqua: 'quaternion',

  imuhea_dev: 'heading_dev',
  imupit_dev: 'pitch_dev',
  imurol_dev: 'roll_dev',

  randst: 'rangefinder',
  randis: 'rangefinderdisplacement',
  ranvel: 'rangefindervelocity',
  ranacc: 'rangefinderacceleration',

  plvol: 'extsensorvoltage',
  plres: 'extsensorresistance',
  plcur: 'extsensorcurrent',
  plpro: 'extsensortemperature',
  pltac: 'extsensorvoltage',
  pltem: 'temperature',
  plpre: 'pressure',
  plhum: 'humidity',
  plalt: 'altitude',
  pldew: 'dewpoint',
  plhea: 'heatindex',
  pluvl: 'uvlight_dev',
  plaml: 'ambientlight',
  plirl: 'irlight_dev',

  // PL1
  'pl1acc-acc': 'pl1acc',
  'pl1acc-acc_sca': 'pl1acc',
  'pl1gyr-ang': 'pl1gyr',
  'pl1mag-mag': 'pl1mag',
  'pl1mag-mag_mag': 'pl1mag',
  'pl1bar-alt': 'pl1bar',
  'pl1bar-pre': 'pl1bar',
  'pl1bar-tem_int': 'pl1bar',

  // Voyager
  'mpu9250-acc': 'mpu9250',
  'mpu9250-acc_sca': 'mpu9250',
  'mpu9250-ang': 'mpu9250',
  'mpu9250-mag': 'mpu9250',
  'mpu9250-mag_mag': 'mpu9250',
  'vl53l0x-ran': 'vl53l0x',
  'vl53l0x-ran_vel': 'vl53l0x',
  'bme280-alt': 'bme280',
  'bme280-pre': 'bme280',
  'apds9301-lig': 'apds9301',
  'bme280-tem_int': 'bme280',
  'ntc10k-tem_pro': 'ntc10k',
  'bme280-hum': 'bme280',
  'bme280-dew': 'bme280',
  'bme280-hea': 'bme280',
  'ntc10k-adc': 'ntc10k',
  'ntc10k-tac': 'ntc10k',

  // Onboard
  'onboardacc-acc': 'onboardacc',
  'onboardacc-acc_sca': 'onboardacc',
  'onboardgyr-ang': 'onboardgyr',
  'onboardmag-mag': 'onboardmag',
  'onboardmag-mag_mag': 'onboardmag',
  'onboardlig-lig': 'onboardlig',
  'onboardmic-mic': 'onboardmic',

  // Air
  'air-plantower': 'plantower',
  'air-cozir': 'cozir',
  'air-ozone': 'ozone',
  'air-aqi': 'plantower',
  'air-pre': 'airBar',
  'air-lig': 'airLig',
  'air-tem_int': 'airBar',
  'air-hum': 'airBar',
  'air-dew': 'airBar',
  'air-hea': 'airBar',
  'air-alt': 'airBar',

  // PL4
  'pl4-pl4tem_pro': 'pl4tempprobe',

  // PL4.2
  'pl4-pl42tem_pro': 'pl42tempprobe',

  // Thermo 2.1
  tem_pro: 'temperature12',
};
