import { EntryType, } from '../models/models-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class LabReportUserEntryIndexManager {
    constructor(invite, classScope) {
        this.invite = invite;
        this.classScope = classScope;
    }
    setIndexAttributes(entry) {
        if (KU.isType(EntryType.LabReportUser, entry)) {
            const lrUser = entry;
            const assignmentSK = KU.parentSortKey(KU.parentSortKey(this.invite.sK));
            lrUser.sE = [
                `${entry.mId}${assignmentSK}${EntryType.IndividualScope}${KU.parseSortKey(this.invite.sK).nodeId.a}`,
            ];
            if (!U.isEmpty(this.classScope)) {
                lrUser.sE.push(KU.stringFromKey(this.classScope));
            }
        }
        return entry;
    }
}
