import {Injectable, Inject} from '@angular/core';

import {
  CreateStrategy,
  ModificationStrategy,
  NoModificationStrategy,
  SomeUpdateStrategy,
  SomeDeleteStrategy,
  SomeUndoStrategy,
  UpdateFailedStrategy,
  UpdateSentStrategy,
  UpdateStrategy,
  DelayCreateContainerStrategy,
  UndoableDeleteFromUpdateFailedStrategy,
  DeleteAfterUpdateFailedStrategy,
  MissingTransitionStrategy,
  UpdateFailedAgainStrategy,
  CreateFailedAfterSent,
  DeleteFailedAfterSent,
  CreateSentStrategy,
  ActivateStrategy,
} from './modification-strategy';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

export enum PendingOperation {
  None, // 0
  Create, // 1
  Update, // 2
  Delete, // 3
  UndoableDelete, // 4
  DelayCreateContainer, // 5
  Ignore, // 6
  UpdateSent, // 7
  UpdateSucceeded, // 8
  UpdateFailed, // 9
  CreateSent, // 10
  CreateSucceeded, // 11
  CreateFailed, // 12
  DeleteSent, // 13
  DeleteSucceeded, // 14
  DeleteFailed, // 15
  UndoableDeleteFromCreate, // 16
  UndoableDeleteFromUpdate, // 17
  Undo, // 18
  UndoableDeleteFromCreateSent, // 19
  Activate, // 20
}

@Injectable({
  providedIn: 'root',
})
export class OperationStateMachine {
  private _transitionMap = new Map<
    PendingOperation,
    Map<PendingOperation, ModificationStrategy>
  >([
    [
      PendingOperation.None,
      new Map<PendingOperation, ModificationStrategy>([
        [PendingOperation.Create, new CreateStrategy()],
        [PendingOperation.Update, new UpdateStrategy(PendingOperation.Update)],
        [
          PendingOperation.Delete,
          new SomeDeleteStrategy(PendingOperation.Delete),
        ],
        [
          PendingOperation.DelayCreateContainer,
          new DelayCreateContainerStrategy(),
        ],
        [
          PendingOperation.UndoableDelete,
          new SomeDeleteStrategy(PendingOperation.UndoableDelete),
        ],
        [
          PendingOperation.UpdateSucceeded,
          new SomeUpdateStrategy(PendingOperation.None),
        ],
        [PendingOperation.UpdateFailed, new UpdateFailedStrategy()],
        [PendingOperation.CreateSucceeded, new SomeUpdateStrategy()],
        [PendingOperation.CreateFailed, new CreateFailedAfterSent()],
        [
          PendingOperation.DeleteSucceeded,
          new NoModificationStrategy(PendingOperation.None),
        ],
        [PendingOperation.DeleteFailed, new DeleteFailedAfterSent()],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.DeleteSent,
          new NoModificationStrategy(PendingOperation.None),
        ],
        [PendingOperation.Activate, new ActivateStrategy()],
        [
          PendingOperation.None,
          new NoModificationStrategy(PendingOperation.None),
        ],
      ]),
    ],
    [
      PendingOperation.Create,
      new Map<PendingOperation, ModificationStrategy>([
        [PendingOperation.Update, new UpdateStrategy(PendingOperation.Create)],
        [
          PendingOperation.None,
          new NoModificationStrategy(PendingOperation.None),
        ],
        [
          PendingOperation.Delete,
          new SomeDeleteStrategy(PendingOperation.None),
        ],
        [
          PendingOperation.UndoableDelete,
          new SomeDeleteStrategy(PendingOperation.UndoableDeleteFromCreate),
        ],
        [PendingOperation.CreateSent, new CreateSentStrategy()],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
      ]),
    ],
    [
      PendingOperation.Update,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.None,
          new NoModificationStrategy(PendingOperation.None),
        ],
        [
          PendingOperation.Delete,
          new SomeDeleteStrategy(PendingOperation.Delete),
        ],
        [PendingOperation.Update, new UpdateStrategy(PendingOperation.Update)],
        [
          PendingOperation.UndoableDelete,
          new SomeDeleteStrategy(PendingOperation.UndoableDeleteFromUpdate),
        ],
        [PendingOperation.UpdateSent, new UpdateSentStrategy()],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
      ]),
    ],
    [
      PendingOperation.Delete,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.Update,
          new NoModificationStrategy(PendingOperation.Delete),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.DeleteSent,
          new NoModificationStrategy(PendingOperation.DeleteSent),
        ],
        [
          PendingOperation.CreateSucceeded,
          new SomeDeleteStrategy(PendingOperation.Delete),
        ],
        [
          PendingOperation.CreateFailed,
          new NoModificationStrategy(PendingOperation.CreateFailed),
        ],
        [
          PendingOperation.UpdateSucceeded,
          new SomeUpdateStrategy(PendingOperation.Delete),
        ],
        [PendingOperation.UpdateFailed, new DeleteAfterUpdateFailedStrategy()],
      ]),
    ],
    [
      PendingOperation.UndoableDelete,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.Delete,
          new NoModificationStrategy(PendingOperation.Delete),
        ],
        [PendingOperation.Undo, new SomeUndoStrategy(PendingOperation.None)],
        [
          PendingOperation.UpdateSucceeded,
          new SomeUpdateStrategy(PendingOperation.UndoableDelete),
        ],
        [
          PendingOperation.UpdateFailed,
          new UndoableDeleteFromUpdateFailedStrategy(),
        ],
        [
          PendingOperation.CreateSucceeded,
          new NoModificationStrategy(PendingOperation.UndoableDelete),
        ],
        [
          PendingOperation.CreateFailed,
          new NoModificationStrategy(PendingOperation.CreateFailed),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
      ]),
    ],
    [
      PendingOperation.DelayCreateContainer,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.Update,
          new UpdateStrategy(PendingOperation.DelayCreateContainer),
        ],
        [
          PendingOperation.None,
          new NoModificationStrategy(PendingOperation.None),
        ],
        [
          PendingOperation.Delete,
          new SomeDeleteStrategy(PendingOperation.None),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
      ]),
    ],
    [
      PendingOperation.Ignore,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.Create,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.Update,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.Delete,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.DelayCreateContainer,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.UndoableDelete,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.CreateFailed,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.UpdateFailed,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.DeleteFailed,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
      ]),
    ],
    [
      PendingOperation.UpdateSent,
      new Map<PendingOperation, ModificationStrategy>([
        [PendingOperation.UpdateSucceeded, new SomeUpdateStrategy()],
        [
          PendingOperation.Update,
          new UpdateStrategy(PendingOperation.UpdateSent),
        ],
        [
          PendingOperation.Delete,
          new SomeDeleteStrategy(PendingOperation.Delete),
        ],
        [
          PendingOperation.UndoableDelete,
          new SomeDeleteStrategy(PendingOperation.UndoableDelete),
        ],
        [PendingOperation.UpdateFailed, new UpdateFailedStrategy()],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.Undo,
          new SomeUndoStrategy(PendingOperation.UpdateSent),
        ],
      ]),
    ],
    [
      PendingOperation.UndoableDeleteFromCreate,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.Delete,
          new NoModificationStrategy(PendingOperation.None),
        ],
        [PendingOperation.Undo, new SomeUndoStrategy(PendingOperation.Create)],
        [
          PendingOperation.CreateSucceeded,
          new NoModificationStrategy(PendingOperation.UndoableDelete),
        ],
        [
          PendingOperation.CreateFailed,
          new NoModificationStrategy(PendingOperation.UndoableDeleteFromCreate),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
      ]),
    ],
    [
      PendingOperation.UndoableDeleteFromUpdate,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.Delete,
          new NoModificationStrategy(PendingOperation.Delete),
        ],
        [PendingOperation.Undo, new SomeUndoStrategy(PendingOperation.Update)],
        [
          PendingOperation.CreateSucceeded,
          new NoModificationStrategy(PendingOperation.UndoableDeleteFromUpdate),
        ],
        [
          PendingOperation.CreateFailed,
          new NoModificationStrategy(PendingOperation.UndoableDeleteFromUpdate),
        ],
        [
          PendingOperation.UpdateSucceeded,
          new NoModificationStrategy(PendingOperation.UndoableDeleteFromUpdate),
        ],
        [
          PendingOperation.UpdateFailed,
          new NoModificationStrategy(PendingOperation.UndoableDeleteFromUpdate),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
      ]),
    ],
    [
      PendingOperation.DeleteSent,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.DeleteSucceeded,
          new NoModificationStrategy(PendingOperation.None),
        ],
        [PendingOperation.DeleteFailed, new DeleteFailedAfterSent()],
        [
          PendingOperation.CreateSucceeded,
          new NoModificationStrategy(PendingOperation.DeleteSent),
        ],
        [
          PendingOperation.CreateFailed,
          new NoModificationStrategy(PendingOperation.DeleteSent),
        ],
        [
          PendingOperation.UpdateSucceeded,
          new NoModificationStrategy(PendingOperation.DeleteSent),
        ],
        [
          PendingOperation.UpdateFailed,
          new NoModificationStrategy(PendingOperation.DeleteSent),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.Undo,
          new SomeUndoStrategy(PendingOperation.DeleteSent),
        ],
      ]),
    ],
    [
      PendingOperation.DeleteFailed,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.DeleteSucceeded,
          new NoModificationStrategy(PendingOperation.None),
        ],
        [
          PendingOperation.DeleteFailed,
          new NoModificationStrategy(PendingOperation.DeleteFailed),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.CreateSucceeded,
          new NoModificationStrategy(PendingOperation.DeleteFailed),
        ],
        [
          PendingOperation.CreateFailed,
          new NoModificationStrategy(PendingOperation.None),
        ],
        [
          PendingOperation.UpdateSucceeded,
          new NoModificationStrategy(PendingOperation.DeleteFailed),
        ],
        [
          PendingOperation.UpdateFailed,
          new NoModificationStrategy(PendingOperation.DeleteFailed),
        ],
        [
          PendingOperation.Delete,
          new NoModificationStrategy(PendingOperation.Delete),
        ],
        [
          PendingOperation.Undo,
          new SomeUndoStrategy(PendingOperation.DeleteFailed),
        ],
      ]),
    ],
    [
      PendingOperation.CreateSent,
      new Map<PendingOperation, ModificationStrategy>([
        [PendingOperation.CreateFailed, new CreateFailedAfterSent()],
        [PendingOperation.CreateSucceeded, new SomeUpdateStrategy()],
        [
          PendingOperation.UndoableDelete,
          new SomeDeleteStrategy(PendingOperation.UndoableDeleteFromCreateSent),
        ],
        [
          PendingOperation.Update,
          new UpdateStrategy(PendingOperation.CreateSent),
        ],
        [
          PendingOperation.Delete,
          new SomeDeleteStrategy(PendingOperation.Delete),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.Undo,
          new SomeUndoStrategy(PendingOperation.CreateSent),
        ],
      ]),
    ],
    [
      PendingOperation.CreateFailed,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [
          PendingOperation.Update,
          new UpdateStrategy(PendingOperation.CreateFailed),
        ],
        [
          PendingOperation.Delete,
          new SomeDeleteStrategy(PendingOperation.Delete),
        ],
        [
          PendingOperation.UndoableDelete,
          new SomeDeleteStrategy(PendingOperation.UndoableDeleteFromCreate),
        ],
        [
          PendingOperation.CreateFailed,
          new NoModificationStrategy(PendingOperation.CreateFailed),
        ],
        [
          PendingOperation.Create,
          new NoModificationStrategy(PendingOperation.Create),
        ],
        [
          PendingOperation.Undo,
          new SomeUndoStrategy(PendingOperation.CreateFailed),
        ],
      ]),
    ],
    [
      PendingOperation.UpdateFailed,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
        [PendingOperation.Update, new UpdateStrategy(PendingOperation.Update)],
        [
          PendingOperation.Delete,
          new NoModificationStrategy(PendingOperation.Delete),
        ],
        [
          PendingOperation.UndoableDelete,
          new NoModificationStrategy(PendingOperation.UndoableDeleteFromUpdate),
        ],
        [PendingOperation.UpdateFailed, new UpdateFailedAgainStrategy()],
        [
          PendingOperation.Undo,
          new SomeUndoStrategy(PendingOperation.UpdateFailed),
        ],
      ]),
    ],
    [
      PendingOperation.UndoableDeleteFromCreateSent,
      new Map<PendingOperation, ModificationStrategy>([
        [
          PendingOperation.Delete,
          new NoModificationStrategy(PendingOperation.Delete),
        ],
        [
          PendingOperation.Undo,
          new SomeUndoStrategy(PendingOperation.CreateSent),
        ],
        [
          PendingOperation.CreateSucceeded,
          new NoModificationStrategy(PendingOperation.UndoableDelete),
        ],
        [
          PendingOperation.CreateFailed,
          new NoModificationStrategy(PendingOperation.UndoableDeleteFromCreate),
        ],
        [
          PendingOperation.Ignore,
          new NoModificationStrategy(PendingOperation.Ignore),
        ],
      ]),
    ],
  ]);

  constructor(
    @Inject('missingTransitionBuilder')
    private missingTransitionStrategyBuilder: (
      i,
      c,
    ) => MissingTransitionStrategy,
  ) {}

  nextState(
    newState: PendingOperation,
    currentState: PendingOperation,
  ): ModificationStrategy {
    const strategy = this._transitionMap.get(currentState).get(newState);
    if (U.isEmpty(strategy)) {
      return this.missingTransitionStrategyBuilder(newState, currentState);
    }
    return strategy;
  }
}
