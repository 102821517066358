// Odyssey specific command codes
// Telemetry Commands
export const READ_PH = 0x50;
export const READ_ORP = 0x51;
export const READ_DISSOLVED_OXYGEN = 0x52;
export const READ_VOLTAGE_1 = 0x53;
export const READ_CURRENT_1 = 0x54;
export const READ_RESISTANCE_1 = 0x55;
export const READ_TEMPERATURE_1 = 0x56;
export const READ_VOLTAGE_2 = 0x57;
export const READ_CURRENT_2 = 0x58;
export const READ_RESISTANCE_2 = 0x59;
export const READ_TEMPERATURE_2 = 0x5a;
export const READ_DIGITAL_INTERFACE_1 = 0x5b;
export const READ_DIGITAL_INTERFACE_2 = 0x5c;
export const READ_ELECTRICAL_CONDUCTIVITY = 0x5d;
export const READ_ABS_PRESSURE = 0x5e;
// Control Commands
export const DIGITAL_INTERFACE_CTRL = 0x2e;
export const I2C_WRITE = 0x00;
export const I2C_WRITE_RS = 0x01;
export const UART_WRITE = 0x02;
export const I2C_READ = 0x80;
export const I2C_READ_ONLY = 0x90;
export const UART_READ = 0xa0;
export const RUN_PROBE_CAL = 0x2d;
// probe cal codes
export const PH_CAL = 0x01;
export const DO_CAL = 0x02;
export const ORP_CAL = 0x03;
export const EC_CAL = 0x04;
export const RESET_CALIBRATION = 0x99;
// Configuration Commands
export const ODYSSEY_SYSTEM_CONFIG_KEY = 0xb9;
export const PH_ORP_DO_CONFIG = 0x00;
export const EC_CONFIG = 0x01;
export const VIRT_CONFIG = 0x02;
export const DIG_CONFIG = 0x03;
export const MAIN_PWR_CONFIG = 0x04;
