export class LenientValidator {
    constructor(value, reasonBuilder) {
        this.value = value;
        this.reasonBuilder = reasonBuilder;
    }
    isValid() {
        return true;
    }
    reasons() {
        return [];
    }
}
