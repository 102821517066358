import { Role } from '../models/models-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export var AdapterUtils;
(function (AdapterUtils) {
    function idProviderAttributes(identity) {
        if (U.isEmpty(identity)) {
            return {};
        }
        const user = {
            identityProvider: identity.providerName,
        };
        switch (user.identityProvider) {
            case "classlink" /* IdentityProvider.Classlink */:
            case "classlinkstudents" /* IdentityProvider.ClasslinkStudent */:
                user.classlinkId = identity.userId;
                break;
            case "Google" /* IdentityProvider.Google */:
            case "googlestudents" /* IdentityProvider.GoogleStudent */:
                user.googleProfileId = identity.userId;
                break;
            case "notebookOIDC" /* IdentityProvider.Ed */:
                user.edId = identity.userId;
                break;
            case "edlink" /* IdentityProvider.EdLink */:
                user.edlinkId = identity.userId;
                break;
            default:
                return {};
        }
        return user;
    }
    AdapterUtils.idProviderAttributes = idProviderAttributes;
    function initializeRole(user, uDP) {
        if (user.role !== Role.Unconfirmed) {
            return user;
        }
        switch (user.identityProvider) {
            case "classlink" /* IdentityProvider.Classlink */:
            case "classlinkstudents" /* IdentityProvider.ClasslinkStudent */:
                if (!U.isEmpty(uDP.profile)) {
                    user.role = uDP.profile.toLowerCase().includes('student')
                        ? Role.Student
                        : Role.Lite;
                }
                break;
            case "notebookOIDC" /* IdentityProvider.Ed */:
                if (!U.isEmpty(uDP.profile)) {
                    user.role = uDP.profile.includes('Learner')
                        ? Role.Student
                        : Role.Lite;
                }
                break;
            default:
                return user;
        }
        return user;
    }
    AdapterUtils.initializeRole = initializeRole;
})(AdapterUtils || (AdapterUtils = {}));
