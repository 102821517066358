import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { SharedObjectUtils as SOU } from '../utils/shared-object-utils.js';
export var ContainerVisitors;
(function (ContainerVisitors) {
    class EntryFilterVisitor {
        constructor(filter) {
            this.filter = filter;
            this.entries = new Array();
        }
        visit(e) {
            if (this.filter(e)) {
                this.entries.push(e);
            }
            return e;
        }
        stepInto(e) { }
        stepOut() { }
    }
    ContainerVisitors.EntryFilterVisitor = EntryFilterVisitor;
    class BranchFilterVisitor {
        get _first() {
            return this._stack[this._stack.length - 1];
        }
        constructor(f) {
            this.f = f;
            this.branches = new Array();
            this._stack = new Array();
        }
        visit(e) {
            if (!!this._first) {
                this._newCont = { e: e, c: [] };
                this._first.c.push(this._newCont);
            }
            else if (this.f(e)) {
                this._newCont = { e: e, c: [] };
                this.branches.push(this._newCont);
            }
            else {
                this._newCont = null;
            }
            return e;
        }
        stepInto(e) {
            if (this._newCont) {
                this._stack.push(this._newCont);
            }
        }
        stepOut() {
            this._stack.pop();
        }
    }
    ContainerVisitors.BranchFilterVisitor = BranchFilterVisitor;
    class EntryTypeChangeVisitor extends BranchFilterVisitor {
        constructor(type, withType, entryAtts) {
            super(() => true);
            this.type = type;
            this.withType = withType;
            this.entryAtts = entryAtts;
        }
        visit(e) {
            if (KU.isType(this.type, e.sK)) {
                e = U.pick(e, ...this.entryAtts);
                e.t = this.withType;
            }
            return super.visit(e);
        }
    }
    ContainerVisitors.EntryTypeChangeVisitor = EntryTypeChangeVisitor;
    class ResetEntryVisitor extends BranchFilterVisitor {
        constructor() {
            super(() => true);
        }
        visit(e) {
            return super.visit(EU.resetEntry(e));
        }
    }
    ContainerVisitors.ResetEntryVisitor = ResetEntryVisitor;
    class SetRefVisitor extends BranchFilterVisitor {
        constructor() {
            super(() => true);
        }
        visit(e) {
            const newE = { ...e };
            if (!U.isEmpty(newE.srcId)) {
                newE.refId = newE.srcId;
                delete newE.srcId;
            }
            else {
                delete newE.refId;
            }
            return super.visit(newE);
        }
    }
    ContainerVisitors.SetRefVisitor = SetRefVisitor;
    class ResetSubsetVisitor extends BranchFilterVisitor {
        constructor(subsetSK) {
            super((e) => U.isEmpty(this.subsetSK) ? true : this._subsetFilter.includes(e.sK));
            this.subsetSK = subsetSK;
            this._localIdRegistry = {};
            this._subsetFilter = new Array();
        }
        visit(e) {
            const newE = { ...e };
            if (EU.isLocalSubset(e) &&
                (U.isEmpty(this.subsetSK) || this.subsetSK == e.sK)) {
                const nDat = {};
                const ts = U.timestamp();
                Object.values(e.dat).forEach((p) => {
                    const nLId = SOU.generateCellId();
                    this._localIdRegistry[p.id] = nLId;
                    nDat[nLId] = { id: nLId, pos: p.pos, ts: ts };
                });
                newE.dat = nDat;
                if (!U.isEmpty(this.subsetSK)) {
                    this._subsetFilter.push(e.sK);
                }
            }
            if (EU.isSortable(e)) {
                const regLId = this._localIdRegistry[e.lId];
                if (U.isEmpty(this.subsetSK)) {
                    newE.lId = regLId || SOU.generateCellId();
                }
                else if (!U.isEmpty(regLId)) {
                    newE.lId = regLId;
                    this._subsetFilter.push(e.sK);
                }
            }
            return super.visit(newE);
        }
    }
    ContainerVisitors.ResetSubsetVisitor = ResetSubsetVisitor;
})(ContainerVisitors || (ContainerVisitors = {}));
