import {PL2} from '@common/utils/dist/index.js';

import {GraphConfig} from '@constants/graph.config';
import {UnitConfig} from '@constants/unit.config';

import {nDP} from '@utils/utils';

export module UnitUtils {
  export function getUnit(unitConfig: UnitConfig, unitIdx: number): string {
    return unitConfig.unit[unitIdx];
  }

  export function convertPoint(
    graphConfig: GraphConfig,
    point: PL2.Point,
    units: number[],
  ): PL2.Point {
    return point.map((v, i) => {
      if (i === 0) {
        return v;
      } else {
        return convertValue(
          graphConfig.unitConfigs[i - 1],
          v as number,
          graphConfig.decimalPlaces[i - 1],
          units[i - 1],
        );
      }
    }) as PL2.Point;
  }

  export function convertAllPoints(
    gC: GraphConfig,
    dcs: PL2.DataConfigState,
  ): PL2.Point[] {
    return convertPoints(dcs[gC.id].p, gC, dcs);
  }

  export function convertPagePoints(
    startIdx: number,
    endIdx: number,
    gC: GraphConfig,
    dcs: PL2.DataConfigState,
  ): PL2.Point[] {
    return convertPoints(dcs[gC.id].p.slice(startIdx, endIdx + 1), gC, dcs);
  }

  export function convertPoints(
    points: PL2.Point[],
    gC: GraphConfig,
    dcs: PL2.DataConfigState,
  ): PL2.Point[] {
    return points.map((p) => convertPoint(gC, p, dcs[gC.id].uIdxs));
  }

  export function convertValue(
    unitConfig: UnitConfig,
    value: number,
    dp: number,
    unitIdx: number,
  ) {
    return dp === -1
      ? value
      : unitIdx === 0
        ? nDP(dp, value)
        : nDP(dp, unitConfig.convert[unitIdx](value));
  }

  export function convertLastPoint(
    gC: GraphConfig,
    dcs: PL2.DataConfigState,
  ): PL2.Point {
    const dvc = dcs[gC.id];
    return convertPoint(gC, dvc.p[dvc.p.length - 1], dvc.uIdxs);
  }

  export function currentUnitsForConfig(
    config: GraphConfig,
    valueIndex: number,
    unitIdxs: number[],
  ): string {
    const unitConfig = config.unitConfigs[valueIndex];
    return unitConfig.unit[unitIdxs[valueIndex]] || '';
  }
}
