export class ExistsValidator {
    constructor(value, reasonBuilder) {
        this.value = value;
        this.reasonBuilder = reasonBuilder;
    }
    isValid() {
        const valid = !!this.value;
        if (!valid) {
            this.causes = [this.reasonBuilder.build("doesn't exist")];
        }
        return valid;
    }
    reasons() {
        return this.causes;
    }
}
