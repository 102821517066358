export class CharacteristicCache {
    // the DeviceConfigurator is the owner of the gatt server (and this cache) but we pass it to the constructor here
    // because if the gatt server changes the characteristic cache should be destroyed anyway
    constructor(gattServer, deviceConfig) {
        this.gattServer = gattServer;
        this.deviceConfig = deviceConfig;
        this.cache = new Map();
    }
    get(sensorId, charName) {
        const charUuid = this._uuid(sensorId, charName);
        if (!charUuid) {
            return Promise.resolve(null);
        }
        if (this.cache.has(charUuid)) {
            return Promise.resolve(this.cache.get(charUuid));
        }
        else {
            return this.gattServer
                .getPrimaryService(this._uuid(sensorId, 'service'))
                .then((service) => service.getCharacteristic(charUuid))
                .then((characteristic) => {
                this.cache.set(charUuid, characteristic);
                return characteristic;
            });
        }
    }
    set(sensorId, charName, characteristic) {
        this.cache.set(this._uuid(sensorId, charName), characteristic);
    }
    _uuid(sensorId, charName) {
        if (this.deviceConfig.sensorConfigs[sensorId]) {
            return this.deviceConfig.sensorConfigs[sensorId][charName];
        }
        else if (this.deviceConfig.memoryConfig &&
            this.deviceConfig.memoryConfig.id === sensorId) {
            return this.deviceConfig.memoryConfig[charName];
        }
        else if (this.deviceConfig.nameConfig &&
            this.deviceConfig.nameConfig.id === sensorId) {
            return this.deviceConfig.nameConfig[charName];
        }
        else if (this.deviceConfig.batteryConfig &&
            this.deviceConfig.batteryConfig.id === sensorId) {
            return this.deviceConfig.batteryConfig[charName];
        }
        else if (this.deviceConfig.crashModeConfig &&
            this.deviceConfig.crashModeConfig.id === sensorId) {
            return this.deviceConfig.crashModeConfig[charName];
        }
        else if (this.deviceConfig.deviceInformationConfigs &&
            this.deviceConfig.deviceInformationConfigs[sensorId].id === sensorId) {
            return this.deviceConfig.deviceInformationConfigs[sensorId][charName];
        }
        else {
            throw Error(`Sensor id ${sensorId} does not exist`);
        }
    }
}
