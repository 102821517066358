import {Injectable} from '@angular/core';

import {APIStateStore} from '@stores/api-state.store';

import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';
import {NEVER, Observable, of} from 'rxjs';

@Injectable()
export class PongMessageProcessor implements WebsocketMessageProcessor {
  constructor(private apiStateStore: APIStateStore) {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const msg = params.msg;
    if (msg.target === 'pong') {
      params.wsAPI.pong();
      this.apiStateStore.online();
      return of(true);
    }
    return NEVER;
  }
}
