import { all, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class TrialAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return this.userIsSetAsCreatorFor(entry);
    }
    async canRead(entry) {
        return this.userIsCreatorOfFetched(entry);
    }
    async canUpdate(entry) {
        return all(hasNoBlacklistedAttsIn(entry), this.userIsCreatorOf(entry))();
    }
    async canDelete(entry) {
        return this.userIsCreatorOf(entry)();
    }
}
