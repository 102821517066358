export class NotificationConfigurator {
    constructor(characteristicCache) {
        this.characteristicCache = characteristicCache;
    }
    stopAllNotifications(deviceConfig) {
        let promise = Promise.resolve();
        Object.keys(deviceConfig.sensorConfigs).forEach((sensorConfig) => {
            promise = promise
                .then(() => this.characteristicCache.get(sensorConfig, 'data'))
                .then((characteristic) => characteristic.stopNotifications())
                .then(() => this.characteristicCache.get(sensorConfig, 'data'));
        });
        return promise;
    }
    enableNotifications(sensorIds) {
        console.log('NotificationConfigurator.enableNotifications', sensorIds);
        const enabled = new Array();
        let promise = Promise.resolve(enabled);
        sensorIds.forEach((sensorId) => {
            promise = promise
                .then(() => this.characteristicCache.get(sensorId, 'data'))
                .then((characteristic) => characteristic.startNotifications().then(() => {
                enabled.push({ c: characteristic, sensorId: sensorId });
                return enabled;
            }));
        });
        return promise;
    }
    disableNotifications(sensorIds) {
        let promise = Promise.resolve();
        sensorIds.forEach((sensorId) => {
            promise = promise
                .then(() => this.characteristicCache.get(sensorId, 'data'))
                .then((characteristic) => characteristic.stopNotifications().then(() => null));
        });
        return promise;
    }
}
