import {Injectable, Inject, OnDestroy} from '@angular/core';

import {ReplaySubject} from 'rxjs';

import {Store} from '@stores/abstract-store';

import {
  UtilsPL2 as U,
  KeysPL2 as K,
  KeyUtilsPL2 as KU,
  PL2,
} from '@common/utils/dist/index.js';
import {EntryStore} from './entry.store';

export interface RecentLesson {
  lesPK: K.LabReportKey;
  n: string;
}

export const allRecentLessonsKey = '__ALL__';
export const myLessonsRecentLessonsKey = '__MY__';

type KeysAccepted =
  | typeof myLessonsRecentLessonsKey
  | typeof allRecentLessonsKey
  | K.LabReportKey
  | K.CurriculumKey
  | K.CourseKey;

type KeysStored =
  | typeof myLessonsRecentLessonsKey
  | typeof allRecentLessonsKey
  | K.CurriculumKey;

@Injectable({
  providedIn: 'root',
})
export class RecentLessonsStore
  extends Store<Record<string, RecentLesson[]>>
  implements OnDestroy
{
  private readonly _recentLessonCount = 5;

  private _storageKey: string;

  private set _userId(userId: string) {
    if (!U.isEmpty(userId)) {
      this._storageKey = `${userId}_rv`;
    }
  }

  private _destroyed$ = new ReplaySubject<boolean>();

  constructor(
    @Inject('STORAGE') private localStorage: Storage,
    private entryStore: EntryStore,
  ) {
    super({});
  }

  ngOnDestroy() {
    this.clear();
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  saveToStorage() {
    if (!U.isEmpty(this?._storageKey)) {
      try {
        if (!U.isEmpty(this.state())) {
          this.localStorage.setItem(
            this._storageKey,
            JSON.stringify(this.state()),
          );
        }
      } catch (error) {
        console.error('Local storage is full');
      }
    }
  }

  loadFromStorage(userId: string) {
    this._userId = userId;
    const data = this.localStorage.getItem(this._storageKey);
    if (!U.isEmpty(data)) {
      const result = JSON.parse(data);

      if (!Array.isArray(result)) {
        this.setState(result);
      }
    }
  }

  add(rL: RecentLesson) {
    const key = this.getKey(rL.lesPK);

    this._addInKey(allRecentLessonsKey, rL);

    if (key !== allRecentLessonsKey && !U.isEmpty(key)) {
      this._addInKey(key, rL);
    }
  }

  remove(pK: K.LabReportKey) {
    const key = this.getKey(pK);
    this._removeWithKey(allRecentLessonsKey, pK);
    if (key !== allRecentLessonsKey && !U.isEmpty(key)) {
      this._removeWithKey(key, pK);
    }
  }

  clear() {
    this.setState({});
  }

  getKey(pKOrKey: KeysAccepted): KeysStored {
    const entryState = this.entryStore.state();

    if (pKOrKey === allRecentLessonsKey) {
      return allRecentLessonsKey;
    } else if (pKOrKey === myLessonsRecentLessonsKey) {
      return myLessonsRecentLessonsKey;
    } else if (KU.isType(PL2.EntryType.LabReport, pKOrKey)) {
      const nodeIds = KU.parseSortKey(KU.pKFromString(pKOrKey).sK).nodeIds;

      switch (nodeIds.length) {
        case 1: // my lessons' lessons or curriculum lesson version
          const entry = entryState[pKOrKey].e;
          const isCurriculumLessonVersion =
            !U.isEmpty(entry.mCats) &&
            entry.mCats.length > 1 &&
            entry.mCats.includes('eL');
          if (isCurriculumLessonVersion) {
            return entry.mCats[1] as K.CurriculumKey;
          } else {
            return myLessonsRecentLessonsKey;
          }
        case 2: // in curriculum or in standalone course
          const parent = KU.parentPK(pKOrKey);
          const isInCurriculum =
            !!parent && KU.isType(PL2.EntryType.Curriculum, parent);
          return isInCurriculum
            ? (parent as K.CurriculumKey)
            : myLessonsRecentLessonsKey;
        case 3: // in course
          return KU.parentPK(KU.parentPK(pKOrKey));
        default:
          console.error(`Invalid lab-report key type ${pKOrKey}`);
      }
    } else if (KU.isType(PL2.EntryType.Course, pKOrKey)) {
      const parent = KU.parentPK(pKOrKey);
      const isInCurriculum =
        !!parent && KU.isType(PL2.EntryType.Curriculum, parent);
      return isInCurriculum
        ? (parent as K.CurriculumKey)
        : myLessonsRecentLessonsKey;
    } else if (KU.isType(PL2.EntryType.Curriculum, pKOrKey)) {
      return pKOrKey;
    }

    return allRecentLessonsKey;
  }

  private _addInKey(key: string, rL: RecentLesson) {
    if (U.isEmpty(this.state()[key])) {
      const currentState = this.state();
      currentState[key] = [rL];
      this.setState(currentState);
      return;
    }

    let recentLessons: RecentLesson[] = [];
    const recordedLessons: RecentLesson[] = this.state()[key].filter(
      (l) => l.lesPK !== rL.lesPK,
    );
    recentLessons = recordedLessons.reverse();
    if (recentLessons.length >= this._recentLessonCount) {
      recentLessons.shift();
    }
    recentLessons.push(rL);
    const currentState = this.state();
    currentState[key] = recentLessons.reverse();
    this.setState(currentState);
  }

  private _removeWithKey(key: string, pK: string) {
    if (U.isEmpty(this.state()[key])) {
      return;
    }

    const recentLessons: RecentLesson[] = this.state()[key].filter(
      (l) => l.lesPK !== pK,
    );
    const currentState = this.state();
    currentState[key] = recentLessons;
    this.setState(currentState);
  }
}
