export var ProcessorUtils;
(function (ProcessorUtils) {
    ProcessorUtils.gToMs2 = 9.81;
    function accShortSignedAtOffset(b, offset) {
        const lowerByte = b[offset + 1] & 0xff;
        const upperByte = b[offset];
        return (upperByte << 8) + lowerByte;
    }
    ProcessorUtils.accShortSignedAtOffset = accShortSignedAtOffset;
    function shortSignedAtOffset(b, offset) {
        const lowerByte = b[offset] & 0xff;
        const upperByte = b[offset + 1];
        return (upperByte << 8) + lowerByte;
    }
    ProcessorUtils.shortSignedAtOffset = shortSignedAtOffset;
    function convertTemperature(value, barCoefficients) {
        const c = barCoefficients;
        const var1 = (((value >> 3) - (c[0] << 1)) * c[1]) >> 11;
        const var2 = (((((value >> 4) - c[0]) * ((value >> 4) - c[0])) >> 12) * c[2]) >> 14;
        const tFine = var1 + var2;
        const T = (tFine * 5 + 128) >> 8;
        // t_fine used in barometer calculation
        return [T / 100, tFine];
    }
    ProcessorUtils.convertTemperature = convertTemperature;
    function twentyFourBitSignedAtOffset(b, offset) {
        const upperByte = b[offset] & 0xff;
        const midByte = b[offset + 1] & 0xff;
        const lowerByte = b[offset + 2] & 0xff;
        return (upperByte << 12) + (midByte << 4) + (lowerByte >> 4);
    }
    ProcessorUtils.twentyFourBitSignedAtOffset = twentyFourBitSignedAtOffset;
    function bme280(value, coefficients) {
        const c = coefficients;
        if (!c || c.length === 0) {
            throw Error('Not calibrated');
        }
        const va = new Int8Array(value);
        // Temperature
        const tv = ProcessorUtils.twentyFourBitSignedAtOffset(va, 3);
        const t_fine = ProcessorUtils.convertTemperature(tv, c);
        const temperature = t_fine[0];
        const temp_coe = t_fine[1];
        // Humidity
        const adc_H = ((va[6] & 0xff) << 8) + (va[7] & 0xff);
        let var_H = temp_coe - 76800.0;
        var_H =
            (adc_H - (c[15] * 64.0 + (c[16] / 16384.0) * var_H)) *
                ((c[13] / 65536.0) *
                    (1.0 +
                        (c[17] / 67108864.0) * var_H * (1.0 + (c[14] / 67108864.0) * var_H)));
        var_H = var_H * (1.0 - (c[12] * var_H) / 524288.0);
        if (var_H > 100.0) {
            var_H = 100.0;
        }
        else if (var_H < 0.0) {
            var_H = 0.0;
        }
        // Barometer
        const pv = ProcessorUtils.twentyFourBitSignedAtOffset(va, 0);
        let var1, var2, p;
        var1 = (temp_coe >> 1) - 64000;
        var2 = (((var1 >> 2) * (var1 >> 2)) >> 11) * c[8];
        var2 = var2 + ((var1 * c[7]) << 1);
        var2 = (var2 >> 2) + (c[6] << 16);
        var1 =
            (((c[5] * (((var1 >> 2) * (var1 >> 2)) >> 13)) >> 3) +
                ((c[4] * var1) >> 1)) >>
                18;
        var1 = ((32768 + var1) * c[3]) >> 15;
        if (var1 === 0) {
            return [0, temperature, var_H]; // avoid exception caused by division by zero
        }
        p = (1048576 - pv - (var2 >> 12)) * 3125;
        if (p < 0x80000000) {
            p = ((p << 1) >>> 0) / var1;
        }
        else {
            p = (p / var1) * 2;
        }
        var1 = (c[11] * (((p >> 3) * (p >> 3)) >> 13)) >> 12;
        var2 = ((p >> 2) * c[10]) >> 13;
        p = p + ((var1 + var2 + c[9]) >> 4);
        return [p / 100, temperature, var_H];
    }
    ProcessorUtils.bme280 = bme280;
    function apds9301(value) {
        const v = new Uint8Array(value);
        let ch0 = ProcessorUtils.shortSignedAtOffset(v, 0);
        const ch1 = ProcessorUtils.shortSignedAtOffset(v, 2);
        let lux = 0.0;
        if (ch0 !== 0) {
            if (ch0 >= 5047) {
                ch0 = 12 * ch1;
            }
            const ratio = ch1 / ch0;
            if (ratio <= 0.0) {
                lux = 0.0304 * ch0 - 0.062 * ch0 * Math.pow(ratio, 1.4);
            }
            else if (ratio <= 0.5) {
                lux = 0.0304 * ch0 - 0.062 * ch0 * Math.pow(ratio, 1.4);
            }
            else if (ratio <= 0.61) {
                lux = 0.0224 * ch0 - 0.031 * ch1;
            }
            else if (ratio <= 0.8) {
                lux = 0.0128 * ch0 - 0.0153 * ch1;
            }
            else if (ratio <= 1.3) {
                lux = 0.00146 * ch0 - 0.00112 * ch1;
            }
            else {
                lux = 0.00146 * ch0 - 0.00112 * ch1;
            }
        }
        return [lux * 60.0];
    }
    ProcessorUtils.apds9301 = apds9301;
})(ProcessorUtils || (ProcessorUtils = {}));
