import { MessageType, userSpaceDataValidationId, } from './connection-types.js';
export class UserSpaceDataProcessorStrategy {
    constructor() {
        this._userSpacePacketId = 0x95;
    }
    process(dataView) {
        console.log(dataView);
        if (dataView.getUint8(0) === this._userSpacePacketId) {
            const validationId = dataView.getUint32(1, true);
            if (validationId !== userSpaceDataValidationId) {
                return [];
            }
            return [
                {
                    type: MessageType.UserSpace,
                    userSpaceData: [
                        // user space data id
                        dataView.getUint32(5, true),
                        // graph config
                        dataView.getUint32(9, true),
                        // frequency index
                        dataView.getUint32(13, true),
                        // start date
                        dataView.getUint32(17, true),
                    ],
                },
            ];
        }
        else {
            return [];
        }
    }
}
