import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class ContainerWalk {
    constructor(visitor, params) {
        this.visitor = visitor;
        this.params = params;
        this.params = params ?? {};
    }
    doWalk(container, modifyInPlace = false) {
        const entries = [];
        this.step(container, entries, modifyInPlace);
        return entries;
    }
    step(ec, entries, modifyInPlace) {
        const seed = this.params?.seedGenerator ?? ((ec) => ec.seed ?? this.params?.seed);
        const node = this.visitor.visit(modifyInPlace ? ec.e : { ...(ec.e || {}) }, {
            seed: seed(ec),
            ...this.params,
        });
        entries.push(node);
        if (U.isEmpty(ec.c)) {
            return;
        }
        else {
            this.visitor.stepInto(node);
            ec.c.forEach((c) => this.step(c, entries, modifyInPlace));
            this.visitor.stepOut();
        }
    }
}
