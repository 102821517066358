import {Injectable} from '@angular/core';

import {Store} from '@stores/abstract-store';

export const enum APIState {
  Default,
  Offline,
  VersionMismatch,
}

@Injectable()
export class APIStateStore extends Store<APIState> {
  constructor() {
    super(APIState.Default);
  }

  setAPIState(state: APIState) {
    this.setState(state);
  }

  offline() {
    this.setState(APIState.Offline);
  }

  online() {
    this.setState(APIState.Default);
  }
}
