import { merge, fromEvent, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageType, ProcessorStatus, } from './connection-types.js';
export class CharacteristicDataProcessorBuilder {
    constructor(processorMap, frequency) {
        this.processorMap = processorMap;
        this.frequency = frequency;
    }
    build(configs) {
        return merge(of(configs.map((e) => ({
            sensorId: e.sensorId,
            type: MessageType.CompletedInitialization,
        }))), ...configs.map((e) => fromEvent(e.c, 'characteristicvaluechanged').pipe(map((event) => [
            {
                values: [
                    [
                        0,
                        ...this.processorMap[e.sensorId](new Uint8Array(event.target['value']['buffer'])),
                    ],
                ],
                sensorId: e.sensorId,
                type: MessageType.Reading,
                status: ProcessorStatus.SensorDataProcessing,
                frequency: this.frequency,
                chunkInterval: 1 / this.frequency,
            },
        ]))));
    }
}
