import { ValidatorFactory, } from './validator-core.js';
import { LenientValidator } from './lenient-validator.js';
import { SectionEntryValidatorFactory } from './section-entry-validator-factory.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
export class CollectDataSectionEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.collectDataSectionEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'dCSP':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            default:
                return new SectionEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
    }
}
