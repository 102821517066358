import { Connection } from './connection.js';
import { ConnectionDetectionStrategyChain } from './connection-detection-strategy-chain.js';
import { DeviceConfigs } from './device.config.js';
export class BluetoothConnectionFactory {
    constructor() {
        this._api = navigator.bluetooth;
    }
    initBluetoothDevice(namePrefixes) {
        const optionalServices = new Array();
        DeviceConfigs.forEach((deviceConfig) => {
            const sensorConfigs = Object.values(deviceConfig.sensorConfigs);
            optionalServices.push(...sensorConfigs.map((sc) => sc.service).filter((service) => !!service));
            if (deviceConfig.memoryConfig) {
                optionalServices.push(deviceConfig.memoryConfig.service);
            }
            if (deviceConfig.nameConfig) {
                optionalServices.push(deviceConfig.nameConfig.service);
            }
            if (deviceConfig.batteryConfig) {
                optionalServices.push(deviceConfig.batteryConfig.service);
            }
            if (deviceConfig.deviceInformationConfigs) {
                optionalServices.push(deviceConfig.deviceInformationConfigs.firmwareinfo.service);
                optionalServices.push(deviceConfig.deviceInformationConfigs.hardwareinfo.service);
                optionalServices.push(deviceConfig.deviceInformationConfigs.manufacturerinfo.service);
            }
            if (deviceConfig.dfuConfig) {
                optionalServices.push(deviceConfig.dfuConfig.service);
            }
        });
        return this._api
            .requestDevice({
            filters: namePrefixes.map((np) => ({ namePrefix: np })),
            optionalServices: optionalServices.filter((os) => os),
        })
            .then((device) => new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('Started connecting');
                device.gatt.connect().then((server) => {
                    resolve(server);
                }, (err) => {
                    console.warn(err);
                    reject(err);
                });
            }, 10);
        }))
            .then((server) => this.constructStrategy(server).then((strategy) => new Connection(server, strategy)))
            .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
    }
    constructStrategy(server) {
        try {
            return new ConnectionDetectionStrategyChain().detect(server);
        }
        catch (e) {
            console.log(e);
            return Promise.reject(e);
        }
    }
}
