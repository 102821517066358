import { of, merge } from 'rxjs';
import { catchError, takeWhile, timeout, filter, take, switchMap, tap, } from 'rxjs/operators';
import { PowerlabDataProcessorBuilder } from './powerlab-data-processor-builder.js';
import { MemoryDataProcessorStrategy } from './memory-data-processor-strategy.js';
import { UserSpaceDataProcessorStrategy } from './user-space-data-processor-strategy.js';
import { MemoryStatus, ProcessorStatus, userSpaceDataValidationId, MessageType, } from './connection-types.js';
import { PowerlabDataPacketPipeline } from './powerlab-data-packet-processor.js';
import { SET_TRANSFER_INTERVAL, START_FLASH_TRANSFER, } from './pl-command-codes.js';
export class PowerlabMemoryConfigurator {
    constructor(deviceConfig, sensorCoordinator, commandQueue, eventObs) {
        this.deviceConfig = deviceConfig;
        this.sensorCoordinator = sensorCoordinator;
        this.commandQueue = commandQueue;
        this.eventObs = eventObs;
        this._readUserSpaceCommand = [0x4c, 0x81];
        this._eraseUserSpaceCommand = [0x4c, 0x97];
        this._writeUserSpaceCommand = [0x52, 0x07];
        this._stopPollingCommand = [0x60, 0x00];
        this._readMemoryStatusCommand = 0x23;
        this._offlineModeCommand = [0x6c, 0x01];
        this._onlineModeCommand = [0x6c, 0x00];
        this._readChunkLengthCommand = 0x27;
        this._readFrequencyInstructionCommand = 0x29;
        this._dataPresentBit = 0x01;
        this._flashRecordingBit = 0x08;
    }
    retrieveMemoryState() {
        return this._readMemoryStatus().then((status) => {
            if (status & this._flashRecordingBit) {
                return MemoryStatus.Recording;
            }
            else if (status & this._dataPresentBit) {
                return MemoryStatus.Recorded;
            }
            return Promise.resolve(MemoryStatus.Empty);
        }, (err) => {
            console.warn('retrieveMemoryState:' + err);
            return Promise.resolve(MemoryStatus.NoMemory);
        });
    }
    enterMode() {
        return this._offlineMode();
    }
    async startRecordingToMemory(params) {
        return this.sensorCoordinator
            .startSensors(params.sensorIds, params.frequencyIndex)
            .then(() => this._stopPolling())
            .then(() => this._writeToUserSpace(params.userSpaceData))
            .then(() => this.commandQueue.add(this.deviceConfig.startRecordingToMemoryCommand));
    }
    stopRecordingToMemory() {
        return this.commandQueue.add(this.deviceConfig.stopRecordingToMemoryCommand);
    }
    stopMemoryDownload() {
        return Promise.resolve();
    }
    clearMemory() {
        return this.commandQueue.addWithoutValidation(this.deviceConfig.clearMemoryCommand);
    }
    setTransferRate(ms) {
        ms = Math.floor(ms);
        const leastSignificant = ms & 0xff;
        const mostSignificant = (ms & 0xff00) >> 8;
        return this.commandQueue.addWithoutValidation([
            START_FLASH_TRANSFER << 1,
            SET_TRANSFER_INTERVAL,
            leastSignificant,
            mostSignificant,
        ]);
    }
    async startMemoryDownload() {
        return this._readMemorySetup().then((setup) => {
            return this._downloadUserSpaceData().pipe(switchMap((userSpaceDataReading) => {
                console.log('Adding download memory operation');
                // This command doesn't appear to maintain the command in the register so it can't be validated
                this.commandQueue.addWithoutValidation(this.deviceConfig.downloadFromMemoryCommand);
                return merge(of(userSpaceDataReading), new PowerlabDataProcessorBuilder(this.eventObs)
                    .build([
                    {
                        sensorId: this.deviceConfig.memoryConfig.id,
                    },
                ], new MemoryDataProcessorStrategy(setup.frequencyInstruction, setup.chunkLength, new PowerlabDataPacketPipeline(this.deviceConfig), userSpaceDataReading.length > 0, this.deviceConfig.flashPacketId))
                    .pipe(takeWhile((readings) => (readings[0] || {}).status !==
                    ProcessorStatus.MemoryDownloadComplete)));
            }));
        });
    }
    exitMode() {
        return this._onlineMode();
    }
    _offlineMode() {
        return this.commandQueue.addWithoutValidation(this._offlineModeCommand);
    }
    _onlineMode() {
        return this.commandQueue.addWithoutValidation(this._onlineModeCommand);
    }
    async _readMemorySetup() {
        return this.commandQueue
            .addReadWithoutValidation(this._readFrequencyInstructionCommand)
            .then((value) => {
            let instruction;
            if (value.byteLength >= 6) {
                console.log('Unprocessed Memory data rate: ');
                console.log(Array.from(new Uint8Array(value.buffer))
                    .map((v) => v.toString(16))
                    .join(' '));
                instruction =
                    (value.getUint16(2, true) << 16) + value.getUint16(4, true);
            }
            else {
                console.log('Using default data rate');
                instruction = this.deviceConfig.frequencyConfigs[0].i;
            }
            return this.commandQueue
                .addReadWithoutValidation(this._readChunkLengthCommand)
                .then((chunkLength) => ({
                frequencyInstruction: instruction,
                chunkLength: !!chunkLength && chunkLength.byteLength >= 3
                    ? chunkLength.getUint8(2)
                    : 0,
            }), () => ({
                frequencyInstruction: instruction,
                chunkLength: 0,
            }))
                .then((setup) => {
                console.log(`setup: ${JSON.stringify(setup)}`);
                return setup;
            });
        });
    }
    async _readMemoryStatus() {
        return this.commandQueue
            .addReadWithoutValidation(this._readMemoryStatusCommand)
            .then((rawStatus) => rawStatus.getUint8(2));
    }
    _stopPolling() {
        return this.commandQueue.addWithoutValidation(this._stopPollingCommand);
    }
    async _writeToUserSpace(userSpaceData) {
        const data = [userSpaceDataValidationId, ...(userSpaceData ?? [])];
        console.log('Writing user space data: ', data);
        return this.commandQueue
            .addWithoutValidation(this._eraseUserSpaceCommand, 700)
            .then(() => this.commandQueue.addWithoutValidation(this._writeUserSpaceCommand, 100, data));
    }
    _downloadUserSpaceData() {
        console.log('Subscribed to user space download');
        const obs = new PowerlabDataProcessorBuilder(this.eventObs)
            .build([
            {
                sensorId: this.deviceConfig.memoryConfig.id,
            },
        ], new UserSpaceDataProcessorStrategy())
            .pipe(tap((readings) => console.log('User space data: ', readings)), timeout(500), filter((readings) => (readings[0] || {}).type === MessageType.UserSpace), take(1), catchError(() => of([])));
        this.commandQueue.addWithoutValidation(this._readUserSpaceCommand);
        return obs;
    }
}
