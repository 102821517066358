import { EntryType } from '../models/models-pl2.js';
import { all, any, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class CourseAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return any(all(this.userIsSetAsCreatorFor(entry), this.userIsPrivilegedParticipantInAuthScope), all(this.tleIsNotType(EntryType.Curriculum), this.userIsTeacher, this.userIsSetAsCreatorFor(entry)))();
    }
    async canRead(entry) {
        return any(this.tleIsPublic, this.userIsPrivilegedParticipantInAuthScope, this.userHasPermissionLicenseFor(entry))();
    }
    async canUpdate(entry) {
        return all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry), this.userIsPrivilegedParticipantInAuthScope)();
    }
    async canDelete(_entry) {
        return all(this.tleIsNotReadOnly, this.userIsPrivilegedParticipantInAuthScope)();
    }
}
