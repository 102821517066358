<div class='dialog-header flex-row'>
  <h3 class='flex' mat-dialog-title> {{title}} </h3>
  <button mat-icon-button class='close'  aria-label="{{ 'common.close' | translate }}" mat-dialog-close><mat-icon svgIcon='close'></mat-icon></button>
</div>
<mat-dialog-content class='flex-column'>
  <div class='content flex-column'>
    <span [innerHtml]='content'></span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button class='save' [hidden]='hideOkay' [mat-dialog-close]='true'>
    {{ 'common.ok' | translate }}
  </button>
</mat-dialog-actions>
