import {Injectable, Inject} from '@angular/core';
import {Router, NavigationEnd, ActivatedRouteSnapshot} from '@angular/router';

import {filter} from 'rxjs/operators';

import {
  KeyUtilsPL2 as KU,
  UtilsPL2 as U,
  PL2,
  KeysPL2 as K,
} from '@common/utils/dist/index.js';
import {environment} from '../../environments/environment';
import {
  collectionPageName,
  lessonViewPageName,
  curriculumPageName,
} from '@constants/app.config';
import {Store} from './abstract-store';

interface UrlParams {
  ref?: string;
  rtdv?: string;
  uEPK?: string;
  confirmed?: string;
  mode?: Mode;
}

export interface LabReportQueryParams {
  se?: string;
  ref?: string;
  uEPK?: string;
  ro?: string;
  to?: boolean;
}

export interface CollectionQueryParams {
  ref?: string;
  ro?: string;
}

export interface CurriculumQueryParams {
  ref?: string;
  filters?: string;
}

export interface RouteState extends LabReportQueryParams {
  containerId?: string;
  cardId?: string;
  pageName?: string;
  view?: string;
  ro?: string;
  userId?: string;
  url?: string;
  rootPK?: string;
  pUrl?: string; // previous url
  filters?: string; // resource filter
  sT?: LessonSlideTab; // slide tab
  nonce?: string;
  priceId?: string;
  productId?: string;
  confirmed?: string;
  mode?: Mode; // teacher notes
  sessionId?: string;
  textFilter?: string;
  queryType?: string;
  term?: string;
  curPK?: K.CurriculumKey;
  utm_source?: string;
  resourceId?: string;
  assignmentId?: string;
  studentId?: string;
  rosterView?: RosterView;
  assignmentOwnerId?: string;
  queryParams?: {[key: string]: string};
  action?: string;
}

export enum LessonSlideTab {
  Closed = 'closed',
  Feedback = 'feedback',
  Notes = 'notes',
  Settings = 'settings',
}

export enum RoutePaths {
  Curriculum = '/curriculum',
  License = '/nar/license',
}

export enum Mode {
  FullScreen = 'fullscreen',
}

export enum RosterView {
  Roster = 'Roster',
}

@Injectable({
  providedIn: 'root',
})
export class RouteStore extends Store<RouteState> {
  constructor(
    @Inject('WINDOW') private window: any,
    private router: Router,
  ) {
    super({containerId: null});
    this.setState(this.buildState());
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const newState = this.buildState();
        if (this._stateHasChanged(newState)) {
          this.setState(newState);
        }
      });
  }

  buildState(): RouteState {
    const newState: RouteState = {};
    const root = this.router.routerState.snapshot.root;
    (function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {
      Object.assign(newState, snapshot.params);
      Object.assign(newState, snapshot.data);
      snapshot.children.forEach(mergeParamsFromSnapshot);
    })(root);
    let namePosition: number;
    if (newState.containerId) {
      const segments = this.router.url.split(/\/|\?/);
      namePosition = segments.indexOf(newState.containerId) - 1;
      newState.pageName = segments[namePosition] || '';
    } else {
      const segments = this.router.url.split('/');
      newState.pageName = segments[segments.length - 1].split('?')[0];
    }
    newState.queryParams = root.queryParams;
    newState.ro = root.queryParamMap.get('ro');
    newState.userId = root.queryParamMap.get('userId');
    newState.ref = root.queryParamMap.get('ref');
    newState.url = this.router.url;
    newState.uEPK = root.queryParamMap.get('uEPK');
    newState.to = !!root.queryParamMap.get('to');
    newState.mode = root.queryParamMap.get('mode') as Mode;
    newState.se = root.queryParamMap.get('se');
    newState.filters = root.queryParamMap.get('filters');
    newState.textFilter = root.queryParamMap.get('textFilter');
    newState.nonce = root.queryParamMap.get('nonce');
    newState.priceId = root.queryParamMap.get('priceId');
    newState.productId = root.queryParamMap.get('productId');
    newState.confirmed = root.queryParamMap.get('confirmed');
    newState.sessionId = root.queryParamMap.get('sessionId');
    newState.rosterView = root.queryParamMap.get('rosterView') as RosterView;
    newState.term = root.queryParamMap.get('term');
    newState.curPK = root.queryParamMap.get('curPK') as K.CurriculumKey;
    newState.utm_source = root.queryParamMap.get('utm_source');
    newState.queryType = root.queryParamMap.get('qT');
    newState.resourceId = root.queryParamMap.get('resource_id');
    newState.assignmentId = root.queryParamMap.get('assignment_id');
    newState.studentId = root.queryParamMap.get('studentId');
    newState.assignmentOwnerId = root.queryParamMap.get('assignment_owner_id');
    newState.action = root.queryParamMap.get('action');
    return newState;
  }

  buildUrl(url?: string, opts?: UrlParams): string {
    if (!url) {
      return '/';
    }
    if (U.isEmpty(opts)) {
      return url;
    }
    let urlWithParams = url;
    Object.keys(opts).forEach((p, i) => {
      if (i === 0) {
        urlWithParams += `${urlWithParams.includes('?') ? '&' : '?'}`;
      } else {
        urlWithParams += '&';
      }
      urlWithParams += `${p}=${encodeURIComponent(opts[p])}`;
    });
    return urlWithParams;
  }

  url(url?: string, opts?: UrlParams) {
    this.router.navigateByUrl(this.buildUrl(url, opts));
  }

  tosUrl() {
    return `${environment.host}/support/tos`;
  }

  user() {
    this.router.navigate(['/user']);
  }

  trials() {
    this.router.navigate(['/trials']);
  }

  home() {
    this.router.navigate(['/'], {queryParams: {}, queryParamsHandling: ''});
  }

  class(pKOrMId: string, sK = '') {
    this.router.navigate(['/class', pKOrMId + sK]);
  }

  assignment(pK: string) {
    this.router.navigate(['/assignment', pK]);
  }

  labReports(queryParams?: {userId?: string; uEPK?: string}): Promise<boolean> {
    return this.router.navigate(['/lab-reports'], {queryParams: queryParams});
  }

  content(): Promise<boolean> {
    return this.router.navigate(['/content']);
  }

  lessons(
    queryParams?: {userId?: string; ro?: string},
    merge = true,
  ): Promise<boolean> {
    return this.router.navigate(['/content/lessons'], {
      queryParams: queryParams,
      queryParamsHandling: merge ? 'merge' : '',
    });
  }

  studentCopies(
    queryParams?: {userId?: string; uEPK?: string; rosterView?: RosterView},
    merge = true,
  ): Promise<boolean> {
    return this.router.navigate(['/content/assignments'], {
      queryParams: queryParams,
      queryParamsHandling: merge ? 'merge' : '',
    });
  }

  collections(): Promise<boolean> {
    return this.router.navigate(['/content/collections']);
  }

  curriculum(
    pK: string,
    queryParams: CurriculumQueryParams,
    textFilter: string | null = this.state().textFilter,
  ): Promise<boolean> {
    return this.router.navigate(['/curriculum', pK], {
      queryParams: textFilter
        ? {...queryParams, textFilter: textFilter}
        : queryParams,
    });
  }

  purchasers(): Promise<boolean> {
    return this.router.navigate(['/purchasers']);
  }

  purchaser(pK: string, queryParams: {ref: string}): Promise<boolean> {
    return this.router.navigate(['/purchaser', pK], {queryParams: queryParams});
  }

  license(
    pK: string,
    queryParams: {ref?: string; filters?: string},
    textFilter: string | null = this.state().textFilter,
  ) {
    return this.router.navigate(['/nar', 'license', pK], {
      queryParams: textFilter
        ? {...queryParams, textFilter: textFilter}
        : queryParams,
    });
  }

  userDashboard(
    pK: string,
    queryParams: {ref?: string; filters?: string},
  ): Promise<boolean> {
    return this.router.navigate(['/nar', 'userDashboard', pK], {queryParams});
  }

  routeWithSearchFilter(
    routePath: RoutePaths,
    pK: string,
    queryParams: {ref?: string; filters?: string},
    textFilter: string | null = this.state().textFilter,
  ): Promise<boolean> {
    return this.router.navigate([routePath, pK], {
      queryParams: textFilter
        ? {...queryParams, textFilter: textFilter}
        : queryParams,
    });
  }

  labReport(
    masterId: string,
    sortKey: string,
    queryParams: LabReportQueryParams,
  ) {
    const qp = this._excludeFalsies(queryParams);
    this.router.navigate(
      ['/lab-report', KU.stringFromKey({mId: masterId, sK: sortKey})],
      {
        queryParams: qp,
        queryParamsHandling: 'merge',
      },
    );
  }

  resource(
    pK: string,
    queryParams:
      | LabReportQueryParams
      | CollectionQueryParams
      | CurriculumQueryParams,
    merge = true,
  ) {
    const qp = this._excludeFalsies(queryParams);
    const type = KU.type(pK);
    let resourceType: string;
    if (type === PL2.EntryType.LabReport) {
      resourceType = lessonViewPageName;
    } else if (type === PL2.EntryType.Course) {
      resourceType = collectionPageName;
    } else if (type === PL2.EntryType.Curriculum) {
      resourceType = curriculumPageName;
    } else {
      throw Error('Invalid pK for method');
    }
    this.router.navigate([resourceType, pK], {
      queryParams: qp,
      queryParamsHandling: merge ? 'merge' : '',
    });
  }

  lesson(pK: string, queryParams: LabReportQueryParams) {
    this.resource(pK, queryParams);
  }

  course(pK: string, queryParams: CollectionQueryParams) {
    this.resource(pK, queryParams);
  }

  slides(
    slidePK: string,
    lrPK: string,
    tab?: LessonSlideTab,
    queryParams: LabReportQueryParams = {},
  ) {
    const outlets = {primary: ['slide', slidePK]};
    if (!U.isEmpty(tab)) {
      outlets['tab'] = tab;
    }
    this.router.navigate(['slides', lrPK, {outlets: outlets}], {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  slidesFullScreenTab() {
    this.newTab(this.buildUrl(this.state().url, {mode: Mode.FullScreen}));
  }

  anchor(elementId: string) {
    const container = document.getElementById(elementId);
    if (container) {
      container.scrollIntoView();
    }
  }

  newTab(url: string) {
    this.window.open(url, '_blank');
  }

  externalUrl(url: string) {
    this.window.open(url, '_self');
  }

  pocketlabLibrary() {
    this.router.navigate(['/pocketlab-library']);
  }

  auth(path: string, queryParams?: {[key: string]: string}) {
    this.setState({...this.state(), pUrl: this.state().url});
    this.router.navigate(['/auth', path], {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  previousUrl() {
    this.url(this.state().pUrl || '/');
  }

  userConfirmation(queryParams: {ref: string}): Promise<boolean> {
    return this.router.navigate(['/payment', 'user-confirmation'], {
      queryParams: queryParams,
    });
  }

  search<QP extends PL2.OpenSearchQuery>(queryParams: QP): Promise<boolean> {
    return this.router.navigate(['/search'], {queryParams: queryParams});
  }

  source(srcId: string): Promise<boolean> {
    return this.router.navigate(['/source', srcId]);
  }

  changeFilters(
    queryParams: {ref?: string; filters?: string},
    textFilter: string | null = this.state().textFilter,
  ) {
    return this.router.navigate([], {
      queryParams: textFilter
        ? {...queryParams, textFilter: textFilter}
        : queryParams,
      queryParamsHandling: 'merge',
    });
  }

  print(pK: string, queryParams: {ref?: string} = {}) {
    return this.router.navigate(['lab-report', pK, 'print'], {
      queryParams: {...queryParams, ro: '1'},
    });
  }

  private _stateHasChanged(newState: RouteState): boolean {
    const s = this.state();
    return (
      s.containerId !== newState.containerId ||
      s.view !== newState.view ||
      s.cardId !== newState.cardId ||
      s.sT !== newState.sT ||
      s.pageName !== newState.pageName ||
      s.rootPK !== newState.rootPK ||
      s.filters !== newState.filters ||
      s.textFilter !== newState.textFilter ||
      s.uEPK !== newState.uEPK ||
      s.ro !== newState.ro ||
      s.term !== newState.term ||
      s.rosterView !== newState.rosterView ||
      s.curPK !== newState.curPK
    );
  }

  private _excludeFalsies(queryParams: {}): any {
    const qp = {};
    Object.keys(queryParams).forEach((p) => {
      if (!!queryParams[p]) {
        qp[p] = queryParams[p];
      } else {
        qp[p] = null;
      }
    });
    return qp;
  }
}
