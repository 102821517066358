import {StateTransformer} from '../abstract-store';

import {SensorMessage, DeviceConfig} from '@common/ble/dist/index.js';

import {PL2} from '@common/utils/dist/index.js';

import {UserSpaceDataConfigStep} from './user-space-data-config-step';
import {MemoryInferenceDataConfigStep} from './memory-inference-data-config-step';
import {UpdatePointsDataConfigStep} from './update-points-data-config-step';

export class ReadingPipelineDataConfigTransformer
  implements StateTransformer<SensorMessage[], PL2.DataConfigState>
{
  userSpaceStep: UserSpaceDataConfigStep;
  memoryInferenceStep: MemoryInferenceDataConfigStep;
  updatePointsStep: UpdatePointsDataConfigStep;
  constructor(
    deviceConfig: DeviceConfig,
    translations: Record<string, string>,
  ) {
    this.userSpaceStep = new UserSpaceDataConfigStep(
      deviceConfig,
      translations,
    );
    this.memoryInferenceStep = new MemoryInferenceDataConfigStep(translations);
    this.updatePointsStep = new UpdatePointsDataConfigStep();
  }
  transform(
    data: SensorMessage[],
    dcs: PL2.DataConfigState,
  ): PL2.DataConfigState {
    let dataConfigState = this.userSpaceStep.transform(data, dcs ?? {});
    dataConfigState = this.memoryInferenceStep.transform(data, dataConfigState);
    dataConfigState = this.updatePointsStep.transform(data, dataConfigState);
    return dataConfigState;
  }
}
