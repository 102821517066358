// Generic PL bus command codes
// Telemetry Commands (read only)
export const READ_CHARGING_STATUS = 0x00;
// Status Commands (read only)
export const PACKET_ID = 0x10;
export const EXTERNAL_MEM_STAT = 0x11;
export const EXTERNAL_MEM_END_ADDR = 0x12;
export const EXTERNAL_MEM_PACKET_SIZE = 0x13;
export const EXTERNAL_MEM_DATA_CONFIG = 0x14;
export const DEVICE_STAT = 0x15;
export const BATTERY_STAT = 0x16;
// Control Commands
export const ENABLE_FLASH = 0x20;
export const ENABLE_FLASH_KEY = 0xc1;
export const WRITE_FLASH = 0x21;
export const WRITE_FLASH_KEY = 0x09;
export const RESTART_DEVICE = 0x22;
export const RESTART_DEVICE_KEY = 0xd9;
export const POWER_DOWN_DEVICE = 0x23;
export const POWER_DOWN_DEVICE_KEY = 0xe3;
export const START_FLASH_TRANSFER = 0x26;
export const START_TRANSFER_CMD = 0x76; // cmd code for START_FLASH_TRANSFER
export const READ_USER_SPACE_CMD = 0x81; // cmd code for START_FLASH_TRANSFER
export const ERASE_FLASH_CMD = 0x97; // cmd code for START_FLASH_TRANSFER
export const READ_DATA_CONFIG = 0xa3; // cmd code for START_FLASH_TRANSFER
export const SET_TRANSFER_INTERVAL = 0xb7; // cmd code for START_FLASH_TRANSFER
export const INIT_TELEMETRY = 0x27;
export const INIT_TELEMETRY_KEY = 0x65;
export const WRITE_DEVICE_NAME = 0x28;
export const WRITE_DEVICE_NAME_KEY = 0x03;
export const WRITE_USER_SPACE = 0x29;
export const WRITE_USER_SPACE_KEY = 0x07;
// Configuration Commands
export const ON_OFF_POLLING = 0x30;
export const ON_OFF_BATTERY_SENSE = 0x31;
export const ON_OFF_LED_FEEDBACK = 0x34;
export const ON_OFF_MEMORY_MODE = 0x35;
export const ON_OFF_OFFLINE_MODE = 0x36;
export const POLLING_PACKET_CONFIG = 0x37;
export const POLLING_DATA_CONFIG = 0x38;
export const BATTERY_READ_PERIOD = 0x39;
export const SYSTEM_CONFIG = 0x3f;
// On Off
export const ON = 0x01;
export const OFF = 0x00;
// default values
export const MEMORY_MODE_DEFAULT = 0x64;
export const OFFLINE_MODE_DEFAULT = 0x66;
