import { ValidatorFactory, } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
export class TurnInAbleValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.turnInAbleAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'tIAt':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'uAt':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            default:
                throw Error(`Attr: ${attr} shouldn\'t exist`);
        }
    }
}
