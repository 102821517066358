import { ValidatorFactory, } from './validator-core.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { ExistsValidator } from './exists-validator.js';
import { SharedDrawingSectionEntryValidatorFactory } from './shared-drawing-section-entry-validator-factory.js';
import { DragAndDropObjectValidatorFactory } from './drag-and-drop-object-validator-factory.js';
export class SharedDragAndDropSectionEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory, objectFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
        this.objectFactory = objectFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.sharedDragAndDropSectionEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'cA':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'iGM':
            case 'mE':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'dat':
                if (U.isEmpty(value)) {
                    return [new ExistsValidator(value, this.builderFactory(attr))];
                }
                return Object.keys(value).map((key) => {
                    const dO = value[key];
                    if (U.isEmpty(dO)) {
                        return new LenientValidator(dO, this.builderFactory(key));
                    }
                    return this.objectFactory(dO, new DragAndDropObjectValidatorFactory(this.builderFactory));
                });
            default:
                return new SharedDrawingSectionEntryValidatorFactory(this.builderFactory, this.objectFactory).getAttributeValidators(attr, value);
        }
    }
}
