import { fromEvent, ReplaySubject, empty, merge, of } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SensorParamsAdjustmentChain } from './sensor-params-adjustment-chain.js';
export class Connection {
    constructor(gattServer, connectionStrategy) {
        this.gattServer = gattServer;
        this.connectionStrategy = connectionStrategy;
        this._destroyed$ = new ReplaySubject();
        this._sensorParamsAdjustmentChain = new SensorParamsAdjustmentChain(connectionStrategy.deviceConfig);
    }
    init() {
        if (!!this.gattServer) {
            this.disconnect$ = fromEvent(this.gattServer.device, 'gattserverdisconnected').pipe(takeUntil(this._destroyed$), take(1));
        }
        else {
            this.disconnect$ = empty();
        }
        return this.connectionStrategy.init();
    }
    disconnect() {
        this._destroyed$.next(true);
        this._destroyed$.complete();
        this.connectionStrategy.disconnect();
        if (this.gattServer) {
            this.gattServer.disconnect();
        }
        this.gattServer = null;
    }
    startSensors(params) {
        console.log('startSensors', params);
        const adjustments = this._sensorParamsAdjustmentChain.execute(params);
        return merge(of(adjustments.initializations), this.connectionStrategy.startSensors(adjustments)).pipe(takeUntil(this._destroyed$));
    }
    calibrate(sensorId, calibrationId, payload) {
        return this.connectionStrategy.calibrate(sensorId, calibrationId, payload);
    }
    readName() {
        if (!!this.gattServer) {
            return this.gattServer.device.name.slice(3);
        }
        else {
            return '';
        }
    }
    memoryConfigurator() {
        return this.connectionStrategy.memoryConfigurator();
    }
    deviceConfigurator() {
        return this.connectionStrategy.deviceConfigurator();
    }
    crashModeConfigurator() {
        return this.connectionStrategy.crashModeConfigurator();
    }
    writeCommand(command) {
        return this.connectionStrategy.writeCommand(command);
    }
    readCommand() {
        return this.connectionStrategy.readCommand();
    }
    getDataCharacteristic() {
        return this.connectionStrategy.getDataCharacteristic();
    }
    getBatteryCharacteristic() {
        return this.connectionStrategy.getBatteryCharacteristic();
    }
}
