import { EntryType, } from '../models/models-pl2.js';
import { ValidatorFactory, } from './validator-core.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
import { LenientValidator } from './lenient-validator.js';
import { BaseLabReportEntryValidatorFactory } from './base-lab-report-entry-validator-factory.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { SortableEntryValidatorFactory } from './sortable-entry-validator-factory.js';
import { IsEmptyValidator } from './is-empty-validator.js';
import { TleMetadataEntryValidatorFactory } from './tle-metadata-entry-validator-factory.js';
import { ThemableValidatorFactory } from './themable-validator-factory.js';
import { LengthValidator } from './length.validator.js';
import { lessonVersionNameMaxLength } from '../config/constants.js';
export class LabReportEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.labReportEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'scC':
            case 'sN':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'isP':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'cSK':
            case 'uLEId':
            case 'srcId':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'vN':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                    new LengthValidator(value, { maxLength: lessonVersionNameMaxLength }, this.builderFactory(attr)),
                ];
            default:
                if (EU.baseLabReportEntryAtts.includes(attr)) {
                    return new BaseLabReportEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.tleMetadataAtts.includes(attr)) {
                    return new TleMetadataEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.sortableEntryAtts.includes(attr)) {
                    return new SortableEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.themableAtts.includes(attr)) {
                    return new ThemableValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
        }
    }
    getMultiAttributeValidators(o) {
        const validators = new Array();
        const pSK = U.isEmpty(o.sK) ? null : KU.parentSortKey(o.sK);
        if (U.isEmpty(pSK)) {
            validators.push(new IsNonEmptyValidator(o.uLEId, this.builderFactory('uLEId')));
        }
        else {
            validators.push(new IsEmptyValidator(o.uLEId, this.builderFactory('uLEId')));
        }
        if (KU.isRootEntryType(EntryType.Curriculum, o.sK)) {
            validators.push(new IsNonEmptyValidator(o.srcId, this.builderFactory('srcId')));
        }
        else {
            validators.push(new IsEmptyValidator(o.srcId, this.builderFactory('srcId')));
        }
        return validators;
    }
}
