import {DeviceConfig} from '@common/ble/dist/index.js';

import {SensorMapperUtils as SMU, GraphParams} from './sensor-mapper-utils';

import {GraphConfigUtils as GCU} from '@utils/graph-config-utils';

export const userSpaceDataVersion = 1;

export function serializeUserSpaceData(
  gCIds: string[],
  frequencyIndex: number,
): number[] {
  const graphConfigNumber = gCIds.reduce(
    (acc, gCId) => acc | GCU.configWithId(gCId)?.configValue ?? 0,
    0,
  );
  return [
    userSpaceDataVersion,
    graphConfigNumber,
    frequencyIndex,
    Math.round(new Date().getTime() / 1000),
  ];
}

export function deserializeUserSpaceData(
  data: number[],
  device: DeviceConfig,
): GraphParams {
  const graphConfigNumber = data[1];
  const frequencyIndex = data[2];
  const startDate = data[3];
  return {
    gCIds: SMU.getGraphConfigIdsForDevice(device, true)
      .map((gCId) => GCU.configWithId(gCId))
      .filter((gC) => gC?.configValue & graphConfigNumber)
      .map((gC) => gC.id),
    frequencyIndex: frequencyIndex,
    startDate: String(startDate * 1000),
  };
}
