export var IndexManagerChainType;
(function (IndexManagerChainType) {
    IndexManagerChainType[IndexManagerChainType["Default"] = 0] = "Default";
    IndexManagerChainType[IndexManagerChainType["WithoutSrcId"] = 1] = "WithoutSrcId";
    IndexManagerChainType[IndexManagerChainType["AcceptInvite"] = 2] = "AcceptInvite";
})(IndexManagerChainType || (IndexManagerChainType = {}));
export var CustomSNSAction;
(function (CustomSNSAction) {
    CustomSNSAction[CustomSNSAction["MigrateExistingDAUser"] = 0] = "MigrateExistingDAUser";
    CustomSNSAction[CustomSNSAction["SynchronizeEdClasses"] = 1] = "SynchronizeEdClasses";
    CustomSNSAction[CustomSNSAction["UpdateHubspotWithUser"] = 2] = "UpdateHubspotWithUser";
    CustomSNSAction[CustomSNSAction["SynchronizeEdlinkClasses"] = 3] = "SynchronizeEdlinkClasses";
    CustomSNSAction[CustomSNSAction["SynchronizeGoogleClasses"] = 4] = "SynchronizeGoogleClasses";
})(CustomSNSAction || (CustomSNSAction = {}));
export var NotificationSNSAction;
(function (NotificationSNSAction) {
    NotificationSNSAction[NotificationSNSAction["PushSubject"] = 0] = "PushSubject";
    NotificationSNSAction[NotificationSNSAction["SupressSubject"] = 1] = "SupressSubject";
})(NotificationSNSAction || (NotificationSNSAction = {}));
export var WebSocketSNSAction;
(function (WebSocketSNSAction) {
    WebSocketSNSAction[WebSocketSNSAction["Connect"] = 0] = "Connect";
    WebSocketSNSAction[WebSocketSNSAction["Disconnect"] = 1] = "Disconnect";
    WebSocketSNSAction[WebSocketSNSAction["Notify"] = 2] = "Notify";
    WebSocketSNSAction[WebSocketSNSAction["Watch"] = 3] = "Watch";
})(WebSocketSNSAction || (WebSocketSNSAction = {}));
export var UserSNSAction;
(function (UserSNSAction) {
    UserSNSAction[UserSNSAction["SetupEdlinkUser"] = 0] = "SetupEdlinkUser";
    // UpdateUser,
})(UserSNSAction || (UserSNSAction = {}));
export var EnvelopeAction;
(function (EnvelopeAction) {
    EnvelopeAction[EnvelopeAction["EnhanceLesson"] = -1] = "EnhanceLesson";
    EnvelopeAction[EnvelopeAction["Notifications"] = -2] = "Notifications";
    EnvelopeAction[EnvelopeAction["GenerateMaterial"] = -3] = "GenerateMaterial";
})(EnvelopeAction || (EnvelopeAction = {}));
export var EventAction;
(function (EventAction) {
    EventAction[EventAction["SignIn"] = 0] = "SignIn";
})(EventAction || (EventAction = {}));
