import * as OdysseyCommandCodes from './odyssey-command-codes.js';
import * as PLCommandCodes from './pl-command-codes.js';
// voltage, current, resistance, temperature config register
export class VIRTConfig {
    constructor(n, configInterface) {
        this.byteCount = 2;
        this.defaultRegisterValues = BigInt(0b00001101001101000100000000001000);
        this.configCommand = OdysseyCommandCodes.VIRT_CONFIG;
        this.resistanceRanging = {
            name: 'r_ranging',
            mask: BigInt(0b0000000000000011),
            bits: 2,
            offset: 0,
        };
        this.resistanceEnable = {
            name: 'r_en',
            mask: BigInt(0b0000000000000100),
            bits: 1,
            offset: 2,
        };
        this.temperatureRanging = {
            name: 't_ranging',
            mask: BigInt(0b0000000000011000),
            bits: 2,
            offset: 3,
        };
        this.temperatureType = {
            name: 't_type',
            mask: BigInt(0b0000000001100000),
            bits: 2,
            offset: 5,
        };
        this.temperatureEnable = {
            name: 't_en',
            mask: BigInt(0b0000000010000000),
            bits: 1,
            offset: 7,
        };
        this.voltageRanging = {
            name: 'v_ranging',
            mask: BigInt(0b0000001100000000),
            bits: 2,
            offset: 8,
        };
        this.voltageEnable = {
            name: 'v_en',
            mask: BigInt(0b0000010000000000),
            bits: 1,
            offset: 10,
        };
        this.currentRanging = {
            name: 'i_ranging',
            mask: BigInt(0b0001100000000000),
            bits: 2,
            offset: 11,
        };
        this.currentEnable = {
            name: 'i_en',
            mask: BigInt(0b0010000000000000),
            bits: 1,
            offset: 13,
        };
        this.autoRanging = {
            name: 'auto_ranging',
            mask: BigInt(0b0100000000000000),
            bits: 1,
            offset: 14,
        };
        this.byteArray = [0x00, 0x00];
        this.name = n;
        this.value = BigInt(0);
        this.configInterface = configInterface;
        this.content = [
            this.resistanceRanging,
            this.resistanceEnable,
            this.temperatureRanging,
            this.temperatureType,
            this.temperatureEnable,
            this.voltageRanging,
            this.voltageEnable,
            this.currentRanging,
            this.currentEnable,
            this.autoRanging,
        ];
    }
} // 16 bit register
// electrical conductivity register
export class ECConfig {
    constructor(n) {
        this.byteCount = 2;
        this.defaultRegisterValues = BigInt(0b0000000000000000);
        this.configCommand = OdysseyCommandCodes.EC_CONFIG;
        this.configInterface = 1;
        this.ecRanging = {
            name: 'ec_ranging',
            mask: BigInt(0b0000000000000011),
            bits: 2,
            offset: 0,
        };
        this.ecPwrEnable = {
            name: 'ec_pwren',
            mask: BigInt(0b0000000000000100),
            bits: 1,
            offset: 2,
        };
        this.ecDacDisable = {
            name: 'ec_dacdis',
            mask: BigInt(0b0000000000001000),
            bits: 1,
            offset: 3,
        };
        this.tempComp1 = {
            name: 'temp_comp_1',
            mask: BigInt(0b0000000000010000),
            bits: 1,
            offset: 4,
        };
        this.tempComp2 = {
            name: 'temp_comp_2',
            mask: BigInt(0b0000000000100000),
            bits: 1,
            offset: 5,
        };
        this.ecFrequency = {
            name: 'ec_freq',
            mask: BigInt(0b1111111111000000),
            bits: 12,
            offset: 6,
        };
        this.byteArray = [0x00, 0x00];
        this.name = n;
        this.value = BigInt(0);
        this.content = [
            this.ecRanging,
            this.ecPwrEnable,
            this.ecDacDisable,
            this.tempComp1,
            this.tempComp2,
            this.ecFrequency,
        ];
    }
} // 16 bit register
// iso high impedance config register
export class ISOHighImpConfig {
    constructor(n) {
        this.byteCount = 1;
        this.defaultRegisterValues = BigInt(0b00000000);
        this.configCommand = OdysseyCommandCodes.PH_ORP_DO_CONFIG;
        this.configInterface = 1;
        this.isoEnable = {
            name: 'iso_en',
            mask: BigInt(0b00000001),
            bits: 1,
            offset: 0,
        };
        this.circuitConfig = {
            name: 'circuit_cfg',
            mask: BigInt(0b00000010),
            bits: 1,
            offset: 1,
        };
        this.circuitEnable = {
            name: 'circuit_en',
            mask: BigInt(0b00000100),
            bits: 1,
            offset: 2,
        };
        this.tempComp1 = {
            name: 'temp_comp_1',
            mask: BigInt(0b00001000),
            bits: 1,
            offset: 3,
        };
        this.tempComp2 = {
            name: 'temp_comp_2',
            mask: BigInt(0b00010000),
            bits: 1,
            offset: 4,
        };
        this.byteArray = [0x00];
        this.name = n;
        this.value = BigInt(0);
        this.content = [
            this.isoEnable,
            this.circuitConfig,
            this.circuitEnable,
            this.tempComp1,
            this.tempComp2,
        ];
    }
} // 8 bit register
// digital interface config register
export class DigitalConfig {
    constructor(n) {
        this.byteCount = 10;
        this.defaultRegisterValues = BigInt(0b00000000000000000000000000000000000000000000000000000000000000000000000000000000);
        this.pwrEnable = {
            name: 'pwr_en',
            mask: BigInt(0b00000000000000000000000000000000000000000000000000000000000000000000000000000001),
            bits: 1,
            offset: 0,
        };
        this.pwrMode = {
            name: 'pwr_mode',
            mask: BigInt(0b00000000000000000000000000000000000000000000000000000000000000000000000000000010),
            bits: 1,
            offset: 1,
        };
        this.ioConfig = {
            name: 'io_cfg',
            mask: BigInt(0b00000000000000000000000000000000000000000000000000000000000000000000000000001100),
            bits: 2,
            offset: 2,
        };
        this.ioEnable = {
            name: 'io_en',
            mask: BigInt(0b00000000000000000000000000000000000000000000000000000000000000000000000000010000),
            bits: 1,
            offset: 4,
        };
        this.address = {
            name: 'address',
            mask: BigInt(0b00000000000000000000000000000000000000000000000000000000000000001111111100000000),
            bits: 8,
            offset: 8,
        };
        this.baudRate = {
            name: 'baud_rate',
            mask: BigInt(0b00000000000000000000000000000000000000000000000011111111111111110000000000000000),
            bits: 16,
            offset: 16,
        };
        this.pwmFreq = {
            name: 'pwm_freq',
            mask: BigInt(0b00000000000000000000000000000000111111111111111100000000000000000000000000000000),
            bits: 16,
            offset: 32,
        };
        this.pwmDC = {
            name: 'pwm_dc',
            mask: BigInt(0b00000000000000001111111111111111000000000000000000000000000000000000000000000000),
            bits: 16,
            offset: 48,
        };
        this.gpio = {
            name: 'gpio',
            mask: BigInt(0b11111111111111110000000000000000000000000000000000000000000000000000000000000000),
            bits: 16,
            offset: 64,
        };
        this.byteArray = [
            0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
        ];
        this.name = n;
        this.value = BigInt(0);
        this.content = [
            this.pwrEnable,
            this.pwrMode,
            this.ioConfig,
            this.ioEnable,
            this.address,
            this.baudRate,
            this.pwmFreq,
            this.pwmDC,
            this.gpio,
        ];
    }
} // 10 byte register
// odyssey power config register
export class OdysseyPwrConfig {
    constructor(n) {
        this.byteCount = 1;
        this.defaultRegisterValues = BigInt(0b00000000);
        this.configCommand = OdysseyCommandCodes.MAIN_PWR_CONFIG;
        this.configInterface = 1;
        this.swVdd = {
            name: 'sw_vdd',
            mask: BigInt(0b00000001),
            bits: 1,
            offset: 0,
        };
        this.fiveVoltEnable = {
            name: 'fiveV_en',
            mask: BigInt(0b00000010),
            bits: 1,
            offset: 1,
        };
        this.byteArray = [0x00];
        this.name = n;
        this.value = BigInt(0);
        this.content = [this.swVdd, this.fiveVoltEnable];
    }
} // 8 bit register
// odyssey system config register
export class OdysseySystemConfig {
    constructor() {
        this.pH_ORP_DO = new ISOHighImpConfig('pH_ORP_DO');
        this.MAIN_PWR = new OdysseyPwrConfig('MAIN_PWR');
        this.EC = new ECConfig('EC');
        this.VIRT1 = new VIRTConfig('VIRT1', 1);
        this.VIRT2 = new VIRTConfig('VIRT2', 2);
        this.DIG1 = new DigitalConfig('DIG1');
        this.DIG2 = new DigitalConfig('DIG2');
        this.registers = [
            this.pH_ORP_DO,
            this.MAIN_PWR,
            this.EC,
            this.VIRT1,
            this.VIRT2,
            this.DIG1,
            this.DIG2,
        ];
    }
}
// readOdysseySystemConfig()
// params:
// cfg - OdysseySystemConfig object to read the system config into
// conn - the connection object
//
// reads the odyssey system config from the device and updates the cfg object
export async function readFullOdysseySystemConfig(cfg, conn) {
    await conn.writeCommand([(PLCommandCodes.SYSTEM_CONFIG << 1) + 1]);
    await conn.readCommand().then((result) => {
        console.log('readOdysseySystemConfig - result.buffer:', result.buffer);
        let index = 2;
        for (var register of cfg.registers) {
            if (register.byteCount == 1) {
                register.value = BigInt(result.getUint8(index));
            }
            else if (register.byteCount == 2) {
                register.value = BigInt(result.getUint16(index, true));
            }
            else if (register.byteCount == 4) {
                register.value = BigInt(result.getUint32(index, true));
            }
            else {
                register.value = BigInt(0); // reset reg value, otherwise will just add new value to old value
                // dig config, 10 bytes
                for (let i = 0; i < register.byteCount; i += 4) {
                    let bytesToRead = Math.min(4, register.byteCount - i); // Read up to 4 bytes, but no more than what's remaining
                    let part = 0;
                    if (bytesToRead === 4) {
                        part = result.getUint32(index + i, true);
                    }
                    else if (bytesToRead === 2) {
                        part = result.getUint16(index + i, true);
                    }
                    else if (bytesToRead === 1) {
                        part = result.getUint8(index + i);
                    }
                    // Shift the part left by 8 * number of bytes already read and combine it
                    register.value += BigInt(part) << BigInt(i * 8);
                }
            }
            // update the register's byte array
            for (let i = 0; i < register.byteArray.length; i++) {
                register.byteArray[i] = Number((register.value >> BigInt(i * 8)) & BigInt(0xff));
            }
            index += register.byteCount;
        }
    });
}
// updateOdysseySystemConfig()
// params:
// cfg - odyssey system config object to write to the device
// conn - the connection object
//
// writes the given cfg to the device
export async function updateFullOdysseySystemConfig(cfg, conn) {
    var odysseySystemConfigArray = [];
    for (let reg of cfg.registers) {
        odysseySystemConfigArray = odysseySystemConfigArray.concat(reg.byteArray); // LSB first
    }
    await conn.writeCommand([
        PLCommandCodes.SYSTEM_CONFIG << 1,
        OdysseyCommandCodes.ODYSSEY_SYSTEM_CONFIG_KEY,
        ...odysseySystemConfigArray,
    ]);
}
// updatepHORPDOConfig()
// params:
// cfg - the predefined config to write to the device
// conn - the connection object
//
// writes the given pH-ORP-DO config to the system config
export async function updatepHORPDOConfig(cfg, conn) {
    await conn.writeCommand([
        PLCommandCodes.SYSTEM_CONFIG << 1,
        OdysseyCommandCodes.PH_ORP_DO_CONFIG,
        1,
        ...cfg.byteArray,
    ]);
}
// updateECConfig()
// params:
// cfg - the predefined config to write to the device
// conn - the connection object
//
// writes the given EC config to the system config
export async function updateECConfig(cfg, conn) {
    await conn.writeCommand([
        PLCommandCodes.SYSTEM_CONFIG << 1,
        OdysseyCommandCodes.EC_CONFIG,
        1,
        ...cfg.byteArray,
    ]);
}
// updateVIRT1Config()
// params:
// cfg - the predefined config to write to the device
// conn - the connection object
//
// writes the given VIRT1 config to the system config
export async function updateVIRT1Config(cfg, conn) {
    await conn.writeCommand([
        PLCommandCodes.SYSTEM_CONFIG << 1,
        OdysseyCommandCodes.VIRT_CONFIG,
        1,
        ...cfg.byteArray,
    ]);
}
// updateVIRT2Config()
// params:
// cfg - the predefined config to write to the device
// conn - the connection object
//
// writes the given VIRT2 config to the system config
export async function updateVIRT2Config(cfg, conn) {
    await conn.writeCommand([
        PLCommandCodes.SYSTEM_CONFIG << 1,
        OdysseyCommandCodes.VIRT_CONFIG,
        2,
        ...cfg.byteArray,
    ]);
}
// updateDIG1Config()
// params:
// cfg - the predefined config to write to the device
// conn - the connection object
//
// writes the given DIG1 config to the system config
export async function updateDIG1Config(cfg, conn) {
    await conn.writeCommand([
        PLCommandCodes.SYSTEM_CONFIG << 1,
        OdysseyCommandCodes.DIG_CONFIG,
        1,
        ...cfg.byteArray,
    ]);
}
// updateDIG2Config()
// params:
// cfg - the predefined config to write to the device
// conn - the connection object
//
// writes the given DIG2 config to the system config
export async function updateDIG2Config(cfg, conn) {
    await conn.writeCommand([
        PLCommandCodes.SYSTEM_CONFIG << 1,
        OdysseyCommandCodes.DIG_CONFIG,
        2,
        ...cfg.byteArray,
    ]);
}
// updateMainPwrConfig()
// params:
// cfg - the predefined config to write to the device
// conn - the connection object
//
// writes the given MAIN_PWR config to the system config
export async function updateMainPwrConfig(cfg, conn) {
    await conn.writeCommand([
        PLCommandCodes.SYSTEM_CONFIG << 1,
        OdysseyCommandCodes.MAIN_PWR_CONFIG,
        1,
        ...cfg.byteArray,
    ]);
}
