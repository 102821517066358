import {Injectable, OnDestroy} from '@angular/core';

import {Observable, Subscriber, of} from 'rxjs';

import {PL2} from '@common/utils/dist/index.js';

import {Store} from './abstract-store';
import {map} from 'rxjs/operators';

export enum GeolocationWatchState {
  Unsupported,
  AwaitingPermission,
  PermissionDenied,
  WaitingToStart,
  NoSignal,
  Watching,
}

@Injectable({
  providedIn: 'root',
})
export class GeolocationStore extends Store<PL2.Location> implements OnDestroy {
  static readonly noLocation: PL2.Location = {lng: null, lat: null};

  readonly timeout = 10000;
  readonly maximumAge = 30000;

  private watchId: number;

  constructor() {
    super(GeolocationStore.noLocation);
  }

  ngOnDestroy() {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  }

  currentWatchState(): Observable<GeolocationWatchState> {
    return new Observable((subscriber) => {
      if (!!this.watchId) {
        this._completeWithWatchState(
          GeolocationWatchState.Watching,
          subscriber,
        );
      } else if (!navigator) {
        this._completeWithWatchState(
          GeolocationWatchState.Unsupported,
          subscriber,
        );
      } else if (!navigator.permissions) {
        // probably iOS where the permissions query isn't supported so lets try start it up
        navigator.geolocation.getCurrentPosition(
          () => {
            this.watchId = navigator.geolocation.watchPosition((position) => {
              this.setState(this._gMapPositionToLocation(position));
              this._completeWithWatchState(
                GeolocationWatchState.Watching,
                subscriber,
              );
            });
          },
          (err) => {
            console.log(err);
            this._completeWithWatchState(
              GeolocationWatchState.PermissionDenied,
              subscriber,
            );
          },
          {timeout: this.timeout},
        );
      } else {
        navigator.permissions
          .query({name: 'geolocation'})
          .then((permissions) => {
            if (permissions.state === 'denied') {
              this._completeWithWatchState(
                GeolocationWatchState.PermissionDenied,
                subscriber,
              );
            } else {
              if (!navigator.geolocation) {
                this._completeWithWatchState(
                  GeolocationWatchState.Unsupported,
                  subscriber,
                );
              } else {
                if (permissions.state === 'prompt') {
                  this._completeWithWatchState(
                    GeolocationWatchState.AwaitingPermission,
                    subscriber,
                  );
                } else {
                  this._completeWithWatchState(
                    GeolocationWatchState.WaitingToStart,
                    subscriber,
                  );
                  this.watchId = navigator.geolocation.watchPosition(
                    (position) => {
                      this.setState(this._gMapPositionToLocation(position));
                      this._completeWithWatchState(
                        GeolocationWatchState.Watching,
                        subscriber,
                      );
                    },
                  );
                }
              }
            }
          });
      }
    });
  }

  startTrackingLocation(): Observable<GeolocationWatchState> {
    if (!!this.watchId) {
      return of(GeolocationWatchState.Watching);
    }
    return new Observable((subscriber) => {
      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          this.setState(this._gMapPositionToLocation(position));
          this._completeWithWatchState(
            GeolocationWatchState.Watching,
            subscriber,
          );
        },
        (err) => {
          if (err.code === err.PERMISSION_DENIED) {
            this._completeWithWatchState(
              GeolocationWatchState.PermissionDenied,
              subscriber,
            );
            this.watchId = undefined;
          } else if (err.code === err.TIMEOUT) {
            // try again in low accuracy mode
            this.watchId = navigator.geolocation.watchPosition(
              (position) => {
                this.setState(this._gMapPositionToLocation(position));
                this._completeWithWatchState(
                  GeolocationWatchState.Watching,
                  subscriber,
                );
              },
              () => {
                this._completeWithWatchState(
                  GeolocationWatchState.NoSignal,
                  subscriber,
                );
              },
              {
                maximumAge: this.maximumAge,
                enableHighAccuracy: false,
                timeout: this.timeout,
              },
            );
          } else {
            this._completeWithWatchState(
              GeolocationWatchState.NoSignal,
              subscriber,
            );
          }
        },
        {
          maximumAge: this.maximumAge,
          enableHighAccuracy: true,
          timeout: this.timeout,
        },
      );
    });
  }

  private _gMapPositionToLocation(position: any): PL2.Location {
    return {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
  }

  private _completeWithWatchState(
    watchState: GeolocationWatchState,
    subscriber: Subscriber<GeolocationWatchState>,
  ) {
    subscriber.next(watchState);
    subscriber.complete();
  }
}
