import { NotificationConfigurator } from './notification-configurator.js';
export class CharacteristicSensorCoordinator {
    constructor(sensorConfigurator, deviceConfig, characteristicCache) {
        this.sensorConfigurator = sensorConfigurator;
        this.deviceConfig = deviceConfig;
        this.characteristicCache = characteristicCache;
        this._sensorRegistry = new Set();
        this._notificationConfigurator = new NotificationConfigurator(this.characteristicCache);
    }
    // The opts are thus so we can trigger trials that aren't in the uuids frequency list such as in
    // memory trials that run slower than 1 hz
    enableSensors(sensorIds, frequencyIndex) {
        console.log('enableSensors()');
        console.log('Registry state pre-config:');
        console.log(Array.from(this._sensorRegistry).join(', '));
        console.log('New indicies:');
        console.log(sensorIds);
        console.log('Frequency index:');
        console.log(frequencyIndex);
        let promise = Promise.resolve([]);
        let running = Array.from(this._sensorRegistry);
        running.forEach((runningId) => {
            promise = promise.then(() => {
                if (!sensorIds.includes(runningId)) {
                    return this.sensorConfigurator
                        .configureSensor(runningId, false)
                        .then(() => {
                        this._sensorRegistry.delete(runningId);
                        console.log('Removed config from sensorRegistry:');
                        console.log(runningId);
                        return [];
                    });
                }
                else {
                    return Promise.resolve([]);
                }
            });
        });
        running = Array.from(this._sensorRegistry);
        const enabledIds = new Array();
        sensorIds.forEach((sensorId) => {
            promise = promise.then(() => {
                if (!this._sensorRegistry.has(sensorId)) {
                    return this.sensorConfigurator
                        .startSensor(sensorId, frequencyIndex)
                        .then(() => {
                        this._sensorRegistry.add(sensorId);
                        enabledIds.push(sensorId);
                        return enabledIds;
                    });
                }
                else {
                    return Promise.resolve(enabledIds);
                }
            });
        });
        return promise;
    }
    stopAllSensors() {
        console.log('stopAllSensors()');
        console.log('Registry state pre-config:');
        console.log(Array.from(this._sensorRegistry).join(', '));
        let promise = Promise.resolve();
        Object.keys(this.deviceConfig.sensorConfigs).forEach((sensorId) => {
            promise = promise
                .then(() => this.sensorConfigurator.configureSensor(sensorId, false))
                .then(() => this._sensorRegistry.delete(sensorId));
        });
        return promise;
    }
    stopAllNotifications() {
        return this._notificationConfigurator.stopAllNotifications(this.deviceConfig);
    }
    startSensors(sensorIds, frequencyIndex) {
        const uniqueSensorIds = [...new Set(sensorIds)];
        return this._notificationConfigurator
            .stopAllNotifications(this.deviceConfig)
            .then(() => this.stopAllSensors())
            .then(() => this.enableSensors(uniqueSensorIds, frequencyIndex))
            .then(() => this._notificationConfigurator.enableNotifications(uniqueSensorIds));
    }
    _stopSensors() {
        return this.enableSensors([]);
    }
}
