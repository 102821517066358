import { ValidatorFactory, } from './validator-core.js';
import { DrawingObjectValidatorFactory } from './drawing-object-validator-factory.js';
import { NotatableSectionEntryValidatorFactory } from './notatable-section-entry-validator-factory.js';
import { ExistsValidator } from './exists-validator.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class SharedDrawingSectionEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory, objectFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
        this.objectFactory = objectFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.sharedDrawingSectionEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'cA':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'sca':
            case 'h':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'bg':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'dat':
                if (U.isEmpty(value)) {
                    return [new ExistsValidator(value, this.builderFactory(attr))];
                }
                return Object.keys(value).map((key) => {
                    const dO = value[key];
                    if (U.isEmpty(dO)) {
                        return new LenientValidator(dO, this.builderFactory(key));
                    }
                    return this.objectFactory(dO, new DrawingObjectValidatorFactory(this.builderFactory));
                });
            default:
                return new NotatableSectionEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
    }
}
