import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CharacteristicCache } from './characteristic-cache.js';
import { CharacteristicDataProcessorBuilder } from './characteristic-data-processor-builder.js';
import { PrePowerlabDeviceConfigurator } from './pre-powerlab-device-configurator.js';
import { ThermoCommandBuilder } from './thermo-command-builder.js';
import { CommandSensorCoordinator } from './command-sensor-coordinator.js';
import { connectionStateDefault, } from './connection-types.js';
export class ThermoStrategy {
    constructor(gattServer, deviceConfig) {
        this.deviceConfig = deviceConfig;
        this.rateInstruction = 0x57;
        this._processorMap = {
            pl4tempprobe: (value) => this._processValue(value),
            pl42tempprobe: (value) => this._processValue(value),
        };
        this._characteristicCache = new CharacteristicCache(gattServer, deviceConfig);
        this._sensorCoordinator = new CommandSensorCoordinator(deviceConfig, this._characteristicCache, new ThermoCommandBuilder());
        this._deviceConfigurator = new PrePowerlabDeviceConfigurator(this._characteristicCache, deviceConfig);
    }
    init() {
        return Promise.resolve({
            ...connectionStateDefault,
            barCoefficients: [],
            deviceConfig: this.deviceConfig,
        });
    }
    startSensors(params) {
        const builder = new CharacteristicDataProcessorBuilder(this._processorMap, this.deviceConfig.frequencyConfigs[params.frequencyIndex]?.f ?? 1);
        return from(this._sensorCoordinator.startSensors(params.sensorIds, params.frequencyIndex)).pipe(mergeMap((dCs) => builder.build(dCs)));
    }
    calibrate(sensorId) {
        console.warn(`Thermo calibration does nothing: ${sensorId}`);
        return Promise.resolve({});
    }
    memoryConfigurator() {
        return null;
    }
    deviceConfigurator() {
        return this._deviceConfigurator;
    }
    crashModeConfigurator() {
        return null;
    }
    disconnect() {
        // do nothing
    }
    _processValue(value) {
        const v = new Int8Array(value);
        const sensorReadings = 2;
        const buffer = new ArrayBuffer(8);
        const view = new DataView(buffer);
        const tempProbes = [0, 0];
        for (let dataIndex = 0; dataIndex < sensorReadings * 9; dataIndex = dataIndex + 9) {
            switch (v[dataIndex]) {
                case 0x00:
                    break;
                case 0x01:
                    break;
                case 0x04:
                    for (let i = 7; i >= 0; i--) {
                        view.setUint8(7 - i, v[dataIndex + i + 1]);
                    }
                    tempProbes[0] = view.getFloat64(0);
                    break;
                case 0x05:
                    for (let i = 7; i >= 0; i--) {
                        view.setUint8(7 - i, v[dataIndex + i + 1]);
                    }
                    tempProbes[1] = view.getFloat64(0);
                    break;
            }
        }
        return tempProbes;
    }
}
