import { all, any, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { SharedSectionAuthPolicy } from './shared-section-auth-policy.js';
export class SharedDataTableSectionAuthPolicy extends SharedSectionAuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return super.canCreate(entry);
    }
    async canRead(entry) {
        return super.canRead(entry);
    }
    async canUpdate(entry) {
        return any(all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry, 'n'), this.userIsParticipantInAuthScope), all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry), this.userIsCreatorOf(entry)))();
    }
    async canDelete(entry) {
        return super.canDelete(entry);
    }
}
