<div class='container flex-column flex'>
  <div class='collapsed flex-row'
    (click)='expandSelected.emit()'>
    <div class='flex'></div>
    <div
      class='bar log flex-40px'
      #tooltip='matTooltip'
      [matTooltip]='l.payload'
      matTooltipClass='pre'
      matTooltipHideDelay='1000'
      matTooltipShowDelay='100'
      [ngClass]='l.status'
      *ngFor='let l of output'></div>
  </div>
  <div
    class='top-line flex-row'>
    <div>User: {{ userId  }}</div>
    <div>Num entries: {{ numEntries }}</div>
    <div class='flex'></div>
    <button mat-icon-button
      class='delete'
      (click)='clearLocalStorage()'>
      <mat-icon svgIcon='delete'></mat-icon>
    </button>
    <button mat-icon-button
      class='report'
      [ngClass]='{"zeroed": maxErrorsZeroed}'
      (click)='reportAllErrors()'>
      <mat-icon svgIcon='speed'></mat-icon>
    </button>
    <button mat-icon-button
      class='export'
      (click)='exportLogs()'>
      <mat-icon svgIcon='file_download'></mat-icon>
    </button>
    <button mat-icon-button
      class='close'
      attr.aria-label='{{ "common.close" | translate }}'
      (click)='close()'>
      <mat-icon svgIcon='close'></mat-icon>
    </button>
  </div>
  <div class='flex'>
    <div
      class='line log flex-row'
      [ngClass]='l.status'
      *ngFor='let l of output'>
      <div>{{ l.pendingOperation }}</div>
      <div class='details'>{{ l.pK }} - {{ l.name }}</div>
      <div class='flex'></div>
      <div>{{ l.dateTime }}</div>
      <mat-icon (click)='show(l.pK)'
        svgIcon='present_to_all'></mat-icon>
    </div>
  </div>
  <div class='command-input flex-36px flex-row'
    *ngIf='showCommandInput'>
    <input class='flex' matInput type='text'
      [(ngModel)]='command'>
    <button mat-button
      (click)='writeCommand()'>
      Write
    </button>
    <button mat-button
      (click)='readCommand()'>
      Read
    </button>
  </div>
  <div class='command-output flex-36px'
    *ngIf='commandOutput'>
    {{ commandOutput }}
  </div>
</div>
