import {CachedEntry, EntryState} from '@stores/entry.store';
import {ErrorReportStore} from '../error-report.store';

import {PendingOperation} from './operation-state-machine';

import {
  PL2,
  UtilsPL2 as U,
  KeyUtilsPL2 as KU,
} from '@common/utils/dist/index.js';
import {APIError} from '@utils/api-errors';
import {EntryStoreUtils as ESU} from '@utils/entry-store-utils';

import {ErrorReportCode} from '@constants/app.config';

export interface ModificationStrategy {
  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
    err?: APIError.Error,
  ): PendingOperation;
}

export class NoModificationStrategy {
  constructor(protected next: PendingOperation) {}

  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey(change);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    draft[pK]._pO = this.next;
    // console.log(`[NoModificationStrategy] ${JSON.stringify(draft[pK])}`);
    return this.next;
  }
}

class HandleCreateStrategy implements ModificationStrategy {
  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey({mId: change.mId, sK: change.sK});
    draft[pK] = {c: [], e: change, _ts: null, _pO: null};
    const pPK = KU.parentPK(pK);
    const pCE = draft[pPK];
    if (pCE && !pCE.c.includes(pK)) {
      pCE.c.push(pK);
    }
    return null;
  }
}

export class CreateStrategy extends HandleCreateStrategy {
  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    super.modify(change, draft);
    const pK = KU.stringFromKey({mId: change.mId, sK: change.sK});
    draft[pK]._pO = PendingOperation.Create;
    return draft[pK]._pO;
  }
}

export class DelayCreateContainerStrategy extends HandleCreateStrategy {
  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    super.modify(change, draft);
    const pK = KU.stringFromKey({mId: change.mId, sK: change.sK});
    draft[pK]._pO = PendingOperation.DelayCreateContainer;
    return draft[pK]._pO;
  }
}

export class UpdateStrategy implements ModificationStrategy {
  constructor(private next: PendingOperation) {}

  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey({mId: change.mId, sK: change.sK});
    // console.debug('[UpdateStrategy] current state', draft[pK]?._pO);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    ESU.merge(draft[pK].e, change);
    // console.debug('[UpdateStrategy] payload', JSON.stringify(draft[pK]?._payload));
    if (!U.isEmpty(draft[pK]._payload)) {
      const updateIdx = draft[pK]._payload.length - 1;
      const merged = draft[pK]._payload;
      ESU.merge(merged[updateIdx], change);
      draft[pK]._payload = merged;
      // console.debug(`[UpdateStrategy]merged payload: ${JSON.stringify(draft[pK]._payload)}`);
    } else {
      draft[pK]._payload = [change];
    }
    draft[pK]._ts = String(new Date().getTime());
    // console.debug('[UpdateStrategy] updated entry', JSON.stringify(draft[pK].e));
    // console.debug('[UpdateStrategy] updated payload', JSON.stringify(draft[pK]?._payload));
    // console.debug('[UpdateStrategy] next state', this.next);
    draft[pK]._pO = this.next;
    return this.next;
  }
}

export class ReceiveUpdateStrategy implements ModificationStrategy {
  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    // console.info('update received');
    const pK = KU.stringFromKey({mId: change.mId, sK: change.sK});
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    ESU.merge(draft[pK].e, change);
    if (!U.isEmpty(draft[pK]._payload)) {
      const idx = draft[pK]._payload.length - 1;
      const attrs = Object.getOwnPropertyNames(draft[pK]._payload[idx]);
      ESU.merge(draft[pK]._payload[idx], change);
      draft[pK]._payload[idx] = U.pick(draft[pK]._payload[idx], ...attrs);
      // console.info(`payload after entryreceived: ${JSON.stringify(draft[pK]._payload[idx])}`);
    }
    return null;
  }
}

export class ReceiveDeleteStrategy implements ModificationStrategy {
  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey(change);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    const strategy = new SomeDeleteStrategy(PendingOperation.None);
    const next = strategy.modify(change, draft);
    draft[pK]._payload = [];
    return next;
  }
}

export class SomeDeleteStrategy implements ModificationStrategy {
  constructor(private next: PendingOperation) {}

  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey(change);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    const pPK = KU.parentPK(pK);
    const pCE = draft[pPK];
    if (pCE) {
      const idx = pCE.c.findIndex((c) => c === pK);
      if (idx >= 0) {
        pCE.c.splice(idx, 1);
      }
    }
    this._recursiveDelete(pK, draft);
    draft[pK]._pO = this.next;
    return this.next;
  }

  private _recursiveDelete(pK: string, draft: EntryState) {
    if (!draft[pK]) {
      return;
    }
    const children = draft[pK].c;
    draft[pK]._pO = PendingOperation.None;
    delete draft[pK].e.isA;
    children.forEach((cPK) => this._recursiveDelete(cPK, draft));
  }
}

export class SomeUndoStrategy implements ModificationStrategy {
  constructor(private next: PendingOperation) {}
  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey(change);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    const pPK = KU.parentPK(pK);
    const pCE = draft[pPK];
    if (pCE && !pCE.c.includes(pK)) {
      pCE.c.push(pK);
    }
    draft[pK]._pO = this.next;
    this._recursiveUndo(pK, draft);
    return draft[pK]._pO;
  }

  private _recursiveUndo(pK: string, draft: EntryState) {
    if (!draft[pK]) {
      return;
    }
    const children = draft[pK].c;
    draft[pK].e.isA = 'y';
    children.forEach((cPK) => this._recursiveUndo(cPK, draft));
  }
}

export class SomeUpdateStrategy implements ModificationStrategy {
  constructor(private next?: PendingOperation) {}

  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey(change);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    draft[pK]._payload = draft[pK]._payload || [];
    // console.debug('[SomeUpdateStrategy] payload', JSON.stringify(draft[pK]._payload));
    draft[pK]._payload.shift();
    if (
      U.isEmpty(draft[pK]._payload) ||
      (draft[pK]._payload.length === 1 && KU.isKeyLike(draft[pK]._payload[0]))
    ) {
      draft[pK]._payload = [];
      draft[pK]._pO = this.next ?? PendingOperation.None;
    } else {
      draft[pK]._payload = [this._merge(draft[pK]._payload)];
      draft[pK]._pO = this.next ?? PendingOperation.Update;
    }
    return draft[pK]._pO;
  }

  private _merge(
    changes: Array<PL2.AnyPartialEntryWithId>,
  ): PL2.AnyPartialEntryWithId {
    return changes.reduce((p, c) => {
      ESU.merge(p, c);
      return p;
    });
  }
}

export class UpdateSentStrategy implements ModificationStrategy {
  modify(param: PL2.AnyPartialEntryWithId, draft: EntryState) {
    const pK = KU.stringFromKey(param);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    // console.info('stashUpdate');
    draft[pK]._pO = PendingOperation.UpdateSent;
    draft[pK]._payload.push(KU.pKFromString(pK));
    return draft[pK]._pO;
  }
}

class HandleUpdateFailedStrategy implements ModificationStrategy {
  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
    err: APIError.Error,
  ): PendingOperation {
    const pK = KU.stringFromKey(entry);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    draft[pK]._payload = !U.isEmpty(draft[pK]._payload)
      ? [this._merge(draft[pK]._payload)]
      : [];
    // draft[pK]._ts = String(new Date().getTime());
    // draft[pK]._pO = PendingOperation.Update;
    draft[pK]._err = err;
    return PendingOperation.Update;
  }

  private _merge(
    changes: Array<PL2.AnyPartialEntryWithId>,
  ): PL2.AnyPartialEntryWithId {
    return changes.reduce((p, c) => {
      ESU.merge(p, c);
      return p;
    });
  }
}

export class UpdateFailedStrategy extends HandleUpdateFailedStrategy {
  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
    err: APIError.Error,
  ): PendingOperation {
    const pK = KU.stringFromKey(entry);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    super.modify(entry, draft, err);
    draft[pK]._pO = PendingOperation.UpdateFailed;
    draft[pK]._ts = String(new Date().getTime());
    return draft[pK]._pO;
  }
}

export class UndoableDeleteFromUpdateFailedStrategy extends HandleUpdateFailedStrategy {
  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
    err: APIError.Error,
  ): PendingOperation {
    const pK = KU.stringFromKey(entry);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    super.modify(entry, draft, err);
    draft[pK]._pO = PendingOperation.UndoableDeleteFromUpdate;
    return draft[pK]._pO;
  }
}

export class DeleteAfterUpdateFailedStrategy extends HandleUpdateFailedStrategy {
  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
    err: APIError.Error,
  ): PendingOperation {
    const pK = KU.stringFromKey(entry);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    super.modify(entry, draft, err);
    draft[pK]._pO = PendingOperation.Delete;
    return draft[pK]._pO;
  }
}

export class UpdateFailedAgainStrategy extends HandleUpdateFailedStrategy {
  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
    err: APIError.Error,
  ): PendingOperation {
    const n = super.modify(entry, draft, err);
    if (n === null) {
      return null;
    }
    const pK = KU.stringFromKey(entry);
    draft[pK]._pO = PendingOperation.UpdateFailed;
    return draft[pK]._pO;
  }
}

export class ReceiveCreateStrategy implements ModificationStrategy {
  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey({mId: change.mId, sK: change.sK});
    draft[pK] = {c: [], e: change, _ts: null, _pO: PendingOperation.None};
    const pPK = KU.parentPK(pK);
    const pCE = draft[pPK];
    if (pCE && !pCE.c.includes(pK)) {
      pCE.c.push(pK);
    }
    return PendingOperation.None;
  }
}

// export class DeleteFailedStrategy implements ModificationStrategy {
//   modify(entry: PL2.AnyPartialEntryWithId, draft: EntryState): PendingOperation {
//     const pK = KU.stringFromKey(entry);
//     if (U.isEmpty(draft[pK])) {
//       return null;
//     }
//     draft[pK]._ts = String(new Date().getTime());
//     draft[pK]._pO = PendingOperation.DeleteFailed;
//     return PendingOperation.DeleteFailed;
//   }
// }

export class MissingTransitionStrategy implements ModificationStrategy {
  constructor(
    private current: PendingOperation,
    private input: PendingOperation,
    private errorReportStore: ErrorReportStore,
  ) {}

  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey(entry);
    const msg = `Invalid transition for entry ${pK}: ${this.current} > ${this.input}`;
    this.errorReportStore.addReport({
      code: ErrorReportCode.MissingTransition,
      message: msg,
      entries: Object.keys(draft).filter((cPK) => cPK.startsWith(pK)),
    });
    console.warn(msg);
    return null as any;
  }
}

// export class CreateFailedStrategy {
//   modify(entry: PL2.AnyPartialEntryWithId, draft: EntryState): PendingOperation {
//     const pK = KU.stringFromKey(entry);
//     if (U.isEmpty(draft[pK])) {
//       return null;
//     }
//     draft[pK]._ts = String(new Date().getTime());
//     draft[pK]._pO = PendingOperation.CreateFailed;
//     return PendingOperation.CreateFailed;
//   }

class HandleFailedAfterSentStrategy implements ModificationStrategy {
  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
    err: APIError.Error,
  ): PendingOperation {
    const pK = KU.stringFromKey(entry);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    draft[pK]._ts = String(new Date().getTime());
    draft[pK]._err = err;
    return draft[pK]._pO;
  }
}

export class CreateFailedAfterSent extends HandleFailedAfterSentStrategy {
  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
    err: APIError.Error,
  ): PendingOperation {
    const pK = KU.stringFromKey(entry);
    const n = super.modify(entry, draft, err);
    if (n === null) {
      return null;
    }
    return (draft[pK]._pO = PendingOperation.CreateFailed);
  }
}

export class DeleteFailedAfterSent extends HandleFailedAfterSentStrategy {
  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
    err: APIError.Error,
  ): PendingOperation {
    const pK = KU.stringFromKey(entry);
    const n = super.modify(entry, draft, err);
    if (n === null) {
      return null;
    }
    return (draft[pK]._pO = PendingOperation.DeleteFailed);
  }
}

export class IgnoreStrategy implements ModificationStrategy {
  constructor() {}

  modify(
    entry: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey(entry);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    draft[pK]._pO = PendingOperation.Ignore;
    return PendingOperation.Ignore;
  }
}

export class CreateSentStrategy implements ModificationStrategy {
  modify(param: PL2.AnyPartialEntryWithId, draft: EntryState) {
    // console.debug('[CreateSentStrategy]');
    const pK = KU.stringFromKey(param);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    const id = KU.pKFromString(pK);
    draft[pK]._pO = PendingOperation.CreateSent;
    draft[pK]._payload = draft[pK]._payload ?? [id];

    /*
     * We need to push an 'empty' update(two if the payload is empty) to be consistent with what other
     * strategies are doing.
     * if any updates are received while in CreateSent they will be accumulated
     * in the last payload entry.
     * If no update is received the payload will be removed by SomeUpdateStrategy and
     * no pending updates will remain.
     */
    draft[pK]._payload.push(id);
    return draft[pK]._pO;
  }
}

export class ActivateStrategy implements ModificationStrategy {
  modify(
    change: PL2.AnyPartialEntryWithId,
    draft: EntryState,
  ): PendingOperation {
    const pK = KU.stringFromKey(change);
    if (U.isEmpty(draft[pK])) {
      return null;
    }
    draft[pK].e.isA = 'y';
    draft[pK]._pO = PendingOperation.None;
    return draft[pK]._pO;
  }
}
