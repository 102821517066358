import { any, all, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class FeedbackAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
        this.fetchEntry = fetchEntry;
    }
    canCreate(entry) {
        return any(this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */), all(this.tleIsNotReadOnly, this.userIsSetAsCreatorFor(entry), this.userIsPrivilegedParticipantInAuthScope))();
    }
    canRead(_entry) {
        return any(this.tleIsPublic, this.userIsParticipantInAuthScope)();
    }
    canUpdate(entry) {
        return all(hasNoBlacklistedAttsIn(entry), this.tleIsNotReadOnly, this.userIsPrivilegedParticipantInAuthScope)();
    }
    canDelete(_entry) {
        return all(this.tleIsNotReadOnly, this.userIsPrivilegedParticipantInAuthScope)();
    }
}
