import {Component, OnInit, Inject} from '@angular/core';

import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'dialog-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent implements OnInit {
  title: string;
  content: string;
  hideOkay: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {title: string; content: string; hideOkay: boolean},
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
    this.hideOkay = this.data.hideOkay;
  }
}
