import { PH_CAL, RESET_CALIBRATION, RUN_PROBE_CAL, } from './odyssey-command-codes.js';
import { ENABLE_FLASH, ENABLE_FLASH_KEY, OFF, ON, ON_OFF_POLLING, WRITE_FLASH, WRITE_FLASH_KEY, } from './pl-command-codes.js';
export class ImuCalibratorStrategy {
    async calibrate(commandQueue, calibrationId) {
        switch (calibrationId) {
            case 'imuv2_tear':
                return commandQueue.addWithoutValidation([0x48, 0x01]);
            case 'imuv2_iron':
                return commandQueue.addWithoutValidation([0x48, 0xf6]);
            default:
                console.warn(`Powerlab calibration feature does nothing: ${calibrationId}`);
                return Promise.resolve();
        }
    }
}
export class AltitudeCalibratorStrategy {
    async calibrate(commandQueue, _calibrationId, payload) {
        const v = new DataView(new Float32Array(payload).buffer);
        return commandQueue.addWithoutValidation([
            0x56,
            v.getUint8(3),
            v.getUint8(2),
            v.getUint8(1),
            v.getUint8(0),
        ]);
    }
}
/*
export class DisplacementCalibratorStrategy
  implements PowerlabCalibratorStrategy
{
  async calibrate(commandQueue: CommandQueue, _feature: number): Promise<void> {
    return commandQueue.addWithoutValidation([0x65]);
  }
}
*/
export class OrientationCalibratorStrategy {
    async calibrate(commandQueue, _calibrationId) {
        return commandQueue.addWithoutValidation([0xf6]);
    }
}
export class RangefinderCalibratorStrategy {
    async calibrate(commandQueue, calibrationId) {
        switch (calibrationId) {
            case 'a54_1':
                return commandQueue.addWithoutValidation([0x54, 0x01]);
            case 'a54_2':
                return commandQueue.addWithoutValidation([0x54, 0x02]);
            case 'a54_3':
                return commandQueue.addWithoutValidation([0x54, 0x03]);
            case 'a54_4':
                return commandQueue.addWithoutValidation([0x54, 0x04]);
            case 'a54_5':
                return commandQueue.addWithoutValidation([0x54, 0x05]);
            case 'a54_6':
                return commandQueue.addWithoutValidation([0x54, 0x06]);
            case 'a54_7':
                return commandQueue.addWithoutValidation([0x54, 0x07]);
            default:
                console.warn(`Powerlab calibration feature does nothing: ${calibrationId}`);
                return Promise.resolve();
        }
    }
}
export class PhCalibratorStrategy {
    async calibrate(commandQueue, calibrationId, payload) {
        const delay = 200;
        payload = payload?.map((v) => Math.round(v * 1000));
        switch (calibrationId) {
            case 'ph_click':
                return commandQueue.addWithoutValidation([RUN_PROBE_CAL << 1, PH_CAL, RESET_CALIBRATION], delay);
            case 'ph_probe_1':
                return commandQueue.addWithoutValidation([RUN_PROBE_CAL << 1, PH_CAL, 0x01], delay, payload);
            case 'ph_probe_2':
                return commandQueue.addWithoutValidation([RUN_PROBE_CAL << 1, PH_CAL, 0x02], delay, payload);
            case 'ph_probe_3':
                commandQueue.addWithoutValidation([RUN_PROBE_CAL << 1, PH_CAL, 0x03], delay, payload);
                // stops polling
                commandQueue.addWithoutValidation([ON_OFF_POLLING << 1, OFF]);
                // enables flash
                commandQueue.addWithoutValidation([
                    ENABLE_FLASH << 1,
                    ENABLE_FLASH_KEY,
                ]);
                // write flash
                commandQueue.addWithoutValidation([WRITE_FLASH << 1, WRITE_FLASH_KEY]);
                // stops polling
                // commandQueue.addWithoutValidation([0x30 << 1, 0x00]);
                // write read enable flash command
                commandQueue
                    .addReadWithoutValidation(ENABLE_FLASH << 1)
                    .then((data) => {
                    if (data.getUint8(1) !== 0x01) {
                        console.error('Failed to write to flash');
                    }
                });
                // starts polling
                return commandQueue.addWithoutValidation([ON_OFF_POLLING << 1, ON]);
            default:
                console.warn(`Powerlab calibration feature does nothing: ${calibrationId}`);
                return Promise.resolve();
        }
    }
}
