import {Injectable} from '@angular/core';

@Injectable()
export class CSVSerializerService {
  constructor() {}

  serialize<T>(
    headers: Array<string>,
    keys: Array<string>,
    data: Array<T>,
  ): string {
    let serialized = '';
    const newLine = '\n';
    serialized =
      headers.reduce((prev, cur) => (prev ? `${prev}, ${cur}` : cur), '') +
      newLine;

    (data || []).forEach((datum) => {
      keys.forEach(
        (key, i) => (serialized += i === 0 ? datum[key] : ', ' + datum[key]),
      );
      serialized += newLine;
    });
    return serialized;
  }
}
