import produce from 'immer';

import {
  SensorMessage,
  MessageType,
  SensorReading,
  ProcessorStatus,
} from '@common/ble/dist/index.js';

import {StateTransformer} from '../abstract-store';

import {PL2, UtilsPL2 as U} from '@common/utils/dist/index.js';

import {SensorMapperUtils as SMU} from '@utils/sensor-mapper-utils';

import {GraphConfigUtils as GCU} from '@utils/graph-config-utils';
import {DataConfigStateUtils as DCSU} from '@utils/data-config-state-utils';

export class MemoryInferenceDataConfigStep
  implements StateTransformer<SensorMessage[], PL2.DataConfigState>
{
  constructor(private translations: {[key: string]: string}) {}

  transform(
    sensorMessage: SensorMessage[],
    dataConfigState: PL2.DataConfigState,
  ): PL2.DataConfigState {
    if (U.isEmpty(sensorMessage)) {
      return dataConfigState;
    }
    if (sensorMessage[0].type !== MessageType.Reading) {
      return dataConfigState;
    }
    const readings = <SensorReading[]>sensorMessage;
    if (
      readings[0].status ===
      ProcessorStatus.MemoryDataProcessingWithUserSpaceData
    ) {
      return dataConfigState;
    }
    const gCIds = SMU.getGraphConfigIdsForReadings(readings);
    const newGCIds = gCIds.filter(
      (gCId) =>
        !GCU.configWithId(gCId)?.underDevelopment &&
        U.isEmpty(dataConfigState[gCId]),
    );
    if (newGCIds.length === 0) {
      return dataConfigState;
    }
    const opts = newGCIds.reduce((acc, gCId) => {
      acc[gCId] = {
        f: readings[0].frequency,
        rT: false,
        dS: PL2.DataStatus.Setup,
        cT: 0,
      };
      return acc;
    }, {});
    return produce(dataConfigState, (draft) => {
      DCSU.buildDataConfigs(opts, draft, this.translations);
    });
  }
}
