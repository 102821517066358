import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { ValidatorFactory, } from './validator-core.js';
import { LenientValidator } from './lenient-validator.js';
import { EntryValidatorFactory } from './entry-validator-factory.js';
import { AuthIdxEntryValidatorFactory } from './auth-idx-entry-validator-factory.js';
import { ClassIdxEntryValidatorFactory } from './class-idx-entry-validator-factory.js';
import { SortableEntryValidatorFactory } from './sortable-entry-validator-factory.js';
import { TleMetadataEntryValidatorFactory } from './tle-metadata-entry-validator-factory.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { IsEmptyValidator } from './is-empty-validator.js';
import { IsNonEmptyValidator } from './is-non-empty-validator.js';
export class CourseEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.courseEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'isP':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'n':
                return [
                    new IsNonEmptyValidator(value, this.builderFactory(attr)),
                ];
            case 'pI':
            case 'uLEId':
            case 'sMId':
            case 'sSK':
            case 'scC':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'chk':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            default:
                if (EU.entryAtts.includes(attr)) {
                    return new EntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.authIdxEntryAtts.includes(attr)) {
                    return new AuthIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.tleMetadataAtts.includes(attr)) {
                    return new TleMetadataEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.sortableEntryAtts.includes(attr)) {
                    return new SortableEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else {
                    return new ClassIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
        }
    }
    getMultiAttributeValidators(o) {
        if (U.isEmpty(o.sK) || U.isEmpty(KU.parentSortKey(o.sK))) {
            return [
                new IsNonEmptyValidator(o.uLEId, this.builderFactory('uLEId')),
            ];
        }
        else {
            return [
                new IsEmptyValidator(o.uLEId, this.builderFactory('uLEId')),
            ];
        }
    }
}
