import { any, all } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class ScopeAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return any(this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */), all(this.userIsSetAsCreatorFor(entry), this.userIsPrivilegedParticipantInAuthScope))();
    }
    async canRead(_entry) {
        return any(this.userIsParticipantInAuthScope, this.user?.auth?.lesson?.canRead)();
    }
    async canUpdate(_entry) {
        return false;
    }
    async canDelete(_entry) {
        return this.userIsPrivilegedParticipantInAuthScope;
    }
}
