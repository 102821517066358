import { ServiceConfigs } from './service.config.js';
import { DeviceConfigs } from './device.config.js';
import { VoyagerStrategy } from './voyager-strategy.js';
import { AirStrategy } from './air-strategy.js';
import { ThermoStrategy } from './thermo-strategy.js';
import { PlStrategy } from './pl-strategy.js';
import { PowerlabStrategy } from './powerlab-strategy.js';
import { DeviceConfigUtils as DCU, isEmpty } from './device-config-utils.js';
export class ConnectionDetectionStrategyChain {
    async detect(server) {
        const services = await server.getPrimaryServices();
        for (const strategy of [
            new BmeCheckStrategy(),
            new FirmwareCheckStrategy(),
            new ServiceCheckStrategy(),
        ]) {
            const connectionStrategy = await strategy.detect(services, server);
            if (!isEmpty(connectionStrategy)) {
                return connectionStrategy;
            }
        }
        return null;
    }
}
class BmeCheckStrategy {
    async detect(services, server) {
        const bmeService = services.find((s) => s.uuid === ServiceConfigs.bme280.service);
        // Can't use isEmpty because it does something weird in Safari/iOS webview
        if (!!bmeService) {
            if (services.length === 1) {
                return new AirStrategy(server, DCU.configWithId('air'));
            }
            const characteristics = await bmeService.getCharacteristics();
            if (characteristics.length === 10) {
                console.log('Connected to device of type Voyager');
                return new VoyagerStrategy(server, DCU.configWithId('voyager'));
            }
            else {
                console.log('Connected to device of type Weather');
                return new VoyagerStrategy(server, DCU.configWithId('weather'));
            }
        }
        else {
            console.log('No BME service');
            return null;
        }
    }
}
class FirmwareCheckStrategy {
    async detect(services, server) {
        const dcs = [
            'air0000',
            'air8e40',
            'aircdea',
            'aircdea2',
            'pl4',
            'pl42',
        ].map((deviceId) => DCU.configWithId(deviceId));
        const deviceConfig = dcs.find((dc) => Object.values(dc.sensorConfigs).find((sc) => services.find((s) => s.uuid === sc.service)));
        if (!isEmpty(deviceConfig)) {
            const deviceInformation = services.find((s) => s.uuid === ServiceConfigs.firmwareinfo.service);
            const c = await deviceInformation.getCharacteristic(ServiceConfigs.firmwareinfo.config);
            const fwBytes = await c.readValue();
            const fw = DCU.fwBytesToString(fwBytes);
            if (deviceConfig.name === 'Air') {
                return new AirStrategy(server, DCU.isNewAir(fw)
                    ? DCU.configWithId('aircdea2')
                    : DCU.configWithId('aircdea'));
            }
            else if (deviceConfig.name === 'Thermo') {
                return new ThermoStrategy(server, DCU.isNewThermo(fw)
                    ? DCU.configWithId('pl42')
                    : DCU.configWithId('pl4'));
            }
        }
        else {
            return null;
        }
    }
}
class ServiceCheckStrategy {
    async detect(services, server) {
        let deviceConfig;
        services.some((service) => {
            deviceConfig = DeviceConfigs.find((dv) => {
                const sensorConfigs = Object.values(dv.sensorConfigs);
                return sensorConfigs.find((sc) => sc.service === service.uuid);
            });
            return !!deviceConfig;
        });
        switch (deviceConfig.name) {
            case 'PL1':
                return new PlStrategy(server, deviceConfig);
            case 'G-Force':
            case 'Voyager 2':
            case 'Weather 2':
            case 'Odyssey':
            case 'Thermo 2.1':
                console.log('Device name', deviceConfig.name);
                return new PowerlabStrategy(server, deviceConfig);
            default:
                throw Error("Can't connect to this weird device");
        }
    }
}
