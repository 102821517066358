import { ValidatorFactory, } from './validator-core.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { LocalSubsetEntryValidatorFactory } from './local-subset-entry-validator-factory.js';
import { ClassIdxEntryValidatorFactory } from './class-idx-entry-validator-factory.js';
export class ClassLocalSubsetEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.classLocalSubsetEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        if (EU.localSubsetEntryAtts.includes(attr)) {
            return new LocalSubsetEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
        else {
            return new ClassIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
    }
}
