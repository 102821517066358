export class LengthValidator {
    constructor(v, constraints, reasonBuilder) {
        this.v = v;
        this.constraints = constraints;
        this.reasonBuilder = reasonBuilder;
        this.causes = [];
    }
    isValid() {
        if (this.v === undefined || this.v === null) {
            const acceptNullish = this.constraints.acceptNullish ?? true;
            if (!acceptNullish) {
                this.causes.push(this.reasonBuilder.build(`value is ${this.v}`));
            }
            return acceptNullish;
        }
        const minLength = this.constraints.minLength ?? -Infinity;
        const maxLength = this.constraints.maxLength ?? Infinity;
        const smaller = this.v.length < minLength;
        const bigger = this.v.length > maxLength;
        if (smaller) {
            this.causes.push(this.reasonBuilder.build('smaller'));
        }
        if (bigger) {
            this.causes.push(this.reasonBuilder.build('bigger'));
        }
        return !smaller && !bigger;
    }
    reasons() {
        return this.causes;
    }
}
