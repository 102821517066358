import { ProcessorStatus, MessageType, } from './connection-types.js';
import { ConnectionStrategyUtils as CSU } from './connection-strategy-utils.js';
class EncoderPacketProcessorStep {
    constructor(deviceConfig, packetConfig) {
        this._enconderTimePacketByteLength = 4;
        this._numEncoderSamplesByteLength = 1;
        this._defaultEncoderTimeInterval = 0.02;
        this._float32Configs = new Array();
        const sortedSensors = Object.values(deviceConfig.sensorConfigs).filter((sensor) => sensor.configValue <= deviceConfig.maxEncoderConfigValue);
        sortedSensors.sort((a, b) => (a.configValue > b.configValue ? 1 : -1));
        sortedSensors.forEach((sensor) => {
            if (packetConfig & sensor.configValue) {
                this._float32Configs.push({ sensorId: sensor.id, isFirst: true });
            }
        });
    }
    execute(dataView, ctx) {
        ctx.timeInterval = this._defaultEncoderTimeInterval;
        let remainingBytes = dataView.byteLength - ctx.index;
        const encoderPacketByteLength = this._enconderTimePacketByteLength + this._float32Configs.length * 4;
        if (remainingBytes < this._numEncoderSamplesByteLength) {
            console.log('Erroneous packet: ', CSU.toHex(dataView));
            return ctx;
        }
        const numPackets = dataView.getUint8(ctx.index);
        ctx.index = ctx.index + this._numEncoderSamplesByteLength;
        const endOfEncoderBytes = ctx.index + numPackets * encoderPacketByteLength;
        let remainingEncoderBytes = endOfEncoderBytes - ctx.index;
        remainingBytes = dataView.byteLength - ctx.index;
        if (remainingEncoderBytes % encoderPacketByteLength > 0 ||
            remainingBytes < remainingEncoderBytes) {
            console.log('Erroneous packet: ', CSU.toHex(dataView));
            return ctx;
        }
        // If there are no values we need to return an empty array to ensure
        // the graph advances
        this._float32Configs.forEach((config) => {
            ctx.sensorVals[config.sensorId] = ctx.sensorVals[config.sensorId] ?? [];
        });
        while (remainingEncoderBytes >= encoderPacketByteLength) {
            const offset = dataView.getFloat32(ctx.index, true);
            ctx.index = ctx.index + this._enconderTimePacketByteLength;
            this._float32Configs.forEach((config) => {
                const processedValue = dataView.getFloat32(ctx.index, true);
                ctx.sensorVals[config.sensorId].push([offset, processedValue]);
                // It's 4 bytes because we're reading a float32
                ctx.index = ctx.index + 4;
            });
            remainingEncoderBytes = endOfEncoderBytes - ctx.index;
        }
        return ctx;
    }
}
class NonEncoderPacketProcessorStep {
    constructor(deviceConfig, packetConfig) {
        this._float32Configs = new Array();
        const flatConfig32Reg = new Map();
        let bigReadingFloatShift = 0;
        Object.values(deviceConfig.sensorConfigs)
            .filter((sensor) => sensor.configValue > deviceConfig.maxEncoderConfigValue &&
            (packetConfig & sensor.configValue) === sensor.configValue)
            .sort((a, b) => (a.configValue > b.configValue ? 1 : -1))
            .forEach((sensor) => {
            const bits = this._getBits(sensor.configValue);
            bits.forEach((bit, i) => {
                const numFloats = sensor.valueLength[i] / 4;
                for (let j = 0; j < numFloats; j++) {
                    const withBigReadingShift = bigReadingFloatShift + j;
                    if (flatConfig32Reg.get(bit << withBigReadingShift) === undefined) {
                        flatConfig32Reg.set(bit << withBigReadingShift, []);
                    }
                    flatConfig32Reg
                        .get(bit << withBigReadingShift)
                        .push({ sensorId: sensor.id, isFirst: i === 0 && j === 0 });
                }
                if (numFloats > 1) {
                    bigReadingFloatShift = bigReadingFloatShift + (numFloats - 1);
                }
            });
        });
        this._float32Configs = Array.from(flatConfig32Reg.values());
    }
    execute(dataView, ctx) {
        let remainingBytes = dataView.byteLength - ctx.index;
        const nonEncoderPacketByteLength = this._float32Configs.length * 4;
        if (nonEncoderPacketByteLength > 0) {
            ctx.timeInterval =
                ctx.chunkInterval / (remainingBytes / nonEncoderPacketByteLength);
        }
        let packetNum = 0;
        if (remainingBytes % nonEncoderPacketByteLength > 0) {
            return ctx;
        }
        while (remainingBytes > 0 &&
            remainingBytes >= nonEncoderPacketByteLength &&
            this._float32Configs.length > 0) {
            const timeValue = ctx.timeInterval * packetNum;
            this._float32Configs.forEach((configs) => {
                configs.forEach((config) => {
                    ctx.sensorVals[config.sensorId] =
                        ctx.sensorVals[config.sensorId] ?? [];
                    if (config.isFirst) {
                        ctx.sensorVals[config.sensorId].push([timeValue]);
                    }
                    const sensorVal = dataView.getFloat32(ctx.index, true);
                    ctx.sensorVals[config.sensorId][ctx.sensorVals[config.sensorId].length - 1].push(sensorVal);
                });
                ctx.index = ctx.index + 4;
            });
            remainingBytes = dataView.byteLength - ctx.index;
            packetNum = packetNum + 1;
        }
        return ctx;
    }
    _getBits(configVal) {
        var b = 1;
        var res = [];
        while (b <= configVal) {
            if (b & configVal)
                res.push(b);
            b <<= 1;
        }
        return res;
    }
}
export class PowerlabDataPacketPipeline {
    constructor(deviceConfig) {
        this.deviceConfig = deviceConfig;
        this._packetConfigByteLength = 4;
        this._packetTypeByteLength = 1;
        // default encoder interval is so we have a valid frequency in the front end and isn't used in the point time calculation
        this._currentPacketConfig = 0;
        this.steps = new Array();
    }
    process(dataView, chunkInterval) {
        // can send empty packets during configuration
        if (dataView.byteLength === 0) {
            return [];
        }
        let ctx = {
            index: this._packetTypeByteLength,
            sensorVals: {},
            chunkInterval: chunkInterval,
            timeInterval: -1,
        };
        let remainingBytes = dataView.byteLength - ctx.index;
        if (remainingBytes < this._packetConfigByteLength) {
            console.log('Erroneous packet: ', CSU.toHex(dataView));
            return [];
        }
        const newPacketConfig = dataView.getUint32(ctx.index, true);
        ctx.index = ctx.index + this._packetConfigByteLength;
        if (newPacketConfig !== this._currentPacketConfig) {
            this.steps = [
                new EncoderPacketProcessorStep(this.deviceConfig, newPacketConfig),
                new NonEncoderPacketProcessorStep(this.deviceConfig, newPacketConfig),
            ];
            this._currentPacketConfig = newPacketConfig;
        }
        this.steps.forEach((step) => {
            ctx = step.execute(dataView, ctx);
        });
        return Object.keys(ctx.sensorVals).map((sensorId) => ({
            sensorId: sensorId,
            values: ctx.sensorVals[sensorId],
            frequency: 1 / ctx.timeInterval,
            type: MessageType.Reading,
            status: ProcessorStatus.SensorDataProcessing,
            chunkInterval: chunkInterval,
        }));
    }
}
