import {EntryState} from '@stores/entry.store';
import {RouteState} from '@stores/route.store';

import {
  PL2,
  UtilsPL2 as U,
  EntryUtilsPL2 as EU,
  KeyUtilsPL2 as KU,
  DateUtils as DU,
  KeysPL2 as K,
  FilterPolicies as FP,
} from '@common/utils/dist/index.js';

import {
  curriculumPageName,
  collectionPageName,
  slidesPageName,
  sectionTypeMap,
  sharedDataTableTypeMap,
  SectionSettings,
  sectionSettingsMap,
} from '@constants/app.config';
import {FilterPolicy} from '@common/utils/src/auth/filter-policies/filter-policy';

export module LabReportUtils {
  export const youtubeRegex =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;

  export interface Version {
    pK: string;
    createdOn: string;
    name?: string;
    icon: string;
  }

  export function isEditableSection(pK: string): boolean {
    const t = KU.type(pK);
    return !(<string[]>[
      PL2.EntryType.IFrameSection,
      PL2.EntryType.ImageSection,
      PL2.EntryType.PhetSection,
      PL2.EntryType.TextSection,
      PL2.EntryType.TrialSection,
      PL2.EntryType.VideoSection,
    ]).includes(t);
  }

  export function nameBySectionType(entry: PL2.AnyEntry): string {
    if (!entry || !EU.isSection(entry)) {
      return '';
    }
    let sectionName: string;
    if (entry.t === PL2.EntryType.SharedDataTableSection) {
      sectionName =
        sharedDataTableTypeMap[(entry as PL2.SharedDataTableSectionEntry).dT];
    } else {
      sectionName = sectionTypeMap[(entry as PL2.AnySectionEntry).t];
    }
    return !U.isEmpty(sectionName) ? sectionName : '';
  }

  export function settingsBySectionType(
    entry: PL2.AnyEntry,
  ): SectionSettings[] {
    if (!entry || !EU.isSection(entry)) {
      return [];
    }
    return sectionSettingsMap[entry.t]?.settings || [];
  }

  export function isConfigurableSection(entry: PL2.AnyEntry): boolean {
    return settingsBySectionType(entry).length > 0;
  }

  export function isMultipleChoice(
    entry: PL2.AnyEntry,
  ): entry is PL2.QuestionSectionEntry & {opts: number[]} {
    return (
      KU.isType(PL2.EntryType.QuestionSection, entry) &&
      Array.isArray((<PL2.QuestionSectionEntry>entry).opts)
    );
  }

  export function isQuestionSection(
    entry: PL2.AnyEntry,
  ): entry is PL2.QuestionSectionEntry {
    return KU.isType(PL2.EntryType.QuestionSection, entry);
  }

  export function isSpeechToTextSection(
    entry: PL2.AnyEntry,
  ): entry is PL2.SpeechToTextSectionEntry {
    return KU.isType(PL2.EntryType.SpeechToTextSection, entry);
  }

  export function getLrUserEntries(
    lrPK: string,
    entryState: EntryState,
    includeInvites = false,
  ): (
    | PL2.LabReportUserEntry
    | PL2.ScopeUserEntry
    | PL2.InvitedLabReportUserEntry
  )[] {
    const entry = entryState[lrPK];
    if (!entry) {
      return [];
    }
    const uETypes = <string[]>[
      PL2.EntryType.LabReportUser,
      PL2.EntryType.ScopeUser,
    ];
    if (includeInvites) {
      uETypes.push(PL2.EntryType.InvitedLabReportUser);
    }
    // On HMH, the user we have in staging as the teacher ("Pocketlab_TeacherCERT")
    // has the same uId as the student "Pocketlaba Student1CERT". Which means it will
    // consider the teacher interactions as student interactions (which is wrong)
    // but it only happen under our testings, not with actual users.
    return (entry.c || [])
      .filter((cPK) => uETypes.includes(KU.type(cPK)))
      .map((uEPK) => entryState[uEPK].e) as (
      | PL2.LabReportUserEntry
      | PL2.ScopeUserEntry
      | PL2.InvitedLabReportUserEntry
    )[];
  }

  export function getCurrentUserName(
    lrPK: string,
    user: PL2.User,
    entryState: EntryState,
  ): string {
    if (!user) {
      return '';
    }
    const name = user.name || user.firstName;
    if (!!name) {
      return name;
    }
    const uEPK = entryState[lrPK].c.find(
      (cPK) =>
        (entryState[cPK].e as PL2.UserEntry).mUId === user.userId &&
        EU.isUserEntries(cPK),
    );
    if (uEPK) {
      return entryState[uEPK].e.n;
    }
    return '';
  }

  export function getLrUser(
    scopePK: string,
    userId: string,
    entryState: EntryState,
  ): string {
    if (!entryState[scopePK]) {
      return null;
    }
    return entryState[scopePK].c.find(
      (cPK) => entryState[cPK].e.uId === userId && EU.isUserEntries(cPK),
    );
  }

  export function getTurnedInDate(
    lruPK: string,
    entryState: EntryState,
    showComplete: boolean = false,
  ): string {
    if (!lruPK || !entryState[lruPK]) {
      return null;
    }
    const tIAt = (entryState[lruPK].e as PL2.LabReportUserEntry).tIAt;
    return tIAt ? DU.formatTimestamp(tIAt, showComplete) : null;
  }

  export function sectionInStudentCopy(pK: string) {
    const entryId = KU.pKFromString(pK);
    const pPK = KU.parentTLEPK(entryId.mId, entryId.sK);
    return (
      KU.isType(PL2.EntryType.ClassScope, pPK) ||
      KU.isType(PL2.EntryType.IndividualScope, pPK) ||
      // Legacy container hierarchy (prior to scope feature)
      KU.isType(PL2.EntryType.StudentCopy, pPK)
    );
  }

  export function getFeedbackPK(
    sectionPK: K.AnySectionInAssignmentKey | string,
    entryState: EntryState,
  ): K.FeedbackKey {
    return (entryState[sectionPK] || {c: []}).c.find((cPK) =>
      KU.isType(PL2.EntryType.Comment, cPK),
    );
  }

  export function getTeacherNotesPK(
    sectionPK: K.AnySection | string,
    entryState: EntryState,
  ): K.TeacherNotesKey {
    return (entryState[sectionPK] || {c: []}).c.find((cPK) =>
      KU.isType(PL2.EntryType.TeacherNotes, cPK),
    );
  }

  export function isValidStudentInteraction(
    responsePK: string,
    entryState: EntryState,
    userId: string,
  ): boolean {
    const sectionPK = KU.parentPK(responsePK);
    const isSectionGroupScoped =
      (entryState[sectionPK]?.e as PL2.SectionEntry).sS ===
      PL2.SectionScope.Group;

    const isSameUser = entryState[responsePK]?.e?.uId === userId;

    // consider only interactions from uIds in the scope/group
    const scopeMUIds = new Set();
    // See "getLrUserEntries" implementation Notes
    getLrUserEntries(KU.parentPK(sectionPK), entryState, true).forEach(
      ({uId}) => scopeMUIds.add(uId),
    );
    const isInteractionInScope = scopeMUIds.has(entryState[responsePK]?.e.uId);
    return (isSectionGroupScoped && isInteractionInScope) || isSameUser;
  }

  export function getResponsePK(
    sectionPK: string,
    entryState: EntryState,
    userId: string,
  ): K.AnswerKey | K.TranscriptKey {
    return (entryState[sectionPK] || {c: []}).c.find(
      (cPK) =>
        !U.isEmpty(entryState[cPK]) &&
        isValidStudentInteraction(cPK, entryState, userId) &&
        (<string[]>[
          PL2.EntryType.AnswerSection,
          PL2.EntryType.Transcript,
        ]).includes(KU.type(cPK)),
    );
  }

  export function isSlideView(routeState: RouteState): boolean {
    return routeState.pageName === slidesPageName;
  }

  export function isNotVersionByAuthor(entry: PL2.AnyEntry): boolean {
    return entry.rC === 0;
  }

  export function isPrimary(entry: PL2.AnyEntry): entry is PL2.LabReportEntry;
  export function isPrimary(
    pK: K.AnyKey | string,
    entryState: EntryState,
  ): pK is K.LabReportKey;
  export function isPrimary(
    entryOrPK: string | PL2.AnyEntry,
    entryState?: EntryState,
  ): boolean {
    let entry: Partial<PL2.AnyEntry>;
    if (typeof entryOrPK === 'string') {
      entry = entryState[entryOrPK]?.e;
    } else {
      entry = entryOrPK;
    }

    if (U.isEmpty(entry)) {
      return false;
    }

    return isVersionByAuthor(<PL2.AnyEntry>entry) && entry.rC === 0;
  }

  export function isVersion(
    entry: PL2.AnyEntry,
    entryState: EntryState,
  ): entry is PL2.LabReportEntry;
  export function isVersion(
    pK: K.AnyKey | string,
    entryState: EntryState,
  ): pK is K.LabReportKey;
  export function isVersion(
    entryOrPK: string | PL2.AnyEntry,
    entryState: EntryState,
  ): boolean {
    let entry: Partial<PL2.AnyEntry>;
    if (typeof entryOrPK === 'string') {
      entry = entryState[entryOrPK]?.e;
    } else {
      entry = entryOrPK;
    }
    if (U.isEmpty(entry)) {
      return false;
    }
    return (
      KU.isType(PL2.EntryType.LabReport, entry) &&
      !U.isEmpty(entry) &&
      !U.isEmpty(entry.refId)
    );
  }

  export function curriculumKey(
    pK: K.LabReportKey,
  ): K.CurriculumKey | undefined {
    const parentPK = KU.parentPK(pK);
    if (U.isEmpty(parentPK)) {
      return undefined;
    }

    if (KU.isType(PL2.EntryType.Course, parentPK)) {
      return KU.parentPK(parentPK);
    }

    return parentPK;
  }

  export function isVersionByAuthor(
    entry: PL2.AnyEntry,
  ): entry is PL2.LabReportEntry;
  export function isVersionByAuthor(
    pK: K.AnyKey | string,
    entryState: EntryState,
  ): pK is K.LabReportKey;
  export function isVersionByAuthor(
    entryOrPK: string | PL2.AnyEntry,
    entryState?: EntryState,
  ): boolean {
    let entry: Partial<PL2.AnyEntry>;
    if (typeof entryOrPK === 'string') {
      entry = entryState[entryOrPK]?.e;
    } else {
      entry = entryOrPK;
    }
    if (U.isEmpty(entry)) {
      return false;
    }
    return (
      KU.isType(PL2.EntryType.LabReport, entry) &&
      !U.isEmpty(entry) &&
      !U.isEmpty(entry.srcId)
    );
  }

  export function lessonParentUrl(pK: string): string {
    const parentPK = KU.parentPK(pK);
    if (U.isEmpty(parentPK)) {
      return '/';
    }
    const hasCollectionParent = KU.isType(PL2.EntryType.Course, parentPK);
    const pageName = hasCollectionParent
      ? collectionPageName
      : curriculumPageName;
    return `/${pageName}/${parentPK}`;
  }

  export function getRefId(pK: string, entryState: EntryState): string {
    const lrE = entryState[pK]?.e as PL2.LabReportEntry;
    return lrE?.srcId ?? lrE?.refId;
  }

  export function youtubeId(url: string): string {
    const regExp = youtubeRegex;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
  }

  export function youtubeHostWithWWW(url: string): string {
    const host = new URL(url).hostname;
    const youtubeNoCookie = 'youtube-nocookie.com';
    const youtubeNoCookieWithWWW = 'www.youtube-nocookie.com';

    if ([youtubeNoCookie, youtubeNoCookieWithWWW].includes(host)) {
      return youtubeNoCookieWithWWW;
    } else {
      return 'www.youtube.com';
    }
  }

  export function buildAppFilterPolicyChain(
    user: PL2.User,
  ): FilterPolicy<PL2.AnyEntry> {
    return FP.FilterChainFilterPolicy<PL2.AnyEntry>([
      FP.EntryPublicFilter(),
      FP.OwnerFilterPolicy(user),
      FP.LicenseFilterPolicy(user),
      FP.CanReadLessonFilter(user),
    ]);
  }

  export function findSourceInEntryStore(
    srcId: string,
    entryState: EntryState,
  ): PL2.LabReportEntry | null {
    const lrCE = Object.values(entryState).find(
      (cE) =>
        cE.e.isA && cE.e?.rC === 0 && (<PL2.Sourceable>cE.e)?.srcId === srcId,
    );
    return lrCE?.e as PL2.LabReportEntry;
  }
}
