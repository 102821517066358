import { any, all, isPublic, hasNoBlacklistedAttsIn, } from '../utils/auth-utils.js';
import { AuthPolicy } from './auth-policy.js';
export class TeacherNotesAuthPolicy extends AuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return any(this.requestInitiatorIs(2 /* RequestInitiator.AcceptLrInvitationStrategy */), all(this.tleIsNotReadOnly, this.userIsSetAsCreatorFor(entry), this.userIsPrivilegedParticipantInAuthScope))();
    }
    async canRead(entry) {
        return any(isPublic(entry), this.userIsPrivilegedParticipantInAuthScope)();
    }
    async canUpdate(entry) {
        return all(hasNoBlacklistedAttsIn(entry), this.tleIsNotReadOnly, this.userIsPrivilegedParticipantInAuthScope)();
    }
    async canDelete(_entry) {
        return all(this.tleIsNotReadOnly, this.userIsPrivilegedParticipantInAuthScope)();
    }
}
