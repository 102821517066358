import {RouteState} from '@cloudlab/stores/route.store';
import {ConnectionState} from '@common/ble/src/connection-types';
import {PL2} from '@common/utils/dist/index.js';
import {OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';

export module StoreEventsPipes {
  export type StoreEvent<State, Event> = {
    state: State;
    event: Event;
  };

  export enum UserStoreEvents {
    UserLoggedIn,
    UserLoggedOut,
    UserUpdated,
  }

  export enum RouteStoreEvents {
    RouteChanged,
    StateChanged,
  }

  export const enum ConnectionStoreEvents {
    DeviceConnected,
    DeviceDisconnected,
    DeviceChanged,
  }

  export function userStoreEvents<T extends PL2.User>(): OperatorFunction<
    T,
    StoreEvent<PL2.User, UserStoreEvents>
  > {
    let previousUser: PL2.User;
    return map((user) => {
      if (previousUser === undefined && user !== undefined) {
        previousUser = user;
        return {event: UserStoreEvents.UserLoggedIn, state: user};
      }

      if (previousUser !== undefined && user === undefined) {
        previousUser = user;
        return {event: UserStoreEvents.UserLoggedOut, state: user};
      }

      return {event: UserStoreEvents.UserUpdated, state: user};
    });
  }

  export function routeStoreEvents<T extends RouteState>(): OperatorFunction<
    T,
    StoreEvent<RouteState, RouteStoreEvents>
  > {
    let previousRouteState: RouteState | undefined;
    return map((routeState) => {
      return {
        event: RouteStoreEvents.RouteChanged,
        state: routeState,
      };
    });
  }

  export function connectionStoreEvents(): OperatorFunction<
    ConnectionState,
    StoreEvent<ConnectionState, ConnectionStoreEvents>
  > {
    let previousState: ConnectionState;
    return map((state) => {
      const connected = !previousState && !!state;
      const disconnected =
        !!previousState && !!previousState.deviceConfig && !state;
      previousState = state;
      if (connected) {
        return {event: ConnectionStoreEvents.DeviceConnected, state: state};
      }
      if (disconnected) {
        return {event: ConnectionStoreEvents.DeviceDisconnected, state: state};
      }
      return {event: ConnectionStoreEvents.DeviceChanged, state: state};
    });
  }
}
