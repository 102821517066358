import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class AppRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(
    _route: ActivatedRouteSnapshot,
    _detachedTree: DetachedRouteHandle,
  ): void {}
  shouldAttach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    const rC = future.routeConfig;
    if (
      rC &&
      (rC.path.includes('lab-report/') ||
        rC.path.includes('course/') ||
        rC.path.includes('license/') ||
        (rC.path.includes('curriculum/') &&
          future.params.containerId !== curr.params.containerId) ||
        (rC.path.includes('slide/') &&
          future.params.cardId !== curr.params.cardId))
    ) {
      return false;
    } else {
      return future.routeConfig === curr.routeConfig;
    }
  }
}
