export var LoggingUtils;
(function (LoggingUtils) {
    let LogLevel;
    (function (LogLevel) {
        LogLevel[LogLevel["INFO"] = 0] = "INFO";
        LogLevel[LogLevel["DEBUG"] = 1] = "DEBUG";
        LogLevel[LogLevel["ERROR"] = 2] = "ERROR";
        LogLevel[LogLevel["WARNING"] = 3] = "WARNING";
    })(LogLevel || (LogLevel = {}));
    function log(level, message, status) {
        const stack = new Error().stack;
        // Parse the stack to find the caller function or method
        const caller = stack.split('\n')[2]?.trim(); // Get the line that called err()
        // Regex to extract function or method name and file name (if available)
        const regex = /at (\S+) \((.+):\d+:\d+\)/;
        const match = caller.match(regex);
        let classOrFile = 'unknown';
        let methodOrFunction = 'unknown';
        if (match) {
            methodOrFunction = match[1]; // Function or method name
            classOrFile = match[2]; // File name or class
        }
        const logLevelName = LogLevel[level] ?? 'INFO';
        message = typeof message === 'string' ? message : JSON.stringify(message);
        console.error(`[${logLevelName}] [STATUS=${status}] ${classOrFile}.${methodOrFunction}: ${message}`);
    }
    function info(message, status = 500) {
        log(LogLevel.INFO, message, status);
    }
    LoggingUtils.info = info;
    function debug(message, status = 500) {
        log(LogLevel.DEBUG, message, status);
    }
    LoggingUtils.debug = debug;
    function error(message, status = 500) {
        log(LogLevel.ERROR, message, status);
    }
    LoggingUtils.error = error;
})(LoggingUtils || (LoggingUtils = {}));
