import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';

import {ReplaySubject} from 'rxjs';
import {takeUntil, map} from 'rxjs/operators';

import {Clipboard} from '@angular/cdk/clipboard';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

import {NotificationDialogComponent} from '@components/dialogs/notification-dialog.component';

import {FileService} from '@services/file.service';

import {EntryStore, CachedEntry} from '@stores/entry.store';
import {PendingOperation} from '@stores/entry-store-automaton/operation-state-machine';
import {UserStore} from '@stores/user.store';
import {ConnectionStore} from '@stores/connection.store';

import {DefaultCacheExpirationStrategyService} from '@services/default-cache-expiration-strategy.service';
import {ConnectionService} from '@services/connection.service';

import {
  PL2,
  KeyUtilsPL2 as KU,
  UtilsPL2 as U,
} from '@common/utils/dist/index.js';

enum Status {
  Pending = 'pending',
  Failed = 'failed',
  Succeeded = 'succeeded',
}

interface Log {
  pendingOperation: string;
  pK: string;
  ts: string;
  dateTime: string;
  name: string;
  payload: string;
  status: string;
}

@Component({
  selector: 'debug-console',
  templateUrl: './debug-console.component.html',
  styleUrls: ['./debug-console.component.scss'],
})
export class DebugConsoleComponent implements OnInit, OnDestroy {
  @Output()
  closeSelected = new EventEmitter<void>();

  @Output()
  expandSelected = new EventEmitter<void>();

  readonly placeholder = 'Write hex device commands here';

  logRegistry: {[pK: string]: Log} = {};
  output = new Array<Log>();
  userId: string;
  numEntries = 0;
  maxErrorsZeroed = false;

  commandOutput: string;
  showCommandInput = false;
  command = this.placeholder;

  collapsed = true;

  private _destroyed$ = new ReplaySubject<boolean>();

  constructor(
    private entryStore: EntryStore,
    private userStore: UserStore,
    private fileService: FileService,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private defaultCacheExpirationStrategyService: DefaultCacheExpirationStrategyService,
    private connectionService: ConnectionService,
    private connectionStore: ConnectionStore,
    @Inject('STORAGE') private localStorage: any,
  ) {}

  ngOnInit() {
    this.userStore.state$
      .pipe(
        map((user) => (this.userId = user ? user.userId : 'none')),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    this.connectionStore.state$
      .pipe(
        map((state) => (this.showCommandInput = !U.isEmpty(state))),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    this.entryStore.state$
      .pipe(
        map((entryState) => {
          const ces = Object.values(entryState);
          this.numEntries = ces.length;
          this._updateLogRegistry(ces);
          const sortedLogs = this.sortLogs();
          this._buildOutput(sortedLogs);
          this._pruneLogs(sortedLogs);
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  exportLogs() {
    this.fileService.save(
      JSON.stringify({logs: Object.values(this.logRegistry)}),
      'entrystate.json',
    );
  }

  close() {
    this.closeSelected.emit();
  }

  reportAllErrors() {
    this.defaultCacheExpirationStrategyService.maxErrors = 0;
    this.maxErrorsZeroed = true;
  }

  show(pK: string) {
    const ce = JSON.stringify(this.entryStore.getCachedEntry(pK));
    this.dialog.open(NotificationDialogComponent, {
      data: {
        title: `${pK} copied to clipboard`,
        content: ce,
      },
    });
    this.clipboard.copy(ce);
  }

  writeCommand() {
    const b = this.command.split(' ').map((item) => parseInt(item.trim(), 16));
    this.connectionService.writeCommand(b).then(() => (this.command = ''));
  }

  readCommand() {
    this.connectionService.readCommand().then((reading: DataView) => {
      console.log(reading);
      this.commandOutput = '';
      new Uint8Array(reading.buffer).forEach((r) => {
        this.commandOutput = `${this.commandOutput} ${r.toString(16)}`;
      });
      this.command = '';
    });
  }

  clearLocalStorage() {
    this.entryStore.setState({});
    this.localStorage.setItem(this.userStore.state().userId, '{}');
  }

  private _updateLogRegistry(ces: CachedEntry[]) {
    ces.forEach((ce) => {
      const pK = KU.stringFromKey(ce.e as PL2.EntryId);
      if (ce._pO) {
        this.logRegistry[pK] = this._buildLog(ce);
      } else if (!U.isEmpty(this.logRegistry[pK])) {
        this.logRegistry[pK] = this._buildLog(ce);
      }
    });
  }

  private sortLogs() {
    return Object.values(this.logRegistry).sort(U.sortBy('ts', U.oBy.desc));
  }

  private _buildOutput(logs: Log[]) {
    this.output = logs.slice(0, 10);
    this._pruneLogs(logs);
  }

  private _pruneLogs(logValues: Log[]) {
    logValues.slice(10).forEach((log) => delete this.logRegistry[log.pK]);
  }

  private _buildLog(ce: CachedEntry): Log {
    const pK = KU.stringFromKey(ce.e as PL2.EntryId);
    let status = Status.Succeeded;
    const existingLog = this.logRegistry[pK];
    switch (ce._pO) {
      case undefined:
      case PendingOperation.None:
      case PendingOperation.UpdateSucceeded:
      case PendingOperation.DeleteSucceeded:
      case PendingOperation.CreateSucceeded:
        status = Status.Succeeded;
        break;
      case PendingOperation.UpdateFailed:
      case PendingOperation.CreateFailed:
      case PendingOperation.DeleteFailed:
        status = Status.Failed;
        break;
      default:
        status =
          !U.isEmpty(existingLog) && existingLog.status === Status.Failed
            ? Status.Failed
            : Status.Pending;
        break;
    }
    return {
      pendingOperation: PendingOperation[ce._pO],
      pK: pK,
      dateTime: new Date(parseInt(ce._ts, 10)).toUTCString(),
      ts: ce._ts,
      name: ce.e.n || '',
      payload: JSON.stringify(ce, null, 2),
      status: status,
    };
  }
}
