import { PowerlabDataPacketPipeline } from './powerlab-data-packet-processor.js';
import { ConnectionStrategyUtils as CSU } from './connection-strategy-utils.js';
export class RealtimeDataProcessorStrategy {
    constructor(deviceConfig, params) {
        this.deviceConfig = deviceConfig;
        this._chunkInterval = 0;
        const frequencyInstruction = this.deviceConfig.frequencyConfigs[params.frequencyIndex].i;
        this._chunkInterval = CSU.chunkInterval(frequencyInstruction);
        this.dataPacketPipeline = new PowerlabDataPacketPipeline(deviceConfig);
    }
    process(dataView) {
        if (dataView.getUint8(0) !== this.deviceConfig.pollingPacketId) {
            console.warn('Not a polling packet');
            console.warn(dataView);
            return [];
        }
        return this.dataPacketPipeline.process(dataView, this._chunkInterval);
    }
}
