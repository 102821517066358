import { from } from 'rxjs';
import { ProcessorUtils as PU } from './processor-utils.js';
import { CharacteristicCache } from './characteristic-cache.js';
import { CharacteristicDataProcessorBuilder } from './characteristic-data-processor-builder.js';
import { CharacteristicSensorCoordinator } from './characteristic-sensor-coordinator.js';
import { PrePowerlabDeviceConfigurator } from './pre-powerlab-device-configurator.js';
import { PlSensorConfigurator } from './pl-sensor-configurator.js';
import { connectionStateDefault, } from './connection-types.js';
import { mergeMap } from 'rxjs/operators';
export class PlStrategy {
    constructor(gattServer, deviceConfig) {
        this.deviceConfig = deviceConfig;
        this.PL1_ACC_RANGE = 8.0;
        this._barCoefficients = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
        this._processorMap = {
            pl1acc: (value) => {
                const v = new Int8Array(value);
                const x = PU.accShortSignedAtOffset(v, 2);
                const y = PU.accShortSignedAtOffset(v, 0);
                const z = PU.accShortSignedAtOffset(v, 4);
                return [
                    x / (32768.0 / this.PL1_ACC_RANGE),
                    (-1 * y) / (32768.0 / this.PL1_ACC_RANGE),
                    z / (32768.0 / this.PL1_ACC_RANGE),
                ];
            },
            pl1mag: (value) => {
                const v = new Int8Array(value);
                const x = PU.shortSignedAtOffset(v, 2) * 0.1 * -1;
                const y = PU.shortSignedAtOffset(v, 0) * 0.1;
                const z = PU.shortSignedAtOffset(v, 4) * 0.1 * -1;
                return [x, y, z];
            },
            pl1gyr: (value) => {
                const v = new Int8Array(value);
                const x = PU.shortSignedAtOffset(v, 0) * (4000 / 65536);
                const y = PU.shortSignedAtOffset(v, 2) * (4000 / 65536);
                const z = PU.shortSignedAtOffset(v, 4) * (4000 / 65536);
                return [x, y, z];
            },
            pl1bar: (value) => {
                const c = this._barCoefficients;
                const va = new Int8Array(value);
                const pv = PU.twentyFourBitSignedAtOffset(va, 0);
                const tv = PU.twentyFourBitSignedAtOffset(va, 3);
                const temp = PU.convertTemperature(tv, c)[0];
                const t_fine = PU.convertTemperature(tv, c)[1];
                let var1, var2, p;
                var1 = (t_fine >> 1) - 64000;
                var2 = (((var1 >> 2) * (var1 >> 2)) >> 11) * c[8];
                var2 = var2 + ((var1 * c[7]) << 1);
                var2 = (var2 >> 2) + (c[6] << 16);
                var1 =
                    (((c[5] * (((var1 >> 2) * (var1 >> 2)) >> 13)) >> 3) +
                        ((c[4] * var1) >> 1)) >>
                        18;
                var1 = ((32768 + var1) * c[3]) >> 15;
                if (var1 === 0) {
                    return [0]; // avoid exception caused by division by zero
                }
                p = (1048576 - pv - (var2 >> 12)) * 3125;
                if (p < 0x80000000) {
                    p = ((p << 1) >>> 0) / var1;
                }
                else {
                    p = (p / var1) * 2;
                }
                var1 = (c[11] * (((p >> 3) * (p >> 3)) >> 13)) >> 12;
                var2 = ((p >> 2) * c[10]) >> 13;
                p = p + ((var1 + var2 + c[9]) >> 4);
                return [p / 100, temp];
            },
        };
        this._characteristicCache = new CharacteristicCache(gattServer, deviceConfig);
        this._sensorConfigurator = new PlSensorConfigurator(deviceConfig, this._characteristicCache);
        this._sensorCoordinator = new CharacteristicSensorCoordinator(this._sensorConfigurator, deviceConfig, this._characteristicCache);
        this._deviceConfigurator = new PrePowerlabDeviceConfigurator(this._characteristicCache, deviceConfig);
    }
    init() {
        return this.calibrate(this.deviceConfig.calibrationConfig).then((partialConnectionState) => {
            this._barCoefficients = partialConnectionState.barCoefficients;
            return {
                ...connectionStateDefault,
                barCoefficients: this._barCoefficients,
                deviceConfig: this.deviceConfig,
            };
        });
    }
    startSensors(params) {
        const builder = new CharacteristicDataProcessorBuilder(this._processorMap, this.deviceConfig.frequencyConfigs[params.frequencyIndex].f);
        return from(this._sensorCoordinator.startSensors(params.sensorIds, params.frequencyIndex)).pipe(mergeMap((dCs) => builder.build(dCs)));
    }
    calibrate(sensorId) {
        return this._sensorConfigurator.calibrate(sensorId);
    }
    memoryConfigurator() {
        return null;
    }
    deviceConfigurator() {
        return this._deviceConfigurator;
    }
    crashModeConfigurator() {
        return null;
    }
    disconnect() {
        // do nothing
    }
}
