import { ValidatorFactory, } from './validator-core.js';
export class ConfigurableValidatorFactory extends ValidatorFactory {
    constructor(builderFactory, configs, 
    // validate non configured attributes with this
    extraConfig) {
        super(builderFactory);
        this.builderFactory = builderFactory;
        this.configs = configs;
        this.extraConfig = extraConfig;
    }
    getInstance(o) {
        const factory = {};
        this.configs.forEach((config) => {
            config.attributes.forEach((attr) => {
                factory[attr] = config.validatorFactories.map((f) => f(o[attr], this.builderFactory(attr)));
            });
        });
        return factory;
    }
    getAttributeValidators(attr, value) {
        const config = this.configs.find((c) => c.attributes.includes(attr));
        if (config) {
            return config.validatorFactories.map((factory) => factory(value, this.builderFactory(attr)));
        }
        else {
            return [this.extraConfig(value, this.builderFactory(attr))];
        }
    }
}
