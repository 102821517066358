import { SecondaryConfigPipeline } from './secondary-config-pipeline.js';
export class PowerlabSensorCoordinator {
    constructor(deviceConfig, notificationConfigurator, commandBuilder, commandQueue) {
        this.deviceConfig = deviceConfig;
        this.notificationConfigurator = notificationConfigurator;
        this.commandBuilder = commandBuilder;
        this.commandQueue = commandQueue;
        this._sensorRegistry = new Set();
    }
    async enableSensors(sensorIds, frequencyIndex) {
        console.log(`Registry state pre-config: ${new Array(...this._sensorRegistry).join(' ')}`);
        console.log(`New indicies: ${sensorIds.join(' ')}`);
        console.log(`Frequency index: ${frequencyIndex}`);
        this._sensorRegistry.clear();
        const command = this.commandBuilder.build(sensorIds);
        this._setSecondaryConfigs(sensorIds, frequencyIndex);
        return this._setFrequency(frequencyIndex, sensorIds)
            .then(() => this.commandQueue.addWithoutValidation(Array.from(new Uint8Array(command))))
            .then(() => {
            sensorIds.forEach((sensorId) => {
                this._sensorRegistry.add(sensorId);
            });
            return sensorIds;
        });
    }
    async startSensors(sensorIds, frequencyIndex) {
        const uniqueSensorIds = [...new Set(sensorIds)];
        return this.enableSensors(uniqueSensorIds, frequencyIndex).then(() => this.notificationConfigurator.enableNotifications(uniqueSensorIds));
    }
    stopAllNotifications() {
        return this.notificationConfigurator.stopAllNotifications(this.deviceConfig);
    }
    stopAllSensors() {
        return this.enableSensors([]);
    }
    _setFrequency(frequencyIndex, sensorIds) {
        if (sensorIds.length === 0 || frequencyIndex === -1) {
            return Promise.resolve();
        }
        const aB = new ArrayBuffer(4);
        const dV = new DataView(aB);
        dV.setUint32(0, this.deviceConfig.frequencyConfigs[frequencyIndex]?.i ?? 0, true);
        const configValues = [
            0x70,
            dV.getUint8(2),
            dV.getUint8(3),
            dV.getUint8(0),
            dV.getUint8(1),
        ];
        return this.commandQueue.addWithoutValidation(configValues);
    }
    _setSecondaryConfigs(sensorIds, frequencyIndex) {
        new SecondaryConfigPipeline().execute(sensorIds, frequencyIndex, this.deviceConfig, this.commandQueue);
    }
}
