import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';

import {NotificationStore} from '@stores/notification.store';
import {EntryStore, EntryState, CachedEntry} from '@stores/entry.store';
import {PendingOperation} from '@stores/entry-store-automaton/operation-state-machine';
import {ErrorReportStore, ErrorReport} from '@stores/error-report.store';

import {Action} from '@actions/action';

import {
  PL2,
  KeyUtilsPL2 as KU,
  UtilsPL2 as U,
} from '@common/utils/dist/index.js';
import {authEntries} from '@utils/auth-utils';

import {ErrorReportCode} from '@constants/app.config';
import {WebsocketService} from '@services/websocket.service';

@Injectable()
export class ExpireEntriesAction implements Action<string[], any> {
  constructor(
    private entryStore: EntryStore,
    private errorReportStore: ErrorReportStore,
    private notificationStore: NotificationStore,
    private websocketService: WebsocketService,
  ) {}

  execute(pKs: string[]): Observable<any> {
    if (U.isEmpty(pKs)) {
      return of(void 0);
    }
    const state = this.entryStore.state();
    const failed: CachedEntry[] = pKs.map((pK) => {
      if (state[pK]) {
        return state[pK];
      }
    });
    const tlePKs = KU.getParentTLEPKs(pKs);
    const tleCEs = this._getTLEs(tlePKs, state);
    const aCEs = this._getAuthEntries(tlePKs, state);
    const ignored = this._getAllIgnored(state);

    const debugState: ErrorReport = {
      code: ErrorReportCode.ExpiredEntries,
      message: 'Expired entries deleted from entry cache.',
      entries: [...failed, ...tleCEs, ...aCEs, ...ignored],
    };

    this.errorReportStore.addReport(debugState);

    this._showErrorDialog(tleCEs);
    this.entryStore.destroyEntries(pKs);
    const opts = {}; // ?
    return this.websocketService.fetchAll(tlePKs, opts);
  }

  private _getTLEs(pKs: string[], state: EntryState): CachedEntry[] {
    const tlCEs: CachedEntry[] = [];
    pKs.forEach((pK) => {
      if (
        state[pK] &&
        !tlCEs.find((ce) => KU.stringFromKey(ce.e as PL2.EntryId) === pK)
      ) {
        tlCEs.push(state[pK]);
      }
    });
    return tlCEs;
  }

  private _getAuthEntries(pKs: string[], state: EntryState): CachedEntry[] {
    const aES: CachedEntry[] = [];
    pKs.forEach((pK) => {
      if (state[pK]) {
        const entry = state[pK].e as PL2.EntryId;
        authEntries(entry.mId, entry.sK, state)
          .map((ae) => ae as PL2.AuthIdxEntry)
          .forEach((ae) => {
            const aPK = KU.stringFromKey({mId: ae.mId, sK: ae.aSK});
            if (state[aPK]) {
              aES.push(state[aPK]);
            }
          });
      }
    });
    return aES;
  }

  private _getAllIgnored(state: EntryState): CachedEntry[] {
    const ignored: CachedEntry[] = [];
    Object.keys(state)
      .map((pK) => state[pK])
      .filter((ce) => ce._pO === PendingOperation.Ignore)
      .forEach((iCE) => ignored.push(iCE));
    return ignored;
  }

  private _showErrorDialog(tles: CachedEntry[]) {
    const tleList = !U.isEmpty(tles)
      ? tles.map((tle) => tle.e.n).join('\n')
      : '';
    this.notificationStore.showDialog(
      'expired-entries-notification.title',
      'expired-entries-notification.content',
      {tles: tleList},
    );
  }
}
