<div class='flex-100 flex-column'>
  <video-tutorial *plIfCan='"actAsTeacher"'></video-tutorial>
  <div class='flex'>
    <router-outlet></router-outlet>
  </div>
  <debug-console [style]='consoleStyle'
    *ngIf='consoleStyle'
    (expandSelected)='expandConsole()'
    (closeSelected)='closeConsole()'>
  </debug-console>
</div>

