import { fromEvent } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { ProcessorUtils as PU } from './processor-utils.js';
import { MemoryStatus, MessageType, ProcessorStatus, } from './connection-types.js';
export class PrePowerlabMemoryConfigurator {
    constructor(characteristicCache, deviceConfig, sensorCoordinator, processorMap) {
        this.characteristicCache = characteristicCache;
        this.deviceConfig = deviceConfig;
        this.sensorCoordinator = sensorCoordinator;
        this.processorMap = processorMap;
        this._frequencyInstructions = [
            174, 173, 172, 171, 170, 1, 5, 10, 20, 25, 50,
        ];
        this._MEMORY_NOT_SUPPORTED = -1;
        this._LOGGING_OFF_NO_DATA = 0;
        this._LOGGING_ON_NO_DATA = 1;
        this._LOGGING_OFF_WITH_DATA = 2;
        this._LOGGING_ON_WITH_DATA = 3;
        this._WEIRD_LOGGING_ON_WITH_DATA = 7;
        this._STARTING_BYTE = 85;
        this._END_BYTE_1 = 0x55;
        this._END_BYTE_2 = 0xaa;
        this._valueBeginning = null;
    }
    retrieveMemoryState() {
        if (!this.deviceConfig.memoryConfig) {
            return Promise.resolve(MemoryStatus.NoMemory);
        }
        return this._readMemoryStatus().then((status) => {
            switch (status) {
                case this._MEMORY_NOT_SUPPORTED:
                    return MemoryStatus.NoMemory;
                case this._LOGGING_OFF_NO_DATA:
                    return MemoryStatus.Empty;
                case this._LOGGING_ON_NO_DATA:
                    return MemoryStatus.Recording;
                case this._WEIRD_LOGGING_ON_WITH_DATA:
                case this._LOGGING_ON_WITH_DATA:
                    return MemoryStatus.Recording;
                case this._LOGGING_OFF_WITH_DATA:
                    return MemoryStatus.Recorded;
                case undefined:
                    return MemoryStatus.Empty;
                default:
                    return MemoryStatus.Empty;
            }
        }, (err) => {
            console.warn('retrieveMemoryState:' + err);
            return MemoryStatus.NoMemory;
        });
    }
    enterMode() {
        return this.sensorCoordinator.stopAllSensors();
    }
    startRecordingToMemory(params) {
        const buffer = new Int8Array(this.deviceConfig.startRecordingToMemoryCommand).buffer;
        return this.sensorCoordinator
            .stopAllNotifications()
            .then(() => this.sensorCoordinator.enableSensors(params.sensorIds, params.frequencyIndex))
            .then(() => this.characteristicCache.get(this.deviceConfig.memoryConfig.id, 'config'))
            .then((characteristic) => characteristic.writeValue(buffer));
    }
    stopRecordingToMemory() {
        const buffer = new Int8Array(this.deviceConfig.stopRecordingToMemoryCommand).buffer;
        return this.sensorCoordinator
            .stopAllSensors()
            .then(() => this.characteristicCache.get(this.deviceConfig.memoryConfig.id, 'config'))
            .then((characteristic) => characteristic.writeValue(buffer));
    }
    stopMemoryDownload() {
        return this.characteristicCache
            .get(this.deviceConfig.memoryConfig.id, 'data')
            .then((characteristic) => characteristic.stopNotifications())
            .then(() => console.log('Stopped memory download'));
    }
    clearMemory() {
        return this.characteristicCache
            .get(this.deviceConfig.memoryConfig.id, 'config')
            .then((characteristic) => {
            const buffer = new Int8Array(this.deviceConfig.clearMemoryCommand)
                .buffer;
            return characteristic.writeValue(buffer);
        });
    }
    setTransferRate() {
        // NOOP: see powerlab-memory-configurator.ts
        return Promise.resolve();
    }
    startMemoryDownload() {
        const buffer = new Int8Array(this.deviceConfig.downloadFromMemoryCommand).buffer;
        return this._readMemoryDataFrequency().then((frequency) => {
            return this.characteristicCache
                .get(this.deviceConfig.memoryConfig.id, 'data')
                .then((characteristic) => characteristic.startNotifications())
                .then(() => this.characteristicCache.get(this.deviceConfig.memoryConfig.id, 'config'))
                .then((characteristic) => characteristic.writeValue(buffer))
                .then(() => this.characteristicCache.get(this.deviceConfig.memoryConfig.id, 'data'))
                .then((characteristic) => {
                console.log('Subscribed to memory download');
                return fromEvent(characteristic, 'characteristicvaluechanged').pipe(map((event) => {
                    const value = this._processMemoryData(new Uint8Array(event.target['value']['buffer']), frequency);
                    return value;
                }), takeWhile((readings) => (readings[0] || {}).status !==
                    ProcessorStatus.MemoryDownloadComplete));
            });
        });
    }
    exitMode() {
        // do nothing
        return Promise.resolve();
    }
    _readMemoryDataFrequency() {
        const buffer = new Int8Array(this.deviceConfig.memoryDataFrequencyCommand).buffer;
        return this.characteristicCache
            .get(this.deviceConfig.memoryConfig.id, 'config')
            .then((characteristic) => characteristic.writeValue(buffer))
            .then(() => this.characteristicCache.get(this.deviceConfig.memoryConfig.id, 'data'))
            .then((characteristic) => characteristic.readValue())
            .then((value) => {
            console.log('Unprocessed Memory data rate: ' + new Uint8Array(value.buffer));
            return this._memoryDataFrequency(value.buffer);
        });
    }
    _readMemoryStatus() {
        const buffer = new Int8Array(this.deviceConfig.memoryStatusCommand)
            .buffer;
        return this.characteristicCache
            .get(this.deviceConfig.memoryConfig.id, 'config')
            .then((characteristic) => characteristic.writeValue(buffer))
            .then(() => this.characteristicCache.get(this.deviceConfig.memoryConfig.id, 'config'))
            .then((characteristic) => characteristic.writeValue(buffer))
            .then(() => this.characteristicCache.get(this.deviceConfig.memoryConfig.id, 'data'))
            .then((characteristic) => characteristic.readValue())
            .then((value) => {
            console.log('Memory status value: ' + new Int8Array(value.buffer)[1]);
            return new Int8Array(value.buffer)[1];
        });
    }
    _memoryDataFrequency(value) {
        const v = new Uint8Array(value);
        const rate = PU.accShortSignedAtOffset(v, 2);
        console.log('Memory frequency: ' + 1000.0 / rate);
        if (rate) {
            return 1000.0 / rate;
        }
        else {
            return 0;
        }
    }
    _processMemoryData(value, frequency) {
        let v = value;
        const values = new Array();
        const sensorConfigs = Object.values(this.deviceConfig.sensorConfigs);
        // console.log('processMemoryData: rawValues');
        // console.log(v);
        if (this._isEndOfData(v)) {
            values.push({
                type: MessageType.Status,
                status: ProcessorStatus.MemoryDownloadComplete,
            });
            return values;
        }
        if (this._valueBeginning !== null) {
            const appendedV = new Uint8Array(this._valueBeginning.length + v.length);
            appendedV.set(this._valueBeginning, 0);
            appendedV.set(v, this._valueBeginning.length);
            this._valueBeginning = null;
            v = appendedV;
        }
        let startingIdx = v.slice(undefined).indexOf(this._STARTING_BYTE);
        while (v.slice(startingIdx).indexOf(this._STARTING_BYTE) !== -1) {
            // const currentVals = new Map<SensorConfig, Array<Uint8Array>>();
            const currentValues = {};
            const bitwiseIdx = startingIdx + 1;
            const bodyStartIdx = startingIdx + 2;
            // Starting byte and bitwise sensor value
            if (v[bodyStartIdx] === undefined) {
                this._valueBeginning = v.slice(startingIdx);
                break;
            }
            let totalValueLength = 2;
            const bitwise = v.slice(bitwiseIdx, bitwiseIdx + 1)[0];
            // console.log(`Bitwise val: ${bitwise}`);
            let byteSummation = this._STARTING_BYTE + bitwise;
            let sensorStartIdx = bodyStartIdx;
            const foundEnd = sensorConfigs.some((config) => {
                if ((config.memoryId & bitwise) === config.memoryId) {
                    if (v[sensorStartIdx + config.valueLength[0]] === undefined) {
                        return true;
                    }
                    const newValue = v.slice(sensorStartIdx, sensorStartIdx + config.valueLength[0]);
                    if (!currentValues[config.id]) {
                        currentValues[config.id] = [];
                    }
                    currentValues[config.id].push(this.processorMap[config.id](newValue));
                    totalValueLength += config.valueLength[0];
                    byteSummation += newValue.reduce((a, b) => a + b, 0);
                    sensorStartIdx = sensorStartIdx + config.valueLength[0];
                    return false;
                }
            });
            if (foundEnd) {
                this._valueBeginning = v.slice(startingIdx);
                break;
            }
            const checksumIdx = startingIdx + totalValueLength;
            if (v[checksumIdx] === undefined) {
                this._valueBeginning = v.slice(startingIdx);
                break;
            }
            else {
                this._valueBeginning = null;
            }
            const checksum = v.slice(checksumIdx, checksumIdx + 1)[0];
            if ((byteSummation & 255) !== checksum) {
                console.log(`Checksum failed byteSummation: ${byteSummation} checksum: ${checksum}`);
                console.dir(v);
                this._valueBeginning = null;
                const relativeStartingIdx = v
                    .slice(startingIdx + 1)
                    .indexOf(this._STARTING_BYTE);
                if (relativeStartingIdx !== -1) {
                    startingIdx = relativeStartingIdx + startingIdx + 1;
                }
                else {
                    break;
                }
            }
            else {
                // console.log(`Checksum passed byteSummation: ${byteSummation} checksum: ${checksum}`);
                startingIdx = checksumIdx + 1;
                values.push(...Object.keys(currentValues).map((sensorId) => ({
                    sensorId: sensorId,
                    values: currentValues[sensorId].map((val) => [0, ...val]),
                    type: MessageType.Reading,
                    status: ProcessorStatus.SensorDataProcessing,
                    frequency: frequency,
                    chunkInterval: 1 / frequency,
                })));
                // console.log(values);
            }
        }
        return values;
    }
    _isEndOfData(data) {
        return (data.filter((d) => d === this._END_BYTE_1).length > 8 &&
            data.filter((d) => d === this._END_BYTE_2).length > 8);
    }
}
