import {EntryStore, CachedEntry, EntryState} from '@stores/entry.store';

import {PL2} from '@common/utils/dist/index.js';

export enum CacheExpirationEvent {
  LoadCache,
  Error,
  Success,
}

export abstract class CacheExpirationStrategyService {
  expire(
    event: CacheExpirationEvent,
    draft?: EntryState,
    changes?: Array<Partial<CachedEntry>>,
  ): void;
  expire(
    _event: CacheExpirationEvent,
    _draft?: any,
    _changes?: Array<any>,
  ): void {}

  deepExpire(
    event: CacheExpirationEvent,
    store: EntryStore,
    errors?: Array<Partial<PL2.AnyEntry>>,
  ): void;
  deepExpire(
    _event: CacheExpirationEvent,
    _store: any,
    _errors?: Array<any>,
  ): void {}
}
