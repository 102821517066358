import { DeviceConfigUtils as DCU } from './device-config-utils.js';
export class PowerlabDeviceConfigurator {
    constructor(characteristicCache, deviceConfig, commandQueue) {
        this.characteristicCache = characteristicCache;
        this.deviceConfig = deviceConfig;
        this.commandQueue = commandQueue;
    }
    writeName(name) {
        const nameWithPrefix = this.deviceConfig.namePrefix + name;
        const command = new Uint8Array(this.deviceConfig.renameCommand.length + nameWithPrefix.length);
        command.set(new Uint8Array(this.deviceConfig.renameCommand));
        command.set(new TextEncoder().encode(nameWithPrefix), this.deviceConfig.renameCommand.length);
        return this.commandQueue
            .addWithoutValidation(Array.from(command))
            .then(() => this.commandQueue.addWithoutValidation(this.deviceConfig.restartCommand));
    }
    readBatteryStatus() {
        return this.characteristicCache
            .get(this.deviceConfig.batteryConfig.id, 'config')
            .then((characteristic) => characteristic.readValue())
            .then((value) => new Uint8Array(value.buffer)[0]);
    }
    readFirmwareVersion() {
        return this.characteristicCache
            .get(this.deviceConfig.deviceInformationConfigs.firmwareinfo.id, 'config')
            .then((characteristic) => characteristic.readValue())
            .then((value) => DCU.fwBytesToString(value));
    }
    readHardwareVersion() {
        return this.characteristicCache
            .get(this.deviceConfig.deviceInformationConfigs['hardwareinfo'].id, 'config')
            .then((characteristic) => characteristic.readValue())
            .then((value) => DCU.fwBytesToString(value));
    }
    readManufacturerName() {
        return this.characteristicCache
            .get(this.deviceConfig.deviceInformationConfigs['manufacturerinfo'].id, 'config')
            .then((characteristic) => characteristic.readValue())
            .then((value) => DCU.fwBytesToString(value));
    }
    shutdown() {
        return this.commandQueue.add(this.deviceConfig.shutdownCommand);
    }
    saveSensorSettings() {
        return this.commandQueue
            .add(this.deviceConfig.enableSaveCommand)
            .then(() => this.commandQueue.add(this.deviceConfig.saveCommand));
    }
}
