import { ValidatorFactory, } from './validator-core.js';
import { DataTableCellValidatorFactory } from './data-table-cell-validator-factory.js';
import { NotatableSectionEntryValidatorFactory } from './notatable-section-entry-validator-factory.js';
import { LenientValidator } from './lenient-validator.js';
import { UtilsPL2 as U } from '../utils/utils-pl2.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
export class SharedDataTableSectionEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory, objectFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
        this.objectFactory = objectFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.sharedDataTableSectionEntryAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'dT':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'dat':
                if (U.isEmpty(value)) {
                    return [new LenientValidator(value, this.builderFactory(attr))];
                }
                return Object.keys(value).map((key) => {
                    const cell = value[key];
                    if (U.isEmpty(cell)) {
                        return new LenientValidator(cell, this.builderFactory(key));
                    }
                    return this.objectFactory(cell, new DataTableCellValidatorFactory(this.builderFactory));
                });
            default:
                return new NotatableSectionEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
        }
    }
}
