import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class IsNonEmptyValidator {
    constructor(value, reasonBuilder) {
        this.value = value;
        this.reasonBuilder = reasonBuilder;
    }
    isValid() {
        const valid = !U.isEmpty(this.value);
        if (!valid) {
            this.causes = [this.reasonBuilder.build('is empty')];
        }
        return valid;
    }
    reasons() {
        return this.causes;
    }
}
