import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';

import {UserStore} from '@stores/user.store';
import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';
import {NEVER, Observable, of} from 'rxjs';

@Injectable()
export class LicenseMessageProcessor implements WebsocketMessageProcessor {
  constructor(private userStore: UserStore) {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const msg = params.msg;
    if (msg.target === '/user.store') {
      this.userStore.refreshAttributes().pipe(take(1)).subscribe();
      return of(true);
    }
    return NEVER;
  }
}
