import { all, any, hasNoBlacklistedAttsIn } from '../utils/auth-utils.js';
import { SectionAuthPolicy } from './section-auth-policy.js';
export class SharedSectionAuthPolicy extends SectionAuthPolicy {
    constructor(entries, user, readOnly, fetchEntry, rI) {
        super(entries, user, readOnly, fetchEntry, rI);
    }
    async canCreate(entry) {
        return super.canCreate(entry);
    }
    async canRead(_entry) {
        return true;
    }
    async canUpdate(entry) {
        return any(all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry), this.userIsCreatorOf(entry), this.userIsParticipantInAuthScope), all(this.tleIsNotReadOnly, hasNoBlacklistedAttsIn(entry, 'n'), this.userIsParticipantInAuthScope))();
    }
    async canDelete(entry) {
        return super.canDelete(entry);
    }
}
