import {Component, OnDestroy, HostListener, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';

import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {MatIconRegistry} from '@angular/material/icon';

import {ConnectionStore} from '@stores/connection.store';
import {EntryStore} from '@stores/entry.store';
import {ErrorReportStore} from '@stores/error-report.store';
import {RecentLessonsStore} from './stores/recent-lessons.store';
import {NotificationStore} from '@stores/notification.store';
import {UserStore} from '@stores/user.store';

import {ConnectionService} from '@services/connection.service';
import {TranslateService} from '@ngx-translate/core';
import {WebsocketService} from '@services/websocket.service';
import {PosthogApiService} from '@services/posthog-api.service';
import {ErrorReportApiService} from '@services/error-report-api.service';

import {environment} from '../environments/environment';
import {environment as prodEnv} from '../environments/environment.prod';

import {userChangeFilter} from '@utils/data-view-pipes';
import {EntryWSAPIService} from '@services/entry-ws-api.service';
import {UserApiService} from '@services/user-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  readonly collapsedStyle = {
    flex: '1 1 6px',
    boxSizing: 'border-box',
    'max-height': '6px',
    'min-height': '6px',
  };
  readonly expandedStyle = {
    flex: '1 1 300px',
    boxSizing: 'border-box',
    'max-height': '300px',
    'min-height': '300px',
  };
  consoleStyle: {[key: string]: string};

  _destroyed$ = new ReplaySubject<boolean>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private connectionStore: ConnectionStore,
    private connectionService: ConnectionService,
    private entryStore: EntryStore,
    private errorReportStore: ErrorReportStore,
    private recentLessonStore: RecentLessonsStore,
    private notificationStore: NotificationStore,
    private userStore: UserStore,
    // WARNING these services are here to initialize them
    private errorReportApiService: ErrorReportApiService,
    private websocketService: WebsocketService,
    private posthogApiService: PosthogApiService,
    private entryWSApiService: EntryWSAPIService,
    private userApiService: UserApiService,
  ) {
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${environment.host}/assets/icons/icon_bundle.svg`,
      ),
    );
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    const currentLanguage = this.translate.getBrowserLang();
    translate.use(currentLanguage);
  }

  @HostListener('window:beforeunload', ['$event'])
  saveToStorage(event: Event) {
    const isNotSynchronized = this.entryStore.saveToStorage();
    this.errorReportStore.saveToStorage();
    this.recentLessonStore.saveToStorage();
    if (isNotSynchronized && !U.isEmpty(event)) {
      event.preventDefault();
      this.notificationStore.showDialog(
        'cloudlab.closeConfirmationTitle',
        'cloudlab.closeConfirmationMessage',
      );
      return false;
    }
    this.websocketService.saveToStorage();
  }

  @HostListener('window:keyup.shift.control.esc', ['$event'])
  keyUp() {
    this.showConsole();
  }

  ngOnDestroy() {
    if (!!this.connectionStore.state()) {
      this.connectionService.disconnect();
    }
  }

  ngOnInit() {
    this.errorReportStore.loadStorage();
    this.userStore.state$
      .pipe(takeUntil(this._destroyed$), userChangeFilter())
      .subscribe((user) => {
        // It shouldn't be necessary to call saveToStorage here as a change in users results in a window reload which
        // triggers it anyway
        // this.entryStore.saveToStorage();
        if (this.consoleStyle !== this.expandedStyle) {
          this.consoleStyle =
            !U.isEmpty(user?.auth) || environment.host !== prodEnv.host
              ? this.collapsedStyle
              : null;
        }
        if (!U.isEmpty(user)) {
          this.entryStore.loadStorage(user.userId);
          this.recentLessonStore.loadFromStorage(user.userId);
        }
      });
    // this.websocketService.loadStorage();
  }

  showConsole() {
    this.consoleStyle = this.expandedStyle;
  }

  closeConsole() {
    this.consoleStyle = null;
  }

  expandConsole() {
    this.consoleStyle = this.expandedStyle;
  }
}
