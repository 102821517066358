import { EntryType } from '../models/models-pl2.js';
import { KeyUtilsPL2 as KU } from '../utils/key-utils-pl2.js';
export class AuthIndexManager {
    constructor() { }
    setIndexAttributes(entry) {
        const output = entry;
        if (this._isAuthEntry(entry)) {
            output.aSK = KU.findTLESK(entry.sK);
        }
        return output;
    }
    _isAuthEntry(e) {
        switch (true) {
            case KU.isTLE(e):
            case e.t === EntryType.LabReportUser:
            case e.t === EntryType.InvitedLabReportUser:
            case e.t === EntryType.UninvitedLabReportUser:
            case e.t === EntryType.ScopeUser:
            case e.t === EntryType.ClassPermission:
                return true;
            default:
                return false;
        }
    }
}
