import {
  PL2,
  KeyUtilsPL2 as KU,
  UtilsPL2 as U,
  EntryUtilsPL2 as EU,
} from '@common/utils/dist/index.js';
import {Store} from '@stores/abstract-store';
import {EntryState, CachedEntry, EntryStore} from '@stores/entry.store';
import {PendingOperation} from '@stores/entry-store-automaton/operation-state-machine';

export module EntryStoreUtils {
  export function formatEntry(
    entry: Partial<PL2.AnyEntry>,
  ): Partial<PL2.AnyEntry> {
    return {...entry, isA: entry.isA === undefined ? 'y' : entry.isA};
  }

  export function isKnownEntry(
    e: Partial<PL2.AnyEntry>,
    entryState: EntryState,
  ): boolean {
    const unverifiableTypes: PL2.EntryType[] = [
      PL2.EntryType.Class,
      PL2.EntryType.ClassPermission,
    ];
    if (unverifiableTypes.includes(KU.type(e))) {
      return true;
    }

    return (
      e.isA !== undefined ||
      !U.isEmpty(entryState[KU.stringFromKey(e as PL2.EntryId)])
    );
  }

  export function cachedEntry(
    store: Store<EntryState>,
    pKOrMId: string,
    sK = '',
  ): CachedEntry {
    return store.state()[pKOrMId + sK];
  }

  export function isActive(
    entryState: EntryState,
    pKOrMId: string,
    sK = '',
  ): boolean {
    return (
      !!entryState[pKOrMId + sK] &&
      !!entryState[pKOrMId + sK].e &&
      !!entryState[pKOrMId + sK].e.isA &&
      EU.isActive(entryState[pKOrMId + sK].e)
    );
  }

  export function wsCopyReceived(pK: string, entryState: EntryState): boolean {
    return !!entryState[pK] && entryState[pK].c.length > 0;
  }

  export function merge(
    target: Partial<PL2.AnyEntry>,
    changes: Partial<PL2.AnyEntry>,
  ) {
    if (!target.uAt || target.uAt < changes.uAt) {
      for (const key in changes) {
        if (['dat', 'mId', 'sK'].includes(key)) {
          continue;
        }
        // should allow empty objects, empty arrays and zero numbers
        if (
          changes[key] === null ||
          typeof changes[key] === 'undefined' ||
          changes[key] === ''
        ) {
          delete target[key];
        } else {
          target[key] = changes[key];
        }
      }
    }

    // dat should be merged even if the uAt is behind to allow for dat changes from multiple
    // users happening around the same time to be registered
    if ((changes as PL2.SharedObjectData).dat) {
      const changesDat = (changes as PL2.SharedObjectData).dat;
      const sharedEntryTarget = target as PL2.SharedObjectData;
      if (!sharedEntryTarget.dat) {
        sharedEntryTarget.dat = {};
      }
      for (const id in changesDat) {
        if (changesDat.hasOwnProperty(id)) {
          if (U.isEmpty(changesDat[id])) {
            sharedEntryTarget.dat[id] = null;
          } else if (sharedEntryTarget.dat[id] === null) {
            // null indicates an existing deletion and so any other changes should be ignored.
            // This prevents creation confirmation overwriting more recent deletes
          } else if (
            U.isEmpty(sharedEntryTarget.dat[id]) ||
            U.isEmpty(sharedEntryTarget.dat[id].ts) ||
            changesDat[id].ts > sharedEntryTarget.dat[id].ts
          ) {
            sharedEntryTarget.dat[id] = changesDat[id];
          }
        }
      }
    }
  }

  export function isOffline(store: EntryStore, user: PL2.EntryId): boolean {
    const pK = KU.stringFromKey(user);
    const pendingCEs = store.subState([
      PendingOperation.Create,
      PendingOperation.CreateFailed,
      PendingOperation.DelayCreateContainer,
    ]);
    return !!pendingCEs[pK];
  }

  export function isEntrySynchronized(cE: CachedEntry): boolean {
    if (U.isEmpty(cE)) {
      console.error(`tried to copy non-existent entry? ${JSON.stringify(cE)}`);
      return false; // ?
    }
    return (
      cE._pO === PendingOperation.None ||
      cE._pO === PendingOperation.DelayCreateContainer
    );
  }

  export function isEntryContainerSynchronized(
    parent: PL2.EntryId,
    entryState: EntryState,
  ): boolean {
    const pK = KU.stringFromKey(parent);
    const cE = entryState[pK];
    const children = Object.keys(entryState).filter(
      (cPK) => cPK.startsWith(pK) && cPK !== pK,
    );
    return (
      isEntrySynchronized(cE) &&
      children.every((cPK) => isEntrySynchronized(entryState[cPK]))
    );
  }

  export function isEntryStateSynchronized(entryState: EntryState): boolean {
    return Object.values(entryState).every((e) => isEntrySynchronized(e));
  }
}
